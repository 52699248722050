// @flow
import type { Curriculum as CurriculumType } from "redux/api-types/Curriculum.js";
import type { CurriculumRecordI } from "Redux/modules/certifications/CurriculumRecord";
import CurriculumRecord from "./CurriculumRecord";

type CurriculumReturn = {
  included: Array<mixed>,
};
export const parseCourseCurriculum = (
  curricula: CurriculumType
): CurriculumRecordI => {
  const {
    id,
    attributes: { name, abbreviation, prerequisites },
  } = curricula;
  const record = new CurriculumRecord({
    id,
    name,
    abbreviation,
    prerequisites,
  });
  return record;
};

export const parseCurriculum = (
  included: Array<CurriculumType>
): CurriculumReturn => {
  let curriculum = {};

  included.forEach(function (curricula: CurriculumType) {
    const record = parseCourseCurriculum(curricula);
    const { id } = curricula;
    curriculum[id] = record;
  });

  return curriculum;
};
