// @flow
import type { InstructorAgreement } from "../../api-types/InstructorAgreement";
import type { InstructorAgreementRecordI } from "./InstructorAgreementRecord";
import InstructorAgreementRecord from "./InstructorAgreementRecord";

export type ParseAgreementsReturn = {
  [string]: InstructorAgreementRecordI,
};

export const parseAgreements = (
  data: Array<InstructorAgreement>
): ParseAgreementsReturn => {
  let agreements = {};

  data.forEach(function ({ id, attributes }: InstructorAgreement) {
    const displayName = attributes.documentType
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, function (str: string): string {
        return str.toUpperCase();
      });

    const agreementRecord = new InstructorAgreementRecord({
      id,
      displayName,
      ...attributes,
    });

    agreements[id] = agreementRecord;
  });

  return agreements;
};
