// @flow
import { handleActions } from "redux-actions";
import { Intent } from "@blueprintjs/core";

import { APP_NAMESPACE } from "shared/constants/application";

const namespace: string = `${APP_NAMESPACE}/toasts`;

// Actions
export const SHOW_TOAST: string = `${namespace}/SHOW_TOAST`;

// Action Creators
export type showToastAction = {
  type: string,
  payload: {
    message: string,
    intent: string,
  },
  meta: {
    timestamp: Date,
  },
};

export const showToast = (
  message: string,
  intent: string = Intent.NONE
): showToastAction => {
  return {
    type: SHOW_TOAST,
    payload: {
      message,
      intent,
    },
    meta: {
      timestamp: Date.now(),
    },
  };
};

// Initial State
export type State = {
  message: ?string,
  intent: ?string,
  timestamp: ?Date,
};

const initialState: State = {
  message: null,
  intent: null,
  timestamp: null,
};

const toasts = handleActions(
  {
    [SHOW_TOAST]: (state: State, action: showToastAction): State => {
      const {
        payload: { message, intent },
        meta: { timestamp },
      } = action;
      return {
        ...state,
        message,
        intent,
        timestamp,
      };
    },
  },
  initialState
);

export default toasts;
