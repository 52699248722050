// @flow
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const ACCESS_TOKEN_NAME = "uscca_access";

type LDUser = {
  key?: string,
  anonymous?: boolean,
};

export default function getLaunchDarklyUser(): LDUser {
  const accessToken = Cookies.get(ACCESS_TOKEN_NAME);
  const user = accessToken ? jwtDecode(accessToken) : undefined;

  return user?.userId ? { key: user.userId } : { anonymous: true };
}
