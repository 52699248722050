import { Record } from "immutable";

const ItemRecord = Record({
  id: "",
  name: "",
  category: "",
  hasQuantity: false,
});

export default ItemRecord;
