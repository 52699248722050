// @flow
export const round5 = [
  {
    courseId: "fbf8f024-de06-11ec-8adc-0242ac12000e",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-bowling-green-KY-6-23-2022?link=training_platform",
  },
  {
    courseId: "4e294010-de07-11ec-9431-0242ac12000e",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-clarksville-IN-6-22-2022?link=training_platform",
  },
  {
    courseId: "6b335a1a-eb5e-11ec-baa4-02420a00017a",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-tullahoma-TN-7-7-2022?link=training_platform",
  },
  {
    courseId: "b49e001e-eb5f-11ec-8445-0242ac12000b",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-oklahoma-city-OK-7-13-2022?link=training_platform",
  },
  {
    courseId: "56b0b96e-eb60-11ec-8b7f-0242ac120006",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-murietta-CA-7-20-2022?link=training_platform",
  },
  {
    courseId: "cabf8772-eb60-11ec-a279-0242ac12000b",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-owings-mills-MD-7-21-2022link=training_platform",
  },
];
