// @flow
import { handleActions } from "redux-actions";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import { NONE, PENDING, SUCCESS, ERROR } from "shared/constants/status";
import type { callApiReturnType } from "redux/middleware/api";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";

// Constants
const namespace: string = `${APP_NAMESPACE}/studentRequestCourseOccurrence`;
export const STUDENT_REQUEST_COURSE_OCCURRENCE_FORM: string = `${namespace}Form`;

// Actions
export const OPEN_DIALOG = `${namespace}/DIALOG/OPEN`;
export const CLOSE_DIALOG = `${namespace}/DIALOG/CLOSE`;
export const SUBMIT_REQUEST = `${namespace}/SUBMIT/REQUEST`;
export const REQUEST_SUCCESS = `${namespace}/SUBMIT/SUCCESS`;
export const REQUEST_ERROR = `${namespace}/SUBMIT/ERROR`;
export const CLEAR = `${namespace}/CLEAR`;

// Action Creator Interfaces
type ReceiveSubmitReturn = {
  type: string,
  meta: {
    receivedAt: number,
  },
};

// Action Creators
type OpenDialogReturn = {
  payload: {
    courseId: string,
  },
} & simpleReduxAction;

export const openDialog = (courseId: string): OpenDialogReturn => ({
  type: OPEN_DIALOG,
  payload: {
    courseId,
  },
});

export const closeDialog = (): simpleReduxAction => ({
  type: CLOSE_DIALOG,
});

export const requestSubmit = (): simpleReduxAction => ({
  type: SUBMIT_REQUEST,
});

export const receiveSubmit = (): ReceiveSubmitReturn => ({
  type: REQUEST_SUCCESS,
  meta: {
    receivedAt: Date.now(),
  },
});

export const submitError = (error: apiErrorParams): apiErrorAction => ({
  type: REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async Actions
export const requestCourseOccurrence = (
  courseId: string,
  email: string,
  phone: string,
  comment: string,
  consentedToContact: boolean
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: `/api/training/courses/${courseId}/requests`,
    options: {
      email,
      phone,
      comment,
      consentedToContact,
    },
    actions: {
      request: requestSubmit,
      success: receiveSubmit,
      failure: submitError,
    },
    toasts: {
      success: "Request Submitted",
      failure: "There was a problem posting your request",
    },
  },
});

// Initial State
export type State = {
  isOpen: boolean,
  courseId: string,
  status: string,
  errorMessage: ?string,
  errors: apiErrorReturn,
};

const initialState: State = {
  isOpen: false,
  courseId: "",
  status: NONE,
  errorMessage: "",
  errors: [],
};

// Reducer
const studentRequestCourseOccurrence = handleActions(
  {
    [OPEN_DIALOG]: (state: State, action: OpenDialogReturn): State => {
      const courseId = action.payload.courseId;
      return {
        ...state,
        isOpen: true,
        courseId,
      };
    },
    [CLOSE_DIALOG]: (state: State, action: simpleReduxAction): State => {
      return initialState;
    },
    [SUBMIT_REQUEST]: (state: State, action: simpleReduxAction): State => {
      return {
        ...state,
        status: PENDING,
        errorMessage: "",
        errors: [],
      };
    },
    [REQUEST_SUCCESS]: (state: State, action: ReceiveResultsReturn): State => {
      return {
        ...state,
        status: SUCCESS,
        isSubmitting: false,
      };
    },
    [REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        isSubmitting: false,
        isError: true,
        errorMessage,
        errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => {
      return initialState;
    },
  },
  initialState
);

export default studentRequestCourseOccurrence;
