// @flow
import type { Node } from "react";
import React from "react";
import { useEffect } from "react";
import "@delta-defense/universal-components/components/authenticated-footer/build/authenticated-footer.css";

const AuthenticatedFooter = (): Node => {
  useEffect(() => {
    import(
      "@delta-defense/universal-components/components/authenticated-footer/build/authenticated-footer"
    );
  }, []);

  return (
    // eslint-disable-next-line react/self-closing-comp
    <authenticated-footer></authenticated-footer>
  );
};

export default AuthenticatedFooter;
