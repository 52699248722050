// @flow
import React from "react";
import type { Node } from "react";

import styles from "./FormFieldError.scss";

type Props = {
  children: Node,
};

const FormFieldError = ({ children }: Props): Node => (
  <div className={styles.error}>{children}</div>
);

FormFieldError.defaultProps = {
  children: "invalid",
};

export default FormFieldError;
