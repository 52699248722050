// @flow
import { Record } from "immutable";

export type InstructorAgreementI = {
  id: string,
  documentType: string,
  documentUrl: string,
  displayName: string,
  updatedAt: string,
  createdAt: string,
  year: string,
};

const InstructorAgreement = Record({
  id: "",
  documentType: "",
  documentUrl: "",
  displayName: "",
  updatedAt: "",
  createdAt: "",
  year: "",
});

export default InstructorAgreement;
