// @flow
import { APP_NAMESPACE } from "shared/constants/application";

export const namespace: string = `${APP_NAMESPACE}/studentDrawerActions`;

// Actions
export const OPEN: string = `${namespace}/OPEN`;
export const CLOSE: string = `${namespace}/CLOSE`;

export const SELECT_TAB: string = `${namespace}/TABS/SELECT`;

export const FETCH_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const FETCH_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const FETCH_ERROR: string = `${namespace}/FETCH/ERROR`;

export const UPDATE_REQUEST: string = `${namespace}/UPDATE/REQUEST`;
export const UPDATE_SUCCESS: string = `${namespace}/UPDATE/SUCCESS`;
export const UPDATE_ERROR: string = `${namespace}/UPDATE/ERROR`;
