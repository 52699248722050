export const colors = {
  SNOW: "#fff",
  TOTORO: "#ededed",
  MIST: "#e0e0e0",
  STONE: "#cbced1",
  BILLOW: "#767676",
  ABYSS: "#24292e",
  SOOT: "#121212",
  SOSUKE: "#ffcd00",
  SOSUKE_DARK: "#e2b500",
  YUPA: "#e2b500",
  ALGAE: "#3c9f84",
  LUPIN: "#456f6c",
  LUPIN_FADE: "#eaeced",
  SOPHIE: "#3d6980",
  SOPHIE_DARK: "#2d4d5d",
};
