// @flow
import { handleActions } from "redux-actions";
import { LOCATION_CHANGE } from "connected-react-router";
import type { Node } from "react";

import { APP_NAMESPACE } from "shared/constants/application";
import type { simpleReduxAction } from "shared/constants/flowTypes";
import { createCourseUrl } from "shared/utilities/course";
import type { receiveCourseReturnType } from "../course/course";
import { COURSE_REQUEST_SUCCESS } from "../course/course";
import type { ReceiveInstructorReturnType } from "../instructor/instructor";
import { INSTRUCTOR_REQUEST_SUCCESS } from "../instructor/instructor";

const namespace: string = `${APP_NAMESPACE}/app`;

// Actions
export const SET_PAGE_TITLE: string = `${namespace}/PAGE_TITLE/SET`;
export const OPEN_DIALOG: string = `${namespace}/DIALOG/OPEN`;
export const CLOSE_DIALOG: string = `${namespace}/DIALOG/CLOSE`;
export const OPEN_CONFIRMATION_MODAL: string = `${namespace}/CONFIRMATION_MODAL/OPEN`;
export const CLOSE_CONFIRMATION_MODAL: string = `${namespace}/CONFIRMATION_MODAL/CLOSE`;
export const TOGGLE_SUBMENU: string = `${namespace}/SUBMENU/TOGGLE`;
export const SET_SHOW_HEADER_SUBMENU: string = `${namespace}/HEADER_SUBMENU/SHOW/SET`;

// Action Flow types
type setPageTitleReturnType = {
  type: string,
  payload: {
    pageTitle: string,
  },
};

type OpenDialogAction = {
  type: string,
  payload: {
    dialog: string,
  },
};

export type OpenConfirmationModalParams = {
  intent?: string,
  title: string,
  message: Node,
  onConfirm: () => mixed,
  onCancel?: () => mixed,
  onLogout?: boolean,
  confirmButtonLabel?: string,
  cancelButtonLabel?: string,
};

type OpenConfirmationModalAction = {
  type: string,
  payload: {
    title: string,
    message: string,
    onConfirm: () => mixed,
    onCancel: () => mixed,
    onLogout?: boolean,
    confirmButtonLabel: string,
    cancelButtonLabel: string,
    intent: string,
  },
};

type SetShowHeaderSubmenuAction = {
  payload: {
    show: boolean,
  },
} & simpleReduxAction;

// Action Creators
export const setPageTitle = (pageTitle: string): setPageTitleReturnType => ({
  type: SET_PAGE_TITLE,
  payload: {
    pageTitle,
  },
});

export const openDialog = (dialog: string): OpenDialogAction => ({
  type: OPEN_DIALOG,
  payload: {
    dialog,
  },
});

export const closeDialog = (): simpleReduxAction => ({
  type: CLOSE_DIALOG,
});

export const openConfirmationModal = ({
  intent,
  title,
  message,
  onConfirm,
  onCancel,
  onLogout,
  confirmButtonLabel,
  cancelButtonLabel,
}: OpenConfirmationModalParams): OpenConfirmationModalAction => ({
  type: OPEN_CONFIRMATION_MODAL,
  payload: {
    intent,
    title,
    message,
    onConfirm,
    onCancel,
    onLogout,
    confirmButtonLabel,
    cancelButtonLabel,
  },
});

export const closeConfirmationModal = (): simpleReduxAction => ({
  type: CLOSE_CONFIRMATION_MODAL,
});

export const toggleSubmenu = (): simpleReduxAction => ({
  type: TOGGLE_SUBMENU,
});

export const setShowHeaderSubmenu = (
  show: boolean
): SetShowHeaderSubmenuAction => ({
  type: SET_SHOW_HEADER_SUBMENU,
  payload: {
    show,
  },
});

// Initial State
export type State = {
  pageTitle: string,
  canonicalUrl: string,
  openDialog: string,
  confirmationModal: {
    isOpen: boolean,
    title: string,
    message: string,
    onConfirm: () => mixed,
    onCancel: () => mixed,
    onLogout: boolean,
    confirmButtonLabel: string,
    cancelButtonLabel: string,
    intent: string,
  },
  isSubmenuOpen: boolean,
  showHeaderSubmenu: boolean,
};

// Initial State
const initialState: State = {
  pageTitle: "USCCA | Training",
  canonicalUrl: "",
  openDialog: "",
  confirmationModal: {
    isOpen: false,
    title: "",
    message: "",
    onConfirm: (): null => null,
    onCancel: (): null => null,
    onLogout: false,
    confirmButtonLabel: "OK",
    cancelButtonLabel: "Cancel",
    intent: "",
  },
  isSubmenuOpen: false,
  showHeaderSubmenu: true,
};

const { APP_URL } = process.env;
const origin = APP_URL || "https://training.usconcealedcarry.com";

// Reducer
const app = handleActions(
  {
    [SET_PAGE_TITLE]: (
      state: State,
      { payload: { pageTitle } }: setPageTitleReturnType
    ): State => ({
      ...state,
      pageTitle,
    }),
    [OPEN_DIALOG]: (
      state: State,
      { payload: { dialog } }: OpenDialogAction
    ): State => ({
      ...state,
      openDialog: dialog,
    }),
    [CLOSE_DIALOG]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      openDialog: "",
    }),
    [COURSE_REQUEST_SUCCESS]: (
      state: State,
      {
        payload: {
          data: {
            attributes: { name },
            id: id,
          },
        },
      }: receiveCourseReturnType
    ): State => {
      const canonicalCourseUrl = `${origin}${createCourseUrl(id, name)}`;
      return {
        ...state,
        pageTitle: `${name} | USCCA Training`,
        canonicalUrl: canonicalCourseUrl,
      };
    },
    [INSTRUCTOR_REQUEST_SUCCESS]: (
      state: State,
      {
        payload: {
          instructor: {
            data: {
              attributes: { companyName, firstName, lastName },
              id: id,
            },
          },
        },
      }: ReceiveInstructorReturnType
    ): State => ({
      ...state,
      pageTitle: `${
        companyName ? companyName : `${firstName} ${lastName}`
      } | USCCA Training`,
      canonicalUrl: `${origin}/instructor/${id}`,
    }),
    [OPEN_CONFIRMATION_MODAL]: (
      state: State,
      {
        payload: {
          title,
          message,
          onConfirm,
          onCancel,
          onLogout,
          confirmButtonLabel,
          cancelButtonLabel,
          intent,
        },
      }: OpenConfirmationModalAction
    ): State => ({
      ...state,
      confirmationModal: {
        isOpen: true,
        title,
        message,
        onConfirm,
        onCancel,
        onLogout,
        confirmButtonLabel,
        cancelButtonLabel,
        intent,
      },
    }),
    [CLOSE_CONFIRMATION_MODAL]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      confirmationModal: {
        isOpen: false,
        title: "",
        message: "",
        onConfirm: (): null => null,
        onCancel: (): null => null,
        onLogout: false,
        confirmButtonLabel: "",
        cancelButtonLabel: "",
        intent: "",
      },
    }),
    [TOGGLE_SUBMENU]: (state: State, action: simpleReduxAction): State => {
      const { isSubmenuOpen } = state;
      return {
        ...state,
        isSubmenuOpen: !isSubmenuOpen,
      };
    },
    [SET_SHOW_HEADER_SUBMENU]: (
      state: State,
      action: SetShowHeaderSubmenuAction
    ): State => ({
      ...state,
      showHeaderSubmenu: action.payload.show,
    }),
    [LOCATION_CHANGE]: (state: State, action: simpleReduxAction): State => ({
      ...initialState,
    }),
  },
  initialState
);

export default app;
