// @flow
import { Record } from "immutable";

export type InstructorRecordInterface = {
  guid: string,
  firstName: string,
  lastName: string,
  email: string,
  number: string,
  link: string,
  averageReviewRating: number,
  totalReviewCount: number,
};

const InstructorRecord = Record({
  guid: "",
  firstName: "",
  lastName: "",
  email: "",
  number: "",
  link: "",
  averageReviewRating: 0,
  totalReviewCount: 0,
});

export default InstructorRecord;
