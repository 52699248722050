// @flow
import { APP_NAMESPACE } from "shared/constants/application";

const namespace: string = `${APP_NAMESPACE}/instructorAgreements`;

export const GET_AGREEMENTS_REQUEST: string = `${namespace}/AGREEMENTS/FETCH/REQUEST`;
export const GET_AGREEMENTS_REQUEST_SUCCESS: string = `${namespace}/AGREEMENTS/FETCH/SUCCESS`;
export const GET_AGREEMENTS_REQUEST_ERROR: string = `${namespace}/AGREEMENTS/FETCH/ERROR`;
export const ACCEPT_AGREEMENTS_REQUEST: string = `${namespace}/ACCEPT/FETCH/REQUEST`;
export const ACCEPT_AGREEMENTS_REQUEST_SUCCESS: string = `${namespace}/ACCEPT/FETCH/SUCCESS`;
export const ACCEPT_AGREEMENTS_REQUEST_ERROR: string = `${namespace}/ACCEPT/FETCH/ERROR`;
