// @flow
import { Record } from "immutable";

export type ReviewRecordI = {
  comment: string,
  createdAt: number,
  firstName: string,
  lastNameInitial: string,
  occurrenceId: string,
  rating: number,
  replyId: string,
  reviewId: string,
};

const ReviewRecord = Record({
  comment: "",
  createdAt: 0,
  firstName: "",
  lastNameInitial: "",
  occurrenceId: "",
  rating: 0,
  replyId: "",
  reviewId: "",
});

export default ReviewRecord;
