// @flow
import { parseISO, format } from "date-fns";

import type { CourseOccurrence } from "redux/api-types/CourseOccurrence";
import type { OccurrenceRecordI } from "./OccurrenceRecord";
import OccurrenceRecord from "./OccurrenceRecord";
import type { DateRecordInterface } from "./DateRecord";
import DateRecord from "./DateRecord";
import { STATUS } from "./status";

const { NONE, UPCOMING, NO_STUDENTS, GRADED, TO_BE_GRADED } = STATUS;

const getStatus = (
  canHaveRegistrants: boolean,
  isResultsSubmitted: boolean,
  isStudentsRegistered: boolean,
  canGrade: boolean
): string => {
  switch (true) {
    case !canHaveRegistrants:
      return NONE;
    case isResultsSubmitted:
      return GRADED;
    case !isStudentsRegistered:
      return NO_STUDENTS;
    case canGrade:
      return TO_BE_GRADED;
    default:
      return UPCOMING;
  }
};

type OccurrencesReturn = {
  occurrences: {
    [string]: OccurrenceRecordI,
  },
  dates: {
    [string]: DateRecordInterface,
  },
};

export const parseOccurrences = (
  data: Array<CourseOccurrence>
): OccurrencesReturn => {
  let occurrences = {};
  let dates = {};
  const mmFormat = "MM";

  data.forEach(function (occurrence: CourseOccurrence) {
    const {
      id,
      attributes: {
        entityId: courseId,
        name: courseName,
        dates: occurrenceDates,
        canHaveRegistrants,
        canSubmitResults,
        resultsSubmittedAt: dateSubmitted,
        numberOfRegistrants,
        capacity,
      },
    } = occurrence;
    const startTime = occurrenceDates[0].startTime;
    const courseLink = `/course/${courseId}`;
    const courseEditLink = `/edit-course/${courseId}`;
    const manageCourseLink = `/manage-course/${courseId}`;
    const courseRegistrantsLink = `/roster/${id}`;
    const canGrade = canSubmitResults && !!numberOfRegistrants;
    const isStudentsRegistered = numberOfRegistrants > 0;
    const isResultsSubmitted = !!dateSubmitted;
    const openSeats = capacity - numberOfRegistrants;
    const groupByMonth = Number(format(parseISO(startTime), mmFormat));
    const status = getStatus(
      canHaveRegistrants,
      isResultsSubmitted,
      isStudentsRegistered,
      canGrade
    );
    // dates are not guaranteed to come in chronological order
    occurrenceDates.sort();

    let dateIds = [];
    for (let i = 0; i < occurrenceDates.length; i++) {
      const date = occurrenceDates[i];
      const { guid: id } = date;
      dateIds.push(id);
      const DateR = new DateRecord({
        id,
        ...date,
      });
      dates[id] = DateR;
    }

    const Record = new OccurrenceRecord({
      id,
      courseId,
      courseName,
      canHaveRegistrants,
      canSubmitResults,
      isResultsSubmitted,
      dateSubmitted,
      numberOfRegistrants,
      courseLink,
      courseEditLink,
      manageCourseLink,
      courseRegistrantsLink,
      status,
      capacity,
      openSeats,
      groupByMonth,
      dateIds,
    });

    occurrences[id] = Record;
  });

  return { occurrences, dates };
};
