// @flow
import type { Saga } from "redux-saga";
import { handleActions } from "redux-actions";
import { put, take, select } from "redux-saga/effects";
import { push } from "connected-react-router";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { routerLocationSelector } from "redux/middleware/watchRouter";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import { createInstructorUrl } from "redux/modules/course/parsers/instructor";
import type { Instructor as InstructorType } from "redux/api-types/Instructor";
import {
  getBlankRecord,
  parseInstructor,
  parseInstructorIncluded,
} from "./parsers";
import type { InstructorRecordType } from "./InstructorRecord";
import type { Image as ImageType } from "redux/api-types/Image";

// actions
const namespace: string = `${APP_NAMESPACE}/instructor`;
export const INSTRUCTOR_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const INSTRUCTOR_REQUEST_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const INSTRUCTOR_REQUEST_ERROR: string = `${namespace}/FETCH/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Flow Types
type RequestInstructorReturnType = {
  type: string,
  payload: {
    uuid: string,
  },
};

export type InstructorReturn = {
  data: InstructorType,
  included: ImageType,
};

export type ReceiveInstructorReturnType = {
  type: string,
  payload: {
    instructor: InstructorReturn,
  },
  meta: {
    receivedAt: number,
  },
};

// action creators
export const requestInstructor = (
  uuid: string
): RequestInstructorReturnType => ({
  type: INSTRUCTOR_REQUEST,
  payload: {
    uuid,
  },
});

export const receiveInstructor = (
  json: InstructorApiReturn
): InstructorReturn => ({
  type: INSTRUCTOR_REQUEST_SUCCESS,
  payload: {
    instructor: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const instructorRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: INSTRUCTOR_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async actions
export const getInstructor = (id: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/instructors/${id}?include=images`,
    actions: {
      request: requestInstructor,
      success: receiveInstructor,
      failure: instructorRequestError,
    },
  },
});

// Sagas
export function* redirectDefunctCRMIdBasedInstructorRoute(): Saga<void> {
  for (;;) {
    const action = yield take(INSTRUCTOR_REQUEST_SUCCESS);
    const { pathname, search } = yield select(routerLocationSelector);
    const {
      data: { id },
    } = action.payload.instructor;
    const url = createInstructorUrl(id);
    if (url !== pathname) {
      const forwardTo = url + search;
      yield put(push(forwardTo));
    }
  }
}

// Initial State
export type State = {
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  receivedAt: number,
  instructorRecord: ?InstructorRecordType,
};

const blankRecord = getBlankRecord();

const initialState: State = {
  status: NONE,
  errorMessage: "",
  errors: [],
  receivedAt: 0,
  instructorRecord: blankRecord,
};

const instructor = handleActions(
  {
    [INSTRUCTOR_REQUEST]: (
      state: State,
      action: RequestInstructorReturnType
    ): State => ({
      ...state,
      status: LOADING,
      errorMessage: "",
      errors: [],
      instructorRecord: blankRecord,
    }),
    [INSTRUCTOR_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveInstructorReturnType
    ): State => {
      const {
        payload: {
          instructor: { data, included },
        },
        meta: { receivedAt },
      } = action;
      const instructor = parseInstructor(data);
      const instructorImage = included ? parseInstructorIncluded(included) : {};
      const instructorRecord = instructor.set(
        "imageUrl",
        instructorImage.imageUrl
      );
      return {
        ...state,
        status: LOADED,
        receivedAt,
        instructorRecord: instructorRecord,
      };
    },
    [INSTRUCTOR_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const {
        payload: { errorMessage, errors },
      } = action;
      return {
        ...state,
        status: ERROR,
        errorMessage,
        errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default instructor;
