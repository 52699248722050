// @flow
import type { Saga } from "redux-saga";
import { fork, take } from "redux-saga/effects";

import enterEmailJourneySaga from "redux/middleware/enterEmailJourneySaga";
import { POST_REVIEW_SUCCESS } from "./reviewOccurrence";

export function* onReviewCourseOccurrenceSuccess(): Saga<void> {
  for (;;) {
    yield take(POST_REVIEW_SUCCESS);
    yield fork(enterEmailJourneySaga);
  }
}
