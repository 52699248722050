// @flow
import ResourceRecord from "./ResourceRecord";
import type { ResourceRecordType } from "./ResourceRecord";
import type { Result } from "./types";

type ParseResultReturn = {
  resource: ResourceRecordType,
};

export const parseResult = (result: Array<Result>): ParseResultReturn => {
  const {
    _id: id,
    stateName,
    stateAbbreviation: { usStateName: usStateAbbreviation },
    postalCode,
    backgroundImageUrls,
    statesHonoredCount,
    reciprocatingStatesCount,
    minimumAgeCount,
    yearsPermitValidCount,
  } = result[0];

  const resource = new ResourceRecord({
    id,
    stateName,
    usStateAbbreviation,
    postalCode,
    backgroundImageUrls,
    statesHonoredCount,
    reciprocatingStatesCount,
    minimumAgeCount,
    yearsPermitValidCount,
  });

  return {
    resource,
  };
};
