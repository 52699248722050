// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".shared-layout-Footer-UnauthenticatedFooter-module__unauthenticatedFooter--ySZIt footer{margin-top:0}.shared-layout-Footer-UnauthenticatedFooter-module__unauthenticatedFooter--ySZIt .footer__non-member-only{display:none}", "",{"version":3,"sources":["webpack://./shared/layout/Footer/UnauthenticatedFooter.module.scss"],"names":[],"mappings":"AACE,wFACE,YAAA,CAIF,0GAEE,YAAA","sourcesContent":[".unauthenticatedFooter {\n  footer {\n    margin-top: 0;\n  }\n\n  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */\n  :global(.footer__non-member-only) {\n    // we don't use this footer when users are authenticated\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unauthenticatedFooter": "shared-layout-Footer-UnauthenticatedFooter-module__unauthenticatedFooter--ySZIt"
};
export default ___CSS_LOADER_EXPORT___;
