// @flow
import { fork, put } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import queryString from "query-string";
import { destroy } from "redux-form";
import { push } from "connected-react-router";

import { getJwtUser } from "shared/utilities/authCookie";
import { setPageTitle } from "redux/modules/app/app";
import {
  getCertifications,
  clear as clearCertifications,
} from "redux/modules/certifications/certifications";
import {
  getStateResources,
  clear as clearStateResource,
} from "redux/modules/stateResources/stateResources";
import { handleConnectResponse } from "redux/modules/connect";
import { getCourse, clear as clearCourse } from "redux/modules/course/course";
import {
  getCourseItems,
  clearCourseItems,
} from "redux/modules/courseItems/courseItems";
import {
  getCourseOccurrences as getPublicCourseOccurrences,
  clear as clearPublicOccurrences,
} from "redux/modules/courseOccurrences/courseOccurrences";
import { getLatLng } from "redux/modules/courseSearchBy/courseSearchByActionCreators";
import {
  setMode,
  setId,
  getCurricula,
  getItems,
  clear as clearCreateCourse,
} from "redux/modules/createCourse/createCourseActionCreators";
import {
  CREATE_COURSE_FORM,
  EDIT,
  CREATE,
} from "redux/modules/createCourse/constants";
import {
  getCurricula as getAllCurricula,
  clear as clearCurricula,
} from "redux/modules/curricula/curricula";
import {
  getCourse as getEditCourse,
  clear as clearEditCourse,
} from "redux/modules/editCourse/editCourse";
import { clear as clearEmailCourse } from "redux/modules/emailCourse/emailCourse";
import {
  getInstructor,
  clear as clearInstructor,
} from "redux/modules/instructor/instructor";
import {
  getAccount,
  clear as clearInstructorAccount,
} from "redux/modules/instructorAccount/instructorAccount";
import {
  getCourses as getInstructorCourses,
  clear as clearInstructorCourses,
} from "redux/modules/instructorCourses/instructorCourses";
import {
  getOccurrencesCall,
  clear as clearInstructorCourseOccurrences,
} from "redux/modules/instructorCourseOccurrences/instructorCourseOccurrences";
import {
  getInstructorResources,
  clear as clearInstructorResources,
} from "redux/modules/instructorResources/instructorResources";
import {
  COURSE,
  types as resourceTypes,
} from "redux/modules/instructorResources/constants";
import {
  getCourse as getManagedCourse,
  clear as clearManagedCourse,
} from "redux/modules/manageCourse/manageCourse";
import {
  getCourses as getManagedCourses,
  clear as clearManagedCourses,
} from "redux/modules/manageCourses/manageCourses";
import {
  getCourseOccurrencesCall,
  clear as clearManagedCourseOccurrences,
} from "redux/modules/manageCourseOccurrences/manageCourseOccurrences";
import { clear as clearManagedOccurrence } from "redux/modules/manageCourseOccurrence/manageCourseOccurrence";
import { FUTURE } from "redux/modules/instructorCourseOccurrences/types";
import {
  getOccurrence,
  clear as clearOccurrence,
} from "redux/modules/occurrence/occurrence";
import { clear as clearRegistrant } from "redux/modules/registrant/registrant";
import {
  getRegistrants,
  clear as clearRegistrants,
} from "redux/modules/registrants/registrants";
import {
  getRegistrantReview,
  clear as clearRegistrantReview,
} from "redux/modules/reviewOccurrence/reviewOccurrence";

const { userId = "" } = getJwtUser();

export function* cleanupRouteTriggers(pathname: string): Saga<void> {
  const pathnames = pathname.split("/");
  pathnames.shift();
  const basePath = pathnames[0];
  // supports url whether it ends in `/` or not, get rid of empty array vals in pathnames
  if (!pathnames[pathnames.length - 1]) {
    pathnames.pop();
  }

  let id;

  switch (basePath) {
    case "certifications":
      yield put(clearCertifications());
      break;
    case "class":
      id = pathnames.pop();
      if (id) {
        yield put(clearCourse());
        yield put(clearCourseItems());
        yield put(clearPublicOccurrences());
        yield put(clearEmailCourse());
      }
      break;
    case "classes":
      id = pathnames[1];
      if (id) {
        yield put(clearStateResource());
      }
      break;
    case "create-course":
      yield put(destroy(CREATE_COURSE_FORM));
      yield put(clearCreateCourse());
      break;
    case "dashboard":
      switch (true) {
        case pathnames.length === 3 && pathnames[1] === "instructor-resources":
          yield put(clearCurricula());
          yield put(clearInstructorResources());
          break;
      }
      break;
    case "edit-course":
      yield put(destroy(CREATE_COURSE_FORM));
      yield put(clearCreateCourse());
      yield put(clearEditCourse());
      break;
    case "instructor":
      yield put(clearInstructor());
      yield put(clearCertifications());
      yield put(clearInstructorCourses());
      break;
    case "instructor-account":
      yield put(clearInstructorAccount());
      break;
    case "manage-course":
      id = pathnames[1];
      if (id) {
        yield put(clearManagedCourse());
        yield put(clearManagedCourseOccurrences());
        yield put(clearManagedOccurrence());
      }
      break;
    case "my-classes":
      yield put(clearInstructorCourseOccurrences());
      break;
    case "my-courses":
      yield put(clearManagedCourses());
      break;
    case "review-class":
      yield put(clearOccurrence());
      yield put(clearRegistrantReview());
      break;
    case "roster":
      yield put(clearRegistrants());
      yield put(clearRegistrant());
      yield put(clearOccurrence());
      break;
    case "submit-results":
      yield put(clearOccurrence());
      yield put(clearRegistrants());
      break;
  }
}

export default function* routeTriggers(
  pathname: string,
  search: string
): Saga<void> {
  const parsedHash = queryString.parse(search);
  let pathnames = pathname.split("/");
  pathnames.shift();
  const basePath = pathnames[0];
  let id;
  let pageTitle;
  let { page = 1, type = FUTURE, resultsperpage } = parsedHash;

  // supports url whether it ends in `/` or not, get rid of empty array vals in pathnames
  if (!pathnames[pathnames.length - 1]) {
    pathnames.pop();
  }

  switch (basePath) {
    case "certifications":
      yield put(getCertifications());
      pageTitle = "Certifications";
      break;
    case "class":
      id = pathnames.pop();
      if (id) {
        yield put(getCourse(id));
        yield put(getCourseItems());
        yield put(getPublicCourseOccurrences(id));
      }
      pageTitle = "Classes";
      break;
    case "classes":
      // state page
      id = pathnames[1];
      if (pathnames.length === 2 && id) {
        yield put(getStateResources(id));
      }
      break;
    case "connect":
      yield fork(handleConnectResponse, parsedHash);
      break;
    case "create-course":
      yield put(setMode(CREATE));
      yield put(getCurricula());
      yield put(getItems());
      pageTitle = "Create Course";
      break;
    case "dashboard":
      pageTitle = "Dashboard";
      switch (true) {
        case pathnames.length === 3 && pathnames[1] === "instructor-resources":
          pageTitle = "Instructor Resources";
          const type = pathnames[2];
          if (resourceTypes.includes(type)) {
            // load curriculum instructor has access to if course resource
            if (type === COURSE) {
              yield put(
                getAllCurricula({
                  instructorId: userId,
                  key: "abbreviation",
                })
              );
            }
            yield put(getInstructorResources(type));
          } else {
            yield put(push("/dashboard/instructor-resources"));
          }
          break;
      }
      break;
    case "edit-course":
      id = pathnames[1];
      if (id) {
        yield put(setMode(EDIT));
        yield put(setId(id));
        yield put(getEditCourse(id));
        yield put(getCurricula());
        yield put(getItems());
      }
      pageTitle = "Edit Course";
      break;
    case "instructor":
      id = pathnames[1];
      if (id) {
        yield put(getInstructor(id));
        yield put(getCertifications(id, "active"));
        yield put(getInstructorCourses(id));
      }
      pageTitle = "Instructors";
      break;
    case "instructor-account":
      yield put(getAccount());
      pageTitle = "Instructor Account";
      break;
    case "instructor-resources":
      pageTitle = "Downloads";
      break;
    case "manage-course":
      pageTitle = "Manage Course";
      id = pathnames[1];
      if (id) {
        yield put(getManagedCourse(id));
        yield put(
          getCourseOccurrencesCall(id, type, parseInt(page, 10), resultsperpage)
        );
      }
      break;
    case "my-classes":
      yield put(getOccurrencesCall(type, parseInt(page, 10), resultsperpage));
      pageTitle = "My Classes";
      break;
    case "my-courses":
      yield put(getManagedCourses());
      pageTitle = "My Courses";
      break;
    case "payments":
      pageTitle = "Payments";
      yield put(getAccount());
      break;
    case "review-class":
      const { oid, rid } = parsedHash;
      if (oid) {
        yield put(getOccurrence(oid));
      }
      if (rid) {
        yield put(getRegistrantReview(rid));
      }
      break;
    case "roster":
      id = pathnames[1];
      if (id) {
        yield put(getOccurrence(id));
        yield put(getRegistrants(id, false));
      }
      pageTitle = "Roster";
      break;
    case "search-by":
      yield put(getLatLng(parsedHash));
      break;
    case "submit-results":
      pageTitle = "Class Results";
      id = pathnames[1];
      if (id) {
        yield put(getOccurrence(id));
        yield put(getRegistrants(id, false));
      }
      break;
    default:
      pageTitle = "Training";
  }
  pageTitle = pageTitle ? `${pageTitle} | USCCA Training` : "USCCA Training";
  yield put(setPageTitle(pageTitle));
}
