// @flow
import type { CourseOccurrence } from "redux/api-types/CourseOccurrence";
import type { Date } from "redux/api-types/CourseDate";
import type { OccurrenceRecordI } from "./OccurrenceRecord";
import OccurrenceRecord from "./OccurrenceRecord";
import type { DateRecordI } from "redux/records/DateRecord";
import DateRecord from "redux/records/DateRecord";
import type { InstructorRecordI } from "./InstructorRecord";
import InstructorRecord from "./InstructorRecord";
import { entityTypes } from "./entityTypes";

type ParseDatesReturn = {
  instructorIds: Array<string>,
  instructorRecords: {
    [string]: DateRecordI,
  },
};

const parseDates = (dates: Array<Date>): ParseDatesReturn => {
  let dateIds = [];
  let dateRecords = {};

  dates.forEach(function ({ guid: id, startTime, endTime }: Date) {
    dateRecords[id] = new DateRecord({
      id,
      startTime,
      endTime,
    });
    dateIds.push(id);
  });

  return {
    dateIds,
    dateRecords,
  };
};

type ParseInstructorsReturn = {
  instructorIds: Array<string>,
  instructorRecords: {
    [string]: InstructorRecordI,
  },
};

const parseInstructors = (
  instructors: Array<OccurrenceInstructor>
): ParseInstructorsReturn => {
  let instructorIds = [];
  let instructorRecords = {};

  instructors.forEach(function (instructor: OccurrenceInstructor) {
    const { guid: id, firstName, lastName, number } = instructor;
    instructorRecords[id] = new InstructorRecord({
      id,
      firstName,
      lastName,
      number,
    });
    instructorIds.push(id);
  });

  return {
    instructorIds,
    instructorRecords,
  };
};

type ParseOccurrenceDataReturn = {
  occurrenceRecord: OccurrenceRecordI,
  instructorRecords: {
    [string]: InstructorRecordI,
  },
};

export const parseOccurrenceData = (
  occurrence: CourseOccurrence
): ParseOccurrenceDataReturn => {
  const {
    id,
    attributes: {
      resultsSubmittedAt: dateSubmitted,
      entityId: parentId,
      entityType,
      name,
      dates,
      instructors,
      ...attributes
    },
  } = occurrence;
  const parentType =
    entityType === "courses" ? entityTypes.COURSE : entityTypes.EVENT;
  const startTime = dates[0].startTime;
  const endTime = dates[0].endTime;

  const { dateIds, dateRecords } = parseDates(dates);
  const { instructorIds, instructorRecords } = parseInstructors(instructors);

  const occurrenceRecord = new OccurrenceRecord({
    id,
    parentType,
    parentId,
    name,
    isResultsSubmitted: !!dateSubmitted,
    dateSubmitted,
    startTime,
    endTime,
    dateIds,
    instructorIds,
    ...attributes,
  });

  return {
    occurrenceRecord,
    dateRecords,
    instructorRecords,
  };
};
