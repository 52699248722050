// @flow
type RegistrantsReturn = {
  inputValues: {
    [string]: boolean,
  },
  statuses: {
    [string]: string,
  },
};

export const parseRegistrants = (
  data: Array<RegistrantInterface>
): RegistrantsReturn => {
  let inputValues = {};
  let statuses = {};

  data.forEach(function (registrant: RegistrantInterface) {
    const { id } = registrant;
    inputValues[id] = false;
    statuses[id] = "";
  });

  return { inputValues, statuses };
};
