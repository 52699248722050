// @flow

type AddressComponent = {
  long_name: string,
  short_name: string,
  types: Array<string>,
};

export const getAddressComponentByType = (
  type: string,
  addressComponents: Array<AddressComponent>
): ?AddressComponent => {
  for (let i = 0; i < addressComponents.length; i++) {
    const current = addressComponents[i];
    if (current.types.includes(type)) {
      return current;
    }
  }
};
