export const tIDs = {
  CREATE_ACCOUNT: "6171b55726089",
  REQUEST_COURSE_OCCURRENCE: "6493679c4bcc8",
  EMAIL_COURSE_DETAILS: "6493678bcdece",
  GET_COURSE_DETAILS: "64930393d80ab",
  REVIEW_COURSE_OCCURRENCE: "6493080a7049b",
  DEFAULT_TRAINING_LEAD: "5d6550a89b784",
  FOOTER: "649306ff11cc1",
};

export const tracking = {
  EMAIL_COURSE_DETAILS: {
    origin: "Training",
    audience: "Course Details Request",
    medium: "Uscca.Com",
    campaign: "In Page Widget",
    targeting: "Training Class Student",
    pageType: "Opt In",
    type: "Uscca Training Classes",
  },
  GET_COURSE_DETAILS: {
    origin: "Training",
    audience: "manual lead signup",
    medium: "Uscca.Com",
    campaign: "training",
    targeting: "Training Class Student",
    pageType: "Opt In",
    type: "Uscca Training Classes",
  },
};
