// @flow
import React from "react";
import type { Node } from "react";

type Props = {
  onSearch: ({
    query: string,
    lat: string,
    lng: string,
    page: number,
  }) => mixed,
};

const SearchInput = ({ onSearch }: Props): Node => {
  return <></>;
};

export default SearchInput;
