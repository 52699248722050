// @flow
import React from "react";
import type { Node } from "react";

type Props = {
  className: string,
  [string]: string,
};

let InteractiveMediaIcon = ({ className, ...props }: Props): Node => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 117.103 150.732"
    {...props}
  >
    <title>Interactive Media - Videos, Tutorials & Images</title>

    <path
      d="m117.058 19.375h-8.424v-16.615a1.588 1.588 0 0 0 -1.365-1.74h-84.406a1.588 1.588 0 0 0 -1.363 1.74v64.368h-18.815a1.588 1.588 0 0 0 -1.365 1.74v43.507a1.588 1.588 0 0 0 1.365 1.74h18.815v11.185h-15.249a1.437 1.437 0 0 0 -1.294 1.189c-2.194 8.366-.288 14.312 1.7 17.826 2.594 4.594 6.761 7.438 9.133 7.438h82.565c5.668 0 10.279-5.923 10.279-13.2v-57.8h8.424a1.588 1.588 0 0 0 1.365-1.74v-57.898a1.588 1.588 0 0 0 -1.365-1.74zm-113.008 91.261v-40.027h46.47v40.027zm11.736 37.636c-1.039 0-4.508-1.723-6.917-5.991-1.519-2.689-2.971-7.163-1.606-13.5h78.855c-2.189 12.775 3.868 19.259 3.964 19.491zm90.119-9.722c0 5.361-3.387 9.722-7.55 9.722h-3.724c-5.821-4.227-7.444-12.659-5.438-20.7a2.14 2.14 0 0 0 -.2-1.554 1.3 1.3 0 0 0 -1.1-.713h-63.665v-11.189h27.657a1.588 1.588 0 0 0 1.365-1.74v-43.507a1.588 1.588 0 0 0 -1.365-1.74h-27.657v-62.629h81.672v14.875h-48.449a1.588 1.588 0 0 0 -1.365 1.74v57.894a1.588 1.588 0 0 0 1.365 1.74h48.449zm9.789-61.281h-56.878v-54.414h56.877z"
      transform="translate(-1.32 -1.02)"
    />
    <path
      d="m47.546 24.482c2.66 0 4.824-2.778 4.824-6.194s-2.164-6.194-4.824-6.194-4.824 2.778-4.824 6.194 2.164 6.194 4.824 6.194zm0-8.908c1.155 0 2.095 1.217 2.095 2.714s-.941 2.712-2.095 2.712-2.095-1.217-2.095-2.714.94-2.712 2.095-2.712z"
      transform="translate(31.573 12.582)"
    />
    <path
      d="m35.1 55.2 28.456.023h.036c.031 0 .057-.02.087-.023l19 .023h.036a1.586 1.586 0 0 0 1.365-1.74 2.016 2.016 0 0 0 -.346-1.159l-16.891-38.238a1.241 1.241 0 0 0 -2.377 0l-8.821 19.906-5.152-10.551a1.229 1.229 0 0 0 -2.317 0l-14.229 29.1a2.167 2.167 0 0 0 -.036 1.765 1.356 1.356 0 0 0 1.189.894zm30.553-36.718 14.694 33.257-16.047-.019-7.011-14.361zm-16.319 9.17 5.209 10.666s0 0 0 0l6.557 13.421-23.532-.018z"
      transform="translate(24.213 14.043)"
    />
    <path
      d="m20.353 33.331c-6.679 0-12.113 6.989-12.113 15.58s5.434 15.6 12.113 15.6 12.132-7 12.132-15.6-5.442-15.58-12.132-15.58zm0 27.7c-5.174 0-9.384-5.438-9.384-12.122s4.209-12.1 9.384-12.1 9.4 5.428 9.4 12.1-4.215 12.124-9.4 12.124z"
      transform="translate(3.315 40.624)"
    />
    <path
      d="m15.066 36.823a1.121 1.121 0 0 0 -1.395-.07 1.859 1.859 0 0 0 -.713 1.53v12.8a1.859 1.859 0 0 0 .713 1.53 1.119 1.119 0 0 0 1.395-.07l7.731-6.4a2.023 2.023 0 0 0 0-2.919zm.622 11.054v-6.39l3.859 3.2z"
      transform="translate(7.182 44.864)"
    />
    <path
      d="m33.259 5.072h-15.476a1.588 1.588 0 0 0 -1.365 1.74v19.925a1.588 1.588 0 0 0 1.365 1.74h15.476a1.588 1.588 0 0 0 1.365-1.74v-19.925a1.588 1.588 0 0 0 -1.365-1.74zm-1.365 19.928h-12.747v-16.448h12.747z"
      transform="translate(10.017 3.31)"
    />
    <path
      d="m47.633 65.347h15.477a1.588 1.588 0 0 0 1.365-1.74v-19.925a1.588 1.588 0 0 0 -1.365-1.74h-15.477a1.588 1.588 0 0 0 -1.365 1.74v19.925a1.588 1.588 0 0 0 1.365 1.74zm1.367-19.925h12.745v16.445h-12.745z"
      transform="translate(34.48 51.993)"
    />
    <path
      d="m34.424 65.347h15.476a1.588 1.588 0 0 0 1.365-1.74v-19.925a1.588 1.588 0 0 0 -1.365-1.74h-15.476a1.588 1.588 0 0 0 -1.365 1.74v19.925a1.588 1.588 0 0 0 1.365 1.74zm1.365-19.925h12.747v16.445h-12.747z"
      transform="translate(23.655 51.993)"
    />
    <path
      d="m19.8 16.69a1.588 1.588 0 0 0 -1.365 1.74v20.1a1.588 1.588 0 0 0 1.365 1.74 1.588 1.588 0 0 0 1.365-1.74v-20.1a1.588 1.588 0 0 0 -1.365-1.74z"
      transform="translate(11.67 18.651)"
    />
    <path
      d="m24.272 16.69a1.588 1.588 0 0 0 -1.365 1.74v14.59a1.588 1.588 0 0 0 1.365 1.74 1.588 1.588 0 0 0 1.365-1.74v-14.589a1.588 1.588 0 0 0 -1.365-1.741z"
      transform="translate(15.335 18.651)"
    />
    <path
      d="m40.912 48.213a1.588 1.588 0 0 0 1.365-1.74v-8.653a1.588 1.588 0 0 0 -1.365-1.74 1.588 1.588 0 0 0 -1.365 1.74v8.653a1.588 1.588 0 0 0 1.365 1.74z"
      transform="translate(28.972 44.253)"
    />
    <path
      d="m36.441 46.863a1.588 1.588 0 0 0 1.365-1.74v-6.28a1.588 1.588 0 0 0 -1.365-1.74 1.588 1.588 0 0 0 -1.365 1.74v6.28a1.588 1.588 0 0 0 1.365 1.74z"
      transform="translate(25.308 45.603)"
    />
    <path
      d="m54.122 48.213a1.588 1.588 0 0 0 1.365-1.74v-8.653a1.405 1.405 0 1 0 -2.729 0v8.653a1.588 1.588 0 0 0 1.364 1.74z"
      transform="translate(39.798 44.253)"
    />
    <path
      d="m49.651 46.863a1.588 1.588 0 0 0 1.365-1.74v-6.28a1.405 1.405 0 1 0 -2.729 0v6.28a1.588 1.588 0 0 0 1.364 1.74z"
      transform="translate(36.133 45.603)"
    />
  </svg>
);

InteractiveMediaIcon.displayName = "InteractiveMediaIcon";

InteractiveMediaIcon.defaultProps = {
  className: "",
};

export default InteractiveMediaIcon;
