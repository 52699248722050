// @flow
import DateRecord from "./DateRecord";
import InstructorRecord from "./InstructorRecord";
import OccurrenceRecord from "./OccurrenceRecord";

import type { CourseOccurrence as CourseOccurrenceType } from "redux/api-types/CourseOccurrence";
import type { CourseOccurrence } from "redux/api-types/CourseOccurrence";
import type { DateRecordI } from "./DateRecord";
import type { InstructorRecordI } from "./InstructorRecord";
import type { OccurrenceInstructor } from "redux/api-types/OccurrenceInstructor";
import type { OccurrenceRecordI } from "./OccurrenceRecord";
import type { Reply } from "redux/api-types/Reply";
import type { Review } from "redux/api-types/Review";

type ParseDatesReturn = {
  ids: Array<string>,
  records: {
    [string]: DateRecordI,
  },
};

const parseDates = (dates: Array<OccurrenceInstructor>): ParseDatesReturn => {
  let ids = [];
  let records = {};

  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];
    const { guid: id } = date;
    ids.push(id);
    records[id] = new DateRecord({
      id,
      ...date,
    });
  }

  return {
    ids,
    records,
  };
};

type ParseInstructorsReturn = {
  instructorIds: Array<string>,
  instructorRecords: {
    [string]: InstructorRecordI,
  },
};

const parseInstructors = (
  instructors: Array<OccurrenceInstructor>
): ParseInstructorsReturn => {
  let instructorIds = [];
  let instructorRecords = {};

  instructors.forEach(function (instructor: OccurrenceInstructor) {
    const { guid: id } = instructor;
    const link = `/instructor/${id}`;
    instructorRecords[id] = new InstructorRecord({
      ...instructor,
      link,
    });
    instructorIds.push(id);
  });

  return {
    instructorIds,
    instructorRecords,
  };
};

type ParseOccurrenceReturn = {
  occurrenceRecord: OccurrenceRecordI,
  dateRecords: {
    [string]: DateRecordI,
  },
  instructorRecords: {
    [string]: InstructorRecordI,
  },
};

const parseOccurrence = (
  occurrence: Array<CourseOccurrenceType | Reply>
): ParseOccurrenceReturn => {
  const {
    id,
    attributes: { courseId, dates, instructors },
  } = occurrence;

  const { instructorIds, instructorRecords } = parseInstructors(instructors);
  const { ids: dateIds, records: dateRecords } = parseDates(dates);
  const occurrenceRecord = new OccurrenceRecord({
    id,
    courseId,
    dateIds,
    instructorIds,
  });

  return {
    occurrenceRecord,
    dateRecords,
    instructorRecords,
  };
};

type ParseOccurrencesReturn = {
  occurrences: {
    [string]: OccurrenceRecordI,
  },
  dates: {
    [string]: DateRecordI,
  },
  instructors: {
    [string]: InstructorRecordI,
  },
};
type itemProps = {
  type: string,
};

export const parseCourseOccurrencesFromInclude = (
  data: Array<Review | CourseOccurrence>
): ParseOccurrencesReturn => {
  let occurrences = {};
  let dates = {};
  let instructors = {};

  const filteredOccurrences = data.filter(
    (item: itemProps): boolean => item.type === "occurrences"
  );

  filteredOccurrences.forEach(function (
    occurrence: Review | CourseOccurrence
  ): ?boolean {
    if (occurrence.type !== "occurrences") {
      return true;
    }
    const { id } = occurrence;
    const { occurrenceRecord, dateRecords, instructorRecords } =
      parseOccurrence(occurrence);

    occurrences[id] = occurrenceRecord;
    dates = Object.assign({}, dates, dateRecords);
    instructors = Object.assign({}, instructors, instructorRecords);
  });

  return {
    occurrences,
    dates,
    instructors,
  };
};
