const steps = [
  {
    key: "class",
    name: "Course",
    title: "Describe your course",
  },
  {
    key: "details",
    name: "Details",
    title: "Provide more details on your course",
  },
  {
    key: "location",
    name: "Location",
    title: "Where will the course be held?",
  },
  {
    key: "equipment",
    name: "Equipment",
    title: "What should students bring to this course?",
  },
  {
    key: "provided",
    name: "Provided",
    title: "What will you provide for your students?",
  },
  {
    key: "registration",
    name: "Registration",
    title: "How will students register?",
  },
];
export default steps;
