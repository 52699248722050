// @flow
import { tIDs } from "shared/constants/tracking";

export const getLoginMutation = (email: string, password: string): string =>
  `mutation {
    authenticateIdentity(
      input: {email: "${email}", password: "${password}"}
    ) {
      token
    }
  }
  `;

export const getCreateAccountMutation = (
  name: string,
  surname: string,
  email: string,
  password: string
): string =>
  `mutation {
    createUserIdentity(input: { name: "${name}" surname: "${surname}" email: "${email}" password: "${password}" tracking: { tID: "${tIDs.CREATE_ACCOUNT}" origin: "USCCA.com" medium: "Training" campaign: "Class Details" audience: "Non-Members" targeting: "Opt In" pageType: "Training" type: "Training" } }) {
      userToken {
        token
      }
    }
  }`;

export const getCreateVerificationMutation = (email: string): string =>
  `mutation {
    createIdentityVerification(input: { email: "${email}" }) {
      id
    }
  }`;

export const getVerifyVerificationMutation = (
  id: string,
  code: string
): string =>
  `mutation {
    verifyIdentityVerification(id: "${id}" input: { code: "${code}" }) {
        token
      }
    }`;
