// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import {
  REMOVE_COURSE_REQUEST,
  REMOVE_COURSE_SUCCESS,
  REMOVE_COURSE_ERROR,
  ARCHIVE_COURSE_REQUEST,
  ARCHIVE_COURSE_SUCCESS,
  ARCHIVE_COURSE_ERROR,
  CLONE_COURSE_REQUEST,
  CLONE_COURSE_SUCCESS,
  CLONE_COURSE_ERROR,
} from "../instructorCourse/instructorCourse";
import type {
  CourseActionRequest,
  CourseAction,
} from "../instructorCourse/instructorCourse";

import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
  apiErrorReturn,
} from "shared/constants/flowTypes";
import type { Course as CourseType } from "redux/api-types/Course";
import { getJwtUser } from "shared/utilities/authCookie";
import { parseCourses, parseCourseReturnToRecord } from "./coursesParsers";
import { parseCurriculum } from "./curriculumParsers";
import { ACTIVE, ARCHIVED } from "./types";

// Actions
const namespace: string = `${APP_NAMESPACE}/manageCourses`;

export const COURSES_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const COURSES_REQUEST_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const COURSES_REQUEST_ERROR: string = `${namespace}/FETCH/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Flow Types
type RequestCoursesReturn = {
  type: string,
};

export type RequestCoursesAction = {
  type: string,
  payload: {
    type: string,
  },
};

export type CoursesReturn = {
  data: Array<CourseType>,
  included: Array<mixed>, //add type
};

export type ReceiveCoursesReturn = {
  type: string,
  payload: CoursesReturn,
  meta: {
    receivedAt: number,
  },
};

// Action Creators
export const requestCourses = ({
  type,
}: RequestCoursesReturn): RequestCoursesAction => ({
  type: COURSES_REQUEST,
  payload: {
    type,
  },
});

export const receiveCourses = (json: CoursesReturn): ReceiveCoursesReturn => ({
  type: COURSES_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const coursesRequestError = (error: apiErrorParams): apiErrorAction => ({
  type: COURSES_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async actions
const { userId } = getJwtUser();

export const getCourses = (type: string = ACTIVE): callApiReturnType => {
  const filter = type === ARCHIVED ? "?filter=archived" : "";
  const separator = type === ARCHIVED ? "&" : "?";
  return {
    type: CALL_API,
    payload: {
      method: "GET",
      endpoint: `/api/training/instructors/${userId}/courses${filter}${separator}include=curriculum`,
      actionParams: {
        type,
      },
      actions: {
        request: requestCourses,
        success: receiveCourses,
        failure: coursesRequestError,
      },
    },
  };
};

// Initial State
const PENDING = "pending";

export type State = {
  status: string,
  errors: apiErrorReturn,
  errorMessage: string,
  courses: {},
  curriculum: {},
  type: string,
  count: number,
};

const initialState: State = {
  status: NONE,
  errorMessage: "",
  errors: [],
  courses: {},
  curriculum: {},
  type: "",
  count: 0,
};

// Reducer
const manageCourses = handleActions(
  {
    [COURSES_REQUEST]: (state: State, action: RequestCoursesAction): State => {
      const { type: type } = action.payload;
      return {
        ...state,
        status: LOADING,
        errorMessage: "",
        errors: [],
        courses: {},
        type,
        count: 0,
      };
    },
    [COURSES_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveCoursesReturn
    ): State => {
      const {
        payload: { data, included },
      } = action;
      const count = data.length;
      const courses = parseCourses(data);
      const curriculum = included ? parseCurriculum(included) : {};
      return {
        ...state,
        status: LOADED,
        errorMessage: "",
        errors: [],
        courses,
        curriculum,
        count,
      };
    },
    [COURSES_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;
      return {
        ...state,
        status: ERROR,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [REMOVE_COURSE_REQUEST]: (
      state: State,
      action: CourseActionRequest
    ): State => {
      const { id } = action.payload;
      const { courses } = state;
      const record = courses[id];
      const update = record.set("isActionPending", true);
      return {
        ...state,
        courses: {
          ...courses,
          [id]: update,
        },
      };
    },
    [REMOVE_COURSE_SUCCESS]: (
      state: State,
      action: RemoveCourseSuccessAction
    ): State => {
      const { count: oldCount } = state;
      const count = oldCount ? oldCount - 1 : 0;
      const {
        payload: {
          data: { id },
        },
      } = action;
      let { courses } = state;
      delete courses[id];

      return {
        ...state,
        courses,
        count,
      };
    },
    [REMOVE_COURSE_ERROR]: (state: State, action: apiErrorAction): State => {
      const { actionParams } = action.payload;
      let { courses } = state;
      if (actionParams && actionParams.hasOwnProperty("id")) {
        const { id } = actionParams;
        const record = courses[id];
        const update = record.set("isActionPending", false);
        courses[id] = update;
      }
      return {
        ...state,
        courses,
      };
    },
    [ARCHIVE_COURSE_REQUEST]: (
      state: State,
      action: CourseActionRequest
    ): State => {
      const { id } = action.payload;
      let { courses } = state;
      const record = courses[id];
      const update = record.set("isActionPending", true);
      courses[id] = update;

      return {
        ...state,
        courses,
      };
    },
    [ARCHIVE_COURSE_SUCCESS]: (state: State, action: CourseAction): State => {
      const { count: oldCount } = state;
      const count = oldCount ? oldCount - 1 : 0;
      const {
        payload: {
          course: {
            data: { id },
          },
        },
      } = action;
      let { courses } = state;
      delete courses[id];

      return {
        ...state,
        courses,
        count,
      };
    },
    [ARCHIVE_COURSE_ERROR]: (state: State, action: apiErrorAction): State => {
      const { actionParams } = action.payload;
      let { courses } = state;
      if (actionParams && actionParams.hasOwnProperty("id")) {
        const { id } = actionParams;
        const record = courses[id];
        const update = record.set("isActionPending", false);
        courses[id] = update;
      }
      return {
        ...state,
        courses,
      };
    },
    [CLONE_COURSE_REQUEST]: (
      state: State,
      action: CourseActionRequest
    ): State => {
      const { type } = state;
      if (type === ARCHIVED) {
        return state;
      }

      const { id } = action.payload;
      let { courses } = state;
      const hasPending = courses.hasOwnProperty(PENDING);

      if (!hasPending) {
        const originalRecord = courses[id];
        const name = `COPY OF ${originalRecord.get("name")}`;
        const clonedRecord = originalRecord.merge({
          id: "",
          name: name,
          isActionPending: true,
        });
        courses[PENDING] = clonedRecord;
      }

      const count = Object.keys(courses).length;

      return {
        ...state,
        courses,
        count,
      };
    },
    [CLONE_COURSE_SUCCESS]: (state: State, action: CourseAction): State => {
      const { type } = state;
      if (type === ARCHIVED) {
        return state;
      }

      const {
        payload: { data },
      } = action;
      let { courses } = state;
      const hasPending = courses.hasOwnProperty(PENDING);
      if (hasPending) {
        delete courses[PENDING];
      }
      const course = parseCourseReturnToRecord(data);

      return {
        ...state,
        courses: {
          ...courses,
          [data.id]: course,
        },
      };
    },
    [CLONE_COURSE_ERROR]: (state: State, action: apiErrorAction): State => {
      let { courses } = state;
      const hasPending = courses.hasOwnProperty(PENDING);
      if (hasPending) {
        delete courses[PENDING];
      }
      const count = Object.keys(courses).length;

      return {
        ...state,
        courses,
        count,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default manageCourses;
