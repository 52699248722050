// @flow
import { Record } from "immutable";

export type CurriculumRecordI = {
  id: string,
  abbreviation: string,
  name: string,
  prerequisites: Array<string>,
};

const CurriculumRecord = Record({
  id: "",
  abbreviation: "",
  name: "",
  prerequisites: [],
});

export default CurriculumRecord;
