import { round1 } from "./round1";
import { round2 } from "./round2";
import { round3 } from "./round3";
import { round4 } from "./round4";
import { round5 } from "./round5";
import { round6 } from "./round6";
import { round7 } from "./round7";
import { round8 } from "./round8";

export const courseLinkOverrides = [
  ...round1,
  ...round2,
  ...round3,
  ...round4,
  ...round5,
  ...round6,
  ...round7,
  ...round8,
];
