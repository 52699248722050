// @flow
import { tIDs } from "shared/constants/tracking";

export default function submitLead(email: string, tID: string | undefined) {
  if (window["deltaAnalytics"] && email) {
    window["deltaAnalytics"].submitLead({
      email,
      tID: tID || tIDs.DEFAULT_TRAINING_LEAD,
    });
  }
}
