// @flow
import { Record } from "immutable";

export type RegistrationQuestionI = {
  question: string,
  shortCode: string,
  type: string,
  options: Array<string>,
};

export type OccurrenceRecordInterface = {
  occurrenceId: string,
  capacity: number,
  numberOfRegistrants: number,
  spotsAvailable: number,
  checkoutUrl: string,
  instructorIds: Array<string>,
  dateIds: Array<string>,
  isEligibleForInstructorAutomation: boolean,
  webPayOnly: boolean,
  startTime: string,
  registrationQuestions: Array<RegistrationQuestionI>,
  isInstructorCertifying: boolean,
  groupByMonth: number,
};

const OccurrenceRecord = Record({
  occurrenceId: "",
  capacity: 0,
  numberOfRegistrants: 0,
  spotsAvailable: 0,
  checkoutUrl: "",
  instructorIds: [],
  dateIds: [],
  isEligibleForInstructorAutomation: false,
  webPayOnly: false,
  startTime: "",
  registrationQuestions: [],
  isInstructorCertifying: false,
  groupByMonth: 1,
});

export default OccurrenceRecord;
