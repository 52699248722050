// @flow
import { Record } from "immutable";

export type InstructorRecordType = {
  id: string,
  status: string,
  isRangePartner: boolean,
  instructorNumber: string,
  firstName: string,
  lastName: string,
  initials: string,
  companyName: string,
  phone: string,
  email: string,
  biography: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  postalCode: string,
  averageReviewRating: number,
  totalReviewCount: number,
  imageUrl: string,
};

const InstructorRecord = Record({
  id: "",
  status: "",
  isRangePartner: false,
  instructorNumber: "",
  firstName: "",
  lastName: "",
  initials: "",
  companyName: "",
  phone: "",
  email: "",
  biography: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postalCode: "",
  averageReviewRating: 0,
  totalReviewCount: 0,
  imageUrl: "",
});

export default InstructorRecord;
