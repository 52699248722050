// @flow
import { Record } from "immutable";

export type CourseDetailRecordI = {
  id: string,
  name: string,
  isArchived: boolean,
  link: string,
  editLink: string,
  price: number,
  location: {
    [string]: mixed,
  },
  capacity: number,
  curriculumId: string,
  curriculumModuleId: string,
  description: string,
  notes: string,
  classroomHours: number,
  rangeHours: number,
  hasLiveFire: boolean,
  grantsCCW: boolean,
  registration: {
    [string]: mixed,
  },
  futureOccurrencesCount: number,
  pastOccurrencesCount: number,
  isMultiday: boolean,
  instructorId: string,
};

const CourseDetailsRecord = Record({
  id: "",
  name: "",
  isArchived: false,
  link: "",
  editLink: "",
  price: 0,
  location: {},
  capacity: 0,
  curriculumId: "",
  curriculumModuleId: "",
  description: "",
  notes: "",
  classroomHours: 0,
  rangeHours: 0,
  hasLiveFire: false,
  grantsCCW: false,
  registration: {},
  futureOccurrencesCount: 0,
  pastOccurrencesCount: 0,
  isMultiday: false,
  instructorId: "",
});

export default CourseDetailsRecord;
