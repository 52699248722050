// @flow
import CourseDetailsRecord from "../records/CourseDetailsRecord";
import type { Course } from "redux/api-types/Course";
import type { courseDetailsRecordType } from "../records/CourseDetailsRecord";
import LocationRecord from "../records/LocationRecord";
import type { locationRecordType } from "../records/LocationRecord";
import RegistrationRecord from "../records/RegistrationRecord";
import type { registrationRecordType } from "../records/RegistrationRecord";
import type { InstructorRecordType } from "../records/InstructorRecord";
import type { CurriculumRecordType } from "../records/CurriculumRecord";
import type { CourseDate } from "../../../api-types/CourseDate";

type courseType = {
  details: courseDetailsRecordType,
  location: locationRecordType,
  registration: registrationRecordType,
  instructor: InstructorRecordType,
  curriculum: CurriculumRecordType,
  dates: Array<CourseDate>,
};

type ItemOption = {
  id: string,
  value: boolean | string,
};

type Item = {
  id: string,
  qty?: number,
};

const parseItems = (items: {
  [string]: ItemOption,
}): {
  [string]: Item,
} => {
  const keys = Object.keys(items);
  keys.forEach(function (key: string) {
    const item = items[key];

    // !! delete eventually, remove incorrectly saved items
    if (key.substring(0, 1) === "-") {
      delete item[key];
      return;
    }

    // first delete item if set to false
    if (!Boolean(item.value)) {
      delete items[key];
      return;
    }

    const isQty = Boolean(key.match(/-qty/g));

    // add quantity to main item
    if (isQty) {
      const qty = parseFloat(item.value);
      const mainKey = key.replace("-qty", "");
      if (items.hasOwnProperty(mainKey)) {
        items[mainKey].qty = qty;
      }
      delete items[key];
      return;
    }

    // delete value, it's always true
    delete items[key].value;
  });

  return items;
};

export const parseCourseResponse = (json: Course): courseType => {
  const {
    id,
    attributes: {
      name,
      overrideUrl,
      price,
      capacity,
      description,
      notes,
      classroomHours,
      rangeHours,
      hasLiveFire,
      grantsCCW,
      providedItems,
      bringableItems,
      location,
      registration,
      cancellationPolicy,
      canPurchase,
      canRegister,
      futureOccurrencesCount,
      isWomensOnly,
      isMultiday,
      averageReviewRating,
      totalReviewCount,
      webPayOnly,
      registrationQuestions,
      isEligibleForInstructorAutomation,
      hasPrerequisiteELearning,
      curriculumModuleId,
    },
  } = json;

  const parsedProvided = parseItems(providedItems);
  const parsedBringable = parseItems(bringableItems);
  let tags = [];
  if (hasLiveFire) {
    tags.push("Live Fire");
  }
  if (grantsCCW) {
    tags.push("CCW Permit");
  }
  if (isWomensOnly) {
    tags.push("Women's Only");
  }

  const courseDetails = new CourseDetailsRecord({
    id,
    name,
    overrideUrl,
    price,
    capacity,
    description,
    notes,
    classroomHours,
    rangeHours,
    hasLiveFire,
    grantsCCW,
    tags,
    providedItems: parsedProvided,
    bringableItems: parsedBringable,
    location,
    registration,
    cancellationPolicy,
    canPurchase,
    canRegister,
    futureOccurrencesCount,
    isWomensOnly,
    isMultiday,
    averageReviewRating,
    totalReviewCount,
    webPayOnly,
    registrationQuestions,
    isEligibleForInstructorAutomation,
    hasPrerequisiteELearning,
    isModule: !!curriculumModuleId,
  });

  let addressArr = [];
  if (location.address1) {
    addressArr.push(location.address1);
  }
  if (location.address2) {
    addressArr.push(location.address2);
  }
  addressArr.push(location.city);
  addressArr.push(`${location.state} ${location.postalCode}`);
  const fullAddress = addressArr.join(", ");

  const locationRecord = new LocationRecord({
    ...location,
    fullAddress,
  });
  const registrationRecord = new RegistrationRecord(registration);

  const course = {
    courseDetails,
    location: locationRecord,
    registration: registrationRecord,
  };

  return course;
};
