// @flow
import { UsaStates } from "usa-states";

const usaStates = new UsaStates({
  // we only want Guam and Puerto Rico; exclude all other territories
  includeTerritories: true,
  exclude: ["AS", "FM", "MH", "MP", "PW", "VI"],
});

type usaStatesType = Array<{
  name: string,
  abbreviation: string,
  territory: boolean,
  capital: string,
  contiguous: boolean,
}>;

export type stateType = {
  label: string,
  value: string,
};

export const states = Array.from(
  usaStates.states,
  ({ name: label, abbreviation: value }: usaStatesType): Array<stateType> => ({
    label,
    value,
  })
);
