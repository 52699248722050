// @flow
import { Record } from "immutable";

export type ResourceRecordType = {
  id: string,
  name: string,
  filetype: string,
  resourceUrl: string,
  fileLastUpdated: string,
  curriculumAbbreviation: string,
  category: string,
  stateName: string,
};

const ResourceRecord = Record({
  id: "",
  name: "",
  filetype: "",
  resourceUrl: "",
  fileLastUpdated: "",
  curriculumAbbreviation: "",
  category: "",
  stateName: "",
});

export default ResourceRecord;
