// @flow
export const round3 = [
  {
    courseId: "26befaa6-d1ff-11ec-9249-02420a000294",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-lorain-OH-6-08-2022?link=training_platform",
  },
  {
    courseId: "dd07b960-d1ff-11ec-9b92-0242ac12000a",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-avon-IN-6-14-2022?link=training_platform",
  },
  {
    courseId: "e5ecac5c-d1ff-11ec-9048-02420a000293",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-6-15-2022?link=training_platform",
  },
  {
    courseId: "0e2b7730-d263-11ec-a5a4-02420a000294",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-litchfield-OH-6-20-2022?link=training_platform",
  },
  {
    courseId: "c8ff58ba-d263-11ec-b933-02420a000293",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-columbus-IN-6-21-2022?link=training_platform",
  },
  {
    courseId: "bd64b76a-d264-11ec-9fa0-0242ac12000a",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-marianna-PA-6-29-2022?link=training_platform",
  },
  {
    courseId: "4bad7778-d265-11ec-aa1a-02420a000293",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-billings-MT-6-30-2022?link=training_platform",
  },
  {
    courseId: "bb04fd7a-d266-11ec-86d7-02420a000294",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-kansas-city-MO-6-13-2022?link=training_platform",
  },
  {
    courseId: "114b3654-d267-11ec-bc54-02420a000294",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-broomfield-CO-6-21-2022?link=training_platform",
  },
  {
    courseId: "0b86522a-d268-11ec-aac4-02420a000294",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-southington-CT-6-21-2022?link=training_platform",
  },
  {
    courseId: "a9e1b2b0-d269-11ec-acf2-0242ac12000a",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-broomfield-CO-6-23-2022?link=training_platform",
  },
  {
    courseId: "3864501a-d26a-11ec-b90c-02420a000294",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-muncie-IN-7-11-2022?link=training_platform",
  },
  {
    courseId: "af00fd14-d5e8-11ec-9c39-02420a000294",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-overland-park-KS-6-16-2022?link=training_platform",
  },
  {
    courseId: "1e45db86-d5e9-11ec-8238-02420a000293",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-columbus-IN-7-12-2022?link=training_platform",
  },
  {
    courseId: "8704c79a-d5e9-11ec-bb85-0242ac12000a",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-broomfield-CO-7-12-2022?link=training_platform",
  },
  {
    courseId: "edee4134-d5e9-11ec-95ab-0242ac12000a",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-avon-IN-7-13-2022?link=training_platform",
  },
];
