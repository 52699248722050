// @flow
import { handleActions } from "redux-actions";
import { put } from "redux-saga/effects";
import type { Saga } from "redux-saga";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { NONE, PENDING, SUCCESS, ERROR } from "shared/constants/status";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import { getJwtUser } from "shared/utilities/authCookie";

const namespace: string = `${APP_NAMESPACE}/connect`;

// Actions
export const SET_CONNECT_ERROR: string = `${namespace}/SET_CONNECT/ERROR`;
export const CONNECT_STRIPE_REQUEST: string = `${namespace}/CONNECT_STRIPE/REQUEST`;
export const CONNECT_STRIPE_SUCCESS: string = `${namespace}/CONNECT_STRIPE/SUCCESS`;
export const CONNECT_STRIPE_ERROR: string = `${namespace}/CONNECT_STRIPE/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creator Interfaces
export type SetConnectErrorReturn = {
  type: string,
  payload: {
    error: string,
    errorDescription: string,
  },
};

// Action Creators
export const setConnectError = (
  error: string,
  errorDescription: string
): SetConnectErrorReturn => ({
  type: SET_CONNECT_ERROR,
  payload: {
    error,
    errorDescription,
  },
});

export const requestStripeConnect = (): simpleReduxAction => ({
  type: CONNECT_STRIPE_REQUEST,
});

export const connectStripeSuccess = (): simpleReduxAction => ({
  type: CONNECT_STRIPE_SUCCESS,
});

export const connectStripeError = (error: apiErrorParams): apiErrorAction => ({
  type: CONNECT_STRIPE_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

const { userId } = getJwtUser();

// Async actions
export const connectStripeAccount = (
  code: string,
  scope: string
): callApiReturnType => {
  return {
    type: CALL_API,
    payload: {
      method: "PATCH",
      endpoint: `/api/training/instructors/${userId}`,
      options: {
        authCode: code,
      },
      actions: {
        request: requestStripeConnect,
        success: connectStripeSuccess,
        failure: connectStripeError,
      },
      toasts: {
        success: "Account Connected",
        failure: "Error Connecting Account",
      },
    },
  };
};

export type ConnectRedirectParamType = {
  scope?: string,
  state?: string,
  code?: string,
  error?: string,
  error_description?: string,
};

export function* handleConnectResponse(
  params: ConnectRedirectParamType
): Saga<void> {
  const { scope, code, error, error_description: errorDescription } = params;

  if (code && scope) {
    const requestConnectAction = connectStripeAccount(code, scope);
    yield put(requestConnectAction);
  }

  if (error && errorDescription) {
    const errorAction = setConnectError(error, errorDescription);
    yield put(errorAction);
  }
}

// Initial State
export type State = {
  stripeError: string,
  stripeErrorDescription: string,
  connectStatus: string,
  isConnectPending: boolean,
  isConnectError: boolean,
  connectError: string,
  connectErrorData: apiErrorReturn,
};

const initialState: State = {
  stripeError: "",
  stripeErrorDescription: "",
  connectStatus: NONE,
  isConnectPending: false,
  isConnectError: false,
  connectError: "",
  connectErrorData: [],
};

const connect = handleActions(
  {
    [SET_CONNECT_ERROR]: (
      state: State,
      action: SetConnectErrorReturn
    ): State => {
      const { error: stripeError, errorDescription: stripeErrorDescription } =
        action.payload;

      return {
        ...state,
        stripeError,
        stripeErrorDescription,
      };
    },
    [CONNECT_STRIPE_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      connectStatus: PENDING,
      connectError: "",
      connectErrorData: [],
    }),
    [CONNECT_STRIPE_SUCCESS]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      connectStatus: SUCCESS,
    }),
    [CONNECT_STRIPE_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        connectStatus: ERROR,
        connectError: errorMessage,
        connectErrorData: errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default connect;
