// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { CourseOccurrence as OccurrenceType } from "redux/api-types/CourseOccurrence";
import { parseOccurrenceData } from "./parseOccurrence";
import type { OccurrenceRecordI } from "./OccurrenceRecord";
import OccurrenceRecord from "./OccurrenceRecord";
import type { DateRecordI } from "redux/records/DateRecord";
import type { InstructorRecordI } from "./InstructorRecord";
import type { RegistrationQuestionI } from "redux/modules/occurrence/OccurrenceRecord";

// Actions
const namespace: string = `${APP_NAMESPACE}/occurrence`;

export const OCCURRENCE_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const OCCURRENCE_REQUEST_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const OCCURRENCE_REQUEST_ERROR: string = `${namespace}/FETCH/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creator Interfaces
type RequestOccurrenceParams = {
  occurrenceId: string,
};

type RequestOccurrenceReturn = {
  type: string,
  payload: {
    occurrenceId: string,
  },
};

type OccurrenceApiReturn = {
  data: OccurrenceType,
};

type ReceiveOccurrenceReturn = {
  type: string,
  payload: {
    occurrence: OccurrenceApiReturn,
  },
  meta: {
    receivedAt: number,
  },
};

// Action Creators
export const requestOccurrence = ({
  occurrenceId,
}: RequestOccurrenceParams): RequestOccurrenceReturn => ({
  type: OCCURRENCE_REQUEST,
  payload: {
    occurrenceId,
  },
});

export const receiveOccurrence = (
  json: OccurrenceApiReturn
): ReceiveOccurrenceReturn => ({
  type: OCCURRENCE_REQUEST_SUCCESS,
  payload: {
    occurrence: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const occurrenceRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: OCCURRENCE_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async Action Creators
export const getOccurrence = (occurrenceId: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/occurrences/${occurrenceId}`,
    actionParams: {
      occurrenceId,
    },
    actions: {
      request: requestOccurrence,
      success: receiveOccurrence,
      failure: occurrenceRequestError,
    },
  },
});

// Initial State
export type State = {
  occurrenceId: string,
  occurrence: OccurrenceRecordI,
  isLoading: boolean,
  isLoaded: boolean,
  isError: boolean,
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  receivedAt: number,
  dates: {
    [id]: DateRecordI,
  },
  instructors: {
    [string]: InstructorRecordI,
  },
  registrationQuestions: Array<RegistrationQuestionI>,
  webPayOnly: boolean,
  isEligibleForInstructorAutomation: boolean,
};

// Initial State
const blankOccurrence = new OccurrenceRecord({});
const initialState: State = {
  occurrenceId: "",
  occurrence: blankOccurrence,
  isLoading: false,
  isLoaded: false,
  isError: false,
  status: NONE,
  errorMessage: "",
  errors: [],
  receivedAt: 0,
  instructors: {},
  registrationQuestions: [],
  webPayOnly: false,
  isEligibleForInstructorAutomation: false,
};

// Selectors
export const occurrenceParentIdSelector = ({
  occurrence: {
    occurrence: { parentId },
  },
}: {
  registrant: State,
}): string => parentId;

// Reducer
const occurrence = handleActions(
  {
    [OCCURRENCE_REQUEST]: (
      state: State,
      action: RequestOccurrenceReturn
    ): State => {
      const { occurrenceId } = action.payload;
      const occurrence = new OccurrenceRecord({});
      return {
        ...state,
        occurrenceId,
        occurrence,
        isLoading: true,
        isError: false,
        status: LOADING,
        errorMessage: null,
        errors: [],
        receivedAt: null,
      };
    },
    [OCCURRENCE_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveOccurrenceReturn
    ): State => {
      const {
        payload: {
          occurrence: { data },
        },
        meta: { receivedAt },
      } = action;
      const { id: occurrenceId } = data;
      const {
        occurrenceRecord: occurrence,
        dateRecords: dates,
        instructorRecords: instructors,
      } = parseOccurrenceData(data);

      return {
        ...state,
        occurrenceId,
        occurrence,
        dates,
        instructors,
        isLoading: false,
        isLoaded: true,
        isError: false,
        status: LOADED,
        receivedAt,
        registrationQuestions: occurrence.registrationQuestions,
        webPayOnly: occurrence.webPayOnly,
        isEligibleForInstructorAutomation:
          occurrence.isEligibleForInstructorAutomation,
      };
    },
    [OCCURRENCE_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        isLoading: false,
        isError: true,
        status: ERROR,
        errorMessage,
        errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => {
      return initialState;
    },
  },
  initialState
);

export default occurrence;
