// @flow
import type { Include as IncludeType } from "redux/api-types/Include";
import InstructorRecord from "../records/InstructorRecord";
import type { InstructorReturn } from "redux/modules/instructor/instructor";

export const createInstructorUrl = (id: string): string => `/instructor/${id}`;

export const parseCourseInstructor = (
  includes: Array<IncludeType>
): InstructorReturn => {
  let instructor = new InstructorRecord();

  includes.forEach(function (include: IncludeType): Void {
    const { type } = include;
    if (type !== "instructors") {
      return true;
    }
    const { attributes, id } = include;
    const { firstName, lastName } = attributes;
    const initials = firstName.charAt(0) + lastName.charAt(0);
    const link = createInstructorUrl(id);

    instructor = new InstructorRecord({
      ...attributes,
      initials,
      link,
      id,
    });
  });

  return instructor;
};
