// @flow
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import {
  OPEN,
  CLOSE,
  SELECT_TAB,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_ERROR,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
} from "./studentDrawerActions";

export type OpenDrawerAction = {
  payload: {
    id: string,
    mode: string,
  },
} & simpleReduxAction;

export const openDrawer = (id: string, mode: string): OpenDrawerAction => ({
  type: OPEN,
  payload: {
    id,
    mode,
  },
});

export const closeDrawer = (): simpleReduxAction => ({
  type: CLOSE,
});

export const TABS = {
  BASIC: "Basic Info",
  HISTORY: "Class History",
};

export type Tab = TABS.BASIC | TABS.HISTORY;

export type SelectTabAction = {
  payload: {
    tab: Tab,
  },
} & simpleReduxAction;

export const selectTab = (tab: Tab): SelectTabAction => ({
  type: SELECT_TAB,
  payload: { tab },
});

export const requestStudent = (): simpleReduxAction => ({
  type: FETCH_REQUEST,
});

export type OccurrenceDate = {
  startDateTime: string,
  endDateTime: string,
};
export type Registration = {
  courseId: string,
  occurrenceId: string,
  courseName: string,
  occurrenceDates: Array<OccurrenceDate>,
  chargeId: string,
  registeredAt: string,
  status: string,
};
export type Student = {
  type: string,
  id: string,
  attributes: {
    instructorUserId: string,
    userId: string,
    email: string,
    firstName: string,
    lastName: string,
    phone: number,
    notes: string,
    lastCourseName: string,
    registrations: Array<Registration>,
    createdAt: string,
    updatedAt: string,
    archivedAt: string,
  },
};

export type StudentReturn = {
  data: Student,
};

export type ReceiveStudentAction = {
  payload: StudentReturn,
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export const receiveStudent = (json: StudentReturn): ReceiveStudentAction => ({
  type: FETCH_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const getStudentError = (error: apiErrorParams): apiErrorAction => {
  return {
    type: FETCH_ERROR,
    error: true,
    payload: {
      errorMessage: "",
      errors: [],
      ...error,
    },
    meta: {
      receivedAt: Date.now(),
    },
  };
};

export const getStudent = (id: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/students/${id}`,
    actions: {
      request: requestStudent,
      success: receiveStudent,
      failure: getStudentError,
    },
  },
});

export const requestStudentUpdate = (): simpleReduxAction => ({
  type: UPDATE_REQUEST,
});

export type ReceiveUpdatedStudentAction = {
  payload: StudentReturn,
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export const receiveUpdatedStudent = (
  json: StudentReturn
): ReceiveUpdatedStudentAction => ({
  type: UPDATE_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export type UpdateStudentErrorAction = {} & simpleReduxAction;

export const updateStudentError = (): UpdateStudentErrorAction => ({
  type: UPDATE_ERROR,
});

export type StudentData = {
  firstName?: string,
  lastName?: string,
  email?: string,
  phone?: string,
  notes?: string,
};

export const updateStudent = (
  id: string,
  student: StudentData
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "PATCH",
    endpoint: `/api/training/students/${id}`,
    options: { ...student },
    actions: {
      request: requestStudentUpdate,
      success: receiveUpdatedStudent,
      failure: updateStudentError,
    },
    toasts: {
      success: "Student Updated",
      failure: "Couldn't Update Student",
    },
  },
});
