// @flow
import type { Node } from "react";
import React, { useRef, useState, useEffect } from "react";
import type { LinkLabelDataId } from "./constants";
import { navFadeDirections, subNavLinks } from "./constants";
import NavigationSearchForm from "./NavigationSearchForm";
import styles from "./SubNavigation.module.scss";

type Props = {
  isAuthed: boolean,
};

const SubNavigation = ({ isAuthed }: Props): Node => {
  const pathname = location.pathname;
  const subNavRef = useRef();
  const [navFadeDirection, setNavFadeDirection] = useState(
    navFadeDirections.Right
  );
  const atCurrentPath = (path: string): boolean =>
    (path !== "/" && pathname.startsWith(path)) || pathname === path;

  const onSubNavScroll = () => {
    const threshold = 15;
    if (subNavRef.current) {
      const scrolledLeft = subNavRef.current.scrollLeft < threshold;
      const scrolledRight =
        subNavRef.current.scrollWidth -
          (subNavRef.current.offsetWidth + subNavRef.current.scrollLeft) <
        threshold;

      let fadeDirection = navFadeDirections.Both;
      if (scrolledLeft) {
        fadeDirection = navFadeDirections.Right;
      } else if (scrolledRight) {
        fadeDirection = navFadeDirections.Left;
      }

      setNavFadeDirection(fadeDirection);
    }
  };

  useEffect((): (() => void) => {
    subNavRef.current?.addEventListener("scroll", onSubNavScroll);

    return () => {
      subNavRef.current?.removeEventListener("scroll", onSubNavScroll);
    };
  });

  return (
    <nav
      className={`${styles.subNavigationWrapper} ${
        isAuthed ? styles.authed : ""
      }`}
    >
      <div ref={subNavRef} className={styles.subNavigation}>
        <div
          className={`${styles.fadeLeft} ${
            navFadeDirection !== navFadeDirections.Right ? styles.show : ""
          }`}
        />
        <div
          className={`${styles.fadeRight} ${
            navFadeDirection !== navFadeDirections.Left ? styles.show : ""
          }`}
        />

        <ul className={styles.subnavlist}>
          {isAuthed && (
            <li>
              <a
                href="/"
                data-id="uscca-header_train-nav_home"
                className={atCurrentPath("/") ? styles.active : ""}
              >
                Home
              </a>
            </li>
          )}

          {subNavLinks.map((l: LinkLabelDataId): Node => (
            <li key={l.label}>
              <a
                href={l.href}
                data-id={l.dataId}
                className={atCurrentPath(l.href) ? styles.active : ""}
              >
                {l.label}
              </a>
            </li>
          ))}

          {isAuthed && (
            <li>
              <a
                href="https://www.usconcealedcarry.com/training/guides/"
                data-id="uscca-header_train-nav_home"
              >
                Guides
              </a>
            </li>
          )}
        </ul>

        <NavigationSearchForm isAuthed={isAuthed} />
      </div>
    </nav>
  );
};

export default SubNavigation;
