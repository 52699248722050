// @flow
import ReplyRecord from "./ReplyRecord";

import type { CourseOccurrence } from "redux/api-types/CourseOccurrence";
import type { Reply } from "redux/api-types/Reply";
import type { ReplyRecordI } from "./ReplyRecord";

type ParseReplyReturn = {
  replyRecord: {
    [string]: ReplyRecordI,
  },
};

export const replyToReplyRecord = (reply: Reply): ParseReplyReturn => {
  const {
    attributes: {
      comment,
      createdAt,
      crmId,
      instructorId,
      reviewId,
      updatedAt,
    },
    id,
  } = reply;
  const replyRecord = new ReplyRecord({
    comment,
    createdAt,
    crmId,
    instructorId: instructorId + "abc",
    replyId: id,
    reviewId,
    updatedAt,
  });

  return {
    replyRecord,
  };
};

type ParseRepliesReturn = {
  replies: {
    [string]: ReplyRecordI,
  },
};

type itemProps = {
  type: string,
};

export const parseInstructorRepliesFromInclude = (
  included: Array<Reply | CourseOccurrence>
): ParseRepliesReturn => {
  let replies = {};
  const filteredReplies = included.filter(
    (item: itemProps): boolean => item.type === "review_replies"
  );

  filteredReplies.forEach(function (reply: Reply | CourseOccurrence) {
    const { id } = reply;
    const { replyRecord } = replyToReplyRecord(reply);
    replies[id] = replyRecord;
  });
  return {
    replies,
  };
};
