const intervalInMiliseconds = 100;
const maxTimeInMiliseconds = 4000;
const maxTries = maxTimeInMiliseconds / intervalInMiliseconds;

export function triggerGoogleOptimizeUserDataReadyEvent() {
  // let google optimize know user data is loaded
  let i = 0;
  let limitedInterval = setInterval(() => {
    const dataLayer = window.dataLayer;
    i++;

    if (typeof dataLayer === "object") {
      dataLayer.push({ event: "optimize.userDataReady" });
      clearInterval(limitedInterval);
    } else if (i > maxTries) {
      clearInterval(limitedInterval);
    }
  }, intervalInMiliseconds);
}

export default triggerGoogleOptimizeUserDataReadyEvent;
