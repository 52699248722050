// @flow
/* eslint flowtype/require-return-type: 0 */
import fetch from "isomorphic-fetch";
import { take, put, call, fork } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { Intent } from "@blueprintjs/core";

import { showToast } from "redux/modules/toasts";
import { MetadataObj } from "shared/constants/flowTypes";
import { getAccessToken } from "shared/utilities/authCookie";
import { openDialog } from "redux/modules/app/app";
import { dialogs } from "redux/modules/app/constants";

export const CALL_API = Symbol("Call API");

type Options = {
  [string]: mixed,
};

type Error = {};

export type callApiReturnType = {
  type: Symbol,
  payload: {
    method: string,
    options?: Options,
    isImage?: boolean,
    endpoint: string,
    actionParams?: {},
    actions: {
      request: (mixed) => mixed,
      success: (mixed) => mixed,
      failure: (mixed) => mixed,
    },
    disableErrorToast?: boolean,
    toasts?: {
      pending?: string,
      success?: string,
      failure?: string,
    },
  },
};

export const makeApiCall = (
  method: string,
  endpoint: string,
  options: MetadataObj,
  isImage: boolean
) => {
  let body = "";
  if (isImage) {
    body = options;
  } else {
    body = options ? JSON.stringify(options) : null;
  }

  let headersDef = {
    Accept: "application/json",
    "X-Auth-Token": getAccessToken(),
    que: "pedo",
  };

  if (!isImage) {
    headersDef["Content-Type"] = "application/json";
  }

  const headers = new Headers(headersDef);

  let params = {
    method,
    mode: "cors",
    headers,
  };

  if (body) {
    params.body = body;
  }

  // eslint-disable-next-line flowtype/require-parameter-type
  return fetch(endpoint, params).then((response) => {
    if (!response.ok && typeof response.body !== "object") {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export function* fetchIt(action: callApiReturnType): Saga<void> {
  const {
    actions: { request, success, failure },
    disableErrorToast = false,
    toasts,
    endpoint,
    method,
    options,
    actionParams,
    isImage,
  } = action.payload;

  const requestAction = actionParams ? request(actionParams) : request();

  let pendingToast, successToast, failureToast;
  if (toasts) {
    pendingToast = toasts.pending;
    successToast = toasts.success;
    failureToast = toasts.failure;
  }
  yield put(requestAction);
  if (pendingToast) {
    const showPendingAction = showToast(pendingToast);
    yield put(showPendingAction);
  }

  try {
    let json = yield call(makeApiCall, method, endpoint, options, isImage);
    if (json.hasOwnProperty("errors") || json.hasOwnProperty("error")) {
      let errors = {
        errorMessage: "",
        errors: [],
      };
      if (json.hasOwnProperty("errors")) {
        errors.errors = json.errors;
      }
      if (
        json.hasOwnProperty("error") &&
        json.error.hasOwnProperty("description")
      ) {
        errors.errorMessage = json.error.description;
      }

      if (actionParams) {
        errors.actionParams = actionParams;
      }
      const failureAction = failure(errors);
      const failureActionErrors = failureAction.payload.errors;
      let errorDetails = failureActionErrors.map((error: mixed): string => {
        return error.message || error.detail;
      });
      if (errorDetails.length > 1) {
        errorDetails = errorDetails.join(", ");
      }
      const errorMessage = failureToast
        ? `${failureToast}: ${errorDetails}.`
        : `${errorDetails}.`;
      // eslint-disable-next-line no-console
      console.warn(errorMessage);
      if (errorMessage === "Invalid Auth Token.") {
        const timeoutAction = openDialog(dialogs.SESSION_TIMEOUT);
        yield put(timeoutAction);
      }

      yield put(failureAction);
      if (!disableErrorToast) {
        const showFailureAction = showToast(errorMessage, Intent.DANGER);
        yield put(showFailureAction);
      }
    } else {
      const successAction = success(json, actionParams);
      yield put(successAction);
      if (successToast) {
        const showSuccessAction = showToast(successToast, Intent.SUCCESS);
        yield put(showSuccessAction);
      }
    }
  } catch (error) {
    let errors = {
      errorMessage: error.toString(),
      errors: [],
    };
    if (actionParams) {
      errors.actionParams = actionParams;
    }
    const failureAction = failure(errors);
    yield put(failureAction);
    if (failureToast && !disableErrorToast) {
      const showFailureAction = showToast(failureToast, Intent.DANGER);
      yield put(showFailureAction);
    }
  }
}

export function* callApi(): Saga<void> {
  for (;;) {
    const action = yield take(CALL_API);
    yield fork(fetchIt, action);
  }
}

export default callApi;
