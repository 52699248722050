// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".shared-dialogs-SessionTimeoutDialog-SessionTimeoutDialog__actions--drttp{display:flex;justify-content:space-around;margin-top:1rem}", "",{"version":3,"sources":["webpack://./shared/dialogs/SessionTimeoutDialog/SessionTimeoutDialog.scss"],"names":[],"mappings":"AAAA,0EACE,YAAA,CACA,4BAAA,CACA,eAAA","sourcesContent":[".actions {\n  display: flex;\n  justify-content: space-around;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "shared-dialogs-SessionTimeoutDialog-SessionTimeoutDialog__actions--drttp"
};
export default ___CSS_LOADER_EXPORT___;
