// @flow
import React from "react";
import type { Node } from "react";
import { FormGroup, Checkbox } from "@blueprintjs/core";

type Props = {
  containerClass: string,
  inputClass: string,
  input: {
    name: string,
    value: string,
  },
  label: string,
  helperText: string,
  type: string,
  placeholder: string,
  disabled: boolean,
  meta: {
    touched: boolean,
    error: boolean,
  },
};

let RenderCheckbox = ({
  containerClass,
  inputClass,
  input,
  placeholder,
  disabled,
  label,
  helperText,
  type,
  meta: { touched, error },
}: Props): Node => (
  <FormGroup
    className={containerClass}
    helperText={helperText}
    labelFor={input.name}
  >
    <Checkbox className={inputClass} label={label} {...input} />
  </FormGroup>
);

RenderCheckbox.defaultProps = {
  containerClass: "",
  inputClass: "",
  label: "",
  helperText: "",
  type: "checkbox",
  placeholder: "",
};

export default RenderCheckbox;
