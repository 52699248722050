// @flow
import React from "react";
import type { Node } from "react";
import { Dialog as BPDialog } from "@blueprintjs/core";

import Close from "./Close";
import Title from "./Title";

import styles from "./Dialog.scss";

type Props = {
  isOpen: boolean,
  isLarge: boolean,
  isDark: boolean,
  autosizeContent: boolean,
  onClose?: () => mixed,
  title: string,
  subhead?: string,
  Icon: () => Node,
  children: Node,
  [string]: mixed,
};

let Dialog = ({
  isOpen,
  isLarge,
  isDark,
  autosizeContent,
  onClose,
  title,
  subhead,
  Icon,
  intent,
  children,
  ...props
}: Props): Node => {
  if (!isOpen) {
    return null;
  }

  return (
    <BPDialog
      className={`${styles.dialog} ${isLarge ? styles.large : ""} ${
        isDark ? styles.dark : ""
      }`}
      isOpen
      onClose={onClose}
      {...props}
    >
      {!!onClose && <Close onClick={onClose} isDark={isDark} />}
      <div className={`${styles.main}`}>
        <Title
          title={title}
          subhead={subhead}
          Icon={Icon}
          intent={intent}
          isDark={isDark}
        />
        <div
          className={`${styles.content} ${
            autosizeContent ? styles.autosize : ""
          }`}
        >
          {children}
        </div>
      </div>
    </BPDialog>
  );
};

Dialog.displayName = "Dialog";

Dialog.defaultProps = {
  title: "",
};

export default Dialog;
