// @flow
import { Record } from "immutable";

export type registrationRecordType = {
  url: string,
  email: string,
  phone: string,
};

const RegistrationRecord = Record({
  url: null,
  email: null,
  phone: null,
});

export default RegistrationRecord;
