// @flow
import type Curriculum from "../createCourse";
import CurriculumRecord from "../records/CurriculumRecord";
import CurriculumModuleRecord from "../records/CurriculumModuleRecord";
import type { CurriculumRecordI } from "../records/CurriculumRecord";
import type { CurriculumModuleRecordI } from "../records/CurriculumModuleRecord";
import { VOID } from "shared/constants/form";

type curriculaType = {};

type CurriculaOptionType = {
  value: string,
  label: string,
};
type ParseCurriculaReturn = {
  curriculumRecord: {
    [string]: CurriculumRecordI,
  },
};
type ParseCurriculuaModulesReturn = {
  curriculumModuleRecord: {
    [string]: CurriculumModuleRecordI,
  },
};

export const parseCurriculaOccurences = (
  curriculum: Curriculum
): ParseCurriculaReturn => {
  let curriculumModules = [];
  const {
    id,
    attributes: {
      minimumChargeAmount: minimumPrice,
      applicationFeeFlatAmount: flatFee,
      ...attributes
    },
  } = curriculum;

  if (curriculum.relationships.curriculumModules.data.length > 0) {
    curriculumModules = curriculum.relationships.curriculumModules.data;
  }

  const curriculumRecord = new CurriculumRecord({
    id,
    curriculumModules,
    minimumPrice,
    flatFee,
    ...attributes,
  });
  return { curriculumRecord };
};

export const parseCurriculaModulesOccurences = (
  curriculum: Curriculum
): ParseCurriculuaModulesReturn => {
  const {
    id,
    attributes: { name, description },
  } = curriculum;

  const curriculumModuleRecord = new CurriculumModuleRecord({
    id,
    name,
    description,
  });
  return { curriculumModuleRecord };
};

export const parseInstructorCurricula = (
  data: Array<Curriculum>
): curriculaType => {
  let curricula = {};

  data.forEach(function (curriculum: Curriculum) {
    const { id } = curriculum;
    const { curriculumRecord } = parseCurriculaOccurences(curriculum);
    curricula[id] = curriculumRecord;
  });
  return curricula;
};

export const parseInstructorCurriculaModules = (
  data: Array<Curriculum>
): curriculaType => {
  let curriculaModules = {};

  data.forEach(function (curriculum: Curriculum) {
    const { id } = curriculum;
    const { curriculumModuleRecord } =
      parseCurriculaModulesOccurences(curriculum);
    curriculaModules[id] = curriculumModuleRecord;
  });
  return curriculaModules;
};

export const parseInstructorCurriculaToOptions = (
  data: Array<Curriculum>
): Array<CurriculaOptionType> => {
  let curricula = [
    {
      value: VOID,
      label: "Non-USCCA curriculum",
    },
  ];

  data.forEach(function ({ id, attributes: { name } }: Curriculum) {
    curricula.push({
      value: id,
      label: name,
    });
  });

  return curricula;
};
export const parseInstructorCurriculaModulesOptions = (
  data: Array<Curriculum>
): Array<CurriculaOptionType> => {
  let curriculaModulesOptions = [
    {
      value: "",
      label: "None",
    },
  ];
  data.forEach(function ({ id, attributes: { name } }: Curriculum) {
    curriculaModulesOptions.push({
      value: id,
      label: name,
    });
  });
  return curriculaModulesOptions;
};
