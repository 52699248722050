// @flow
import { handleActions } from "redux-actions";
import { put, take, select } from "redux-saga/effects";
import type { Saga } from "redux-saga";

import { CALL_API } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import type { callApiReturnType } from "redux/middleware/api";
import { NONE, PENDING, SUCCESS, ERROR } from "shared/constants/status";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { Registrant as RegistrantType } from "redux/api-types/Registrant";
import {
  FUTURE,
  TO_BE_GRADED,
} from "../manageCourseOccurrences//occurrencesTypes";
import {
  getCourseOccurrencesCall,
  clear as clearManagedCourseOccurrences,
} from "../manageCourseOccurrences/manageCourseOccurrences";
import { occurrenceParentIdSelector } from "../occurrence/occurrence";
import validateAdd from "./validateAdd";

const namespace: string = `${APP_NAMESPACE}/registrant`;

// constants
export const EDIT_REGISTRANT_FORM: string = "editRegistrant";

// Actions
export const UPDATE_ADD_FORM: string = `${namespace}/ADD_FORM/UPDATE`;
export const ADD_REGISTRANT_REQUEST: string = `${namespace}/ADD/REQUEST`;
export const ADD_REGISTRANT_SUCCESS: string = `${namespace}/ADD/SUCCESS`;
export const ADD_REGISTRANT_ERROR: string = `${namespace}/ADD/ERROR`;
export const REMOVE_REGISTRANT_REQUEST: string = `${namespace}/REMOVE/REQUEST`;
export const REMOVE_REGISTRANT_SUCCESS: string = `${namespace}/REMOVE/SUCCESS`;
export const REMOVE_REGISTRANT_ERROR: string = `${namespace}/REMOVE/ERROR`;
export const EDIT_REGISTRANT_REQUEST: string = `${namespace}/EDIT/REQUEST`;
export const EDIT_REGISTRANT_SUCCESS: string = `${namespace}/EDIT/SUCCESS`;
export const EDIT_REGISTRANT_ERROR: string = `${namespace}/EDIT/ERROR`;
export const RESCHEDULE_REGISTRANT_REQUEST: string = `${namespace}/RESCHEDULE/REQUEST`;
export const RESCHEDULE_REGISTRANT_SUCCESS: string = `${namespace}/RESCHEDULE/SUCCESS`;
export const RESCHEDULE_REGISTRANT_ERROR: string = `${namespace}/RESCHEDULE/ERROR`;
export const OPEN_DRAWER: string = `${namespace}/DRAWER/OPEN`;
export const CLOSE_DRAWER: string = `${namespace}/DRAWER/CLOSE`;
export const SET_DRAWER_STEP: string = `${namespace}/DRAWER/STEP/SET`;
export const TOGGLE_RESCHEDULE_OCCURRENCE_TYPE: string = `${namespace}/DRAWER/RESCHEDULE/OCCURRENCE_TYPE/TOGGLE`;
export const SET_RESCHEDULE_OCCURRENCE_ID: string = `${namespace}/DRAWER/RESCHEDULE/OCCURRENCE/SET/ID`;
export const TOGGLE_RESCHEDULE_SHOW_ALL: string = `${namespace}/DRAWER/RESCHEDULE/OCCURRENCE/TOGGLE/SHOW_ALL`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creators
type UpdateAddFormReturn = {
  type: string,
  payload: {
    name: string,
    value: string,
  },
};

export const updateAddForm = (
  name: string,
  value: string
): UpdateAddFormReturn => ({
  type: UPDATE_ADD_FORM,
  payload: {
    name,
    value,
  },
});

export const requestAddRegistrant = (): simpleReduxAction => ({
  type: ADD_REGISTRANT_REQUEST,
});

type RegistrantReturn = {
  data: RegistrantType,
};

export type RegistrantAction = {
  type: string,
  payload: {
    registrant: RegistrantReturn,
  },
  meta: {
    receivedAt: number,
  },
};

export const receiveAddRegistrant = (
  json: RegistrantReturn
): RegistrantAction => ({
  type: ADD_REGISTRANT_SUCCESS,
  payload: {
    registrant: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const addRegistrantRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: ADD_REGISTRANT_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestRescheduleRegistrant = (): simpleReduxAction => ({
  type: RESCHEDULE_REGISTRANT_REQUEST,
});

export const receiveRescheduleRegistrant = (
  json: RegistrantReturn
): RegistrantAction => ({
  type: RESCHEDULE_REGISTRANT_SUCCESS,
  payload: {
    registrant: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const rescheduleRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: RESCHEDULE_REGISTRANT_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export type RequestRemoveRegistrantReturn = {
  id: string,
};

export type RequestRemoveRegistrantAction = {
  type: string,
  payload: {
    id: string,
  },
};

export const requestRemoveRegistrant = ({
  id,
}: RequestRemoveRegistrantReturn): RequestRemoveRegistrantAction => ({
  type: REMOVE_REGISTRANT_REQUEST,
  payload: {
    id,
  },
});

export const receiveRemoveRegistrant = (
  json: RegistrantReturn
): RegistrantAction => ({
  type: REMOVE_REGISTRANT_SUCCESS,
  payload: {
    registrant: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const removeRegistrantRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: REMOVE_REGISTRANT_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

type RequestEditRegistrantParams = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
};

export type RequestEditRegistrantAction = {
  type: string,
  payload: {
    registrants: RequestEditRegistrantParams,
  },
  meta: {
    receivedAt: number,
  },
};

export const requestEditRegistrant = (
  registrant: RequestEditRegistrantParams
): RequestEditRegistrantAction => ({
  type: EDIT_REGISTRANT_REQUEST,
  payload: registrant,
});

export const receiveEditRegistrant = (
  json: RegistrantReturn
): RegistrantAction => ({
  type: EDIT_REGISTRANT_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export type EditRegistrantRequestErrorAction = {
  type: string,
  error: true,
  payload: {
    errorMessage: string,
    errors: Array<apiError>,
  },
  meta: {
    receivedAt: number,
  },
};

export const editRegistrantRequestError = (
  error: EditRegistrantRequestErrorAction
): apiErrorAction => ({
  type: EDIT_REGISTRANT_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

type OpenDrawerAction = {
  payload: {
    id: string,
    type: string,
  },
} & simpleReduxAction;

export const openDrawer = (type: string, id: string): OpenDrawerAction => ({
  type: OPEN_DRAWER,
  payload: {
    type,
    id,
  },
});

export const closeDrawer = (): simpleReduxAction => ({
  type: CLOSE_DRAWER,
});

type SetDrawerStepAction = {
  payload: {
    step: number,
  },
} & simpleReduxAction;

export const setDrawerStep = (step: number): SetDrawerStepAction => ({
  type: SET_DRAWER_STEP,
  payload: {
    step,
  },
});

export const toggleRescheduleOccurrenceType = (): simpleReduxAction => ({
  type: TOGGLE_RESCHEDULE_OCCURRENCE_TYPE,
});

type SetRescheduleOccurrenceIdAction = {
  payload: {
    step: number,
  },
} & simpleReduxAction;

export const setRescheduleOccurrenceId = (
  id: string
): SetRescheduleOccurrenceIdAction => ({
  type: SET_RESCHEDULE_OCCURRENCE_ID,
  payload: {
    id,
  },
});

export const toggleRescheduleShowAll = (): simpleReduxAction => ({
  type: TOGGLE_RESCHEDULE_SHOW_ALL,
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

export const drawerTypes = {
  EDIT: "edit",
  RESCHEDULE: "reschedule",
};

// Async Actions
export const addRegistrant = (
  occurrenceId: string,
  registrant: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
  }
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: "/api/training/registrants",
    options: {
      occurrenceId,
      ...registrant,
      isManualRegistrant: true,
    },
    actions: {
      request: requestAddRegistrant,
      success: receiveAddRegistrant,
      failure: addRegistrantRequestError,
    },
    toasts: {
      success: "Student Added To Roster",
      failure: "Error Adding Student To Roster",
    },
  },
});

type Registrant = {
  firstName: string,
  lastName: string,
  phone: string,
};

export const editRegistrant = (
  id: string,
  registrant: Registrant
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "PATCH",
    endpoint: `/api/training/registrants/${id}`,
    options: {
      ...registrant,
    },
    actionParams: {
      id,
      ...registrant,
    },
    actions: {
      request: requestEditRegistrant,
      success: receiveEditRegistrant,
      failure: editRegistrantRequestError,
    },
    toasts: {
      success: "Student Updated",
      failure: "Error Updating Student",
    },
  },
});

export const rescheduleRegistrant = (
  id: string,
  occurrenceId: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "PATCH",
    endpoint: `/api/training/registrants/${id}`,
    options: {
      occurrenceId,
    },
    actionParams: {
      id,
      occurrenceId,
    },
    actions: {
      request: requestRescheduleRegistrant,
      success: receiveRescheduleRegistrant,
      failure: rescheduleRequestError,
    },
    toasts: {
      success: "Student rescheduled",
      failure: "Problem rescheduling Student",
    },
  },
});

export const removeRegistrant = (registrantId: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "DELETE",
    endpoint: `/api/training/registrants/${registrantId}`,
    actionParams: {
      id: registrantId,
    },
    actions: {
      request: requestRemoveRegistrant,
      success: receiveRemoveRegistrant,
      failure: removeRegistrantRequestError,
    },
    toasts: {
      success: "Student Removed From Roster",
      failure: "Error Removing Student From Roster",
    },
  },
});

// Initial State
export type State = {
  addStatus: string,
  isAddPending: boolean,
  isAddError: boolean,
  addErrorMessage: string,
  addErrors: apiErrorReturn,
  addForm: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
  },
  addFormErrors: {
    [string]: string,
  },
  drawer: {
    type: string,
    step: number,
    registrantId: string,
    rescheduleShowUpcoming: boolean,
    rescheduleSelectedOccurrenceId: string,
    rescheduleIsShowAll: boolean,
  },
  rescheduleStatus: string,
  rescheduleErrorMessage: string,
  rescheduleErrors: apiErrorReturn,
};

const drawerInitialState = {
  type: "",
  step: 0,
  registrantId: "",
  rescheduleShowUpcoming: true,
  rescheduleSelectedOccurrenceId: "",
  rescheduleIsShowAll: "",
};

const initialState: State = {
  addStatus: NONE,
  isAddPending: false,
  isAddError: false,
  addErrorMessage: "",
  addErrors: [],
  addForm: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  addFormErrors: {},
  drawer: drawerInitialState,
};

// Selectors
const rescheduleShowUpcomingSelector = ({
  registrant: {
    drawer: { rescheduleShowUpcoming },
  },
}: {
  registrant: State,
}): boolean => rescheduleShowUpcoming;

// Sagas
export function* onSuccessfulRegistrantEdit(): Saga<void> {
  for (;;) {
    yield take(EDIT_REGISTRANT_SUCCESS);
    const closeAction = closeDrawer();
    yield put(closeAction);
  }
}

export function* onOpenRegistrantDrawer(): Saga<void> {
  for (;;) {
    const {
      payload: { type },
    } = yield take(OPEN_DRAWER);
    if (type === drawerTypes.RESCHEDULE) {
      const parentId = yield select(occurrenceParentIdSelector);
      const page = 1;
      const perPage = 10000; // no pagination so lets make the occurrences we are showing artifically high
      yield put(getCourseOccurrencesCall(parentId, FUTURE, page, perPage));
    }
  }
}

export function* onCloseRegistrantDrawer(): Saga<void> {
  for (;;) {
    yield take(CLOSE_DRAWER);
    yield put(clearManagedCourseOccurrences());
  }
}

export function* onToggleRescheduleOccurrenceType(): Saga<void> {
  for (;;) {
    yield take(TOGGLE_RESCHEDULE_OCCURRENCE_TYPE);
    const parentId = yield select(occurrenceParentIdSelector);
    const showUpcoming = yield select(rescheduleShowUpcomingSelector);
    const type = showUpcoming ? FUTURE : TO_BE_GRADED;
    const page = 1;
    const perPage = 10000; // no pagination so lets make the occurrences we are showing artifically high
    yield put(clearManagedCourseOccurrences());
    yield put(getCourseOccurrencesCall(parentId, type, page, perPage));
  }
}

// Reducer
const registrant = handleActions(
  {
    [UPDATE_ADD_FORM]: (state: State, action: UpdateAddFormReturn): State => {
      const { name, value } = action.payload;
      const addForm = {
        ...state.addForm,
        [name]: value,
      };
      const addFormErrors = validateAdd(addForm);
      return {
        ...state,
        addForm,
        addFormErrors,
      };
    },
    [ADD_REGISTRANT_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      addStatus: PENDING,
      isAddPending: true,
      isAddError: false,
      addErrorMessage: "",
      addErrors: [],
    }),
    [ADD_REGISTRANT_SUCCESS]: (
      state: State,
      action: RegistrantAction
    ): State => ({
      ...state,
      isAddPending: false,
      status: SUCCESS,
      addForm: initialState.addForm,
    }),
    [ADD_REGISTRANT_ERROR]: (
      state: State,
      {
        payload: { errorMessage: addErrorMessage, errors: addErrors },
      }: apiErrorAction
    ): State => ({
      ...state,
      isAddPending: false,
      isAddError: true,
      addStatus: ERROR,
      addErrorMessage,
      addErrors,
    }),
    [OPEN_DRAWER]: (
      state: State,
      { payload: { type, id } }: OpenDrawerAction
    ): State => ({
      ...state,
      drawer: {
        ...drawerInitialState,
        type,
        registrantId: id,
      },
    }),
    [CLOSE_DRAWER]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      drawer: drawerInitialState,
      rescheduleStatus: NONE,
      rescheduleErrorMessage: "",
      rescheduleErrors: [],
    }),
    [SET_DRAWER_STEP]: (
      state: State,
      { payload: { step } }: SetDrawerStepAction
    ): State => ({
      ...state,
      drawer: {
        ...state.drawer,
        step,
      },
    }),
    [TOGGLE_RESCHEDULE_OCCURRENCE_TYPE]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      drawer: {
        ...state.drawer,
        rescheduleShowUpcoming: !state.drawer.rescheduleShowUpcoming,
        rescheduleSelectedOccurrenceId: "",
        rescheduleIsShowAll: false,
      },
    }),
    [SET_RESCHEDULE_OCCURRENCE_ID]: (
      state: State,
      { payload: { id } }: SetRescheduleOccurrenceIdAction
    ): State => ({
      ...state,
      drawer: {
        ...state.drawer,
        rescheduleSelectedOccurrenceId: id,
      },
    }),
    [TOGGLE_RESCHEDULE_SHOW_ALL]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      drawer: {
        ...state.drawer,
        rescheduleIsShowAll: !state.drawer.rescheduleIsShowAll,
      },
    }),
    [RESCHEDULE_REGISTRANT_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      rescheduleStatus: PENDING,
      rescheduleErrorMessage: "",
      rescheduleErrors: [],
    }),
    [RESCHEDULE_REGISTRANT_SUCCESS]: (
      state: State,
      action: RegistrantAction
    ): State => ({
      ...state,
      drawer: drawerInitialState,
      rescheduleStatus: SUCCESS,
    }),
    [RESCHEDULE_REGISTRANT_ERROR]: (
      state: State,
      {
        payload: {
          errorMessage: rescheduleErrorMessage,
          errors: rescheduleErrors,
        },
      }: apiErrorAction
    ): State => ({
      ...state,
      rescheduleStatus: ERROR,
      rescheduleErrorMessage,
      rescheduleErrors,
    }),
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default registrant;
