// @flow
import { Record } from "immutable";

export type ReplyRecordI = {
  comment: string,
  createdAt: number,
  instructorId: string,
  replyId: string,
  reviewId: string,
};

const ReplyRecord = Record({
  comment: "",
  createdAt: 0,
  instructorId: "",
  replyId: "",
  reviewId: "",
});

export default ReplyRecord;
