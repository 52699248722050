// @flow
import React from "react";
import type { Node } from "react";

import styles from "./Title.scss";
import { FaExclamationCircle } from "react-icons/fa";

type Props = {
  Icon: () => Node,
  title: string,
  subhead?: string,
  isDark: boolean,
  intent: string,
};

let Dialog = ({ Icon, title, subhead, isDark, intent }: Props): Node => {
  if (!title) {
    return null;
  }

  return (
    <>
      <h2 className={styles.title}>
        {!!Icon && (
          <Icon
            className={styles.icon}
            color={styles[intent]}
            stroke={styles[intent]}
          />
        )}
        {intent && !Icon && (
          <FaExclamationCircle
            className={styles.icon}
            color={styles[intent]}
            stroke={styles[intent]}
          />
        )}
        <span>{title}</span>
      </h2>
      {!!subhead && (
        <span className={`${styles.subhead} ${isDark ? styles.dark : ""}`}>
          {subhead}
        </span>
      )}
    </>
  );
};

Dialog.displayName = "Dialog";

Dialog.defaultProps = {
  title: "",
  intent: "",
};

export default Dialog;
