// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, PENDING, SUCCESS, ERROR } from "shared/constants/status";
import type { CourseOccurrence as CourseOccurrenceType } from "redux/api-types/CourseOccurrence";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";

// Actions
const namespace: string = `${APP_NAMESPACE}/manageCourseOccurrence`;
export const OPEN_ADD_OCCURRENCE_MODAL: string = `${namespace}/OPEN_ADD_OCCURRENCE_MODAL`;
export const CLOSE_ADD_OCCURRENCE_MODAL: string = `${namespace}/CLOSE_ADD_OCCURRENCE_MODAL`;
export const ADD_OCCURRENCE_REQUEST: string = `${namespace}/ADD_OCCURRENCE_REQUEST`;
export const ADD_OCCURRENCE_SUCCESS: string = `${namespace}/ADD_OCCURRENCE_SUCCESS`;
export const ADD_OCCURRENCE_ERROR: string = `${namespace}/ADD_OCCURRENCE_ERROR`;
export const EDIT_OCCURRENCE_REQUEST: string = `${namespace}/EDIT_OCCURRENCE_REQUEST`;
export const EDIT_OCCURRENCE_SUCCESS: string = `${namespace}/EDIT_OCCURRENCE_SUCCESS`;
export const EDIT_OCCURRENCE_ERROR: string = `${namespace}/EDIT_OCCURRENCE_ERROR`;
export const DELETE_OCCURRENCE_REQUEST: string = `${namespace}/DELETE_OCCURRENCE_REQUEST`;
export const DELETE_OCCURRENCE_SUCCESS: string = `${namespace}/DELETE_OCCURRENCE_SUCCESS`;
export const DELETE_OCCURRENCE_ERROR: string = `${namespace}/DELETE_OCCURRENCE_ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creator Interfaces
type OpenAddOccurrenceModalReturn = {
  payload: {
    occurrenceId: string,
    promotionId: string,
  },
} & simpleReduxAction;

type OccurrenceReponse = {
  data: CourseOccurrenceType,
};

export type AddOccurrenceSuccessAction = {
  payload: {
    occurrence: OccurrenceReponse,
  },
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export type EditOccurrenceSuccessAction = {
  payload: {
    occurrence: OccurrenceReponse,
  },
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export type RequestDeleteOccurrenceAction = {
  payload: {
    id: string,
  },
} & simpleReduxAction;

export type DeleteOccurrenceSuccessAction = {
  payload: {
    occurrence: OccurrenceReponse,
  },
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

// Action Creators
export const openAddOccurrenceModal = (
  occurrenceId: ?string,
  promotionId: ?string
): OpenAddOccurrenceModalReturn => ({
  type: OPEN_ADD_OCCURRENCE_MODAL,
  payload: {
    occurrenceId: typeof occurrenceId === "string" ? occurrenceId : "",
    promotionId,
  },
});

export const closeAddOccurrenceModal = (): simpleReduxAction => ({
  type: CLOSE_ADD_OCCURRENCE_MODAL,
});

export const requestAddOccurrence = (): simpleReduxAction => ({
  type: ADD_OCCURRENCE_REQUEST,
});

export const addOccurrenceSuccess = (
  json: OccurrenceReponse
): AddOccurrenceSuccessAction => ({
  type: ADD_OCCURRENCE_SUCCESS,
  payload: {
    occurrence: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const addOccurrenceError = (error: apiErrorParams): apiErrorAction => ({
  type: ADD_OCCURRENCE_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestEditOccurrence = (): simpleReduxAction => ({
  type: EDIT_OCCURRENCE_REQUEST,
});

export const editOccurrenceSuccess = (
  json: OccurrenceReponse
): EditOccurrenceSuccessAction => ({
  type: EDIT_OCCURRENCE_SUCCESS,
  payload: {
    occurrence: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const editOccurrenceError = (error: apiErrorParams): apiErrorAction => ({
  type: EDIT_OCCURRENCE_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestDeleteOccurrence = ({
  id,
}: {
  id: string,
}): RequestDeleteOccurrenceAction => ({
  type: DELETE_OCCURRENCE_REQUEST,
  payload: {
    id,
  },
});

export const deleteOccurrenceSuccess = (
  json: OccurrenceReponse
): DeleteOccurrenceSuccessAction => ({
  type: DELETE_OCCURRENCE_SUCCESS,
  payload: {
    occurrence: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const deleteOccurrenceError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: DELETE_OCCURRENCE_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async Action Creators
type DateType = {
  startTime: Date,
  endTime: Date,
};

export type AddCourseOccurrenceParams = {
  courseId: string,
  paromotionId: string,
  dates: Array<DateType>,
};

export const addCourseOccurrence = (
  options: AddCourseOccurrenceParams
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: "/api/training/occurrences",
    options,
    actions: {
      request: requestAddOccurrence,
      success: addOccurrenceSuccess,
      failure: addOccurrenceError,
    },
    toasts: {
      success: "Class Added",
    },
  },
});

export type EditCourseOccurrenceParams = {
  occurrenceId: string,
  paromotionId: string,
  dates: Array<DateType>,
};

export const editCourseOccurrence = ({
  occurrenceId,
  ...options
}: EditCourseOccurrenceParams): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "PATCH",
    endpoint: `/api/training/occurrences/${occurrenceId}`,
    options,
    actions: {
      request: requestEditOccurrence,
      success: editOccurrenceSuccess,
      failure: editOccurrenceError,
    },
    toasts: {
      success: "Class Updated",
    },
  },
});

export const deleteCourseOccurrence = (id: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "DELETE",
    endpoint: `/api/training/occurrences/${id}`,
    actionParams: {
      id,
    },
    actions: {
      request: requestDeleteOccurrence,
      success: deleteOccurrenceSuccess,
      failure: deleteOccurrenceError,
    },
    toasts: {
      success: "Class Deleted",
      failure: "Problem Deleting Class",
    },
  },
});

// Initial State
export type State = {
  isAddOccurrenceModalOpen: boolean,
  addOccurrenceModalEditId: string,
  promotionId: string,
  requestAddOccurrenceStatus: string,
  addOccurrenceErrorMessage: string,
  addOccurrenceErrors: apiErrorReturn,
  requestEditOccurrenceStatus: string,
  editOccurrenceErrorMessage: string,
  editOccurrenceErrors: apiErrorReturn,
};

const initialState: State = {
  isAddOccurrenceModalOpen: false,
  addOccurrenceModalEditId: "",
  promotionId: "",
  requestAddOccurrenceStatus: NONE,
  addOccurrenceErrorMessage: "",
  addOccurrenceErrors: [],
  requestEditOccurrenceStatus: NONE,
  editOccurrenceErrorMessage: "",
  editOccurrenceErrors: [],
};

// reducer
const manageCourseOccurrence = handleActions(
  {
    [OPEN_ADD_OCCURRENCE_MODAL]: (
      state: State,
      {
        payload: { occurrenceId: addOccurrenceModalEditId, promotionId },
      }: simpleReduxAction
    ): State => ({
      ...state,
      isAddOccurrenceModalOpen: true,
      addOccurrenceModalEditId,
      promotionId,
    }),
    [CLOSE_ADD_OCCURRENCE_MODAL]: (
      state: State,
      action: simpleReduxAction
    ): State => initialState,
    [ADD_OCCURRENCE_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      requestAddOccurrenceStatus: PENDING,
      addOccurrenceErrorMessage: "",
      addOccurrenceErrors: [],
    }),
    [ADD_OCCURRENCE_SUCCESS]: (
      state: State,
      action: AddOccurrenceSuccessAction
    ): State => ({
      ...state,
      isAddOccurrenceModalOpen: false,
      requestAddOccurrenceStatus: SUCCESS,
    }),
    [ADD_OCCURRENCE_ERROR]: (
      state: State,
      {
        payload: {
          errorMessage: addOccurrenceErrorMessage,
          errors: addOccurrenceErrors,
        },
      }: apiErrorAction
    ): State => ({
      ...state,
      requestAddOccurrenceStatus: ERROR,
      addOccurrenceErrorMessage,
      addOccurrenceErrors,
    }),
    [EDIT_OCCURRENCE_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      requestEditOccurrenceStatus: PENDING,
      editOccurrenceErrorMessage: "",
      editOccurrenceErrors: [],
    }),
    [EDIT_OCCURRENCE_SUCCESS]: (
      state: State,
      action: EditOccurrenceSuccessAction
    ): State => ({
      ...state,
      isAddOccurrenceModalOpen: false,
      requestEditOccurrenceStatus: SUCCESS,
    }),
    [EDIT_OCCURRENCE_ERROR]: (
      state: State,
      {
        payload: {
          errorMessage: editOccurrenceErrorMessage,
          errors: editOccurrenceErrors,
        },
      }: apiErrorAction
    ): State => ({
      ...state,
      requestEditOccurrenceStatus: ERROR,
      editOccurrenceErrorMessage,
      editOccurrenceErrors,
    }),
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default manageCourseOccurrence;
