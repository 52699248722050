// @flow
import { Record } from "immutable";

export type OccurrenceRecordI = {
  canHaveRegistrants: false,
  canSubmitResults: false,
  capacity: number,
  courseEditLink: string,
  courseId: string,
  courseLink: string,
  courseName: string,
  courseRegistrantsLink: string,
  dateSubmitted: string,
  id: string,
  isResultsSubmitted: false,
  manageCourseLink: string,
  numberOfRegistrants: number,
  openSeats: number,
  status: string,
  groupByMonth: number,
  dateIds: Array<number>,
};

const OccurrenceRecord = Record({
  canHaveRegistrants: false,
  canSubmitResults: false,
  capacity: 0,
  courseEditLink: "",
  courseId: "",
  courseLink: "",
  courseName: "",
  courseRegistrantsLink: "",
  dateSubmitted: "",
  id: "",
  isResultsSubmitted: false,
  manageCourseLink: "",
  numberOfRegistrants: 0,
  openSeats: 0,
  status: "",
  groupByMonth: 1,
  dateIds: [],
});

export default OccurrenceRecord;
