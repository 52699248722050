// @flow
import styled, { css } from "styled-components";
import { colors } from "shared/constants/colors";
import { intent } from "shared/constants/intent";
import type { Props } from "./Button";

const { SNOW, STONE, BILLOW, SOOT, SOSUKE, SOSUKE_DARK } = colors;
const { PRIMARY, SECONDARY } = intent;
const SECONDARY_HOVER = "#444546";

const disabledStyle = `
  &:disabled {
    color: ${SNOW};
    cursor: not-allowed;
    background-color: ${STONE};
    border-color: ${STONE};
  }
`;

export const Button = styled.button`
  background-color: ${SNOW};
  border-color: ${BILLOW};
  border-radius: 5px;
  border-width: 1px;
  border-color: ${BILLOW};
  color: ${BILLOW};
  cursor: pointer;
  font-family: ${({ theme: { impactFont } }: Props): string => impactFont};
  font-weight: ${({ theme: { impactFontBoldWeight } }: Props): number =>
    impactFontBoldWeight};
  font-size: 1rem;
  line-height: 1;
  margin: 4px 8px 4px 0;
  padding: 11px 28px;
  text-transform: ${({ intent }: Props): string =>
    intent === PRIMARY ? "uppercase" : "capitalize"};

  &:hover {
    color: ${SNOW};
    background-color: ${BILLOW};
  }

  ${disabledStyle}

  ${({ intent }: Props): string =>
    intent === PRIMARY &&
    css`
      background-color: ${SOSUKE};
      border-color: ${SOSUKE};
      color: ${SOOT};

      &:hover {
        background-color: ${SOSUKE_DARK};
        border-color: ${SOSUKE_DARK};
        color: ${SOOT};
      }

      ${disabledStyle}
    `}

    ${({ intent }: Props): string =>
    intent === SECONDARY &&
    css`
      background-color: ${SOOT};
      border-color: ${SOOT};
      color: ${SNOW};

      &:hover {
        background-color: ${SECONDARY_HOVER};
        border-color: ${SECONDARY_HOVER};
        color: ${SNOW};
      }

      ${disabledStyle}
    `}
`;
