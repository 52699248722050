// @flow
import { Record } from "immutable";

export type CurriculumModuleRecordType = {
  id: string,
  type: string,
  attributes: {
    name: string,
    description: string,
    receivedUponCompletion: Array<string>,
  },
};

const CurriculumModuleRecord = Record({
  id: "",
  type: "",
  attributes: {
    name: "",
    description: "",
    receivedUponCompletion: [],
  },
});

export default CurriculumModuleRecord;
