// @flow
import { handleActions } from "redux-actions";
import { ERROR, PENDING, SUCCESS } from "shared/constants/status";

// Type imports
import type { Attributes } from "./RosterUploadResponseTypes";
import type { ReceiveRosterUploadReturn } from "./rosterUploadActionCreators";
import type {
  apiErrorAction,
  apiErrorReturn,
  simpleReduxAction,
} from "shared/constants/flowTypes";

// Action inports
import {
  BULK_ROSTER_UPLOAD_REQUEST,
  BULK_ROSTER_UPLOAD_REQUEST_SUCCESS,
  BULK_ROSTER_UPLOAD_REQUEST_ERROR,
  OPEN_ROSTER_MODAL,
  CLOSE_ROSTER_MODAL,
  CLEAR,
  CLEAR_API_STATE,
} from "./rosterUploadActions";

// Initial State
export type State = {
  errors: apiErrorReturn,
  errorMessage: string,
  status: string,
  attributes: Attributes,
  isOpen: boolean,
};

const initialState: State = {
  errors: [],
  errorMessage: "",
  status: "",
  attributes: {},
  isOpen: false,
};

const rosterUpload = handleActions(
  {
    [OPEN_ROSTER_MODAL]: (state: State, action: simpleReduxAction): State => {
      return {
        ...state,
        isOpen: true,
      };
    },
    [CLOSE_ROSTER_MODAL]: (state: State, action: simpleReduxAction): State => {
      return {
        ...state,
        isOpen: false,
      };
    },
    [BULK_ROSTER_UPLOAD_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => {
      return {
        ...state,
        status: PENDING,
      };
    },
    [BULK_ROSTER_UPLOAD_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveRosterUploadReturn
    ): State => {
      return {
        ...state,
        attributes: action.payload.data.attributes,
        status: SUCCESS,
      };
    },
    [BULK_ROSTER_UPLOAD_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        errors: errors,
        errorMessage: errorMessage,
      };
    },
    [CLEAR_API_STATE]: (state: State, action: simpleReduxAction): State => {
      return {
        ...state,
        errors: [],
        errorMessage: "",
        status: "",
        attributes: {},
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default rosterUpload;
