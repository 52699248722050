// @flow
const { URL_LOGIN, APP_URL } = process.env;
const loginUrl = URL_LOGIN ? URL_LOGIN : "";
const baseUrl = APP_URL ? APP_URL : "";

export const createUuid = (): string => {
  let random;
  let uuid = "";

  for (let i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0;
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += "-";
    }
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
  }

  return uuid;
};

export const urlParamToArray = (
  param: string | Array<string>
): Array<string> => {
  const paramType = typeof param;

  if (Array.isArray(param)) {
    return param;
  }

  if (paramType === "string") {
    return [param];
  }

  return [];
};

export const getLoginUrl = (
  pathname: string = "",
  search: string = ""
): string => {
  const redirectUrl = `${baseUrl}${pathname}${search}`;

  if (redirectUrl === baseUrl) {
    return `${loginUrl}?redirectTo=${redirectUrl}/dashboard`;
  }

  return `${loginUrl}?redirectTo=${redirectUrl}`;
};

export const storageAvailable = (): boolean => {
  try {
    const test = "test";
    localStorage.setItem(test, test);
    localStorage.removeItem(test);

    return true;
  } catch {
    return false;
  }
};

export const setLocalStorage = (
  key: string,
  value: string,
  showErrors: boolean = false
) => {
  try {
    if (storageAvailable()) {
      localStorage.setItem(key, value);
    }
  } catch (error) {
    if (showErrors) {
      // eslint-disable-next-line no-console
      console.log("👾 localStorage not accepting new items", error);
    }
  }
};

export const getLocalStorage = (
  key: string,
  showErrors: boolean = false
): ?string => {
  try {
    if (storageAvailable()) {
      return localStorage.getItem(key);
    }
  } catch (error) {
    if (showErrors) {
      // eslint-disable-next-line no-console
      console.log("👾 localStorage not accepting new items", error);
    }
  }
};

export const setSessionStorage = (
  key: string,
  value: string,
  showErrors: boolean = false
) => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(key, value);
    }
  } catch (error) {
    if (showErrors) {
      // eslint-disable-next-line no-console
      console.log("👾 sessionStorage not accepting new items", error);
    }
  }
};

export const getSessionStorage = (
  key: string,
  showErrors: boolean = false
): ?string => {
  try {
    if (sessionStorage) {
      return sessionStorage.getItem(key);
    }
  } catch (error) {
    if (showErrors) {
      // eslint-disable-next-line no-console
      console.log("👾 sessionStorage not accepting new items", error);
    }
  }
};
