// @flow
import React, { Component } from "react";
import type { Node } from "react";

import styles from "./ErrorBoundary.scss";

type Props = {
  children: Node,
};

type State = {
  hasError: boolean,
  error: ?Error,
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error: error,
    };
  }

  componentDidCatch(error: mixed, info: mixed) {
    // eslint-disable-next-line no-console
    console.log("👾 fatal error:", error);
    // eslint-disable-next-line no-console
    console.log("👾 fatal error info", info);
  }

  render(): Node {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      const chunkError = "ChunkLoadError";
      const networkErrorMessage =
        error &&
        error.toString().substring(0, chunkError.length) !==
          chunkError ? null : (
          <p>
            We couldn't load this content after several attempts. Check your
            network connection!
          </p>
        );

      return (
        <div className={styles.wrap}>
          <h1>😲 Something went wrong, please reload.</h1>
          {networkErrorMessage}
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
