// @flow
export type LinkLabelDataId = { label: string, href: string, dataId: string };

export const navFadeDirections = {
  Left: "left",
  Right: "right",
  Both: "both",
};

const dataIdPrefix = "uscca_header_train-nav_";

export const subNavLinks: LinkLabelDataId[] = [
  {
    label: "Qualifications",
    href: "https://academy-qualifications.usconcealedcarry.com/qualifications/",
    dataId: `${dataIdPrefix}qualifications`,
  },
  {
    label: "Courses",
    href: "http://academy.usconcealedcarry.com/courses",
    dataId: `${dataIdPrefix}courses`,
  },
  {
    label: "Videos",
    href: "http://academy.usconcealedcarry.com/videos",
    dataId: `${dataIdPrefix}videos`,
  },
];
