// @flow
export const round6 = [
  {
    courseId: "be77fde0-ec1a-11ec-b47b-0242ac120006",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-hixson-TN-6-22-2022?link=training_platform",
  },
  {
    courseId: "ad4cafdc-edbb-11ec-8dd1-02420a000288",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-metairie-LA-7-21-2022?link=training_platform",
  },
  {
    courseId: "5434236a-edbd-11ec-a83c-02420a000288",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-bowling-green-KY-7-21-2022?link=training_platform",
  },
  {
    courseId: "59a7faee-f199-11ec-bb7e-0242ac120009",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-elizabethtown-KY-7-22-2022?link=training_platform",
  },
  {
    courseId: "e1583256-f199-11ec-bfcf-0242ac120009",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-broomfield-CO-7-26-2022?link=training_platform",
  },
  {
    courseId: "ce5d3f9c-f19a-11ec-a24e-0242ac120009",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-avon-IN-7-28-2022?link=training_platform",
  },
  {
    courseId: "34c71a5a-f19b-11ec-8304-02420a000291",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-raymond-MS-7-28-2022?link=training_platform",
  },
  {
    courseId: "8c95f1e8-f19b-11ec-ad8b-02420a000291",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-broomfield-CO-7-28-2022?link=training_platform",
  },
  {
    courseId: "e0478d4c-f19b-11ec-bb87-02420a000291",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-owings-mills-MD-7-28-2022?link=training_platform",
  },
  {
    courseId: "3734eeba-f19c-11ec-a1fd-0242ac120009",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-los-fresnos-7-28-2022?link=training_platform",
  },
  {
    courseId: "9ac16346-f19c-11ec-ba4f-02420a000291",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-murfreesboro-TN-6-28-2022?link=training_platform",
  },
];
