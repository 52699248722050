// @flow
import { handleActions } from "redux-actions";
import type { Saga } from "redux-saga";
import { take, select, put } from "redux-saga/effects";
import { push } from "connected-react-router";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import { NONE, PENDING, SUCCESS, ERROR } from "shared/constants/status";
import type { callApiReturnType } from "redux/middleware/api";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { ReceiveRegistrantsReturnType } from "../registrants/registrants";
import type { Result as ResultApiType } from "redux/api-types/Result";
import { REGISTRANTS_REQUEST_SUCCESS, CLEAR } from "../registrants/registrants";
import { parseRegistrants } from "./parseRegistrants";
import { getBulkSelectStatus, getCanSubmit } from "./parseInputs";

// Actions
const namespace: string = `${APP_NAMESPACE}/submitOccurrenceResults`;

export const SELECT_ALL: string = `${namespace}/SELECT_ALL`;
export const UPDATE_INPUT: string = `${namespace}/UPDATE_INPUT`;
export const BULK_SET_STATUS: string = `${namespace}/BULK_SET_STATUS`;
export const SET_STATUS: string = `${namespace}/SET_STATUS`;
export const SUBMIT_RESULTS_REQUEST = `${namespace}/SUBMIT_RESULTS_REQUEST`;
export const SUBMIT_RESULTS_SUCCESS = `${namespace}/SUBMIT_RESULTS_SUCCESS`;
export const SUBMIT_RESULTS_ERROR = `${namespace}/SUBMIT_RESULTS_ERROR`;

// Action Creator Interfaces
type SelectAllReturn = {
  type: string,
  payload: {
    checked: boolean,
  },
};

type UpdateInputReturn = {
  type: string,
  payload: {
    id: string,
    checked: boolean,
  },
};

type BulkSetStatusReturn = {
  type: string,
  payload: {
    status: string,
  },
};

type SetStatusReturn = {
  type: string,
  payload: {
    id: string,
    status: string,
  },
};

type ResultType = {
  registrantId: string,
  status: string,
};

export type ResultsType = Array<ResultType>;

type ResultsApiReturn = {
  data: Array<ResultApiType>,
};

type ReceiveResultsReturn = {
  type: string,
  payload: {
    results: ResultsApiReturn,
  },
  meta: {
    receivedAt: number,
  },
};

// Action Creators
export const selectAll = (checked: boolean): SelectAllReturn => ({
  type: SELECT_ALL,
  payload: {
    checked,
  },
});

export const updateInput = (
  id: string,
  checked: boolean
): UpdateInputReturn => ({
  type: UPDATE_INPUT,
  payload: {
    id,
    checked,
  },
});

export const bulkSetStatus = (status: string): BulkSetStatusReturn => ({
  type: BULK_SET_STATUS,
  payload: {
    status,
  },
});

export const setStatus = (id: string, status: string): SetStatusReturn => ({
  type: SET_STATUS,
  payload: {
    id,
    status,
  },
});

export const requestSubmitResults = (): simpleReduxAction => {
  return {
    type: SUBMIT_RESULTS_REQUEST,
  };
};

export const receiveResults = (
  json: ResultsApiReturn
): ReceiveResultsReturn => {
  return {
    type: SUBMIT_RESULTS_SUCCESS,
    payload: {
      results: json,
    },
    meta: {
      receivedAt: Date.now(),
    },
  };
};

export const submitResultsError = (error: apiErrorParams): apiErrorAction => {
  return {
    type: SUBMIT_RESULTS_ERROR,
    error: true,
    payload: {
      errorMessage: undefined,
      errors: [],
      ...error,
    },
    meta: {
      receivedAt: Date.now(),
    },
  };
};

// Async Actions
export const submitResults = (
  occurrenceId: string,
  results: ResultsType
): callApiReturnType => {
  const options = {
    occurrenceId,
    results,
  };

  return {
    type: CALL_API,
    payload: {
      method: "POST",
      endpoint: "/api/training/results",
      options,
      actions: {
        request: requestSubmitResults,
        success: receiveResults,
        failure: submitResultsError,
      },
      toasts: {
        success: "Results Submitted",
        failure: "There was a problem submitting results",
      },
    },
  };
};

// Selectors
export const occurrenceIdSelector = (state: {
  occurrence: {
    occurrenceId: ?string,
  },
}): ?string => state.occurrence.occurrenceId;

// Sagas
export function* onSubmitResults(): Saga<void> {
  for (;;) {
    yield take(SUBMIT_RESULTS_SUCCESS);
    const occurrenceId = yield select(occurrenceIdSelector);
    const url = `/roster/${occurrenceId}`;
    yield put(push(url));
  }
}

// Initial State
export type State = {
  bulkInputChecked: boolean,
  bulkInputIndeterminate: boolean,
  inputValues: {
    [string]: boolean,
  },
  statuses: {
    [string]: string,
  },
  canSubmit: boolean,
  status: string,
  isSubmitting: boolean,
  isError: boolean,
  errorMessage: ?string,
  errors: apiErrorReturn,
};

const initialState: State = {
  bulkInputChecked: false,
  bulkInputIndeterminate: false,
  inputValues: {},
  statuses: {},
  canSubmit: false,
  status: NONE,
  isSubmitting: false,
  isError: false,
  errorMessage: "",
  errors: [],
};

// Reducer
const submitOccurrenceResults = handleActions(
  {
    [REGISTRANTS_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveRegistrantsReturnType
    ): State => {
      const {
        registrants: { data },
      } = action.payload;
      const { inputValues, statuses } = parseRegistrants(data);

      return {
        ...state,
        inputValues,
        statuses,
      };
    },
    [SELECT_ALL]: (state: State, action: SelectAllReturn): State => {
      const { checked } = action.payload;
      const { inputValues: inputValuesOrig } = state;
      const ids = Object.keys(inputValuesOrig);
      let inputValues = {};
      ids.forEach(function (id: string) {
        inputValues[id] = checked;
      });

      return {
        ...state,
        bulkInputChecked: checked,
        bulkInputIndeterminate: false,
        inputValues,
      };
    },
    [UPDATE_INPUT]: (state: State, action: UpdateInputReturn): State => {
      const { id, checked } = action.payload;
      const { inputValues: inputValuesOrig } = state;
      const inputValues = {
        ...inputValuesOrig,
        [id]: checked,
      };
      const { bulkInputChecked, bulkInputIndeterminate } =
        getBulkSelectStatus(inputValues);

      return {
        ...state,
        bulkInputChecked,
        bulkInputIndeterminate,
        inputValues,
      };
    },
    [BULK_SET_STATUS]: (state: State, action: BulkSetStatusReturn): State => {
      const { status } = action.payload;
      const { inputValues, statuses: statusesOrig } = state;
      const ids = Object.keys(inputValues);
      let statuses = {
        ...statusesOrig,
      };
      ids.forEach(function (id: string) {
        const val = inputValues[id];
        if (val) {
          statuses[id] = status;
        }
      });
      const canSubmit = getCanSubmit(statuses);

      return {
        ...state,
        statuses,
        canSubmit,
      };
    },
    [SET_STATUS]: (state: State, action: SetStatusReturn): State => {
      const { id, status } = action.payload;
      const { statuses: statusesOrig } = state;
      const statuses = {
        ...statusesOrig,
        [id]: status,
      };
      const canSubmit = getCanSubmit(statuses);

      return {
        ...state,
        statuses,
        canSubmit,
      };
    },
    [SUBMIT_RESULTS_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => {
      return {
        ...state,
        status: PENDING,
        isSubmitting: true,
        isError: false,
        errorMessage: "",
        errors: [],
      };
    },
    [SUBMIT_RESULTS_SUCCESS]: (
      state: State,
      action: ReceiveResultsReturn
    ): State => {
      return {
        ...state,
        status: SUCCESS,
        isSubmitting: false,
      };
    },
    [SUBMIT_RESULTS_ERROR]: (state: State, action: apiErrorAction): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        isSubmitting: false,
        isError: true,
        errorMessage,
        errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => {
      return initialState;
    },
  },
  initialState
);

export default submitOccurrenceResults;
