// @flow
import React from "react";
import type { Node } from "react";

import type { State as Theme } from "redux/modules/theme/theme";
import { Button as ButtonEle } from "./ButtonElements";
import { intent } from "shared/constants/intent";

export type Props = {
  theme?: Theme,
  intent: string,
  children: Node,
  [string]: mixed,
};

let Button = ({ children, ...props }: Props): Node => (
  <ButtonEle {...props}>{children}</ButtonEle>
);

Button.displayName = "Button";

Button.defaultProps = {
  intent: intent.NONE,
  type: "button",
};

export default Button;
