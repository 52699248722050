// @flow
import validator from "validator";

type loginValuesType = {
  email: string,
  password: string,
};

type loginErrorsType = {
  email?: string,
  password?: string,
};

export const validateLogin = (values: loginValuesType): loginErrorsType => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  }
  if (values.email && !validator.isEmail(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
};

type createAccountValuesType = {
  name: string,
  surname: string,
  email: string,
  password: string,
  confirmPassword: string,
};

type createAccountErrorsType = {
  name?: string,
  surname?: string,
  email?: string,
  password?: string,
  confirmPassword?: string,
};

export const validateCreateAccount = (
  values: createAccountValuesType
): createAccountErrorsType => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.surname) {
    errors.surname = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  }
  if (values.email && !validator.isEmail(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "Required";
  }
  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "Passwords do not match";
  }

  return errors;
};

type createVerificationValuesType = {
  email: string,
};

type createVerificationErrorsType = {
  email?: string,
};

export const validateCreateVerification = (
  values: createVerificationValuesType
): createVerificationErrorsType => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  }
  if (values.email && !validator.isEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

type verifyVerificationValuesType = {
  code: string,
};

type verifyVerificationErrorsType = {
  code?: string,
};

export const validateVerifyVerification = (
  values: verifyVerificationValuesType
): verifyVerificationErrorsType => {
  const errors = {};

  if (!values.code) {
    errors.code = "Required";
  }

  return errors;
};
