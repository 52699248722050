// @flow
// module for any form data necessary beyond redux forms
import { handleActions } from "redux-actions";
import merge from "deepmerge";
import { LOCATION_CHANGE } from "connected-react-router";

import { APP_NAMESPACE } from "shared/constants/application";
import type { simpleReduxAction } from "shared/constants/flowTypes";
import { ACCOUNT_REQUEST_SUCCESS } from "./instructorAccount/instructorAccount";
import type { AccountAction } from "./instructorAccount/instructorAccount";
import { returnOptionByStateAbbreviation } from "shared/utilities/state";

const namespace: string = `${APP_NAMESPACE}/forms`;

// Actions
export const CHANGE = `${namespace}/CHANGE`;
export const CLEAR = `${namespace}/CLEAR`;

// Action Creators
type changeType = {
  type: string,
  payload: {
    form: string,
    field: string,
    value: mixed,
  },
};

export const change = (
  form: string,
  field: string,
  value: mixed
): changeType => ({
  type: CHANGE,
  payload: {
    form,
    field,
    value,
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Initial State
export type State = {
  [string]: {
    [string]: string,
  },
};

const initialState: State = {};

// Reducer
const createCourse = handleActions(
  {
    [CHANGE]: (state: State, action: changeType): State => {
      const {
        payload: { form, field, value },
      } = action;
      const update = {
        [form]: {
          [field]: value,
        },
      };

      return merge(state, update);
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
    [LOCATION_CHANGE]: (state: State, action: simpleReduxAction): State =>
      initialState,
    [ACCOUNT_REQUEST_SUCCESS]: (state: State, action: AccountAction): State => {
      const {
        payload: {
          data: {
            attributes: { state: locationState },
          },
        },
      } = action;
      const selectedState = returnOptionByStateAbbreviation(locationState);

      const update = {
        instructorAccount: {
          state: selectedState,
        },
      };

      return merge(state, update);
    },
  },
  initialState
);

export default createCourse;
