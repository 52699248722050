// @flow
import { call, fork, take, select } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { LOCATION_CHANGE } from "connected-react-router";
import routeTriggers, { cleanupRouteTriggers } from "./routeTriggers";

// Selectors
type RouterLocation = {
  pathname: string,
  search: string,
  hash: ?string,
  key: ?string,
};

export const routerLocationSelector = ({
  router: { location },
}: {
  router: {
    location: RouterLocation,
  },
}): RouterLocation => location;

export default function* watchRouter(): Saga<void> {
  for (;;) {
    const { pathname: prevPathname } = yield select(routerLocationSelector);
    const action = yield take(LOCATION_CHANGE);
    const {
      payload: {
        location: { pathname, search },
      },
    } = action;
    if (prevPathname) {
      yield call(cleanupRouteTriggers, prevPathname);
    }
    yield fork(routeTriggers, pathname, search);
  }
}
