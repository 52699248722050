// @flow
type QualificationProgress = {
  progress: {
    percentage: number,
  },
};

export const getQualificationLevel = async (
  authToken: string
): Promise<number> => {
  const meshUri = "/api/mesh/";
  const httpMethod = "POST";
  const sdlQuery = `{
    qualification {
      progress {
        percentage
      }
    }
  }`;
  const requestBody = `{"query":"${sdlQuery}"}`;

  try {
    const response = await fetch(meshUri, {
      method: httpMethod,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": authToken,
      },
      body: requestBody,
    });

    const jsonResponse = await response.json();
    return (
      jsonResponse.data.qualification
        // eslint-disable-next-line flowtype/require-return-type
        .filter((q: QualificationProgress) => q.progress.percentage === 100)
        .length
    );
  } catch {
    return 0;
  }
};
