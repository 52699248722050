// @flow
export const round2 = [
  {
    courseId: "ad5cf0c4-bf1f-11ec-91c9-02420a000286",
    overrideUrl:
      "https://uscca.swoogo.com/rock-realtor-workshop-grapevine-TX-5-12-22",
  },
  {
    courseId: "aa0728d0-bf25-11ec-86bf-02420a00028d",
    overrideUrl:
      "https://uscca.swoogo.com/rock-realtor-workshop-hanford-CA-5-12-22",
  },
  {
    courseId: "55a67d44-bf26-11ec-bbd9-02420a000193",
    overrideUrl:
      "https://uscca.swoogo.com/rock-realtor-workshop-lansing-MI-5-12-22",
  },
  {
    courseId: "d02b324c-bfeb-11ec-b644-02420a000286",
    overrideUrl:
      "https://uscca.swoogo.com/rock-realtor-workshop-owings-mills-MD-5-12-22",
  },
  {
    courseId: "2d143ec2-bfec-11ec-8bee-02420a000286",
    overrideUrl:
      "https://uscca.swoogo.com/rock-realtor-workshop-ramsey-MN-5-19-22",
  },
  {
    courseId: "7c9a1a16-bfec-11ec-bebf-02420a00028d",
    overrideUrl:
      "https://uscca.swoogo.com/rock-realtor-workshop-roswell-GA-5-12-22",
  },
  {
    courseId: "fb6ee164-bfec-11ec-bc2c-02420a000193",
    overrideUrl:
      "https://uscca.swoogo.com/rock-realtor-workshop-phoenix-AZ-5-12-22",
  },
  {
    courseId: "4c79790c-bfed-11ec-9a91-02420a00028d",
    overrideUrl:
      "https://uscca.swoogo.com/rock-realtor-workshop-springfield-MO-5-12-22",
  },
  {
    courseId: "d5d49cfe-bfed-11ec-8969-02420a00028d",
    overrideUrl:
      "https://uscca.swoogo.com/rock-realtor-workshop-urbana-IL-5-12-22",
  },
];
