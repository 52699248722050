// @flow
import { handleActions } from "redux-actions";
import { colors } from "shared/constants/colors";

// Initial State
export type State = {
  [string]: string | number,
};

const initialState: State = {
  headerBackground: `transparent linear-gradient(180deg, ${colors.SOOT} 0%, #232324 100%) 0% 0% no-repeat padding-box`,
  footerBackgroundColor: colors.SOOT,
  borderRadius: "5px",
  mainFont: "'Open Sans', sans-serif",
  mainFontBoldWeight: 600,
  lineHeightSpacy: 1.4,
  impactFont: "'Open Sans', sans-serif",
  impactFontBoldWeight: 600,
  highlight: colors.SOSUKE,
  highlight40: "#b88e33",
};

const theme = handleActions({}, initialState);

export default theme;
