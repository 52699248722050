// @flow
export const APP_NAMESPACE: string = "deltaDefenseTrainingApp";

// google maps
export const GOOGLE_MAPS_GEOCODE_API_KEY: string =
  "AIzaSyD3ceL9aW3c0TtKT3OA8HdjCwEIdL5M-jY";
export const GOOGLE_MAPS_API_KEY: string =
  "AIzaSyAKJ9ogz5A_4xysiGJ_8389zmv0FVeMdQU";
export const MAPS_US_CENTER_LAT: number = 39.8283;
export const MAPS_US_CENTER_LNG: number = 98.5795;

// Tracking
export const DEFAULT_TID: string = "5bf32e360a14a";
export const EVENTS_TID: string = "5f6b7ce4e7e36";
export const TARGET_AUDIENCE: string = "Training Platform 2019";
export const GTM_ID: string = "GTM-MNXQGG";

// various links: faq, legal, documentation, forms
export const TRAINING_SITE_TERMS_OF_SERVICE_URL: string =
  "https://www.notion.so/usccatech/USCCA-Training-Website-Terms-of-Service-6f5781f3c75c49c6a50a032c7041d888";

// Instructor Tutorial Links
export const INSTRUCTOR_STARTER_GUIDE: string =
  "https://go.usconcealedcarry.com/training-instructor-info-ci/getting-started-as-a-uscca-instructor";
export const CREATE_COURSE_AND_CLASS_TUTORIAL: string =
  "https://go.usconcealedcarry.com/Instructor_Renewal_Process";
export const WEB_PAYMENT_TUTORIAL: string =
  "https://go.usconcealedcarry.com/training-instructor-info-ci/web-payment-tutorial-training-site";

// Web Payment and Stripe
export const WEB_PAYMENT_FAQ_URL: string =
  "https://www.notion.so/usccatech/Selling-Classes-With-Stripe-FAQ-2bbc2978da4c4cc8bc0b638d4ae4025e";
export const WEB_PAYMENT_TERMS_OF_SERVICE_URL: string =
  "https://www.notion.so/usccatech/USCCA-Training-Payment-Terms-of-Service-cb4f6c14e5834df781395671a9f3d93b";
export const STRIPE_DASHBOARD_URL: string = "https://dashboard.stripe.com";
export const STRIPE_PAYMENT_BASE_URL: string =
  "https://dashboard.stripe.com/payments/";

export const DEFAULT_CANCELLATION_POLICY =
  "Students are required to cancel at least 48 hours in advance of a class date in order to receive a full refund. Classes cancelled less than 48 hours before the class will not be refunded.";

// Offsite links
export const COMMUNITY_LINK =
  "https://community.usconcealedcarry.com/c/Training";

// "Other" Curriculum Images
export const OTHER_CURRICULUM_IMAGE_CARD =
  "https://training.cdn.usconcealedcarry.com/assets/curricula/other/eu/featured/415x150-other-firearms-training-card.jpg";
export const OTHER_CURRICULUM_IMAGE_CARD_RETINA =
  "https://training.cdn.usconcealedcarry.com/assets/curricula/other/eu/featured/415x150-other-firearms-training-card@2x.jpg";

// Instructor support contact info
export const INSTRUCTOR_SUPPORT: {
  email: string,
  hours: string,
  phone: string,
} = {
  email: "support@usccainstructors.com",
  hours: "Mon–Fri 8am–9pm CT, Sat 8am–4:30pm CT",
  phone: "(877) 577-4800", // format: (area code)-exchange-number
};

export const INSTRUCTOR_TOOLKIT_PRICE: number = 250;
