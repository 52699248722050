// @flow
import { handleActions } from "redux-actions";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";

import { NONE, PENDING, SUCCESS, ERROR } from "shared/constants/status";
import { tIDs, tracking } from "shared/constants/tracking";
import validate from "./validate";

import type { callApiReturnType } from "redux/middleware/api";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";

// Actions
const namespace: string = `${APP_NAMESPACE}/emailCourse`;
export const OPEN_MODAL: string = `${namespace}/MODAL/OPEN`;
export const CLOSE_MODAL: string = `${namespace}/MODAL/CLOSE`;
export const UPDATE_FORM: string = `${namespace}/FORM/UPDATE`;
export const EMAIL_REQUEST: string = `${namespace}/SEND/REQUEST`;
export const EMAIL_SUCCESS: string = `${namespace}/SEND/SUCCESS`;
export const EMAIL_ERROR: string = `${namespace}/SEND/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creators
type UpdateFormReturn = {
  type: string,
  payload: {
    name: string,
    value: string,
  },
};

type RequestEmailCourseReturn = {
  email: string,
};

type RequestEmailCourseAction = {
  type: string,
  payload: {
    email: string,
  },
};

type EmailCourseReturn = {
  data: {
    email: string,
    courseUrl: string,
  },
};

type ReceiveEmailCourseAction = {
  type: string,
  payload: {
    email: EmailCourseReturn,
  },
  meta: {
    receivedAt: number,
  },
};

export const openModal = (): simpleReduxAction => ({
  type: OPEN_MODAL,
});

export const closeModal = (): simpleReduxAction => ({
  type: CLOSE_MODAL,
});

export const updateForm = (name: string, value: string): UpdateFormReturn => ({
  type: UPDATE_FORM,
  payload: {
    name,
    value,
  },
});

export const requestEmailCourse = ({
  email,
}: RequestEmailCourseReturn): RequestEmailCourseAction => ({
  type: EMAIL_REQUEST,
  payload: {
    email,
  },
});

export const receiveEmailCourse = (
  json: EmailCourseReturn
): ReceiveEmailCourseAction => ({
  type: EMAIL_SUCCESS,
  payload: {
    email: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const emailCourseRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: EMAIL_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async Actions
export const sendCourseEmail = (
  courseId: string,
  email: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: `/api/training/courses/${courseId}/email-details`,
    options: {
      email,
      courseUrl: document.location.href,
      tID: tIDs.EMAIL_COURSE_DETAILS,
      tracking: tracking.EMAIL_COURSE_DETAILS,
    },
    actions: {
      request: requestEmailCourse,
      success: receiveEmailCourse,
      failure: emailCourseRequestError,
    },
    actionParams: {
      email,
    },
    toasts: {
      success: "Email Sent",
      failure: "Error Sending Email",
    },
  },
});

// State
export type State = {
  isOpen: boolean,
  form: {
    email: string,
  },
  formErrors: {
    email?: string,
  },
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
};

const form = {
  email: "",
  optIn: false,
};

const initialState: State = {
  isOpen: false,
  form,
  formErrors: {},
  status: NONE,
  errorMessage: "",
  errors: [],
};

// Reducer
const emailCourse = handleActions(
  {
    [OPEN_MODAL]: (state: State, action: simpleReduxAction): State => {
      return {
        ...state,
        isOpen: true,
      };
    },
    [CLOSE_MODAL]: (state: State, action: simpleReduxAction): State => {
      return initialState;
    },
    [UPDATE_FORM]: (state: State, action: UpdateFormReturn): State => {
      const { name, value } = action.payload;
      const form = {
        ...state.form,
        [name]: value,
      };
      const formErrors = validate(form);
      return {
        ...state,
        form,
        formErrors,
      };
    },
    [EMAIL_REQUEST]: (
      state: State,
      action: RequestEmailCourseAction
    ): State => ({
      ...state,
      status: PENDING,
      errorMessage: "",
      errors: [],
    }),
    [EMAIL_SUCCESS]: (
      state: State,
      action: ReceiveEmailCourseAction
    ): State => ({
      ...state,
      isOpen: false,
      form,
      formErrors: {},
      isPending: false,
      status: SUCCESS,
    }),
    [EMAIL_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        status: ERROR,
        errorMessage,
        errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default emailCourse;
