// @flow
import React from "react";
import type { Node } from "react";
import { Button } from "@blueprintjs/core";

import styles from "./Close.scss";

type Props = {
  onClick: () => mixed,
  isDark: boolean,
};

let Close = ({ onClick, isDark }: Props): Node => (
  <Button
    className={`${styles.button} ${isDark ? styles.dark : ""}`}
    type="button"
    icon="cross"
    minimal
    onClick={onClick}
  >
    close
  </Button>
);

Close.displayName = "Close";

export default Close;
