// @flow
import React from "react";
import type { Node } from "react";

type Props = {
  className: string,
  [string]: string,
};

let PersonOnLoadspeakerIcon = ({ className, ...props }: Props): Node => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 143.594 143.594"
    {...props}
  >
    <title>Person Speaking through Loudspeaker</title>
    <path
      d="m43.689 22.844a20.844 20.844 0 1 0 -20.845 20.845 20.868 20.868 0 0 0 20.845-20.845zm-37.056 0a16.212 16.212 0 1 1 16.211 16.212 16.231 16.231 0 0 1 -16.212-16.212z"
      transform="translate(.316 .316)"
    />
    <path
      d="m17.264 32.792v-2.742a6.931 6.931 0 0 0 -2.316-13.47 2.316 2.316 0 1 1 2.316-2.316h4.636a6.939 6.939 0 0 0 -4.632-6.522v-2.742h-4.636v2.742a6.931 6.931 0 0 0 2.316 13.47 2.316 2.316 0 1 1 -2.316 2.316h-4.632a6.939 6.939 0 0 0 4.632 6.522v2.742z"
      transform="translate(8.212 4.264)"
    />
    <path
      d="m3.316 89.321h32.424a2.315 2.315 0 0 0 2.316-2.321v-6.944a18.54 18.54 0 0 0 -10.873-16.856 11.58 11.58 0 1 0 -15.309 0 18.54 18.54 0 0 0 -10.874 16.856v6.944a2.315 2.315 0 0 0 2.316 2.321zm9.264-34.741a6.948 6.948 0 1 1 6.948 6.948 6.957 6.957 0 0 1 -6.948-6.948zm-6.948 25.476a13.9 13.9 0 1 1 27.792 0v4.632h-27.792z"
      transform="translate(-1 54.273)"
    />
    <path
      d="m0 0h36.176v4.632h-36.176z"
      transform="matrix(.64020356 -.76820531 .76820531 .64020356 88.612 55.385)"
    />
    <path
      d="m51.9 18.948a6.948 6.948 0 1 0 -6.952 6.952 6.957 6.957 0 0 0 6.952-6.952zm-9.264 0a2.316 2.316 0 1 1 2.316 2.316 2.318 2.318 0 0 1 -2.32-2.316z"
      transform="translate(47.693 13.476)"
    />
    <path
      d="m52.948 20a6.948 6.948 0 1 0 6.952 6.948 6.957 6.957 0 0 0 -6.952-6.948zm0 9.264a2.316 2.316 0 1 1 2.316-2.316 2.318 2.318 0 0 1 -2.316 2.316z"
      transform="translate(58.221 24.005)"
    />
    <path
      d="m109.006 40.574-12.155-5.436 7.812-10.783a2.314 2.314 0 0 0 -2.115-3.662l-13.248 1.383 1.386-13.246a2.316 2.316 0 0 0 -3.662-2.117l-10.785 7.813-5.439-12.157a2.317 2.317 0 0 0 -4.229 0l-5.433 12.157-10.783-7.812a2.316 2.316 0 0 0 -3.662 2.116l1.38 13.243-13.243-1.384a2.314 2.314 0 0 0 -2.115 3.662l7.81 10.786-12.154 5.435a2.318 2.318 0 0 0 0 4.231l12.155 5.436-7.812 10.783a2.314 2.314 0 0 0 2.115 3.662l13.245-1.384-1.383 13.244a2.316 2.316 0 0 0 3.662 2.117l10.786-7.814 5.435 12.153a2.315 2.315 0 0 0 4.229 0l5.436-12.155 10.783 7.812a2.316 2.316 0 0 0 3.662-2.117l-1.384-13.24 13.243 1.385a2.342 2.342 0 0 0 2.247-1.144 2.316 2.316 0 0 0 -.132-2.518l-7.81-10.788 12.158-5.435a2.316 2.316 0 0 0 0-4.229zm-16.648 6.6a2.315 2.315 0 0 0 -.931 3.472l6.439 8.894-10.925-1.14a2.316 2.316 0 0 0 -2.541 2.543l1.139 10.925-8.894-6.443a2.317 2.317 0 0 0 -3.474.931l-4.482 10.031-4.489-10.031a2.317 2.317 0 0 0 -3.474-.931l-8.894 6.443 1.139-10.927a2.311 2.311 0 0 0 -.665-1.878 2.346 2.346 0 0 0 -1.878-.667l-10.921 1.144 6.441-8.891a2.317 2.317 0 0 0 -.931-3.474l-10.029-4.486 10.031-4.489a2.315 2.315 0 0 0 .931-3.472l-6.439-8.894 10.925 1.142a2.354 2.354 0 0 0 1.878-.667 2.31 2.31 0 0 0 .665-1.878l-1.142-10.924 8.894 6.443a2.314 2.314 0 0 0 3.472-.931l4.486-10.029 4.486 10.031a2.317 2.317 0 0 0 3.474.931l8.894-6.443-1.143 10.927a2.31 2.31 0 0 0 .665 1.878 2.354 2.354 0 0 0 1.878.667l10.927-1.142-6.441 8.891a2.317 2.317 0 0 0 .931 3.474l10.026 4.484z"
      transform="translate(33.217 -1)"
    />
    <path
      d="m61.322 38.412a2.327 2.327 0 0 0 -2.133-.266l-5.517 2.068a74.02 74.02 0 0 1 -26.092 4.734h-4.632a6.957 6.957 0 0 0 -6.948 6.952v11.58a6.939 6.939 0 0 0 4.632 6.52v16.636a2.315 2.315 0 0 0 2.316 2.316h9.264a2.317 2.317 0 0 0 2.247-2.877 36.136 36.136 0 0 1 -.438-15.392 73.883 73.883 0 0 1 23.318 5.748l1.756.753a2.3 2.3 0 0 0 .91.188 2.314 2.314 0 0 0 2.316-2.316v-34.74a2.316 2.316 0 0 0 -.999-1.904zm-31.422 11.133a78.623 78.623 0 0 0 18.528-2.777v21.7a78.438 78.438 0 0 0 -18.528-2.657zm-9.268 2.355a2.318 2.318 0 0 1 2.316-2.316h2.316v16.208h-2.316a2.318 2.318 0 0 1 -2.316-2.316zm8.727 32.42h-4.095v-13.9h2.8c.447 0 .892.025 1.339.032a40.623 40.623 0 0 0 -.044 13.868zm28.33-12.759c-1.529-.621-3.076-1.172-4.632-1.693v-24.524c.75-.257 1.5-.512 2.247-.79l2.386-.9z"
      transform="translate(18.74 47.693)"
    />
    <path d="m87.611 101.905h9.264v4.632h-9.264z" />
    <path
      d="m0 0h9.264v4.632h-9.264z"
      transform="matrix(.70710678 -.70710678 .70710678 .70710678 84.053 92.922)"
    />
    <path
      d="m0 0h4.632v9.264h-4.632z"
      transform="matrix(.70710678 -.70710678 .70710678 .70710678 84.054 115.521)"
    />
    <path
      d="m23.161 14.09a59.852 59.852 0 0 1 22.782-4.458c1.492 0 2.992.053 4.463.162l.338-4.62c-1.582-.116-3.2-.174-4.8-.174a64.51 64.51 0 0 0 -24.544 4.8z"
      transform="translate(25.854 4.264)"
    />
    <path
      d="m10.742 20.555a65.043 65.043 0 0 0 -3.05 50.438l4.366-1.545a60.42 60.42 0 0 1 2.832-46.832z"
      transform="translate(2.948 24.735)"
    />
    <path
      d="m26.805 94.455-1.026 4.516a64.9 64.9 0 0 0 79.257-63.241c0-1.607-.058-3.224-.176-4.8l-4.618.34c.109 1.468.162 2.967.162 4.463a60.273 60.273 0 0 1 -73.6 58.725z"
      transform="translate(31.61 38.383)"
    />
  </svg>
);

PersonOnLoadspeakerIcon.displayName = "PersonOnLoadspeakerIcon";

PersonOnLoadspeakerIcon.defaultProps = {
  className: "",
};

export default PersonOnLoadspeakerIcon;
