// @flow
import { handleActions } from "redux-actions";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { NONE, PENDING, SUCCESS, ERROR } from "shared/constants/status";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { Instructor } from "redux/api-types/Instructor";

// Actions
const namespace: string = `${APP_NAMESPACE}/manageCourseOccurrenceInstructors`;
export const OPEN_DIALOG: string = `${namespace}/OPEN_DIALOG`;
export const CLOSE_DIALOG: string = `${namespace}/CLOSE_DIALOG`;
export const UPDATE_INPUT: string = `${namespace}/UPDATE_INPUT`;
export const SHOW_REMOVE: string = `${namespace}/SHOW_REMOVE`;
export const ADD_REQUEST: string = `${namespace}/ADD_REQUEST`;
export const ADD_SUCCESS: string = `${namespace}/ADD_SUCCESS`;
export const ADD_ERROR: string = `${namespace}/ADD_ERROR`;
export const REMOVE_REQUEST: string = `${namespace}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS: string = `${namespace}/REMOVE_SUCCESS`;
export const REMOVE_ERROR: string = `${namespace}/REMOVE_ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creator Interfaces
type OpenDialogAction = {
  type: string,
  payload: {
    occurrenceId: string,
  },
};

type UpdateInputAction = {
  type: string,
  payload: {
    value: string,
  },
};

type ShowRemoveAction = {
  type: string,
  payload: {
    id: string,
  },
};

type ActionParam = {
  occurrenceId: string,
};

type InstructorReturn = {
  data: Instructor,
};

export type InstructorAction = {
  type: string,
  payload: {
    instructor: InstructorReturn,
  } & ActionParam,
  meta: {
    receivedAt: number,
  },
};

type RequestRemoveParam = {
  instructorId: string,
};

type RequestRemoveAction = {
  type: string,
  payload: {
    instructorId: string,
  },
};

type RemoveActionParams = {
  occurrenceId: string,
  instructorId: string,
};

export type RemoveActionReturn = {
  type: string,
  payload: RemoveActionParams,
  meta: {
    receivedAt: number,
  },
};

// Action Creators
export const openDialog = (occurrenceId: string): OpenDialogAction => ({
  type: OPEN_DIALOG,
  payload: {
    occurrenceId,
  },
});

export const closeDialog = (): simpleReduxAction => ({
  type: CLOSE_DIALOG,
});

export const updateInput = (value: string): UpdateInputAction => ({
  type: UPDATE_INPUT,
  payload: {
    value,
  },
});

export const showRemove = (id: string): ShowRemoveAction => ({
  type: SHOW_REMOVE,
  payload: {
    id,
  },
});

export const requestAdd = (): simpleReduxAction => ({
  type: ADD_REQUEST,
});

export const receiveAdd = (
  json: InstructorReturn,
  actionParams: ActionParam
): InstructorAction => {
  return {
    type: ADD_SUCCESS,
    payload: {
      instructor: json,
      ...actionParams,
    },
    meta: {
      receivedAt: Date.now(),
    },
  };
};

export const addError = (error: apiErrorParams): apiErrorAction => ({
  type: ADD_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestRemove = ({
  instructorId,
}: RequestRemoveParam): RequestRemoveAction => ({
  type: REMOVE_REQUEST,
  payload: {
    instructorId,
  },
});

export const receiveRemove = (
  json: InstructorReturn,
  actionParams: RemoveActionParams
): RemoveActionReturn => ({
  type: REMOVE_SUCCESS,
  payload: {
    ...actionParams,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const removeError = (error: apiErrorParams): apiErrorAction => ({
  type: REMOVE_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async Actions
export const addInstructor = (
  occurrenceId: string,
  instructorNumber: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: `/api/training/occurrences/${occurrenceId}/instructors`,
    options: {
      instructorNumber,
    },
    actionParams: {
      occurrenceId,
    },
    actions: {
      request: requestAdd,
      success: receiveAdd,
      failure: addError,
    },
    toasts: {
      success: "Instructor Added",
      failure: "Error Adding Instructor",
    },
  },
});

export const removeInstructor = (
  occurrenceId: string,
  instructorId: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "DELETE",
    endpoint: `/api/training/occurrences/${occurrenceId}/instructors/${instructorId}`,
    actionParams: {
      occurrenceId,
      instructorId,
    },
    actions: {
      request: requestRemove,
      success: receiveRemove,
      failure: removeError,
    },
    toasts: {
      success: "Instructor Removed",
      failure: "Error Removing Instructor",
    },
  },
});

// Initial state
export type State = {
  isDialogOpen: boolean,
  occurrenceId: string,
  instructorNumberInput: string,
  showRemoveId: string,
  addStatus: string,
  isAddPending: boolean,
  isAddError: boolean,
  addErrorMessage: ?string,
  addErrors: apiErrorReturn,
  removingId: string,
  removeStatus: string,
  isRemovePending: boolean,
  isRemoveError: boolean,
  removeErrorMessage: ?string,
  removeErrors: apiErrorReturn,
};

const initialState: State = {
  isDialogOpen: false,
  occurrenceId: "",
  instructorNumberInput: "",
  showRemoveId: "",
  addStatus: NONE,
  isAddPending: false,
  isAddError: false,
  addErrorMessage: null,
  addErrors: [],
  removeStatus: NONE,
  removingId: "",
  isRemovePending: false,
  isRemoveError: false,
  removeErrorMessage: null,
  removeErrors: [],
};

// reducer
const manageCourseOccurrence = handleActions(
  {
    [OPEN_DIALOG]: (
      state: State,
      { payload: { occurrenceId } }: OpenDialogAction
    ): State => ({
      ...state,
      isDialogOpen: true,
      occurrenceId: occurrenceId,
    }),
    [CLOSE_DIALOG]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      isDialogOpen: false,
      occurrenceId: "",
      showRemoveId: "",
    }),
    [UPDATE_INPUT]: (
      state: State,
      { payload: { value: instructorNumberInput } }: UpdateInputAction
    ): State => ({
      ...state,
      instructorNumberInput,
    }),
    [SHOW_REMOVE]: (
      state: State,
      { payload: { id: showRemoveId } }: ShowRemoveAction
    ): State => ({
      ...state,
      showRemoveId,
    }),
    [ADD_REQUEST]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      addStatus: PENDING,
      isAddPending: true,
      isAddError: false,
      addErrorMessage: null,
      addErrors: [],
    }),
    [ADD_SUCCESS]: (state: State, action: InstructorAction): State => ({
      ...state,
      instructorNumberInput: "",
      addStatus: SUCCESS,
      isAddPending: false,
    }),
    [ADD_ERROR]: (
      state: State,
      {
        payload: { errorMessage: addErrorMessage, errors: addErrors },
      }: apiErrorAction
    ): State => ({
      ...state,
      addStatus: ERROR,
      isAddPending: false,
      isAddError: true,
      addErrorMessage,
      addErrors,
    }),
    [REMOVE_REQUEST]: (
      state: State,
      { payload: { instructorId } }: RequestRemoveAction
    ): State => ({
      ...state,
      showRemoveId: "",
      removingId: instructorId,
      removeStatus: PENDING,
      isRemovePending: true,
      isRemoveError: false,
      removeErrorMessage: null,
      removeErrors: [],
    }),
    [REMOVE_SUCCESS]: (state: State, action: InstructorAction): State => ({
      ...state,
      removingId: "",
      removeStatus: SUCCESS,
      isRemovePending: false,
    }),
    [REMOVE_ERROR]: (
      state: State,
      {
        payload: { errorMessage: removeErrorMessage, errors: removeErrors },
      }: apiErrorAction
    ): State => ({
      ...state,
      removingId: "",
      removeStatus: ERROR,
      isRemovePending: false,
      isRemoveError: true,
      removeErrorMessage,
      removeErrors,
    }),
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default manageCourseOccurrence;
