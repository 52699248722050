// @flow
import React from "react";
import type { Node } from "react";
import { connect } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { PENDING } from "shared/constants/status";
import { tIDs } from "shared/constants/tracking";
import {
  closeDialog as close,
  requestCourseOccurrence as request,
} from "redux/modules/studentRequestCourseOccurrence/studentRequestCourseOccurrence.js";
import type { State } from "redux/modules/studentRequestCourseOccurrence/studentRequestCourseOccurrence.js";
import Dialog from "../Dialog/Dialog";
import CalendarIcon from "shared/icons/CalendarIcon";
import submitLead from "shared/utilities/submitLead";
import RequestForm from "./RequestForm";

type Values = {
  email: string,
  phone: string,
  comment: string,
  consentedToContact: boolean,
};

type ReduxProps = {
  courseId: string,
  isOpen: boolean,
  isPending: boolean,
};

type Props = {
  close: () => mixed,
  request: (
    occurrenceId: string,
    email: string,
    phone: string,
    comment: string,
    consentedToContact
  ) => mixed,
};

let RequestCourseOccurrenceDialog = ({
  courseId,
  isOpen,
  isPending,
  close,
  request,
}: Props): Node => {
  const { leadOptInAlignment } = useFlags();

  if (!isOpen) {
    return null;
  }

  const onSubmit = ({ email, phone, comment, consentedToContact }: Values) => {
    if (leadOptInAlignment) {
      submitLead(email, tIDs.REQUEST_COURSE_OCCURRENCE);
    }

    request(courseId, email, phone, comment, consentedToContact);
  };

  return (
    <Dialog
      isOpen
      isLarge
      title="Request a Class Date"
      Icon={CalendarIcon}
      onClose={close}
    >
      <p>
        Complete the class request form below and we will connect you with the
        instructor. Your message will be received within one business day.
      </p>
      <RequestForm isPending={isPending} onSubmit={onSubmit} />
    </Dialog>
  );
};

RequestCourseOccurrenceDialog.displayName = "RequestCourseOccurrenceDialog";

const mapStateToProps = ({
  studentRequestCourseOccurrence: { courseId, isOpen, status },
}: {
  studentRequestCourseOccurrence: State,
}): ReduxProps => ({
  courseId,
  isOpen,
  isPending: status === PENDING,
});

const actions = {
  close,
  request,
};

export default connect(mapStateToProps, actions)(RequestCourseOccurrenceDialog);
