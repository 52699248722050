// @flow
import { handleActions } from "redux-actions";
import type { Saga } from "redux-saga";
import { put, take } from "redux-saga/effects";
import { push } from "connected-react-router";
import queryString from "query-string";
import { camelCase } from "lodash";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import { RESULTS_PER_PAGE } from "shared/constants/localStorageKeys";
import { setLocalStorage, getLocalStorage } from "shared/utilities/helpers";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { CourseOccurrence } from "redux/api-types/CourseOccurrence";
import type { OccurrenceRecordI } from "./OccurrenceRecord";
import type { DateRecordInterface } from "./DateRecord";
import { getJwtUser } from "shared/utilities/authCookie";
import { parseOccurrences } from "./occurrencesParsers";
import { getResultsPerPage } from "shared/utilities/resultsPerPageModification";
import { FUTURE } from "./types";

const namespace: string = `${APP_NAMESPACE}/instructorCourseOccurrences`;
const DEFAULT_RESULTS_PER_PAGE = 10;

// Actions
export const GET_OCCURRENCES: string = `${namespace}/GET`;
export const OCCURRENCES_REQUEST: string = `${namespace}/REQUEST/FETCH`;
export const OCCURRENCES_REQUEST_SUCCESS: string = `${namespace}/REQUEST/SUCCESS`;
export const OCCURRENCES_REQUEST_ERROR: string = `${namespace}/REQUEST/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Interfaces
type GetOccurrencesAction = {
  type: string,
  payload: {
    type: string,
    resultsPerPage: number,
    page: number,
  },
};

type RequestOccurrencesParams = {
  resultsPerPage: number | string,
  currentPage: number,
};

type RequestOccurrencesReturn = {
  type: string,
  payload: {
    resultsPerPage: number,
  },
};

type OccurrencesReturn = {
  data: Array<CourseOccurrence>,
  meta: {
    total: number,
  },
};

type ReceiveOccurrencesReturn = {
  type: string,
  payload: OccurrencesReturn,
  meta: {
    receivedAt: number,
  },
};

// Action Creators
export const getOccurrences = (
  type: string = FUTURE,
  page: number,
  resultsPerPage: number | string
): GetOccurrencesAction => {
  resultsPerPage = resultsPerPage === 0 ? "All" : resultsPerPage;
  setLocalStorage(RESULTS_PER_PAGE, resultsPerPage);

  return {
    type: GET_OCCURRENCES,
    payload: {
      type,
      page,
      resultsPerPage,
    },
  };
};

export const requestOccurrences = (
  params: RequestOccurrencesParams = {}
): RequestOccurrencesReturn => ({
  type: OCCURRENCES_REQUEST,
  payload: {
    ...params,
  },
});

export const receiveOccurrences = (
  json: OccurrencesReturn
): ReceiveOccurrencesReturn => ({
  type: OCCURRENCES_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const occurrencesRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: OCCURRENCES_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Sagas
export function* watchGetOccurrences(): Saga<void> {
  for (;;) {
    const action = yield take(GET_OCCURRENCES);
    const params = queryString.stringify(action.payload).toLowerCase();
    const url = `/my-classes?${params}`;
    yield put(push(url));
  }
}

export const getOccurrencesCall = (
  type: string = FUTURE,
  page: number,
  resultsPerPage?: number | string
): callApiReturnType => {
  resultsPerPage = getResultsPerPage(resultsPerPage);

  const { userId } = getJwtUser();
  if (!userId) {
    return;
  }
  let filter = camelCase(type);

  let endpoint = `/api/training/instructors/${userId}/occurrences`;
  let actionParams = {
    type,
    resultsPerPage,
  };
  let getParams = ["entity=courses", `filter=${filter}`];

  if (resultsPerPage) {
    getParams.push(`perPage=${resultsPerPage}`);
    if (!page) {
      page = 1;
    }
  }

  if (typeof page === "number") {
    getParams.push(`page=${page}`);
    actionParams.currentPage = page;
  }

  endpoint += `?${getParams.join("&")}`;

  return {
    type: CALL_API,
    payload: {
      method: "GET",
      endpoint,
      actionParams,
      actions: {
        request: requestOccurrences,
        success: receiveOccurrences,
        failure: occurrencesRequestError,
      },
    },
  };
};

// Initial State
export type State = {
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  occurrences: {
    [string]: OccurrenceRecordI,
  },
  dates: {
    [string]: DateRecordInterface,
  },
  occurrencesCount: number,
  totalOccurrencesCount: number,
  type: string,
  currentPage: number,
  resultsPerPage: number | string,
  totalPages: number,
};

const initialState: State = {
  status: NONE,
  errorMessage: "",
  errors: [],
  occurrences: {},
  dates: {},
  occurrencesCount: 0,
  totalOccurrencesCount: 0,
  type: "",
  currentPage: 1,
  resultsPerPage: getLocalStorage(RESULTS_PER_PAGE) ?? DEFAULT_RESULTS_PER_PAGE,
  totalPages: 0,
};

// Reducer
const instructorCourseOccurrences = handleActions(
  {
    [OCCURRENCES_REQUEST]: (
      state: State,
      action: RequestOccurrencesReturn
    ): State => ({
      ...state,
      status: LOADING,
      errorMessage: "",
      errors: [],
      ...action.payload,
    }),
    [OCCURRENCES_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveOccurrencesReturn
    ): State => {
      const { data, meta } = action.payload;
      const occurrencesCount = data.length;
      const { occurrences, dates } = parseOccurrences(data);
      const totalOccurrencesCount =
        meta && meta.hasOwnProperty("total") ? meta.total : data.length;
      const totalPages =
        meta && meta.hasOwnProperty("totalPages") ? meta.totalPages : 1;

      return {
        ...state,
        status: LOADED,
        occurrences,
        dates,
        occurrencesCount,
        totalOccurrencesCount,
        totalPages,
      };
    },
    [OCCURRENCES_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const {
        payload: { errorMessage, errors },
      } = action;
      return {
        ...state,
        status: ERROR,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default instructorCourseOccurrences;
