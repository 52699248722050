// @flow
import CertificationRecord from "./CertificationRecord";
import type { Certification } from "redux/api-types/Certification";

export const parseCertifications = (data: Array<Certification>): {} => {
  let certifications = {};

  data.forEach(function ({
    id,
    attributes: {
      curriculumId,
      status,
      certifiedAt,
      expiresAt,
      activeEndDate,
      activeCreditsCount,
      requiredCreditsForRenewal,
      certificateUrl,
    },
  }: Certification) {
    const Record = CertificationRecord({
      id,
      status,
      certifiedAt,
      expiresAt,
      curriculumId,
      activeEndDate,
      activeCreditsCount,
      requiredCreditsForRenewal,
      certificateUrl,
    });
    certifications[id] = Record;
  });

  return certifications;
};
