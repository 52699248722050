// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";

import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { Course as CourseType } from "redux/api-types/Course";
import type { Include as IncludeType } from "redux/api-types/Include";

import { parseCourses } from "./coursesParsers";

// Actions
const namespace: string = `${APP_NAMESPACE}/instructorCourses`;

export const COURSES_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const COURSES_REQUEST_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const COURSES_REQUEST_ERROR: string = `${namespace}/FETCH/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Flow Types
export type CoursesReturn = {
  data: Array<CourseType>,
  included: Array<IncludeType>,
};

export type ReceiveCoursesReturn = {
  type: string,
  payload: CoursesReturn,
  meta: {
    receivedAt: number,
  },
};

// Action Creators
export const requestCourses = (): simpleReduxAction => ({
  type: COURSES_REQUEST,
});

export const receiveCourses = (json: CoursesReturn): ReceiveCoursesReturn => ({
  type: COURSES_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const coursesRequestError = (error: apiErrorParams): apiErrorAction => ({
  type: COURSES_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async actions
export const getCourses = (id: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/instructors/${id}/courses?filter=searchable&include=images,curriculum`,
    actions: {
      request: requestCourses,
      success: receiveCourses,
      failure: coursesRequestError,
    },
  },
});

// Initial State
export type State = {
  status: string,
  errorMessage: string,
  courses: {},
  count: number,
};

const initialState: State = {
  status: NONE,
  errorMessage: "",
  errors: [],
  courses: {},
  count: 0,
};

// Reducer
const manageCourses = handleActions(
  {
    [COURSES_REQUEST]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      status: LOADING,
      errorMessage: "",
      errors: [],
      courses: {},
      count: 0,
    }),
    [COURSES_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveCoursesReturn
    ): State => {
      const {
        payload: { data, included },
      } = action;
      const count = data.length;
      const courses = parseCourses(data, included);
      return {
        ...state,
        status: LOADED,
        errorMessage: "",
        errors: [],
        courses,
        count,
      };
    },
    [COURSES_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;
      return {
        ...state,
        status: ERROR,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default manageCourses;
