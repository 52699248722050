/* eslint no-console: 0 */
// @flow
const { LOAD_SEGMENT, SEGMENT_TRACKING } = process.env;

export default function enterEmailJourney(): boolean {
  switch (true) {
    case LOAD_SEGMENT === "false":
    case SEGMENT_TRACKING === "false":
      return false;
    case typeof window.deltaAnalytics !== "object":
      console.log(
        "🌚 deltaAnalytics not loaded, cannot send enterJourney action"
      );
      return false;
    default:
      window.deltaAnalytics.track("enterJourney", {
        canvasName: "Training_Leadsource",
      });
      return true;
  }
}
