// @flow
import React from "react";
import type { Node } from "react";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import FormFieldError from "./FormFieldError";

type Props = {
  containerClass: string,
  inputClass: string,
  input: {
    name: string,
    value: string,
  },
  label: string,
  labelinfo: string,
  helperText: string,
  type: string,
  placeholder: string,
  disabled: boolean,
  leftIcon?: string,
  meta: {
    touched: boolean,
    error: boolean,
  },
};

let RenderField = ({
  containerClass,
  inputClass,
  input,
  placeholder,
  disabled,
  label,
  labelinfo,
  helperText,
  type,
  leftIcon,
  meta: { touched, error },
}: Props): Node => (
  <FormGroup
    className={containerClass}
    label={label}
    labelinfo={labelinfo}
    helperText={helperText}
    labelFor={input.name}
  >
    <InputGroup
      className={inputClass}
      type={type}
      {...input}
      leftIcon={leftIcon}
      disabled={disabled}
    />
    {touched && error && <FormFieldError>{error}</FormFieldError>}
  </FormGroup>
);

RenderField.defaultProps = {
  containerClass: "",
  inputClass: "",
  label: "",
  labelinfo: "",
  helperText: "",
  type: "text",
  placeholder: "",
};

export default RenderField;
