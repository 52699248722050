// @flow
import * as slugify from "slugify";

const states = [
  { state: "AL", label: "alabama" },
  { state: "AK", label: "alaska" },
  { state: "AZ", label: "arizona" },
  { state: "AR", label: "arkansas" },
  { state: "CA", label: "california" },
  { state: "CO", label: "colorado" },
  { state: "CT", label: "connecticut" },
  { state: "DE", label: "delaware" },
  { state: "FL", label: "florida" },
  { state: "GA", label: "georgia" },
  { state: "HI", label: "hawaii" },
  { state: "ID", label: "idaho" },
  { state: "IL", label: "illinois" },
  { state: "IN", label: "indiana" },
  { state: "IA", label: "iowa" },
  { state: "KS", label: "kansas" },
  { state: "KY", label: "kentucky" },
  { state: "LA", label: "louisiana" },
  { state: "ME", label: "maine" },
  { state: "MD", label: "maryland" },
  { state: "MA", label: "massachusetts" },
  { state: "MI", label: "michigan" },
  { state: "MN", label: "minnesota" },
  { state: "MS", label: "mississippi" },
  { state: "MO", label: "missouri" },
  { state: "MT", label: "montana" },
  { state: "NE", label: "nebraska" },
  { state: "NV", label: "nevada" },
  { state: "NH", label: "new-hampshire" },
  { state: "NJ", label: "new-jersey" },
  { state: "NM", label: "new-mexico" },
  { state: "NY", label: "new-york" },
  { state: "NC", label: "north-carolina" },
  { state: "ND", label: "north-dakota" },
  { state: "OH", label: "ohio" },
  { state: "OK", label: "oklahoma" },
  { state: "OR", label: "oregon" },
  { state: "PA", label: "pennsylvania" },
  { state: "RI", label: "rhode-island" },
  { state: "SC", label: "south-carolina" },
  { state: "SD", label: "south-dakota" },
  { state: "TN", label: "tennessee" },
  { state: "TX", label: "texas" },
  { state: "UT", label: "utah" },
  { state: "VT", label: "vermont" },
  { state: "VA", label: "virginia" },
  { state: "WA", label: "washington" },
  { state: "WV", label: "west-virginia" },
  { state: "WI", label: "wisconsin" },
  { state: "WY", label: "wyoming" },
];

const { URL_CHECKOUT = "https://checkout.training.usconcealedcarry.com" } =
  process.env;

const slugifyOptions = {
  replacement: "-", // replace spaces with replacement character, defaults to `-`
  remove: "", // remove characters that match regex, defaults to `undefined`
  lower: true, // convert to lower case, defaults to `false`
  strict: true, // strip special characters except replacement, defaults to `false`
  locale: "vi", // language code of the locale to use
  trim: true, // trim leading and trailing replacement chars, defaults to `true`
};

const cleanSlug = (name: string): string => {
  const slug = slugify(name, slugifyOptions);
  return slug;
};

export const createCourseUrl = (id: string, name: string): string => {
  const slug = cleanSlug(name);
  return `/class/${slug}/${id}`;
};

export const createFirearmsTrainingUrl = (
  slug: string,
  state: string,
  instructorSlug: string
): string => {
  const organicUrl = process.env.URL_ORGANIC;
  if (!organicUrl) {
    throw new Error("URL_ORGANIC environment variable is not defined");
  }
  const stateLabel = states.find(
    (s: string): string => s.state === state
  )?.label;
  if (!stateLabel) {
    throw new Error(`State label not found for state: ${state}`);
  }
  return `${organicUrl}/firearms-training/instructors/${stateLabel}-instructors/${instructorSlug}/${slug}`;
};

export const createBuyClassUrl = (
  courseId: string,
  courseName: string,
  occurrenceId: string,
  tID: string
): string => {
  const slug = cleanSlug(courseName);
  return `${URL_CHECKOUT}/register/${slug}/${courseId}?oid=${occurrenceId}${
    tID && tID !== "unknown" ? `?tid=${tID}` : ""
  }`;
};
