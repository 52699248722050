// @flow
import { handleActions } from "redux-actions";
import { put, take } from "redux-saga/effects";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import { parseCourse } from "./parseCourse";
import {
  ADD_OCCURRENCE_SUCCESS,
  DELETE_OCCURRENCE_SUCCESS,
} from "../manageCourseOccurrence/manageCourseOccurrence";
import type {
  AddOccurrenceSuccessAction,
  DeleteOccurrenceSuccessAction,
} from "../manageCourseOccurrence/manageCourseOccurrence";
import { getCoursePromotions } from "../manageCoursePromotions/coursePromotionsActionCreators";

// actions
const namespace: string = `${APP_NAMESPACE}/manageCourse`;
export const COURSE_REQUEST: string = `${namespace}/COURSE/REQUEST`;
export const COURSE_REQUEST_SUCCESS: string = `${namespace}/COURSE/SUCCESS`;
export const COURSE_REQUEST_ERROR: string = `${namespace}/COURSE/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Flow Types
type CourseReturn = {};
type requestCourseReturnType = {
  type: string,
  payload: {
    id: string,
  },
};

export type receiveCourseReturnType = {
  type: string,
  payload: {
    course: CourseReturn,
  },
  meta: {
    receivedAt: number,
  },
};

// Actions
export const requestCourse = (id: string): requestCourseReturnType => ({
  type: COURSE_REQUEST,
  payload: {
    id,
  },
});

export const receiveCourse = (json: CourseReturn): receiveCourseReturnType => ({
  type: COURSE_REQUEST_SUCCESS,
  payload: {
    course: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const courseRequestError = (error: apiErrorParams): apiErrorAction => ({
  type: COURSE_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// async actions
export const getCourse = (id: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/courses/${id}`,
    actions: {
      request: requestCourse,
      success: receiveCourse,
      failure: courseRequestError,
    },
  },
});

// sagas
export function* watchCourseRequestSuccess(): Saga<void> {
  for (;;) {
    yield take(COURSE_REQUEST_SUCCESS);

    yield put(getCoursePromotions());
  }
}

// initial state
export type State = {
  isLoading: boolean,
  isLoaded: boolean,
  isError: boolean,
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  course: {},
};

const initialState: State = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  status: NONE,
  errorMessage: "",
  errors: [],
  course: {},
};

// reducer
const manageCourse = handleActions(
  {
    [COURSE_REQUEST]: (state: State): State => ({
      ...state,
      isLoading: true,
      isError: false,
      status: LOADING,
      errorMessage: "",
      errors: [],
      course: {},
    }),
    [COURSE_REQUEST_SUCCESS]: (
      state: State,
      action: receiveCourseReturnType
    ): State => {
      const { data } = action.payload.course;
      const course = parseCourse(data);

      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        status: LOADED,
        course,
      };
    },
    [COURSE_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        isLoading: false,
        isError: true,
        status: ERROR,
        errorMessage,
        errors,
      };
    },
    [ADD_OCCURRENCE_SUCCESS]: (
      state: State,
      action: AddOccurrenceSuccessAction
    ): State => {
      const { course } = state;
      const { isPast } = action.payload.occurrence.data.attributes;
      const updateAttribute = isPast
        ? "pastOccurrencesCount"
        : "futureOccurrencesCount";
      const count = course.get(updateAttribute) + 1;
      const update = course.set(updateAttribute, count);

      return {
        ...state,
        course: update,
      };
    },
    [DELETE_OCCURRENCE_SUCCESS]: (
      state: State,
      action: DeleteOccurrenceSuccessAction
    ): State => {
      const { course } = state;
      const { isPast } = action.payload.occurrence.data.attributes;
      const updateAttribute = isPast
        ? "pastOccurrencesCount"
        : "futureOccurrencesCount";
      const count = course.get(updateAttribute) - 1;
      const update = course.set(updateAttribute, count);

      return {
        ...state,
        course: update,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default manageCourse;
