// @flow
import { handleActions } from "redux-actions";
import type { Saga } from "redux-saga";
import { take, put } from "redux-saga/effects";
import { initialize } from "redux-form";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import {
  NONE,
  LOADING,
  LOADED,
  PENDING,
  SUCCESS,
  ERROR,
} from "shared/constants/status";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { Instructor } from "redux/api-types/Instructor";
import { INSTRUCTOR_ACCOUNT_FORM } from "./constants";
import { CONNECT_STRIPE_SUCCESS } from "../connect";
import { getJwtUser } from "shared/utilities/authCookie";

const namespace: string = `${APP_NAMESPACE}/instructorAccount`;

// Actions
export const ACCOUNT_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const ACCOUNT_REQUEST_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const ACCOUNT_REQUEST_ERROR: string = `${namespace}/FETCH/ERROR`;
export const UPDATE_ACCOUNT_REQUEST: string = `${namespace}/UPDATE/REQUEST`;
export const UPDATE_ACCOUNT_SUCCESS: string = `${namespace}/UPDATE/SUCCESS`;
export const UPDATE_ACCOUNT_ERROR: string = `${namespace}/UPDATE/ERROR`;
export const PROFILE_IMAGE_REQUEST: string = `${namespace}/PROFILE_IMAGE/REQUEST`;
export const PROFILE_IMAGE_SUCCESS: string = `${namespace}/PROFILE_IMAGE/SUCCESS`;
export const PROFILE_IMAGE_ERROR: string = `${namespace}/PROFILE_IMAGE/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creators
export type accountType = {
  id: string,
  number: string,
  firstName: string,
  lastName: string,
  email: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  postalCode: string,
  phone: string,
  isSearchable: boolean,
  hasPaymentProcessorId: boolean,
  paymentEnabled: boolean,
  cancellationPolicy: string,
  imageIds?: {
    [string]: string,
  },
  imageUrl?: string,
};

type AccountResponse = {
  data: Instructor,
};

export type AccountAction = {
  type: string,
  payload: {
    data: Instructor,
    included: mixed,
  },
  meta: {
    receivedAt: number,
  },
};

export const requestAccount = (): simpleReduxAction => ({
  type: ACCOUNT_REQUEST,
});

export const receiveAccount = (json: AccountResponse): AccountAction => ({
  type: ACCOUNT_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const accountRequestError = (error: apiErrorParams): apiErrorAction => ({
  type: ACCOUNT_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

export type requestAccountUpdateParams = {
  email: string,
  address1: string,
  city: string,
  state: string,
  postalCode: string,
  state: string,
};

export type requestAccountUpdateReturn = {
  type: string,
  payload: {
    account: requestAccountUpdateParams,
  },
};

export const requestAccountUpdate = (
  account: requestAccountUpdateParams
): requestAccountUpdateReturn => ({
  type: UPDATE_ACCOUNT_REQUEST,
  payload: {
    account,
  },
});

export const updateAccountSuccess = (json: AccountResponse): AccountAction => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const updateAccountError = (error: apiErrorParams): apiErrorAction => ({
  type: UPDATE_ACCOUNT_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

const jwtUser = getJwtUser();
const { userId } = jwtUser;
const instructorId = userId ? userId : "";

// Async actions
export const getAccount = (): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/instructors/${instructorId}?include=images`,
    actions: {
      request: requestAccount,
      success: receiveAccount,
      failure: accountRequestError,
    },
  },
});

export const updateAccount = (account: accountType): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "PATCH",
    endpoint: `/api/training/instructors/${instructorId}`,
    options: account,
    actions: {
      request: requestAccountUpdate,
      success: updateAccountSuccess,
      failure: updateAccountError,
    },
    toasts: {
      success: "Account Updated",
      failure: "Update Error",
    },
  },
});

const accountDataToInitialValues = (data: Instructor): accountType => {
  const {
    id,
    attributes: {
      number,
      firstName,
      lastName,
      companyName,
      biography,
      email,
      address1,
      address2,
      city,
      state: locationState,
      postalCode,
      phone,
      hasPaymentProcessorId,
      paymentEnabled,
      isSearchable,
      cancellationPolicy,
      isRangePartner,
      imageIds,
      imageUrl,
    },
  } = data;

  const account = {
    id,
    number,
    firstName,
    lastName,
    companyName,
    email,
    address1,
    address2,
    city,
    state: locationState,
    postalCode,
    phone,
    biography,
    hasPaymentProcessorId,
    paymentEnabled,
    isSearchable,
    cancellationPolicy,
    isRangePartner,
    imageIds,
    imageUrl,
  };

  return account;
};

// Sagas
export function* onInstructorAccountSuccessfulUpdate(): Saga<void> {
  for (;;) {
    const action = yield take(UPDATE_ACCOUNT_SUCCESS);
    const account = accountDataToInitialValues(action.payload.data);
    const initializeAction = initialize(INSTRUCTOR_ACCOUNT_FORM, account);
    yield put(initializeAction);
  }
}

// Initial State
export type State = {
  status: string,
  errorMessage: string,
  errors: ?apiErrorReturn,
  updateStatus: string,
  updateErrorMessage: string,
  updateErrors: apiErrorReturn,
  account: accountType,
};

const initialState: State = {
  status: NONE,
  errorMessage: "",
  errors: [],
  updateStatus: NONE,
  updateErrorMessage: "",
  updateErrors: [],
  account: {
    id: "",
    number: "",
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    phone: "",
    biography: "",
    hasPaymentProcessorId: false,
    paymentEnabled: false,
    isSearchable: false,
    isRangePartner: false,
    cancellationPolicy: "",
    imageUrl: "",
    imageIds: [],
  },
};

const account = handleActions(
  {
    [ACCOUNT_REQUEST]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      status: LOADING,
      errorMessage: "",
      errors: [],
    }),
    [ACCOUNT_REQUEST_SUCCESS]: (state: State, action: AccountAction): State => {
      const { data, included } = action.payload;
      let imageUrl = "";
      if (included && included[0]) {
        imageUrl = included[0].attributes.url;
      }
      let account = accountDataToInitialValues(data);
      account = {
        ...account,
        imageUrl: imageUrl,
      };
      return {
        ...state,
        status: LOADED,
        account,
      };
    },
    [ACCOUNT_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        status: ERROR,
        errorMessage,
        errors,
      };
    },
    [UPDATE_ACCOUNT_REQUEST]: (
      state: State,
      action: requestAccountUpdateReturn
    ): State => ({
      ...state,
      updateStatus: PENDING,
      updateError: "",
      updateErrorData: [],
    }),
    [UPDATE_ACCOUNT_SUCCESS]: (state: State, action: AccountAction): State => {
      const { data } = action.payload;
      const account = accountDataToInitialValues(data);
      return {
        ...state,
        account,
        updateStatus: SUCCESS,
      };
    },
    [UPDATE_ACCOUNT_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        updateStatus: ERROR,
        updateErrorMessage: errorMessage,
        updateErrors: errors,
      };
    },
    [CONNECT_STRIPE_SUCCESS]: (
      state: State,
      action: simpleReduxAction
    ): State => {
      const { account } = state;
      return {
        ...state,
        account: {
          ...account,
          hasPaymentProcessorId: true,
          paymentEnabled: true,
        },
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default account;
