// @flow
import CurriculumRecord from "./CurriculumRecord";
import type { Curriculum } from "redux/api-types/Curriculum";

export const parseCurriculi = (data: Array<Curriculum>): {} => {
  let certifications = {};

  data.forEach(function ({
    id,
    attributes: {
      name,
      imgUrl: imageUrl,
      thumbnail: thumbnailUrl,
      badgeImageUrl,
    },
  }: Certification) {
    const Record = CurriculumRecord({
      id,
      name,
      imageUrl,
      thumbnailUrl,
      badgeImageUrl,
    });
    certifications[id] = Record;
  });

  return certifications;
};
