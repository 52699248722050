// @flow
import { Record } from "immutable";

export type CurriculumRecordType = {
  id: string,
  name: string,
  abbreviation: string,
  canReceiveCertification: boolean,
  createdAt: string,
  description: string,
  imageUrl: {
    landscape: string,
    landscapeLrg: string,
  },
  liveFireOptional: boolean,
  liveFireProhibited: boolean,
  liveFireRequired: boolean,
  prerequisites: Array<string>,
  receivedUponCompletion: Array<string>,
  thumbnailUrl: string,
  isCiLevel: boolean,
  toolkit: {
    items: {
      instructor: Array<string>,
      student: Array<string>,
    },
  },
};

const CurriculumRecord = Record({
  id: "",
  name: "",
  abbreviation: "",
  canReceiveCertification: false,
  createdAt: "",
  description: "",
  imageUrl: {
    small: "",
    large: "",
    landscape: "",
    landscapeLrg: "",
  },
  liveFireOptional: false,
  liveFireProhibited: false,
  liveFireRequired: false,
  prerequisites: [],
  receivedUponCompletion: [],
  thumbnailUrl: "",
  isCiLevel: false,
  toolkit: {
    items: {
      instructor: ["item 1", "item 2", "item 3"],
      student: ["item 1", "item 2", "item 3"],
    },
  },
});

export default CurriculumRecord;
