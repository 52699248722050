// @flow
import { take } from "redux-saga/effects";
import * as Sentry from "@sentry/browser";
import type { Scope } from "@sentry/browser";
import { GET_ME_REQUEST_SUCCESS } from "./meActions";
import triggerGoogleOptimizeUserDataReadyEvent from "redux/middleware/triggerGoogleOptimizeUserDataReadyEvent";

const { SENTRY_ERROR_TRACKING } = process.env;
const initSentry = SENTRY_ERROR_TRACKING === "true";

export function* onMeRequestSuccess(): Saga<void> {
  for (;;) {
    const action = yield take(GET_ME_REQUEST_SUCCESS);

    if (initSentry) {
      const {
        id,
        name: firstName,
        surname: lastName,
        email,
        instructor,
      } = action.payload.data.me;

      Sentry.configureScope((scope: Scope) => {
        scope.setUser({
          email,
          id,
          firstName,
          lastName,
          isInstructor: !!instructor?.number,
          instructorNumber: instructor?.number,
        });
      });
    }

    triggerGoogleOptimizeUserDataReadyEvent();
  }
}
