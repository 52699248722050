// @flow
/* eslint flowtype/require-parameter-type: 0 */
/* eslint flowtype/require-return-type: 0 */
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware as createRouterMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import sagaMonitor from "redux-saga";
import type { History } from "@types/history";

import createRootReducer from "redux/modules/reducer";

const { NODE_ENV } = process.env;
// eslint-disable-next-line no-console
console.log("👟 running on NODE_ENV", NODE_ENV);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

function configureStore(history: History) {
  const routerMiddleware = createRouterMiddleware(history);
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  const middleWares = [routerMiddleware, sagaMiddleware];
  const enhancers = [applyMiddleware(...middleWares)];

  return {
    ...createStore(createRootReducer(history), composeEnhancer(...enhancers)),
    runSaga: sagaMiddleware.run,
  };
}

export default configureStore;
