// @flow
import { call, fork } from "redux-saga/effects";
import type { Saga } from "redux-saga";

// main sagas
import { callApi } from "./api";
import watchRouter from "./watchRouter";
import sessionCheck from "./sessionCheck";

// module actions
import { onLogout } from "redux/modules/authSagas";
import { onRequestCourseDetailsByEmailSuccess } from "redux/modules/courseInformationDialog/courseInformationDialogSagas";
import {
  watchGetLatLng,
  watchGetLatLngSuccess,
  watchGetLatLngError,
} from "redux/modules/courseSearchBy/courseSearchBySagas";
import {
  onSelectChange,
  onMultiSelectChange,
  onPostCourse,
} from "redux/modules/createCourse/createCourseSagas";
import { redirectLegacyCourseRoute } from "redux/modules/course/course";
import { onEmailCourseSuccess } from "redux/modules/emailCourse/emailCourseSagas";
import { redirectDefunctCRMIdBasedInstructorRoute } from "redux/modules/instructor/instructor";
import {
  initilizeSelects,
  onUpdateCourse,
} from "redux/modules/editCourse/editCourse";
import { onInstructorAccountSuccessfulUpdate } from "redux/modules/instructorAccount/instructorAccount";
import { watchGetOccurrences } from "redux/modules/instructorCourseOccurrences/instructorCourseOccurrences";
import { watchCourseRequestSuccess } from "redux/modules/manageCourse/manageCourse";
import { watchGetCourseOccurrences } from "redux/modules/manageCourseOccurrences/manageCourseOccurrences";
import { onCloneSuccess } from "redux/modules/manageCourses/manageCoursesSagas";
import { onMeRequestSuccess } from "redux/modules/me/meSagas";
import {
  onSuccessfulRegistrantEdit,
  onOpenRegistrantDrawer,
  onCloseRegistrantDrawer,
  onToggleRescheduleOccurrenceType,
} from "redux/modules/registrant/registrant";
import { onReviewCourseOccurrenceSuccess } from "redux/modules/reviewOccurrence/reviewOccurrenceSagas";
import { onSubmitResults } from "redux/modules/submitOccurrenceResults/submitOccurrenceResults";
import { onRequestSuccess as onStudentRequestCourseOccurrenceSuccess } from "redux/modules/studentRequestCourseOccurrence/studentRequestCourseOccurrenceSagas";
import {
  onOpen as onOpenStudentDrawer,
  onUpdate as onUpdateStudent,
  onStudentFetchSuccess,
} from "redux/modules/studentDrawer/studentDrawerSagas";

export default function* root(): Saga<void> {
  // initialize app
  yield fork(callApi);
  yield fork(onMeRequestSuccess);

  yield fork(watchRouter);
  yield fork(onLogout);

  // dialogs
  yield fork(onRequestCourseDetailsByEmailSuccess);
  yield fork(onStudentRequestCourseOccurrenceSuccess);
  // course
  yield fork(redirectLegacyCourseRoute);
  yield fork(onEmailCourseSuccess);
  // instructor
  yield fork(redirectDefunctCRMIdBasedInstructorRoute);
  // manage course
  yield fork(onCloneSuccess);
  // create course
  yield fork(onPostCourse);
  yield fork(onSelectChange);
  yield fork(onMultiSelectChange);
  // edit course
  yield fork(initilizeSelects);
  yield fork(onUpdateCourse);
  // instructor account
  yield fork(onInstructorAccountSuccessfulUpdate);
  // manage course
  yield fork(watchGetCourseOccurrences);
  yield fork(watchCourseRequestSuccess);
  // my classes
  yield fork(watchGetOccurrences);
  // review course occurrence
  yield fork(onReviewCourseOccurrenceSuccess);
  // roster
  yield fork(onSuccessfulRegistrantEdit);
  yield fork(onOpenRegistrantDrawer);
  yield fork(onCloseRegistrantDrawer);
  yield fork(onToggleRescheduleOccurrenceType);
  // search by
  yield fork(watchGetLatLng);
  yield fork(watchGetLatLngSuccess);
  yield fork(watchGetLatLngError);
  // students
  yield fork(onOpenStudentDrawer);
  yield fork(onUpdateStudent);
  yield fork(onStudentFetchSuccess);
  // submit results
  yield fork(onSubmitResults);
  // start app
  yield call(sessionCheck);
}
