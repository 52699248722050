// @flow
import ItemRecord from "./ItemRecord";
import type { CourseItem } from "redux/api-types/CourseItem";
import type { CourseItemI } from "./courseItems";

type ItemsI = {
  [string]: CourseItemI,
};

export const parseItems = (data: Array<CourseItem>): ItemsI => {
  let items = {};

  data.forEach(function ({
    id,
    attributes: { name, category, hasQuantity },
  }: CourseItem) {
    const Record = ItemRecord({
      id,
      name,
      category,
      hasQuantity: Boolean(hasQuantity),
    });
    items[id] = Record;
  });

  return items;
};
