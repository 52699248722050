// @flow
import { Record } from "immutable";

export type CurriculumModuleRecordI = {
  id: string,
  description: string,
  name: string,
};

const CurriculumModuleRecord = Record({
  id: "",
  description: "",
  name: "",
});

export default CurriculumModuleRecord;
