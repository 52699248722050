// @flow
import type { CourseOccurrence as CourseOccurrenceType } from "redux/api-types/CourseOccurrence";
import type { CourseOccurrence } from "redux/api-types/CourseOccurrence";
import type { InstructorRecordInterface } from "./InstructorRecord";
import type { OccurrenceInstructor } from "redux/api-types/OccurrenceInstructor";
import InstructorRecord from "./InstructorRecord";
import OccurrenceRecord from "./OccurrenceRecord";
import DateRecord from "./DateRecord";
import { createBuyClassUrl } from "shared/utilities/course";
import { getTID } from "shared/utilities/leadFieldCookie";
import { parseISO, format } from "date-fns";

import type { OccurrenceRecordInterface } from "./OccurrenceRecord";
import type { DateRecordInterface } from "./DateRecord";
import { createUuid } from "shared/utilities/helpers";

type ParseDatesReturn = {
  ids: Array<string>,
  records: {
    [string]: DateRecordInterface,
  },
};

const parseDates = (dates: Array<OccurrenceInstructor>): ParseDatesReturn => {
  let ids = [];
  let records = {};

  for (let i = 0; i < dates.length; i++) {
    const id = createUuid();
    const date = dates[i];
    ids.push(id);
    records[id] = new DateRecord({
      id,
      ...date,
    });
  }

  return {
    ids,
    records,
  };
};

type ParseInstructorsReturn = {
  instructorIds: Array<string>,
  instructorRecords: {
    [string]: InstructorRecordInterface,
  },
};

const parseInstructors = (
  instructors: Array<OccurrenceInstructor>
): ParseInstructorsReturn => {
  let instructorIds = [];
  let instructorRecords = {};

  instructors.forEach(function (instructor: OccurrenceInstructor) {
    const { guid: id, averageReviewRating, totalReviewCount } = instructor;

    const link = `/instructor/${id}`;
    instructorRecords[id] = new InstructorRecord({
      ...instructor,
      link,
      averageReviewRating,
      totalReviewCount,
    });
    instructorIds.push(id);
  });

  return {
    instructorIds,
    instructorRecords,
  };
};

type ParseOccurrenceReturn = {
  occurrenceRecord: OccurrenceRecordInterface,
  dateRecords: {
    [string]: DateRecordInterface,
  },
  instructorRecords: {
    [string]: InstructorRecordInterface,
  },
};

const parseOccurrence = (
  occurrence: Array<CourseOccurrenceType>
): ParseOccurrenceReturn => {
  const mmFormat = "MM";
  const {
    id,
    attributes: {
      name: courseName,
      entityId,
      capacity,
      numberOfRegistrants,
      dates,
      instructors,
      isEligibleForInstructorAutomation,
      webPayOnly,
      registrationQuestions,
      isInstructorCertifying,
    },
  } = occurrence;
  const startTime = dates[0].startTime;
  const { instructorIds, instructorRecords } = parseInstructors(instructors);
  const { ids: dateIds, records: dateRecords } = parseDates(dates);
  const spotsAvailable =
    capacity >= numberOfRegistrants ? capacity - numberOfRegistrants : 0;
  const tID = getTID();
  const checkoutUrl = createBuyClassUrl(entityId, courseName, id, tID);
  const occurrenceId = id;
  const groupByMonth = Number(format(parseISO(dates[0].startTime), mmFormat));

  const occurrenceRecord = new OccurrenceRecord({
    occurrenceId,
    capacity,
    numberOfRegistrants,
    spotsAvailable,
    checkoutUrl,
    dateIds,
    instructorIds,
    isEligibleForInstructorAutomation,
    webPayOnly,
    registrationQuestions,
    isInstructorCertifying,
    groupByMonth,
    startTime,
  });
  return {
    occurrenceRecord,
    dateRecords,
    instructorRecords,
  };
};

type ParseOccurrencesReturn = {
  occurrences: {
    [string]: OccurrenceRecordInterface,
  },
  dates: {
    [string]: DateRecordInterface,
  },
  instructors: {
    [string]: InstructorRecordInterface,
  },
};

export const parseCourseOccurrences = (
  data: Array<CourseOccurrence>
): ParseOccurrencesReturn => {
  let occurrences = {};
  let dates = {};
  let instructors = {};

  data.forEach(function (occurrence: CourseOccurrence) {
    const { id } = occurrence;
    const { occurrenceRecord, dateRecords, instructorRecords } =
      parseOccurrence(occurrence);

    occurrences[id] = occurrenceRecord;
    dates = {
      ...dates,
      ...dateRecords,
    };
    instructors = {
      ...instructors,
      ...instructorRecords,
    };
  });

  return {
    occurrences,
    dates,
    instructors,
  };
};
