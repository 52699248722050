// @flow
import React from "react";
import type { Node } from "react";

import { intent } from "shared/constants/intent";
import Dialog from "../Dialog/Dialog";
import Button from "shared/components/Button/Button";
import styles from "./ConfirmationDialog.scss";

const { SECONDARY } = intent;

type Props = {
  isOpen: boolean,
  title: string,
  message: Node,
  onConfirm: () => mixed,
  onCancel: () => mixed,
  onClose: () => mixed,
  confirmButtonLabel: string,
  cancelButtonLabel: string,
};

let ConfirmationDialog = ({
  title,
  message,
  isOpen,
  onConfirm,
  onCancel,
  onClose,
  confirmButtonLabel,
  cancelButtonLabel,
  intent,
}: Props): Node => (
  <Dialog
    title={title}
    onClose={onClose}
    autoFocus
    canEscapeKeyClose
    canOutsideClickClose
    enforceFocus
    isOpen={isOpen}
    usePortal
    isLarge
    intent={intent}
  >
    <div>{message}</div>

    <div className={styles.actions}>
      <Button intent={SECONDARY} onClick={onConfirm}>
        {confirmButtonLabel}
      </Button>
      <Button onClick={onCancel}>{cancelButtonLabel}</Button>
    </div>
  </Dialog>
);

ConfirmationDialog.defaultProps = {
  title: "",
  message: "",
  confirmButtonLabel: "OK",
  cancelButtonLabel: "Cancel",
};

export default ConfirmationDialog;
