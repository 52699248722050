// @flow
import type { Saga } from "redux-saga";
import { fork, put, select, take } from "redux-saga/effects";
import { push } from "connected-react-router";
import { change } from "redux-form";
import { touch } from "redux-form";

import { states } from "shared/constants/states";
import { CREATE_COURSE_FORM } from "./constants";
import { selectCurricula } from "./createCourseSelectors";
import { setSpecialSelect } from "./createCourseActionCreators";
import { getRanges } from "./createCourse";
import { getCurriculumId } from "../editCourse/editCourse";
import {
  SET_SPECIAL_SELECT,
  SET_SPECIAL_MULTISELECT,
  POST_COURSE_SUCCESS,
} from "./createCourseActions";

const abbreviationToStateSelectOption = {
  ["AR-CCHDF"]: { label: "Arkansas", value: "AR" },
  ["FL-CCHDF"]: { label: "Florida", value: "FL" },
  ["MN-CCHDF"]: { label: "Minnesota", value: "MN" },
  ["TX-CCHDF"]: { label: "Texas", value: "TX" },
};

// Sagas
function* onCurriculumChange(id: string): Saga<void> {
  const curricula = yield select(selectCurricula);
  const initialCurriculumId = yield select(getCurriculumId);

  // set minimumPrice in form, so we have it available ot check in the validator
  const minimumPrice = curricula?.hasOwnProperty(id)
    ? curricula[id].get("minimumPrice")
    : 0;
  const minimumPriceAction = change(
    CREATE_COURSE_FORM,
    "curriculumMinimumPrice",
    minimumPrice
  );
  yield put(minimumPriceAction);

  const curriculumRequiresModule = curricula?.hasOwnProperty(id)
    ? curricula[id].get("requiresModule")
    : false;
  const moduleAction = change(
    CREATE_COURSE_FORM,
    "curriculumRequiresModule",
    curriculumRequiresModule
  );
  yield put(moduleAction);

  // clear submodule value when curriculum changes
  /*
  Without this, if changing to another curriculum with submodules
  and no other submodule is selected after curriculum change
  Then the original submodule persists, meaning the new selected curriculum
  will have the wrong submodule
  */
  if (id !== initialCurriculumId) {
    const clearModuleAction = change(
      CREATE_COURSE_FORM,
      "curriculumModuleId",
      ""
    );
    yield put(clearModuleAction);
  }

  // set touched on liveFire field, so errors will be visible to user
  if (curricula?.hasOwnProperty(id)) {
    yield put(touch(CREATE_COURSE_FORM, "hasLiveFire"));
  }

  // set if live fire is required or not
  const liveFireRequired = curricula?.hasOwnProperty(id)
    ? curricula[id].get("liveFireRequired")
    : false;
  yield put(change(CREATE_COURSE_FORM, "liveFireRequired", liveFireRequired));

  // set if live fire is prohibited or not
  const liveFireProhibited = curricula?.hasOwnProperty(id)
    ? curricula[id].get("liveFireProhibited")
    : false;
  yield put(
    change(CREATE_COURSE_FORM, "liveFireProhibited", liveFireProhibited)
  );

  let liveFireValue;
  switch (true) {
    case liveFireRequired:
      liveFireValue = true;
      break;
    case liveFireProhibited:
      liveFireValue = false;
      break;
  }

  // leave live fire alone unless there are curriculum restrictions
  if (liveFireValue !== undefined) {
    const clearLiveFireAction = change(
      CREATE_COURSE_FORM,
      "hasLiveFire",
      liveFireValue
    );
    yield put(clearLiveFireAction);
  }

  // searchableProhibited: set isSearchable as false (and NOT editable) if true.
  const searchableProhibited = curricula?.hasOwnProperty(id)
    ? curricula[id].get("searchableProhibited")
    : false;
  // searchableOptional: set isSearchable as true (and editable) if true.
  const searchableOptional = curricula?.hasOwnProperty(id)
    ? curricula[id].get("searchableOptional")
    : false;
  // searchableRequired: set isSearchable as true (and NOT editable) if true.
  const searchableRequired = curricula?.hasOwnProperty(id)
    ? curricula[id].get("searchableRequired")
    : false;

  let isSearchable;

  switch (true) {
    case !!searchableProhibited:
      isSearchable = false;
      break;
    case !!searchableOptional:
    case !!searchableRequired:
      isSearchable = true;
      break;
    default:
      isSearchable = undefined;
  }

  /* NOTE: Only execute yield below when the user selects a curriculum different from
  the saved curriculum. This is needed so when editing the course
  isSearchable is not modified on first load since onCurriculumChange
  fires off on first load when editing. */

  if (initialCurriculumId !== id && isSearchable !== undefined) {
    yield put(change(CREATE_COURSE_FORM, "isSearchable", isSearchable));
  }

  // set extra data in hidden fields
  yield put(
    change(
      CREATE_COURSE_FORM,
      "curriculumAbbreviation",
      curricula[id]?.abbreviation || ""
    )
  );

  // set the state for state specific curriculum
  const abbreviation = curricula[id]?.abbreviation || null;
  const stateOption = abbreviationToStateSelectOption[abbreviation] || null;
  if (stateOption) {
    yield put(setSpecialSelect("state", stateOption));
  }
}

export function* onRangeChange(id: string): Saga<void> {
  const ranges = yield select(getRanges);
  const range = !!id && ranges.hasOwnProperty(id) ? ranges[id] : {};
  const rangeStateAbbr = range.shippingAddress?.state || "";
  const stateOption = rangeStateAbbr
    ? states.find(
        (state: {
          label: string,
          value: string,
        }): ?{ label: string, value: string } => state.value === rangeStateAbbr
      )
    : {
        label: "",
        value: "",
      };
  yield put(setSpecialSelect("state", stateOption));

  // set fields
  yield put(change(CREATE_COURSE_FORM, "location", range.name || ""));
  yield put(
    change(
      CREATE_COURSE_FORM,
      "address1",
      range.shippingAddress?.address1 || ""
    )
  );
  yield put(change(CREATE_COURSE_FORM, "address2", ""));
  yield put(
    change(CREATE_COURSE_FORM, "city", range.shippingAddress?.city || "")
  );
  yield put(
    change(CREATE_COURSE_FORM, "state", range.shippingAddress?.state || "")
  );
  yield put(
    change(
      CREATE_COURSE_FORM,
      "postalCode",
      range.shippingAddress?.postalCode || ""
    )
  );
}

export function* onSelectChange(): Saga<void> {
  for (;;) {
    // main effect, sets select value to redux form
    const {
      payload: { field, value },
    } = yield take(SET_SPECIAL_SELECT);
    const changeAction = change(CREATE_COURSE_FORM, field, value.value);
    yield put(changeAction);

    switch (true) {
      case field === "curriculumId":
        yield fork(onCurriculumChange, value.value);
        break;
      case field === "organizationId":
        yield fork(onRangeChange, value.value);
        break;
    }
  }
}

export function* onMultiSelectChange(): Saga<void> {
  for (;;) {
    const {
      payload: { field, values },
    } = yield take(SET_SPECIAL_MULTISELECT);
    let vals = [];

    for (let i = 0; i < values.length; i++) {
      vals.push(values[i]);
    }

    const changeAction = change(CREATE_COURSE_FORM, field, vals);
    yield put(changeAction);
  }
}

export function* onPostCourse(): Saga<void> {
  for (;;) {
    yield take(POST_COURSE_SUCCESS);
    const url = "/my-courses";
    yield put(push(url));
  }
}
