// @flow
import React from "react";
import type { Node } from "react";
import { Spinner, Intent } from "@blueprintjs/core";

import styles from "./loading.scss";

const LargeSpinner = (): Node => (
  <div className={styles.spinner}>
    <Spinner size={Spinner.SIZE_LARGE} intent={Intent.PRIMARY} />
  </div>
);

export default LargeSpinner;
