// @flow
import type { CourseOccurrence } from "redux/api-types/CourseOccurrence";
import type { Instructor } from "redux/api-types/Instructor";
import type { OccurrenceInstructor } from "redux/api-types/OccurrenceInstructor";
import type { OccurrenceRecordInterface } from "./OccurrenceRecord";
import OccurrenceRecord from "./OccurrenceRecord";
import type { DateRecordInterface } from "./DateRecord";
import DateRecord from "./DateRecord";
import type { InstructorRecordInterface } from "./InstructorRecord";
import InstructorRecord from "./InstructorRecord";

type ParseDatesReturn = {
  ids: Array<string>,
  records: {
    [string]: DateRecordInterface,
  },
};

const parseDates = (dates: Array<OccurrenceInstructor>): ParseDatesReturn => {
  let ids = [];
  let records = {};

  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];
    const { guid: id } = date;
    ids.push(id);
    records[id] = new DateRecord({
      id,
      ...date,
    });
  }

  return {
    ids,
    records,
  };
};

type ParseInstructorsReturn = {
  instructorIds: Array<string>,
  instructorRecords: {
    [string]: InstructorRecordInterface,
  },
};

export const instructorResponseToRecord = (
  instructor: Instructor
): InstructorRecordInterface => {
  const {
    id,
    attributes: { firstName, lastName, number, slug },
  } = instructor;

  const record = new InstructorRecord({
    id,
    firstName,
    lastName,
    number,
    slug,
  });

  return record;
};

const parseInstructors = (
  instructors: Array<OccurrenceInstructor>
): ParseInstructorsReturn => {
  let instructorIds = [];
  let instructorRecords = {};

  instructors.forEach(function (instructor: OccurrenceInstructor) {
    const { guid: id, firstName, lastName, number, slug } = instructor;
    instructorRecords[id] = new InstructorRecord({
      id,
      firstName,
      lastName,
      number,
      slug,
    });
    instructorIds.push(id);
  });

  return {
    instructorIds,
    instructorRecords,
  };
};

type ParseOccurrenceReturn = {
  occurrenceRecord: OccurrenceRecordInterface,
  dateRecords: {
    [string]: DateRecordInterface,
  },
  instructorRecords: {
    [string]: InstructorRecordInterface,
  },
};

export const parseOccurrence = (
  occurrence: CourseOccurrence
): ParseOccurrenceReturn => {
  const {
    id,
    attributes: {
      capacity,
      isPast,
      canHaveRegistrants,
      canSubmitResults,
      canDelete,
      numberOfRegistrants: registrantsCount,
      resultsSubmittedAt: dateSubmitted,
      dates,
      instructors,
      promotionId,
      slug,
    },
  } = occurrence;

  const { instructorIds, instructorRecords } = parseInstructors(instructors);
  const { ids: dateIds, records: dateRecords } = parseDates(dates);

  const registrantsLink = `/roster/${id}`;

  const occurrenceRecord = new OccurrenceRecord({
    id,
    isPast,
    isResultsSubmitted: !!dateSubmitted,
    canEdit: !dateSubmitted,
    canHaveRegistrants,
    canSubmitResults,
    dateSubmitted,
    canDelete,
    registrantsCount,
    capacity,
    registrantsLink,
    dateIds,
    instructorIds,
    promotionId,
    slug,
  });

  return {
    occurrenceRecord,
    dateRecords,
    instructorRecords,
  };
};

type ParseOccurrencesReturn = {
  occurrences: {
    [string]: OccurrenceRecordInterface,
  },
  dates: {
    [string]: DateRecordInterface,
  },
  instructors: {
    [string]: InstructorRecordInterface,
  },
};

export const parseOccurrences = (
  data: Array<CourseOccurrence>
): ParseOccurrencesReturn => {
  let occurrences = {};
  let dates = {};
  let instructors = {};

  data.forEach(function (occurrence: CourseOccurrence) {
    const { id } = occurrence;
    const { occurrenceRecord, dateRecords, instructorRecords } =
      parseOccurrence(occurrence);
    occurrences[id] = occurrenceRecord;
    dates = {
      ...dates,
      ...dateRecords,
    };
    instructors = {
      ...instructors,
      ...instructorRecords,
    };
  });
  return {
    occurrences,
    dates,
    instructors,
  };
};
