// @flow
import type { Saga } from "redux-saga";
import { fork, put, take } from "redux-saga/effects";

import enterEmailJourneySaga from "redux/middleware/enterEmailJourneySaga";
import { REQUEST_SUCCESS, closeDialog } from "./studentRequestCourseOccurrence";

export function* onRequestSuccess(): Saga<void> {
  for (;;) {
    yield take(REQUEST_SUCCESS);
    yield fork(enterEmailJourneySaga);
    const action = closeDialog();
    yield put(action);
  }
}
