// @flow
import { Record } from "immutable";

export type CourseRecordI = {
  id: string,
  name: string,
  overrideUrl: string,
  link: string,
  price: number,
  venue: string,
  city: string,
  state: string,
  postalCode: string,
  futureOccurrencesCount: number,
  curriculumImages: {
    card: string,
    cardRetina: string,
  },
  abbreviation: string,
  isWomensOnly: boolean,
  isWheelchairAccessible: boolean,
  hasLiveFire: boolean,
  grantsCCW: boolean,
  isModule: boolean,
  taughtBy: string,
};

const CourseRecord = Record({
  id: "",
  name: "",
  overrideUrl: "",
  link: "",
  price: 0,
  venue: "",
  city: "",
  state: "",
  postalCode: "",
  futureOccurrencesCount: 0,
  curriculumImages: {
    card: "",
    cardRetina: "",
  },
  abbreviation: "",
  isWomensOnly: false,
  isWheelchairAccessible: false,
  hasLiveFire: false,
  grantsCCW: false,
  isModule: false,
  taughtBy: "",
});

export default CourseRecord;
