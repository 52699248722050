// @flow
import { CALL_API } from "redux/middleware/api";
import type { InstructorAgreement } from "../../api-types/InstructorAgreement";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import {
  GET_AGREEMENTS_REQUEST,
  GET_AGREEMENTS_REQUEST_SUCCESS,
  GET_AGREEMENTS_REQUEST_ERROR,
  ACCEPT_AGREEMENTS_REQUEST,
  ACCEPT_AGREEMENTS_REQUEST_SUCCESS,
  ACCEPT_AGREEMENTS_REQUEST_ERROR,
} from "./instructorAgreementsActions";

type AgreementsResponse = {
  data: Array<InstructorAgreement>,
};

export type AgreementsAction = {
  type: string,
  payload: AgreementsResponse,
  meta: {
    receivedAt: number,
  },
};

export const requestAgreements = (): simpleReduxAction => ({
  type: GET_AGREEMENTS_REQUEST,
});

export const receiveAgreements = (
  json: AgreementsResponse
): AgreementsAction => ({
  type: GET_AGREEMENTS_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const agreementsRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: GET_AGREEMENTS_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const getInstructorAgreements = (): callApiReturnType => {
  const currentYear = new Date().getFullYear();
  return {
    type: CALL_API,
    payload: {
      method: "GET",
      endpoint: `/api/training/agreements?year=${currentYear}`,
      disableErrorToast: true,
      actions: {
        request: requestAgreements,
        success: receiveAgreements,
        failure: agreementsRequestError,
      },
    },
  };
};

export const acceptAgreementsRequest = (): simpleReduxAction => ({
  type: ACCEPT_AGREEMENTS_REQUEST,
});

export const acceptAgreementsSuccess = (
  json: AgreementsResponse
): AgreementsAction => ({
  type: ACCEPT_AGREEMENTS_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const acceptAgreementsRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: ACCEPT_AGREEMENTS_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const acceptAgreements = (): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: "/api/training/agreements",
    actions: {
      request: acceptAgreementsRequest,
      success: acceptAgreementsSuccess,
      failure: acceptAgreementsRequestError,
    },
    toasts: {
      success: "Agreements Accepted",
      failure: "Error Accepting Agreements",
    },
  },
});
