// @flow
import type { Saga } from "redux-saga";
import { call, take, put } from "redux-saga/effects";
import queryString from "query-string";

import { GOOGLE_MAPS_GEOCODE_API_KEY } from "shared/constants/application";
import { getAddressComponentByType } from "shared/utilities/googleGeocodeApi";
import {
  LATLNG_REQUEST,
  LATLNG_SUCCESS,
  LATLNG_ERROR,
} from "./courseSearchByActions";
import {
  getLatLngSuccess,
  getLatLngError,
} from "./courseSearchByActionCreators";

export function* watchGetLatLng(): Saga<void> {
  for (;;) {
    const action = yield take(LATLNG_REQUEST);
    const {
      payload: { postal_code = "", f = "" },
    } = action;

    const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAPS_GEOCODE_API_KEY}&components=postal_code:${postal_code}`;

    try {
      const response = yield call(fetch, url);
      const data = yield call([response, response.json]);
      const successAction = getLatLngSuccess({ ...data, f });
      yield put(successAction);
    } catch (error) {
      const failureAction = getLatLngError(error);
      yield put(failureAction);
    }
  }
}

export function* watchGetLatLngSuccess(): Saga<void> {
  for (;;) {
    const action = yield take(LATLNG_SUCCESS);
    let {
      payload: { results, f },
    } = action;
    let url = "/";
    if (results.length) {
      const {
        address_components,
        geometry: {
          location: { lat, lng },
        },
        formatted_address,
      } = results[0];
      const addressComponent = getAddressComponentByType(
        "postal_code",
        address_components
      );
      const query = addressComponent
        ? addressComponent.long_name
        : formatted_address;
      const latLngLength = 7;
      const args = {
        query,
        lat: lat.toString().substring(0, latLngLength),
        lng: lng.toString().substring(0, latLngLength),
        f,
      };
      const params = queryString.stringify(args, {
        skipEmptyString: true,
      });
      // example: https://academy.usconcealedcarry.com/search?query=Milwaukee,%20Wisconsin&lat=43.0349&lng=-87.9225
      url = `${process.env.URL_ACADEMY}/search?${params}`;
      window.location.href = url;
    }
  }
}

export function* watchGetLatLngError(): Saga<void> {
  for (;;) {
    yield take(LATLNG_ERROR);
  }
}
