// @flow
import type { Certification } from "redux/api-types/Certification";
import type { Curriculum } from "redux/api-types/Curriculum";

type Payload = {
  data: Array<Certification>,
  included: Array<Curriculum>,
};

type ActiveCerts = Array<string>;

export const parseActiveCerts = (payload: Payload): ActiveCerts => {
  const { data, included } = payload;
  let activeCurriculum = [];
  let curriculumIds = [];
  for (let i = 0; i < data.length; i++) {
    const { curriculumId, status } = data[i].attributes;
    if (status === "active") {
      curriculumIds.push(curriculumId);
    }
  }

  for (let i = 0; i < included.length; i++) {
    const {
      id,
      attributes: { abbreviation },
    } = included[i];
    // check if cert is active
    if (curriculumIds.includes(id)) {
      activeCurriculum.push(abbreviation);
    }
  }

  return activeCurriculum;
};
