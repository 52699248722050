// @flow
import Cookies from "js-cookie";

export type leadFieldCookie = {
  origin?: string,
  medium?: string,
  campaign?: string,
  audience?: string,
  targeting?: string,
  pageType?: string,
  type?: string,
  tID?: ?string,
};

const leadFieldCookieName = "uscca_lead_fields";

export const getLeadFieldCookie = (): leadFieldCookie => {
  return Cookies.get(leadFieldCookieName);
};

export const getTID = (): string => {
  if (getLeadFieldCookie()) {
    // grab the current lead cookie
    const currentLeadCookie = window.atob(getLeadFieldCookie());
    // it's a string, so let's get it back to an obj
    const leadFields = JSON.parse(currentLeadCookie);
    // return the tID
    return leadFields.tID;
  }

  return "";
};

export const getLeadAudience = (): string => {
  if (getLeadFieldCookie()) {
    // grab the current lead cookie
    const currentLeadCookie = window.atob(getLeadFieldCookie());
    // it's a string, so let's get it back to an obj
    const leadFields = JSON.parse(currentLeadCookie);
    // return the tID
    return leadFields.audience;
  }

  return "";
};
