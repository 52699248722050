// @flow
import { Record } from "immutable";

export type CurriculumRecordI = {
  id: string,
  name: string,
  abbreviation: string,
};

const CurriculumRecord = Record({
  id: "",
  name: "",
  abbreviation: "",
});

export default CurriculumRecord;
