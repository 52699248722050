// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import {
  NONE,
  LOADING,
  LOADED,
  PENDING,
  SUCCESS,
  ERROR,
} from "shared/constants/status";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { Review as ReviewType } from "redux/api-types/Review";
import type { Review as ReviewRecordI } from "./ReviewRecord";
import ReviewRecord from "./ReviewRecord";

const namespace: string = `${APP_NAMESPACE}/reviewOccurrence`;
export const REVIEW_FORM = "REVIEW_COURSE_OCCURRENCE";

// Actions
export const REGISTRANT_REVIEW_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const REGISTRANT_REVIEW_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const FETCH_REGISTRANT_REVIEW_ERROR: string = `${namespace}/FETCH/ERROR`;
export const POST_REVIEW_REQUEST: string = `${namespace}/POST/REQUEST`;
export const POST_REVIEW_SUCCESS: string = `${namespace}/POST/SUCCESS`;
export const POST_REVIEW_ERROR: string = `${namespace}/POST/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creators
export const requestRegistrantReview = (): simpleReduxAction => ({
  type: REGISTRANT_REVIEW_REQUEST,
});

type ReceiveRegistrantReviewReturn = {
  payload: ReviewType,
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export const receiveRegistrantReview = (
  json: ReviewType
): ReceiveRegistrantReviewReturn => ({
  type: REGISTRANT_REVIEW_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestRegistrantReviewError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: FETCH_REGISTRANT_REVIEW_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestPostReview = (): simpleReduxAction => ({
  type: POST_REVIEW_REQUEST,
});

type ReceivePostReviewReturn = {
  payload: ReviewType,
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export const receivePostReview = (
  json: ReviewType
): ReceivePostReviewReturn => ({
  type: POST_REVIEW_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestPostReviewError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: POST_REVIEW_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async actions
export const getRegistrantReview = (id: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/registrants/${id}/review`,
    actions: {
      request: requestRegistrantReview,
      success: receiveRegistrantReview,
      failure: requestRegistrantReviewError,
    },
    disableErrorToast: true,
  },
});

type Review = {
  registrantToken: string,
  rating: number,
  comment: string,
  isPublic: boolean,
  subscribe: boolean,
};

export const postReview = (id: string, review: Review): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: `/api/training/registrants/${id}/reviews`,
    options: review,
    actions: {
      request: requestPostReview,
      success: receivePostReview,
      failure: requestPostReviewError,
    },
  },
});

export const updateReview = (
  registrantId: string,
  reviewId: string,
  review: Review
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "PATCH",
    endpoint: `/api/training/registrants/${registrantId}/reviews/${reviewId}`,
    options: review,
    actions: {
      request: requestPostReview,
      success: receivePostReview,
      failure: requestPostReviewError,
    },
  },
});

// Initial State
export type State = {
  status: string,
  errors: apiErrorReturn,
  errorMessage: string,
  postReviewStatus: string,
  postReviewErrors: apiErrorReturn,
  postReviewErrorMessage: string,
  reviewId: string,
  review: ReviewRecordI,
};

const blankReview = new ReviewRecord({});

const initialState: State = {
  status: NONE,
  errors: [],
  errorMessage: "",
  postReviewStatus: NONE,
  postReviewErrors: [],
  postReviewErrorMessage: "",
  reviewId: "",
  review: blankReview,
};

// Reducer
const manageCourses = handleActions(
  {
    [REGISTRANT_REVIEW_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      status: LOADING,
      errorMessage: "",
      errors: [],
    }),
    [REGISTRANT_REVIEW_SUCCESS]: (
      state: State,
      {
        payload: {
          data: {
            id: reviewId,
            attributes: { rating, comment, isPublic },
          },
        },
      }: ReceiveRegistrantReviewReturn
    ): State => ({
      ...state,
      status: LOADED,
      reviewId,
      review: new ReviewRecord({
        id: reviewId,
        rating,
        comment,
        isPublic,
      }),
    }),
    [FETCH_REGISTRANT_REVIEW_ERROR]: (
      state: State,
      { payload: { errors, errorMessage } }: apiErrorAction
    ): State => ({
      ...state,
      status: ERROR,
      errors,
      errorMessage,
    }),
    [POST_REVIEW_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      postReviewStatus: PENDING,
      postReviewErrorMessage: "",
      postReviewErrors: [],
    }),
    [POST_REVIEW_SUCCESS]: (
      state: State,
      action: ReceivePostReviewReturn
    ): State => ({
      ...state,
      postReviewStatus: SUCCESS,
    }),
    [POST_REVIEW_ERROR]: (
      state: State,
      {
        payload: {
          errors: postReviewErrors,
          errorMessage: postReviewErrorMessage,
        },
      }: apiErrorAction
    ): State => ({
      ...state,
      postReviewStatus: ERROR,
      postReviewErrors,
      postReviewErrorMessage,
    }),
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default manageCourses;
