// @flow
import type { Saga } from "redux-saga";
import { take, put } from "redux-saga/effects";
import { initialize } from "redux-form";

import { OPEN, UPDATE_SUCCESS, FETCH_SUCCESS } from "./studentDrawerActions";
import { STUDENT_INFO_FORM } from "./constants";
import { getStudent, closeDrawer } from "./studentDrawerActionCreators";

export function* onOpen(): Saga<void> {
  for (;;) {
    const action = yield take(OPEN);
    yield put(getStudent(action.payload.id));
  }
}

export function* onUpdate(): Saga<void> {
  for (;;) {
    yield take(UPDATE_SUCCESS);
    yield put(closeDrawer());
  }
}

export function* onStudentFetchSuccess(): Saga<void> {
  for (;;) {
    const action = yield take(FETCH_SUCCESS);
    const account = action.payload.data.attributes;
    const initializeAction = initialize(STUDENT_INFO_FORM, account);
    yield put(initializeAction);
  }
}
