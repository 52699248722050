// @flow

import { Record } from "immutable";

export type DateRecordI = {
  endTime: string,
  id: string,
  startTime: string,
};

const DateRecord = Record({
  endTime: "",
  id: "",
  startTime: "",
});

export default DateRecord;
