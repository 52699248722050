// @flow
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

import { DEV, STAGING } from "../constants/environment";

export type JwtUserDataType = {
  userId: ?string,
  jti: ?string,
  iss: ?string,
  iat: ?number,
  exp: ?number,
};

const { NODE_ENV, APP_BASE_DOMAIN } = process.env;
const isEnvironmentDev = NODE_ENV === DEV;
const isEnvironmentStaging = NODE_ENV === STAGING;
const ACCESS_TOKEN_NAME = "uscca_access";

export const getAccessToken = (): string =>
  Cookies.get(ACCESS_TOKEN_NAME) || "";

export const setAccessToken = (
  cookieValue: string,
  expires: number = 3600
): string => {
  const options = {
    expires,
    domain: APP_BASE_DOMAIN, // set the domain for all web properties, not just training.*
    secure: true,
  };

  Cookies.set(ACCESS_TOKEN_NAME, cookieValue, options);
  return getAccessToken();
};

export const getDecodedAccessToken = (): JwtUserDataType | null => {
  const accessToken = getAccessToken();

  try {
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      return decodedToken;
    }
  } catch (error) {
    if (isEnvironmentDev || isEnvironmentStaging) {
      // eslint-disable-next-line no-console
      console.error("👾 Invalid token format. Clear your cookies!", error);
    }
  }

  return null;
};

export const isAccessTokenSet = (): boolean => {
  return !!getDecodedAccessToken()?.userId;
};

export const isAccessTokenExpired = (): boolean => {
  const token = getDecodedAccessToken();

  if (token) {
    return Math.round(Date.now() / 1000) >= (token.exp || 0);
  }

  return true;
};

export function getRemainingSessionTime(): number {
  return getDecodedAccessToken()?.exp || 0;
}

export const getJwtUser = (): JwtUserDataType => {
  return (
    getDecodedAccessToken() || {
      userId: null,
      jti: null,
      iss: null,
      iat: null,
      exp: null,
    }
  );
};

export const deleteAuthCookies = () => {
  const domain = APP_BASE_DOMAIN;
  Cookies.remove(ACCESS_TOKEN_NAME, { domain });
};

export function loginRedirect() {
  const pathname = window.location.pathname;
  const {
    URL_LOGIN = "https://portal.usconcealedcarry.com",
    APP_URL = "https://training.usconcealedcarry.com",
  } = process.env;
  const returnTo = encodeURIComponent(APP_URL + pathname);
  const redirectUrl = `${URL_LOGIN}/?redirectTo=${returnTo}`;
  window.location.replace(redirectUrl);
}
