// @flow
import { Record } from "immutable";

export type Review = {
  id: string,
  rating: number,
  comment: string,
  isPublic: boolean,
};

const ReviewRecord = Record({
  id: "",
  rating: 0,
  comment: "",
  isPublic: true,
});

export default ReviewRecord;
