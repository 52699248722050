// @flow
import type { Organization } from "redux/api-types/Organization";
import type { RangesResponse } from "../createCourseActionCreators";
import type { RangeRecordI } from "../records/RangeRecord";
import RangeRecord from "../records/RangeRecord";
import type { selectOption as SelectOption } from "shared/constants/flowTypes";

type Ranges = {
  [string]: RangeRecordI,
};

export const parseInstructorRanges = (json: RangesResponse): Ranges => {
  const ranges = {};

  json.included.forEach(function (org: Organization) {
    ranges[org.id] = new RangeRecord({
      id: org.id,
      ...org.attributes,
    });
  });

  return ranges;
};

export const parseInstructorRangeOptions = (
  json: RangesResponse
): Array<SelectOption> => {
  const options = [
    {
      value: "",
      label: "None",
    },
  ];

  json.included.forEach(function (org: Organization) {
    options.push({
      value: org.id,
      label: org.attributes.name,
    });
  });

  return options;
};
