// @flow
import { Record } from "immutable";

export type locationRecordType = {
  name: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  zip: string,
  lat: number,
  lng: number,
  fullAddress: string,
};

const LocationRecord = Record({
  name: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  postalCode: null,
  lat: null,
  lng: null,
  fullAddress: null,
});

export default LocationRecord;
