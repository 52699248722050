// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".shared-layout-ErrorBoundary__wrap--xJ4dY{font-size:18px;line-height:1.6;padding:40px;text-align:center}", "",{"version":3,"sources":["webpack://./shared/layout/ErrorBoundary.scss","webpack://./styles/variables.scss"],"names":[],"mappings":"AAGA,0CACE,cAAA,CACA,eCUmB,CDTnB,YAAA,CACA,iBAAA","sourcesContent":["@import \"~Styles/colors\";\n@import \"~Styles/variables\";\n\n.wrap {\n  font-size: 18px;\n  line-height: $lineheight-spacier;\n  padding: 40px;\n  text-align: center;\n}\n","@import \"colors\";\n\n// colors\n$color-background: $snow;\n$color-header-text: $midnight;\n\n// fonts\n$main-font: \"Open Sans\", sans-serif;\n$main-font-bold-weight: 600;\n$impact-font: \"Open Sans\", sans-serif;\n$impact-font-bold-weight: 600;\n\n// line height\n$lineheight: 1.2;\n$lineheight-spacy: 1.4;\n$lineheight-spacier: 1.6;\n$lineheight-spaciest: 1.8;\n$lineheight-spaciest-est: 2;\n\n// layout\n$max-width: 1400px;\n$subnav-height: 56px;\n$header-breakpoint-xs-sm: 576px;\n$minfooterheight: 50px;\n$border-radius: 5px;\n$dashboard-nav-width: 260px;\n$dashboard-content-horizontal-margin: 60px;\n$page-dropshadow: 7px 7px 7px $mono-2;\n$max-content-width: 1200px;\n$mobile-header-height: 56px;\n$desktop-header-breakpoint: 1100px;\n$desktop-header-height: 90px;\n\n$header-footer-z-index: 2;\n\n// Box shadows\n$box-shadow: 0px 11px 37px #00000029;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "shared-layout-ErrorBoundary__wrap--xJ4dY"
};
export default ___CSS_LOADER_EXPORT___;
