// @flow
import { handleActions } from "redux-actions";

import type { Me } from "../../api-types/Me";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import {
  GET_ME_REQUEST,
  GET_ME_REQUEST_SUCCESS,
  GET_ME_REQUEST_ERROR,
} from "./meActions";

// Initial State
export type State = {
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  user?: Me | {},
};

const initialState: State = {
  status: NONE,
  errorMessage: "",
  errors: [],
  user: null,
};

const me = handleActions(
  {
    [GET_ME_REQUEST]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      status: LOADING,
      errorMessage: "",
      errors: [],
      user: null,
    }),
    [GET_ME_REQUEST_SUCCESS]: (
      state: State,
      action: CoursePromotionsAction
    ): State => {
      const { data } = action.payload;
      const user = data.me;
      return {
        ...state,
        status: LOADED,
        user,
      };
    },
    [GET_ME_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        errorMessage,
        errors,
      };
    },
  },
  initialState
);

export default me;
