// @flow
export const round4 = [
  {
    courseId: "19ade5e8-dbcd-11ec-857e-02420a0002cd",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-owings-mills-MD-6-16-2022?link=training_platform",
  },
  {
    courseId: "d300e532-dbcc-11ec-a131-02420a0002cd",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-louisville-KY-6-17-2022?link=training_platform",
  },
  {
    courseId: "69a7199e-dbcc-11ec-8bb2-0242ac12000e",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-harlingen-TX-6-23-2022?link=training_platform",
  },
  {
    courseId: "137536fa-dbcc-11ec-95cf-02420a0002ce",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-leavenworth-KS-6-23-2022?link=training_platform",
  },
  {
    courseId: "bec6d2f8-dbcb-11ec-9c61-02420a0002cd",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-owings-mills-MD-06-23-2022?link=training_platform",
  },
  {
    courseId: "7645842a-dbcb-11ec-98bb-02420a0002ce",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-elizabethtown-KY-06-24-2022?link=training_platform",
  },
  {
    courseId: "0f3600ac-dbcb-11ec-9a74-02420a0002ce",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-muncie-IN-7-19-2022?link=training_platform",
  },
  {
    courseId: "db2f10de-dbcd-11ec-aa81-0242ac12000e",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-owings-mills-MD-6-30-2022?link=training_platform",
  },
  {
    courseId: "6d4f17d4-dbce-11ec-8c08-0242ac12000e",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-owings-mills-MD-7-7-2022?link=training_platform",
  },
  {
    courseId: "0cc632a2-dbcf-11ec-81aa-02420a0002ce",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-jeanette-PA-7-13-2022?link=training_platform",
  },
  {
    courseId: "799d1a80-dbcf-11ec-bbb1-0242ac12000e",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-owings-mills-MD-7-14-2022?link=training_platform",
  },
  {
    courseId: "00089f18-dbd0-11ec-990a-02420a0002cd",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-broomfield-CO-7-14-2022?link=training_platform",
  },
];
