// @flow
import React from "react";
import type { Node } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import type { State } from "Redux/modules/app/app";
import { closeConfirmationModal } from "redux/modules/app/app";
import { dialogs } from "redux/modules/app/constants";
import ErrorBoundary from "./ErrorBoundary";
import ConfirmationDialog from "shared/dialogs/ConfirmationDialog/ConfirmationDialog";
import SessionTimeoutDialog from "shared/dialogs/SessionTimeoutDialog/SessionTimeoutDialog";

const getContainerClass = (
  overrideContainerClassName: ?string,
  className: ?string
): string => {
  if (overrideContainerClassName) {
    return overrideContainerClassName;
  }

  const classesList = ["keep-it-100"];
  if (className) {
    classesList.push(className);
  }

  return classesList.join(" ");
};

type StateProps = {
  pageTitle: string,
  canonicalUrl: string,
  isInstructor: boolean,
  openDialog: string,
  confirmationModalIsOpen: boolean,
  confirmationModalTitle: string,
  confirmationModalMessage: string,
  confirmationModalOnConfirm: () => mixed,
  confirmationModalOnCancel: () => mixed,
  confirmationModalOnLogout: () => mixed,
  confirmationModalconfirmButtonLabel: string,
  confirmationModalCancelButtonLabel: string,
  confirmationModalIntent: string,
};

type Props = {
  className: string,
  overrideContainerClassName: string,
  children: Node,
  closeConfirmationModal: () => mixed,
} & StateProps;

const Main = ({
  className,
  overrideContainerClassName,
  pageTitle,
  canonicalUrl,
  isInstructor,
  openDialog,
  confirmationModalIsOpen,
  confirmationModalTitle,
  confirmationModalMessage,
  confirmationModalOnConfirm,
  confirmationModalOnCancel,
  confirmationModalOnLogout,
  confirmationModalconfirmButtonLabel,
  confirmationModalCancelButtonLabel,
  confirmationModalIntent,
  closeConfirmationModal,
  children,
}: Props): Node => (
  <ErrorBoundary>
    <Helmet
      title={pageTitle}
      link={
        canonicalUrl
          ? [
              {
                rel: `canonical`,
                href: canonicalUrl,
              },
            ]
          : []
      }
    />
    <div className={getContainerClass(overrideContainerClassName, className)}>
      {children}
    </div>
    <ConfirmationDialog
      title={confirmationModalTitle}
      isOpen={confirmationModalIsOpen}
      message={confirmationModalMessage}
      onConfirm={confirmationModalOnConfirm}
      onCancel={confirmationModalOnCancel}
      onLogout={confirmationModalOnLogout}
      onClose={closeConfirmationModal}
      confirmButtonLabel={confirmationModalconfirmButtonLabel}
      cancelButtonLabel={confirmationModalCancelButtonLabel}
      intent={confirmationModalIntent}
    />
    {openDialog === dialogs.SESSION_TIMEOUT && <SessionTimeoutDialog />}
  </ErrorBoundary>
);

Main.defaultProps = {
  className: "",
  overrideContainerClassName: "",
};

const mapStateToProps = ({
  app: {
    pageTitle,
    canonicalUrl,
    openDialog,
    confirmationModal: {
      isOpen,
      title,
      message,
      onConfirm,
      onCancel,
      onLogout,
      confirmButtonLabel,
      cancelButtonLabel,
      intent,
    },
  },
  me: { user },
}: {
  app: State,
}): StateProps => ({
  pageTitle,
  canonicalUrl,
  isInstructor: user?.instructor?.instructorNumber,
  openDialog,
  confirmationModalIsOpen: isOpen,
  confirmationModalTitle: title,
  confirmationModalMessage: message,
  confirmationModalOnConfirm: onConfirm,
  confirmationModalOnCancel: onCancel,
  confirmationModalOnLogout: onLogout,
  confirmationModalconfirmButtonLabel: confirmButtonLabel,
  confirmationModalCancelButtonLabel: cancelButtonLabel,
  confirmationModalIntent: intent,
});

const actions = {
  closeConfirmationModal,
};

export default connect(mapStateToProps, actions)(Main);
