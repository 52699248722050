// @flow
import { handleActions } from "redux-actions";
import { parseCourseOccurrencesFromInclude } from "./courseOccurrencesParser";
import { parseInstructorReviews } from "./instructorReviewsParser";
import { setLocalStorage, getLocalStorage } from "shared/utilities/helpers";
import {
  ERROR,
  LOADED,
  LOADING,
  NONE,
  PENDING,
  SUCCESS,
} from "shared/constants/status";
import {
  parseInstructorRepliesFromInclude,
  replyToReplyRecord,
} from "./instructorRepliesParser";
import {
  PER_PAGE,
  DEFAULT_PER_PAGE,
  storedPerPage,
} from "./instructorReviewActionCreators";

// Type imports
import type { OccurrenceRecordI } from "./OccurrenceRecord";
import type { DateRecordI } from "./DateRecord";
import type { InstructorRecordI } from "./InstructorRecord";
import type { ReplyRecordI } from "./ReplyRecord";
import type { ReviewRecordI } from "./ReviewRecord";
import type {
  ReceiveRepliesReturnType,
  ReceiveReviewsReturnType,
  ReplyAction,
  RequestReviewsReturnType,
  setActiveReviewIdAction,
  setReplyInputValueAction,
} from "./instructorReviewActionCreators";
import type {
  apiErrorAction,
  apiErrorReturn,
  simpleReduxAction,
} from "shared/constants/flowTypes";

// Action inports
import {
  POST_REPLY_REQUEST,
  REMOVE_REPLY_REQUEST,
  REVIEWS_REQUEST,
  POST_REPLY_REQUEST_SUCCESS,
  REMOVE_REPLY_SUCCESS,
  REVIEWS_REQUEST_SUCCESS,
  POST_REPLY_REQUEST_ERROR,
  REMOVE_REPLY_ERROR,
  REVIEWS_REQUEST_ERROR,
  SET_ACTIVE_REVIEW_ID,
  SET_REPLY_INPUT_VALUE,
  CANCEL_REPLY,
  CLEAR,
} from "./instructorReviewActions";

// local storage
if (storedPerPage === undefined) {
  setLocalStorage(PER_PAGE, DEFAULT_PER_PAGE);
}

// Initial State
export type State = {
  activeReviewId: string,
  count: number,
  currentPage: number,
  dates: {
    [string]: DateRecordI,
  },
  deletePending: boolean,
  deleteReplyError: string,
  deleteReplyStatus: string,
  errorMessage: string,
  errors: apiErrorReturn,
  instructorId: string,
  instructors: {
    [string]: InstructorRecordI,
  },
  occurrences: {
    [string]: OccurrenceRecordI,
  },
  perPage: number | string,
  postReplyStatus: string,
  replyInputValue: string,
  replyPostError: string,
  replies: {
    [string]: ReplyRecordI,
  },
  replyStatus: boolean,
  reviews: {
    [string]: ReviewRecordI,
  },
  status: string,
  totalCount: number,
  totalPages: number,
};

const initialState: State = {
  activeReviewId: "",
  count: 0,
  currentPage: 1,
  dates: {},
  deletePending: false,
  deleteReplyError: "",
  deleteReplyStatus: NONE,
  errorMessage: "",
  errors: [],
  instructorId: "",
  instructors: {},
  occurrences: {},
  perPage: getLocalStorage(PER_PAGE) ?? DEFAULT_PER_PAGE,
  postReplyStatus: NONE,
  replies: {},
  replyPostError: "",
  replyInputValue: "",
  replyStatus: false,
  reviews: {},
  status: NONE,
  totalCount: 0,
  totalPages: 1,
};

const instructorReviews = handleActions(
  {
    [SET_REPLY_INPUT_VALUE]: (
      state: State,
      { payload: { replyInputValue } }: setReplyInputValueAction
    ): State => ({
      ...state,
      replyInputValue,
    }),
    [SET_ACTIVE_REVIEW_ID]: (
      state: State,
      { payload: { activeReviewId } }: setActiveReviewIdAction
    ): State => ({
      ...state,
      activeReviewId,
      postReplyStatus: NONE,
      deleteReplyStatus: NONE,
    }),
    [REVIEWS_REQUEST]: (
      state: State,
      {
        payload: { instructorId, page: currentPage, perPage },
      }: RequestReviewsReturnType
    ): State => ({
      ...state,
      instructorId,
      page: currentPage,
      perPage: perPage !== "All" ? perPage : 0,
      totalPages: perPage === "All" ? 1 : state.totalPages,
      status: LOADING,
      reviews: {},
      replies: {},
      instructors: {},
      occurrences: {},
      dates: {},
      postReplyStatus: NONE,
      deleteReplyStatus: NONE,
      activeReviewId: "",
    }),
    [REVIEWS_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveReviewsReturnType
    ): State => {
      const { data, included, meta } = action.payload;
      const { reviews } = parseInstructorReviews(data);
      const { replies } = parseInstructorRepliesFromInclude(included);
      const {
        occurrences,
        dates,
        instructors,
        // $FlowFixMe
      } = parseCourseOccurrencesFromInclude(included);
      const { total: totalCount, count, currentPage, totalPages } = meta;

      return {
        ...state,
        status: LOADED,
        currentPage,
        totalPages,
        count,
        totalCount,
        reviews,
        occurrences,
        dates,
        instructors,
        replies,
      };
    },
    [REVIEWS_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [POST_REPLY_REQUEST]: (state: State, action: simpleReduxAction): State => {
      return {
        ...state,
        postReplyStatus: PENDING,
      };
    },
    [POST_REPLY_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveRepliesReturnType
    ): State => {
      const { replies } = state;
      const { reviews } = state;
      // Reply ID
      const { id } = action.payload.data;
      const { replyRecord } = replyToReplyRecord(action.payload.data);
      // Review record
      const record = reviews[action.payload.data.attributes.reviewId];
      const { reviewId } = record;
      const update = record.set("replyId", action.payload.data.id);

      return {
        ...state,
        replies: {
          ...replies,
          [id]: replyRecord,
        },
        reviews: {
          ...reviews,
          [reviewId]: update,
        },
        activeReviewId: "",
        replyInputValue: "",
        postReplyStatus: SUCCESS,
      };
    },
    [POST_REPLY_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        postReplyStatus: ERROR,
        replyPostError: errorMessage,
        errors: errors,
        activeReviewId: "",
      };
    },
    [REMOVE_REPLY_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => {
      return {
        ...state,
        deletePending: true,
      };
    },
    [REMOVE_REPLY_SUCCESS]: (state: State, action: ReplyAction): State => {
      const reviewId = action.payload.reply.data.attributes.reviewId;
      const replyId = action.payload.reply.data.id;
      let { replies, reviews } = state;
      replies = {
        ...replies,
      };
      // Remove reply from replies
      delete replies[replyId];
      // Update review object, remove replyId
      const reviewRecord = reviews[reviewId];
      const updatedReview = reviewRecord.set("replyId", "");
      return {
        ...state,
        deletePending: false,
        reviews: {
          ...reviews,
          [reviewId]: updatedReview,
        },
        replies: {
          ...replies,
        },
      };
    },
    [REMOVE_REPLY_ERROR]: (state: State, action: apiErrorAction): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        deleteReplyStatus: ERROR,
        deleteReplyError: errorMessage,
        errors: errors,
      };
    },
    [CANCEL_REPLY]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      activeReviewId: "",
      replyInputValue: "",
    }),
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default instructorReviews;
