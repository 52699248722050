// @flow
import CurriculumRecord from "../records/CurriculumRecord";
import type { CurriculumRecordType } from "../records/CurriculumRecord";
import type { Curriculum } from "redux/api-types/Curriculum";
import CurriculumModuleRecord from "../records/CurriculumModuleRecord";
import type { CurriculumModuleRecordType } from "../records/CurriculumModuleRecord";
//import type { CurriculumModule } from 'redux/api-types/CurriculumModule';

const DEFAULT_IMAGE_URL =
  "/api/training-assets/curricula/other/eu/featured/756x250.jpg";
const DEFAULT_IMAGE_URL_LRG =
  "/api/training-assets/curricula/other/eu/featured/756x250@2x.jpg";

export const parseCurriculumModule = (
  filteredCurriculumModule: CurriculumModuleRecordType
): CurriculumModuleRecordType => {
  let module = {};
  const { id, attributes } = filteredCurriculumModule;
  module = {
    id,
    attributes,
  };
  const curriculumModuleRecord = new CurriculumModuleRecord(module);
  return curriculumModuleRecord;
};

export const parseCurriculum = (
  filteredCurriculum: Array<Curriculum>
): CurriculumRecordType => {
  let curriculum = {};
  filteredCurriculum.forEach(function (
    include: Curriculum
  ): CurriculumRecordType | void {
    const { id, type, attributes } = include;
    if (type === "curricula") {
      const { abbreviation } = attributes;
      const isCiLevel = abbreviation.slice(-2) === "CI";

      const {
        "landscape-large": landscape,
        "landscape-large-retina": landscapeLrg,
      } = attributes.imageUrl;

      curriculum = {
        id,
        ...attributes,
        imageUrl: {
          landscape: landscape ? landscape : DEFAULT_IMAGE_URL,
          landscapeLrg: landscapeLrg ? landscapeLrg : DEFAULT_IMAGE_URL_LRG,
        },
        isCiLevel,
      };
    }
  });
  const curriculumRecord = new CurriculumRecord(curriculum);

  return curriculumRecord;
};

type itemProps = {
  type: string,
};

export const parseCourseCurriculum = (
  includes: Array<Curriculum>
): CurriculumRecordType => {
  let curriculumRecord = {};
  let curriculumModuleRecord = {};

  const images = includes.filter(
    (item: itemProps): boolean => item.type === "images"
  );

  const filteredCurriculum = includes.filter(
    (item: itemProps): boolean => item.type !== "curriculum_module"
  );

  curriculumRecord = parseCurriculum(filteredCurriculum);
  // If curriculum module present
  const filteredCurriculumModule = includes.filter(
    (item: itemProps): boolean => item.type === "curriculum_module"
  );
  // parse and:
  // set curriculum 'description' to module description
  // set curriculum 'receivedUponCompletion' to module's certs.
  if (filteredCurriculumModule.length > 0) {
    curriculumModuleRecord = parseCurriculumModule(filteredCurriculumModule[0]);
    if (curriculumModuleRecord.attributes.receivedUponCompletion) {
      const moduleCertifications =
        curriculumModuleRecord.attributes.receivedUponCompletion;
      curriculumRecord = curriculumRecord.set(
        "receivedUponCompletion",
        moduleCertifications
      );
    }
    if (curriculumModuleRecord.attributes.description) {
      curriculumRecord = curriculumRecord.set(
        "description",
        curriculumModuleRecord.attributes.description
      );
    }
  }
  if (images.length > 0) {
    const image = images[0];
    const { url } = image.attributes;
    curriculumRecord = curriculumRecord.set("imageUrl", {
      landscape: url,
      landscapeLrg: url,
    });
  } else {
    curriculumRecord = curriculumRecord.set("imageUrl", {
      landscape: DEFAULT_IMAGE_URL,
      landscapeLrg: DEFAULT_IMAGE_URL_LRG,
    });
  }
  return curriculumRecord;
};
