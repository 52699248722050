// @flow
import { handleActions } from "redux-actions";

import { NONE, LOADING, SUCCESS, ERROR } from "shared/constants/status";
import {
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_REQUEST_SUCCESS,
  GET_PROMOTIONS_REQUEST_ERROR,
} from "./coursePromotionActions";
import { parseCoursePromotions } from "./parsePromotions";

// Initial State
export type State = {
  isLoading: boolean,
  isLoaded: boolean,
  isError: boolean,
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  promotions: [],
};

const initialState: State = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  status: NONE,
  errorMessage: "",
  errors: [],
  promotions: [],
};

const coursePromotions = handleActions(
  {
    [GET_PROMOTIONS_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      isLoading: true,
      isError: false,
      status: LOADING,
      errorMessage: "",
      errors: [],
      promotions: [],
    }),
    [GET_PROMOTIONS_REQUEST_SUCCESS]: (
      state: State,
      action: CoursePromotionsAction
    ): State => {
      const { data } = action.payload;
      const promotions = parseCoursePromotions(data);
      return {
        ...state,
        isLoading: false,
        status: SUCCESS,
        promotions: promotions,
      };
    },
    [GET_PROMOTIONS_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        isLoading: false,
        isError: true,
        status: ERROR,
        errorMessage,
        errors,
      };
    },
  },
  initialState
);

export default coursePromotions;
