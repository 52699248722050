// @flow
import { instructorRoutes, authedRoutes } from "../../routes";
import type { RouteI } from "../../routes";

export default function doesRouteRequireAuth(path: string): boolean {
  const allAuthedRoutes = [].concat(instructorRoutes).concat(authedRoutes);
  return !!allAuthedRoutes.find(
    (element: RouteI): ?boolean => element.path === path
  );
}
