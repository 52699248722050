// @flow
import { Record } from "immutable";

export type CourseItem = {
  name: string,
  qty?: number,
};

export type RegistrationQuestionI = {
  question: string,
  shortCode: string,
  type: string,
  options: Array<string>,
};

export type courseDetailsRecordType = {
  id: string,
  name: string,
  overrideUrl: string,
  price: number,
  capacity: number,
  description: string,
  notes: string,
  classroomHours: number,
  rangeHours: number,
  hasLiveFire: boolean,
  grantsCCW: boolean,
  tags: Array<string>,
  providedItems: {
    [string]: CourseItem,
  },
  bringableItems: {
    [string]: CourseItem,
  },
  location: {
    [string]: mixed,
  },
  registration: {
    [string]: mixed,
  },
  cancellationPolicy: string,
  canPurchase: boolean,
  canRegister: boolean,
  futureOccurrencesCount: number,
  isMultiday: boolean,
  averageReviewRating: number,
  totalReviewCount: number,
  webPayOnly: false,
  registrationQuestions: Array<RegistrationQuestionI>,
  isEligibleForInstructorAutomation: false,
  hasPrerequisiteELearning: boolean,
  isWheelchairAccessible: boolean,
  isWomensOnly: boolean,
  isModule: boolean,
  instructorId: string,
};

const CourseDetailsRecord = Record({
  id: "",
  name: "",
  overrideUrl: "",
  price: 0,
  capacity: 0,
  description: "",
  notes: "",
  classroomHours: 0,
  rangeHours: 0,
  hasLiveFire: false,
  grantsCCW: false,
  tags: [],
  providedItems: {},
  bringableItems: {},
  location: {},
  registration: {},
  cancellationPolicy: "",
  canPurchase: false,
  canRegister: false,
  futureOccurrencesCount: 0,
  isMultiday: false,
  averageReviewRating: 0,
  totalReviewCount: 0,
  webPayOnly: false,
  registrationQuestions: [],
  isEligibleForInstructorAutomation: false,
  hasPrerequisiteELearning: false,
  isWheelchairAccessible: false,
  isWomensOnly: false,
  isModule: false,
  instructorId: "",
});

export default CourseDetailsRecord;
