// @flow

type SelectOption = {
  label: string,
  value: string,
};

export const getOptionFromSelectedValue = (
  value: string,
  options: Array<SelectOption>
): SelectOption => {
  const option = options.filter(function (option: SelectOption): boolean {
    return option.value === value;
  });

  return option
    ? option
    : {
        label: value,
        value,
      };
};
