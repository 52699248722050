// @flow
import React from "react";
import type { Node } from "react";
import { FormGroup, TextArea } from "@blueprintjs/core";
import FormFieldError from "./FormFieldError";

type Props = {
  id?: string,
  containerClass: string,
  inputClass: string,
  input: {
    name: string,
    value: string,
  },
  helperText: string,
  label: string,
  placeholder: string,
  meta: {
    touched: boolean,
    error: boolean,
  },
};

let RenderTextArea = ({
  containerClass,
  inputClass,
  input,
  helperText,
  placeholder,
  label,
  meta: { touched, error },
  ...props
}: Props): Node => (
  <FormGroup
    className={containerClass}
    label={label}
    labelFor={input.name}
    helperText={helperText}
  >
    <TextArea className={inputClass} {...input} {...props} />
    {touched && error && <FormFieldError>{error}</FormFieldError>}
  </FormGroup>
);

RenderTextArea.defaultProps = {
  containerClass: "",
  inputClass: "",
  label: "",
  labelinfo: "",
  helperText: "",
  placeholder: "",
};

export default RenderTextArea;
