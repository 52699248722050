// @flow
import type { Saga } from "redux-saga";
import { fork, take } from "redux-saga/effects";

import enterEmailJourneySaga from "redux/middleware/enterEmailJourneySaga";
import { EMAIL_SUCCESS } from "./emailCourse";

export function* onEmailCourseSuccess(): Saga<void> {
  for (;;) {
    yield take(EMAIL_SUCCESS);
    yield fork(enterEmailJourneySaga);
  }
}
