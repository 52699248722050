// @flow
import { Record } from "immutable";

export type OccurrenceRecordI = {
  courseId: string,
  dateIds: Array<string>,
  id: string,
  instructorIds: Array<string>,
};

const OccurrenceRecord = Record({
  courseId: "",
  dateIds: [],
  id: "",
  instructorIds: [],
});

export default OccurrenceRecord;
