// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".shared-loading-loading__spinner--m9Eyf{text-align:center}", "",{"version":3,"sources":["webpack://./shared/loading/loading.scss"],"names":[],"mappings":"AAAA,wCACE,iBAAA","sourcesContent":[".spinner {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "shared-loading-loading__spinner--m9Eyf"
};
export default ___CSS_LOADER_EXPORT___;
