// @flow
import type { CoursePromotion } from "redux/api-types/CoursePromotion";
import CoursePromotionRecord from "./CoursePromotionRecord";
import { CoursePromotionRecordI } from "./CoursePromotionRecord";

export type ParseCoursePromotionsReturn = {
  [string]: CoursePromotionRecordI,
};

export const parseCoursePromotions = (
  data: Array<CoursePromotion>
): ParseCoursePromotionsReturn => {
  let promotions = [];

  data.forEach(function ({ id, attributes }: CoursePromotion) {
    const promotionRecord = new CoursePromotionRecord({
      id,
      ...attributes,
    });

    promotions.push(promotionRecord);
  });

  return promotions;
};
