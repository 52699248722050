// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".shared-dialogs-ConfirmationDialog-ConfirmationDialog__actions--FksnF{display:flex;justify-content:space-between;margin-top:15px}.shared-dialogs-ConfirmationDialog-ConfirmationDialog__actions--FksnF button{text-transform:unset}", "",{"version":3,"sources":["webpack://./shared/dialogs/ConfirmationDialog/ConfirmationDialog.scss"],"names":[],"mappings":"AAAA,sEACE,YAAA,CACA,6BAAA,CACA,eAAA,CACA,6EACE,oBAAA","sourcesContent":[".actions {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 15px;\n  button {\n    text-transform: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "shared-dialogs-ConfirmationDialog-ConfirmationDialog__actions--FksnF"
};
export default ___CSS_LOADER_EXPORT___;
