// @flow
export const getBulkSelectStatus = (inputs: {
  [id]: string,
}): {
  bulkInputChecked: boolean,
  bulkInputIndeterminate: boolean,
} => {
  let trueCount = 0;
  let bulkInputChecked = false;
  let bulkInputIndeterminate = true;
  const ids = Object.keys(inputs);

  ids.forEach(function (id: string) {
    const val = inputs[id];
    if (val) {
      trueCount++;
    }
  });

  if (trueCount === ids.length) {
    bulkInputChecked = true;
    bulkInputIndeterminate = false;
  } else if (trueCount === 0) {
    bulkInputChecked = false;
    bulkInputIndeterminate = false;
  }

  return {
    bulkInputChecked,
    bulkInputIndeterminate,
  };
};

export const getCanSubmit = (statuses: { [id]: string }): boolean => {
  let canSubmit = true;
  const ids = Object.keys(statuses);

  ids.forEach(function (id: string) {
    const val = statuses[id];
    if (val === "") {
      canSubmit = false;
    }
  });

  return canSubmit;
};
