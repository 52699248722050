// @flow
import React from "react";
import type { Node } from "react";

import Main from "./Main";
import Header from "./Header/Header";
import RequestCourseOccurrenceDialog from "../dialogs/RequestCourseOccurrence/RequestCourseOccurrenceDialog";
import AuthenticatedFooter from "./Footer/AuthenticatedFooter";
import UnauthenticatedFooter from "./Footer/UnauthenticatedFooter";

import styles from "./DefaultLayout.scss";

type Props = {
  isBlank?: boolean,
  children?: Node,
  isAuthed: boolean,
};

const DefaultLayout = ({
  isBlank = false,
  children,
  isAuthed,
}: Props): Node => {
  if (isBlank) {
    return <Main>{children}</Main>;
  }

  return (
    <Main className={styles.wrap}>
      <Header />
      <div className={styles.content}>{children}</div>

      <RequestCourseOccurrenceDialog />

      {isAuthed ? <AuthenticatedFooter /> : <UnauthenticatedFooter />}
    </Main>
  );
};

export default DefaultLayout;
