// @flow
import typeof { RecordInstance } from "../../../../../node_modules/immutable/dist/immutable";
import { Record } from "immutable";

export type InstructorRecordType = {
  accountId: string,
  address1: string,
  address2: string,
  biography: string,
  city: string,
  companyName: string,
  email: string,
  firstName: string,
  lastName: string,
  gender: string,
  imageUrl: string,
  lat: number,
  lng: number,
  number: string,
  phone: string,
  postalCode: string,
  state: string,
  trainingCounselor: string,
  initials: string,
  link: string,
  averageReviewRating: number,
  totalReviewCount: number,
  userId: string,
  id: string,
};

const InstructorRecord: RecordInstance = Record({
  accountId: "",
  address1: "",
  address2: "",
  biography: "",
  city: "",
  companyName: "",
  email: "",
  firstName: "",
  lastName: "",
  gender: "",
  imageUrl: "",
  lat: "",
  lng: "",
  number: "",
  phone: "",
  postalCode: "",
  state: "",
  trainingCounselor: "",
  initials: "",
  link: "",
  averageReviewRating: 0,
  totalReviewCount: 0,
  userId: "",
  id: "",
});

export default InstructorRecord;
