// @flow
import { APP_NAMESPACE } from "shared/constants/application";

//Actions
const namespace: string = `${APP_NAMESPACE}/rosterUpload`;

export const BULK_ROSTER_UPLOAD_REQUEST: string = `${namespace}/BULK_ROSTER_UPLOAD_REQUEST/REQUEST`;
export const BULK_ROSTER_UPLOAD_REQUEST_ERROR: string = `${namespace}/BULK_ROSTER_UPLOAD_REQUEST_ERROR/ERROR`;
export const BULK_ROSTER_UPLOAD_REQUEST_SUCCESS: string = `${namespace}/BULK_ROSTER_UPLOAD_REQUEST_SUCCESS/SUCCESS`;

export const OPEN_ROSTER_MODAL: string = `${namespace}/OPEN_ROSTER_MODAL`;
export const CLOSE_ROSTER_MODAL: string = `${namespace}/CLOSE_ROSTER_MODAL`;

export const CLEAR_API_STATE: string = `${namespace}/CLEAR_API_STATE`;
export const CLEAR: string = `${namespace}/CLEAR`;
