// @flow
import validator from "validator";

type valuesType = {
  email: string,
  phone: string,
  comment: string,
};

type errorsType = {
  email?: string,
  phone?: string,
  comment?: string,
};

const validate = ({ email, phone, comment }: valuesType): errorsType => {
  const errors = {};

  if (!email) {
    errors.email = "Required";
  }

  if (email && !validator.isEmail(email)) {
    errors.email = "Invalid email address";
  }

  if (!comment) {
    errors.comment = "Required";
  }

  return errors;
};

export default validate;
