// @flow
import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import type {
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { callApiReturnType } from "redux/middleware/api";
import type { Course as CourseType } from "redux/api-types/Course";

const namespace: string = `${APP_NAMESPACE}/instructorCourse`;

// Actions
export const REMOVE_COURSE_REQUEST: string = `${namespace}/REMOVE/REQUEST`;
export const REMOVE_COURSE_SUCCESS: string = `${namespace}/REMOVE/SUCCESS`;
export const REMOVE_COURSE_ERROR: string = `${namespace}/REMOVE/ERROR`;
export const ARCHIVE_COURSE_REQUEST: string = `${namespace}/ARCHIVE/REQUEST`;
export const ARCHIVE_COURSE_SUCCESS: string = `${namespace}/ARCHIVE/SUCCESS`;
export const ARCHIVE_COURSE_ERROR: string = `${namespace}/ARCHIVE/ERROR`;
export const CLONE_COURSE_REQUEST: string = `${namespace}/CLONE/REQUEST`;
export const CLONE_COURSE_SUCCESS: string = `${namespace}/CLONE/SUCCESS`;
export const CLONE_COURSE_ERROR: string = `${namespace}/CLONE/ERROR`;

// Shared Interfaces
type CourseActionParams = {
  id: string,
};

type CourseActionRequest = {
  type: string,
  payload: CourseActionParams,
};

type CourseReturn = {
  data: CourseType,
};

export type CourseAction = {
  type: string,
  payload: {
    course: CourseReturn,
  },
  meta: {
    receivedAt: number,
  },
};

// Action Creators
export const requestRemoveCourse = ({
  id,
}: CourseActionParams): CourseActionRequest => ({
  type: REMOVE_COURSE_REQUEST,
  payload: {
    id,
  },
});

export const removeCourseSuccess = (json: CourseReturn): CourseAction => ({
  type: REMOVE_COURSE_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const removeCourseError = (error: apiErrorParams): apiErrorAction => ({
  type: REMOVE_COURSE_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestArchiveCourse = ({
  id,
}: CourseActionParams): RequestCourseAction => ({
  type: ARCHIVE_COURSE_REQUEST,
  payload: {
    id,
  },
});

export const receiveArchiveCourse = (json: CourseReturn): CourseAction => ({
  type: ARCHIVE_COURSE_SUCCESS,
  payload: {
    course: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const archiveCourseRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: ARCHIVE_COURSE_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestCloneCourse = ({
  id,
}: CourseActionParams): CourseActionRequest => ({
  type: CLONE_COURSE_REQUEST,
  payload: {
    id,
  },
});

export const cloneCourseSuccess = (json: CourseReturn): CourseAction => ({
  type: CLONE_COURSE_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const cloneCourseError = (error: apiErrorParams): apiErrorAction => ({
  type: CLONE_COURSE_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

// Async Actions
export const removeCourse = (courseId: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "DELETE",
    endpoint: `/api/training/courses/${courseId}`,
    actionParams: {
      id: courseId,
    },
    actions: {
      request: requestRemoveCourse,
      success: removeCourseSuccess,
      failure: removeCourseError,
    },
    toasts: {
      success: "Course Deleted",
      failure: "Problem Deleting Course",
    },
  },
});

export const archiveCourse = (courseId: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: `/api/training/courses/${courseId}/archive`,
    actionParams: {
      id: courseId,
    },
    actions: {
      request: requestArchiveCourse,
      success: receiveArchiveCourse,
      failure: archiveCourseRequestError,
    },
    toasts: {
      success: "Course Archived",
      failure: "Error Archiving Course",
    },
  },
});

export const cloneCourse = (courseId: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: `/api/training/courses?idToCopy=${courseId}`,
    actionParams: {
      id: courseId,
    },
    actions: {
      request: requestCloneCourse,
      success: cloneCourseSuccess,
      failure: cloneCourseError,
    },
    toasts: {
      success: "Course Copy Created",
      failure: "Problem Copying Course",
    },
  },
});
