// @flow
import { handleActions } from "redux-actions";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { getJwtUser, isAccessTokenExpired } from "shared/utilities/authCookie";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";

const namespace: string = `${APP_NAMESPACE}/auth`;

// Actions
export const LOGOUT: string = `${namespace}/LOGOUT`;
export const SUBMIT_LAST_TOUCH_REQUEST: string = `${namespace}/SUBMIT_LAST_TOUCH/REQUEST`;
export const SUBMIT_LAST_TOUCH_REQUEST_SUCCESS: string = `${namespace}/SUBMIT_LAST_TOUCH/SUCCESS`;
export const SUBMIT_LAST_TOUCH_REQUEST_ERROR: string = `${namespace}/SUBMIT_LAST_TOUCH/ERROR`;

// Action Flow types
type logoutAction = {
  type: string,
};

// Action Creators
export const logout = (): logoutAction => ({
  type: LOGOUT,
});

export const lastTouchRequest = (): simpleReduxAction => ({
  type: SUBMIT_LAST_TOUCH_REQUEST,
});

export const lastTouchRequestSuccess = (): simpleReduxAction => ({
  type: SUBMIT_LAST_TOUCH_REQUEST_SUCCESS,
});

export const lastTouchRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: SUBMIT_LAST_TOUCH_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

// Async Actions
export const patchInstructorLastTouch = (
  instructorId: string,
  lastTouchedAt: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "PATCH",
    endpoint: `/api/training/instructors/${instructorId}`,
    options: {
      lastTouchedAt,
    },
    actions: {
      request: lastTouchRequest,
      success: lastTouchRequestSuccess,
      failure: lastTouchRequestError,
    },
  },
});

// assume that if the access token isn't expired, you're authed until further notice
const userFromCookie = getJwtUser();
const isInitiallyAuthed = isAccessTokenExpired() ? false : true;

// Initial State
export type State = {
  userId: ?string,
  isAuthed: boolean,
};

const initialState: State = {
  userId: userFromCookie.userId,
  isAuthed: isInitiallyAuthed,
};

// Reducer
const auth = handleActions(
  {
    [LOGOUT]: (state: State, action: logoutAction): State => ({
      ...state,
      userId: "",
      isAuthed: false,
    }),
  },
  initialState
);

export default auth;
