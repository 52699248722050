// @flow
import ResourceRecord from "./ResourceRecord";
import type { ResourceRecordType } from "./ResourceRecord";
import type { Result } from "./types";

type ParseResultReturn = {
  curriculumCodesToIds: {
    [string]: Array<string>,
  },
  categoriesToIds: {
    [string]: Array<string>,
  },
  resources: {
    [string]: ResourceRecordType,
  },
};

export const parseResult = (result: Array<Result>): ParseResultReturn => {
  let resources = {};
  let curriculumCodesToIds = {
    none: [],
  };
  let categoriesToIds = {};

  for (let i = 0; i < result.length; i++) {
    const {
      _id: id,
      category = "",
      curriculum = {},
      fileLastUpdated,
      filetype: filetypeObj = {},
      name,
      resourceUrl,
      stateName: stateNameObj = {},
    } = result[i];

    const { abbreviation: curriculumAbbreviation = "none" } = curriculum;

    const { filetype = "" } = filetypeObj;
    const { usStateName: stateName = "" } = stateNameObj;

    if (!curriculumCodesToIds[curriculumAbbreviation]) {
      curriculumCodesToIds[curriculumAbbreviation] = [];
    }
    curriculumCodesToIds[curriculumAbbreviation].push(id);

    if (category) {
      if (!categoriesToIds[category]) {
        categoriesToIds[category] = [];
      }
      categoriesToIds[category].push(id);
    }

    resources[id] = new ResourceRecord({
      id,
      curriculumAbbreviation,
      fileLastUpdated,
      filetype,
      name,
      resourceUrl,
      category,
      stateName,
    });
  }

  return {
    curriculumCodesToIds,
    categoriesToIds,
    resources,
  };
};
