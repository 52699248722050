// @flow
import type { Course as CourseType } from "redux/api-types/Course";
import type { CourseRecordI } from "./CourseRecord";
import type { Image as ImageType } from "redux/api-types/Image";
import type { Include as IncludeType } from "redux/api-types/Include";
import CourseRecord from "./CourseRecord";
import { createCourseUrl } from "shared/utilities/course";

import {
  OTHER_CURRICULUM_IMAGE_CARD,
  OTHER_CURRICULUM_IMAGE_CARD_RETINA,
} from "shared/constants/application";

type CoursesReturn = {
  [string]: CourseRecordI,
};

export const parseCourseReturnToRecord = (
  course: CourseType,
  images: Array<ImageType>,
  curriculum: Any
): CourseRecordI => {
  const {
    id,
    attributes: {
      name,
      overrideUrl,
      price,
      location: { name: venue, city, state, postalCode },
      curriculum: { imageUrl },
      futureOccurrencesCount,
      isWomensOnly,
      isWheelchairAccessible,
      hasLiveFire,
      grantsCCW,
      curriculumModuleId,
      taughtBy,
    },
  } = course;
  const {
    attributes: { abbreviation },
  } = curriculum;

  const link = createCourseUrl(id, name);
  const curriculumImages = imageUrl || {};

  let cardUrl = curriculumImages.hasOwnProperty("card")
    ? curriculumImages.card
    : OTHER_CURRICULUM_IMAGE_CARD;
  let cardRetina = curriculumImages.hasOwnProperty("card-retina")
    ? curriculumImages["card-retina"]
    : OTHER_CURRICULUM_IMAGE_CARD_RETINA;
  if (images[0]) {
    cardUrl = images[0].attributes.url;
    cardRetina = images[0].attributes.url;
  }

  const record = new CourseRecord({
    id,
    name,
    overrideUrl,
    link,
    price,
    venue,
    city,
    state,
    postalCode,
    futureOccurrencesCount,
    curriculumImages: {
      card: cardUrl,
      cardRetina: cardRetina,
    },
    abbreviation,
    isWomensOnly,
    isWheelchairAccessible,
    hasLiveFire,
    grantsCCW,
    isModule: !!curriculumModuleId,
    taughtBy,
  });

  return record;
};

export const parseCourses = (
  data: Array<CourseType>,
  included: Array<IncludeType>
): CoursesReturn => {
  let courses = {};
  data.forEach(function (course: CourseType) {
    let courseImageIds = course.attributes.imageIds;
    let curriculaId = course.relationships.curriculum.data?.id;
    let images = [];
    let curriculum = { attributes: { abbreviation: "" } };
    if (courseImageIds && courseImageIds.length > 0) {
      const imageId = courseImageIds[0];
      const image = included.find(
        (image: Image): boolean => image.id === imageId
      );
      images.push(image);
    }
    if (curriculaId) {
      const matchedCurriculum = included.find(
        (curricula: IncludeType): boolean => curricula.id === curriculaId
      );
      curriculum = matchedCurriculum;
    }

    const record = parseCourseReturnToRecord(course, images, curriculum);
    const { id } = course;
    courses[id] = record;
  });
  return courses;
};
