// @flow
import React from "react";
import type { Node } from "react";

type Props = {
  className: string,
  [string]: string,
};

const FaAlarmExclamation = ({ className, ...props }: Props): Node => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    {...props}
  >
    <title>Analog Alarm Clock with Exclamation Point in Clock Face</title>
    <path
      d="M5.625-26.25A5.625,5.625,0,0,0,0-20.625a5.582,5.582,0,0,0,.888,3.01l8.549-7.124A5.589,5.589,0,0,0,5.625-26.25ZM15-22.5A13.125,13.125,0,0,0,1.876-9.375,13.063,13.063,0,0,0,4.783-1.146l-2.5,2.5A1.406,1.406,0,1,0,4.276,3.338l2.5-2.5A13.063,13.063,0,0,0,15,3.75,13.063,13.063,0,0,0,23.23.843l2.5,2.5a1.406,1.406,0,1,0,1.989-1.989l-2.5-2.5a13.063,13.063,0,0,0,2.907-8.229A13.125,13.125,0,0,0,15-22.5ZM15,.937A10.324,10.324,0,0,1,4.688-9.375,10.324,10.324,0,0,1,15-19.687,10.324,10.324,0,0,1,25.313-9.375,10.324,10.324,0,0,1,15,.937ZM24.375-26.25a5.589,5.589,0,0,0-3.812,1.511l8.549,7.124A5.582,5.582,0,0,0,30-20.625,5.625,5.625,0,0,0,24.375-26.25ZM15-5.625A1.875,1.875,0,0,0,13.126-3.75,1.875,1.875,0,0,0,15-1.875,1.875,1.875,0,0,0,16.876-3.75,1.875,1.875,0,0,0,15-5.625ZM15-7.5a1.407,1.407,0,0,0,1.406-1.406v-6.562A1.407,1.407,0,0,0,15-16.875a1.407,1.407,0,0,0-1.406,1.406v6.562A1.407,1.407,0,0,0,15-7.5Z"
      transform="translate(0 26.25)"
    />
  </svg>
);

FaAlarmExclamation.defaultProps = {
  className: "",
};

export default FaAlarmExclamation;
