// @flow
import { getQualificationLevel } from "./getQualificationLevel";
import { getAccessToken } from "../../../shared/utilities/authCookie.js";

const localDomainOverrides = {
  academyQualifications: "http://localhost:8000",
  checkout: "http://localhost:3000",
  deltaDefense: "http://localhost:8080",
  academy: "http://localhost:5050",
  myUscca: "http://localhost:3001",
  organic: "http://uscca.local",
  portal: "http://localhost:3000",
  store: "http://localhost:8000",
  training: "http://localhost:8080",
  trainingCheckout: "http://localhost:3000",
};

export const updateNavAuthedAttributes = async (
  name: string,
  accountNumber: string,
  subscriptionLevel: string,
  isInstructor: boolean,
  isGroupMember: boolean
) => {
  const deltaNavElement = document.querySelector("delta-nav");
  if (deltaNavElement) {
    deltaNavElement.setAttribute("name", name);
    deltaNavElement.setAttribute("accountnumber", accountNumber);
    deltaNavElement.setAttribute("membershiplevel", subscriptionLevel);
    deltaNavElement.setAttribute("isinstructor", isInstructor.toString());
    deltaNavElement.setAttribute("groupmember", isGroupMember.toString());
    deltaNavElement.setAttribute(
      "localdomains",
      JSON.stringify(localDomainOverrides)
    );

    const accessToken = await getAccessToken();
    const qualificationLevel = await getQualificationLevel(accessToken);
    deltaNavElement.setAttribute(
      "qualificationlevel",
      qualificationLevel.toString()
    );
  }
};
