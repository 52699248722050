// @flow
import { APP_NAMESPACE } from "shared/constants/application";

const namespace: string = `${APP_NAMESPACE}/courseSearchBy`;

// Actions
export const LATLNG_REQUEST: string = `${namespace}/LATLNG/REQUEST`;
export const LATLNG_SUCCESS: string = `${namespace}/LATLNG/SUCCESS`;
export const LATLNG_ERROR: string = `${namespace}/LATLNG/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;
