// @flow
import { handleActions } from "redux-actions";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import { parseCourseOccurrences } from "./courseOccurrencesParsers";

import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { callApiReturnType } from "redux/middleware/api";
import type { Include as IncludeType } from "redux/api-types/include";
import type { CourseOccurrence as CourseOccurrenceType } from "redux/api-types/CourseOccurrence";
import type { InstructorRecordInterface } from "./InstructorRecord";
import type { OccurrenceRecordInterface } from "./OccurrenceRecord";
import type { DateRecordInterface } from "./DateRecord";

// actions
const namespace: string = `${APP_NAMESPACE}/courseOccurrences`;
export const COURSE_OCCURRENCES_REQUEST: string = `${namespace}/REQUEST/FETCH`;
export const COURSE_OCCURRENCES_REQUEST_SUCCESS: string = `${namespace}/REQUEST/SUCCESS`;
export const COURSE_OCCURRENCES_REQUEST_ERROR: string = `${namespace}/REQUEST/ERROR`;
export const TOGGLE_SHOW_ALL_OCCURRENCES: string = `${namespace}/SHOW_ALL/TOGGLE`;
export const CLEAR: string = `${namespace}/CLEAR`;

// interfaces
type OccurrencesReturn = {
  data: Array<CourseOccurrenceType>,
  included: Array<IncludeType>,
};

export type ReceiveCourseReturnType = {
  type: string,
  payload: OccurrencesReturn,
  meta: {
    receivedAt: number,
  },
};

// Actions
export const requestCourseOccurrences = (): simpleReduxAction => {
  return {
    type: COURSE_OCCURRENCES_REQUEST,
  };
};

export const receiveCourseOccurrences = (
  json: OccurrencesReturn
): ReceiveCourseReturnType => {
  return {
    type: COURSE_OCCURRENCES_REQUEST_SUCCESS,
    payload: json,
    meta: {
      receivedAt: Date.now(),
    },
  };
};

export const courseOccurrencesRequestError = (
  error: apiErrorParams
): apiErrorAction => {
  return {
    type: COURSE_OCCURRENCES_REQUEST_ERROR,
    error: true,
    payload: {
      errorMessage: "",
      errors: [],
      ...error,
    },
    meta: {
      receivedAt: Date.now(),
    },
  };
};

export const toggleShowAllOccurrences = (): simpleReduxAction => {
  return {
    type: TOGGLE_SHOW_ALL_OCCURRENCES,
  };
};

export const clear = (): simpleReduxAction => {
  return {
    type: CLEAR,
  };
};

// Async actions
export const getCourseOccurrences = (courseId: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/courses/${courseId}/occurrences?filter=future`,
    actions: {
      request: requestCourseOccurrences,
      success: receiveCourseOccurrences,
      failure: courseOccurrencesRequestError,
    },
  },
});

// Initial State
export type State = {
  status: string,
  isShowAll: boolean,
  errorMessage: string,
  errors: apiErrorReturn,
  instructors: {
    [string]: InstructorRecordInterface,
  },
  occurrences: {
    [string]: OccurrenceRecordInterface,
  },
  dates: {
    [string]: DateRecordInterface,
  },
  totalCount: number,
};

const initialState: State = {
  status: NONE,
  isShowAll: false,
  errorMessage: "",
  errors: [],
  instructors: {},
  occurrences: {},
  dates: {},
  totalCount: 0,
};

const courseOccurrences = handleActions(
  {
    [COURSE_OCCURRENCES_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...initialState,
      status: LOADING,
    }),
    [COURSE_OCCURRENCES_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveCourseReturnType
    ): State => {
      const totalCount = action.payload.data.length;
      const { occurrences, dates, instructors } = parseCourseOccurrences(
        action.payload.data
      );

      return {
        ...state,
        status: LOADED,
        instructors,
        occurrences,
        dates,
        totalCount,
      };
    },
    [COURSE_OCCURRENCES_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [TOGGLE_SHOW_ALL_OCCURRENCES]: (
      state: State,
      action: simpleReduxAction
    ): State => {
      const { isShowAll } = state;
      return {
        ...state,
        isShowAll: !isShowAll,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default courseOccurrences;
