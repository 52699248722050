// @flow
import { delay, put } from "redux-saga/effects";
import {
  isAccessTokenSet,
  getRemainingSessionTime,
} from "shared/utilities/authCookie";
import doesRouteRequireAuth from "shared/utilities/doesRouteRequireAuth";
import { getMe } from "redux/modules/me/meActionCreators";
import { openDialog } from "redux/modules/app/app";
import { dialogs } from "redux/modules/app/constants";
import triggerGoogleOptimizeUserDataReadyEvent from "./triggerGoogleOptimizeUserDataReadyEvent";

export default function* sessionCheck(): Saga<void> {
  // Gated resources:
  // if not logged in, take to login and redirect back to resource
  if (!isAccessTokenSet()) {
    const { URL_LOGIN, APP_URL } = process.env;
    const pathname = window.location.pathname;
    const requiresAuth = doesRouteRequireAuth(pathname);

    if (requiresAuth) {
      let redirectUrl = `${URL_LOGIN}/?redirectTo=${encodeURIComponent(
        APP_URL + pathname
      )}`;
      window.location.replace(redirectUrl);
    }
    triggerGoogleOptimizeUserDataReadyEvent();
  } else {
    yield put(getMe());

    // set up session timeout
    const remainingSessionTime = getRemainingSessionTime();
    yield delay(remainingSessionTime);
    const timeoutAction = openDialog(dialogs.SESSION_TIMEOUT);
    yield put(timeoutAction);
  }
}
