// @flow
export const round7 = [
  {
    courseId: "1adacec0-f321-11ec-a837-02420a0002c8",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-clearfield-UT-7-19-2022?link=training_platform",
  },
  {
    courseId: "afbe24f6-f321-11ec-87b4-0242ac120006",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-nashville-TN-7-20-2022?link=training_platform",
  },
  {
    courseId: "dccc990e-f322-11ec-915c-02420a0003b5",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-meridian-ID-7-26-2022?link=training_platform",
  },
  {
    courseId: "a036362a-f323-11ec-940f-0242ac120006",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-jackson-TN-6-29-2022?link=training_platform",
  },
  {
    courseId: "0ad85206-f324-11ec-bdc8-02420a0002c8",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-knoxville-TN-7-5-2022?link=training_platform",
  },
];
