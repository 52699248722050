// @flow
export const round8 = [
  {
    courseId: "7b715d72-f8ac-11ec-a85a-02420a000144",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-chesterfield-MI-07-19-2022?link=training_platform",
  },
  {
    courseId: "488182c4-f8ad-11ec-98de-02420a000144",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-sevierville-TN-7-25-2022?link=training_platform",
  },
  {
    courseId: "0345e536-f8af-11ec-8f21-0242ac12000b",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-worskshop-harrison-TN-7-25-2022?link=training_platform",
  },
  {
    courseId: "db09f6c4-f8af-11ec-b37e-02420a0002d4",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-lafayette-IN-7-26-2022?link=training_platform",
  },
  {
    courseId: "9ef41636-f8b1-11ec-8a3a-0242ac12000b",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-spencer-TN-7-27-2022?link=training_platform",
  },
  {
    courseId: "5b096358-f8b2-11ec-939c-02420a0002d4",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-sacramento-CA-7-27-2022?link=training_platform",
  },
  {
    courseId: "b5cd99de-f8b3-11ec-ae3b-0242ac12000b",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-longmeadow-MA-7-27-2022?link=training_platform",
  },
  {
    courseId: "40338570-f8b4-11ec-bd1b-0242ac12000b",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-kansas-city-MO-7-28-2022?link=training_platform",
  },
  {
    courseId: "9b29e046-f8b4-11ec-a574-0242ac12000b",
    overrideUrl:
      "https://uscca.swoogo.com/rock-self-defense-workshop-knoxville-TN-7-29-2022?link=training_platform",
  },
];
