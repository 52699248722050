// @flow
import type { Saga } from "redux-saga";
import { fork, take } from "redux-saga/effects";

import enterEmailJourneySaga from "redux/middleware/enterEmailJourneySaga";
import { REQUEST_SUCCESS } from "./courseInformationDialog";

export function* onRequestCourseDetailsByEmailSuccess(): Saga<void> {
  for (;;) {
    yield take(REQUEST_SUCCESS);
    yield fork(enterEmailJourneySaga);
  }
}
