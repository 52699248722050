// @flow
import { CALL_API } from "redux/middleware/api";

import type { Me } from "../../api-types/Me";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";

import {
  GET_ME_REQUEST,
  GET_ME_REQUEST_SUCCESS,
  GET_ME_REQUEST_ERROR,
} from "./meActions";
import { GET_ME_QUERY } from "./queries";

type MeResponse = {
  data: Me,
};

export type MeAction = {
  type: string,
  payload: MeResponse,
  meta: {
    receivedAt: number,
  },
};

export const requestMe = (): simpleReduxAction => ({
  type: GET_ME_REQUEST,
});

export const receiveMe = (json: CoursePromotionsResponse): MeAction => ({
  type: GET_ME_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestMeError = (error: apiErrorParams): apiErrorAction => ({
  type: GET_ME_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const getMe = (): callApiReturnType => {
  return {
    type: CALL_API,
    payload: {
      method: "POST",
      endpoint: "/api/mesh/",
      options: {
        query: GET_ME_QUERY,
        variables: {},
      },
      actions: {
        request: requestMe,
        success: receiveMe,
        failure: requestMeError,
      },
    },
  };
};
