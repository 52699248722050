// @flow
import type { Saga } from "redux-saga";
import { put, take, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { handleActions } from "redux-actions";

import type { Curriculum as ApiCurriculum } from "redux/api-types/Curriculum";
import type { Course as ApiCourse } from "redux/api-types/Course";
import type { Instructor as ApiInstructor } from "redux/api-types/Instructor";
import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { routerLocationSelector } from "redux/middleware/watchRouter";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import { createCourseUrl } from "shared/utilities/course";
import { parseCourseResponse } from "./parsers/courseDetails";
import { parseCourseInstructor } from "./parsers/instructor";
import { parseCourseCurriculum } from "./parsers/curriculum";

import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import CourseDetailsRecord from "./records/CourseDetailsRecord";
import type { courseDetailsRecordType } from "./records/CourseDetailsRecord";
import InstructorRecord from "./records/InstructorRecord";
import type { InstructorRecordType } from "./records/InstructorRecord";
import LocationRecord from "./records/LocationRecord";
import type { locationRecordType } from "./records/LocationRecord";
import RegistrationRecord from "./records/RegistrationRecord";
import type { registrationRecordType } from "./records/RegistrationRecord";
import CurriculumRecord from "./records/CurriculumRecord";
import type { CurriculumRecordType } from "./records/CurriculumRecord";

// actions
const namespace: string = `${APP_NAMESPACE}/course`;
export const COURSE_REQUEST: string = `${namespace}/FETCH`;
export const COURSE_REQUEST_SUCCESS: string = `${namespace}/SET`;
export const COURSE_REQUEST_ERROR: string = `${namespace}/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Flow Types
type requestCourseReturnType = {
  type: string,
  payload: {
    uuid: string,
  },
};

type CourseReturn = {
  data: ApiCourse,
  included: Array<ApiCurriculum | ApiInstructor>,
};

export type receiveCourseReturnType = {
  type: string,
  payload: CourseReturn,
  meta: {
    receivedAt: number,
  },
};

// Actions
export const requestCourse = (uuid: string): requestCourseReturnType => ({
  type: COURSE_REQUEST,
  payload: {
    uuid,
  },
});

export const receiveCourse = (json: CourseReturn): receiveCourseReturnType => ({
  type: COURSE_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const courseRequestError = (error: apiErrorParams): apiErrorAction => ({
  type: COURSE_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async actions
export const getCourse = (uuid: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/courses/${uuid}?include=tags,instructor,curriculum,curriculumModule,images`,
    actions: {
      request: requestCourse,
      success: receiveCourse,
      failure: courseRequestError,
    },
  },
});

// Sagas
export function* redirectLegacyCourseRoute(): Saga<void> {
  for (;;) {
    const action = yield take(COURSE_REQUEST_SUCCESS);
    const { pathname, search } = yield select(routerLocationSelector);
    const {
      id,
      attributes: { name },
    } = action.payload.data;
    const url = createCourseUrl(id, name);
    if (url !== pathname) {
      const forwardTo = url + search;
      yield put(push(forwardTo));
    }
  }
}

// Initial State
export type State = {
  isLoading: boolean,
  isLoaded: boolean,
  isError: boolean,
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  receivedAt: number,
  courseDetails: courseDetailsRecordType,
  instructor: InstructorRecordType,
  location: locationRecordType,
  registration: registrationRecordType,
  curriculum: CurriculumRecordType,
};

const initialState: State = {
  isLoading: true,
  isLoaded: false,
  isError: false,
  status: NONE,
  errorMessage: "",
  errors: [],
  receivedAt: 0,
  courseDetails: new CourseDetailsRecord(),
  instructor: new InstructorRecord(),
  location: new LocationRecord(),
  registration: new RegistrationRecord(),
  curriculum: new CurriculumRecord(),
};

const course = handleActions(
  {
    [COURSE_REQUEST]: (state: State): State => ({
      ...initialState,
      status: LOADING,
    }),
    [COURSE_REQUEST_SUCCESS]: (
      state: State,
      action: receiveCourseReturnType
    ): State => {
      const {
        payload: { data, included },
        meta: { receivedAt },
      } = action;
      const course = parseCourseResponse(data);
      const courseInstructor = included
        ? parseCourseInstructor(included)
        : new InstructorRecord();
      let courseCurriculum = included ? parseCourseCurriculum(included) : {};
      return {
        ...state,
        status: LOADED,
        receivedAt,
        ...course,
        instructor: courseInstructor,
        curriculum: courseCurriculum,
      };
    },

    [COURSE_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        status: ERROR,
        errorMessage,
        errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default course;
