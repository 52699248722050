// @flow
import { Record } from "immutable";

export type CoursePromotionRecordI = {
  id: string,
  name: string,
  description: string,
  startTime: string,
  endTime: string,
};

const CoursePromotionRecord = Record({
  id: "",
  name: "",
  description: "",
  startTime: "",
  endTime: "",
});

export default CoursePromotionRecord;
