// @flow
import React from "react";
import type { Node } from "react";

import styles from "./Button.scss";

type Props = {
  type: string,
  disabled: boolean,
  children: Node,
};

const Button = ({ children, disabled, type }: Props): Node => (
  <button className={styles.button} type={type} disabled={disabled}>
    {children}
  </button>
);

Button.displayName = "Button";

Button.defaultProps = {
  type: "button",
  disabled: false,
};

export default Button;
