//@flow
import { CALL_API } from "redux/middleware/api";
import type { CoursePromotion } from "../../api-types/CoursePromotion";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";

import {
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_REQUEST_SUCCESS,
  GET_PROMOTIONS_REQUEST_ERROR,
} from "./coursePromotionActions";

type CoursePromotionsResponse = {
  data: Array<CoursePromotion>,
};

export type CoursePromotionsAction = {
  type: string,
  payload: CoursePromotionsResponse,
  meta: {
    receivedAt: number,
  },
};

export const requestCoursePromotions = (): simpleReduxAction => ({
  type: GET_PROMOTIONS_REQUEST,
});

export const receiveCoursePromotions = (
  json: CoursePromotionsResponse
): CoursePromotionsAction => ({
  type: GET_PROMOTIONS_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const coursePromotionsRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: GET_PROMOTIONS_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const getCoursePromotions = (): callApiReturnType => {
  return {
    type: CALL_API,
    payload: {
      method: "GET",
      endpoint: `/api/training/promotions`,
      disableErrorToast: true,
      actions: {
        request: requestCoursePromotions,
        success: receiveCoursePromotions,
        failure: coursePromotionsRequestError,
      },
    },
  };
};
