// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
  apiErrorReturn,
} from "shared/constants/flowTypes";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import type { ResourceRecordType } from "./ResourceRecord";
import { parseResult } from "./parsers";
import type { Result } from "./types";

const namespace: string = `${APP_NAMESPACE}/stateResources`;

// actions
const GET_RESOURCES = `${namespace}/FETCH`;
const RECEIVE_RESOURCES = `${namespace}/RECEIVE`;
const GET_RESOURCES_ERROR = `${namespace}/GET_ERROR`;
const CLEAR = `${namespace}/CLEAR`;

// action creators
type GetResourceReturn = {
  payload: {
    query: string,
    type: string,
  },
} & simpleReduxAction;

export const getResources = ({
  query,
  type,
}: {
  query: string,
  type: string,
}): GetResourceReturn => ({
  type: GET_RESOURCES,
  payload: {
    query,
    type,
  },
});

type ReceiveResourcesParams = {
  ms: number,
  query: string,
  result: Array<Result>,
};

type ReceiveResourcesReturn = {
  payload: ReceiveResourcesParams,
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export const receiveResources = (
  json: ReceiveResourcesParams
): ReceiveResourcesReturn => ({
  type: RECEIVE_RESOURCES,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const getResourcesError = (error: apiErrorParams): apiErrorAction => ({
  type: GET_RESOURCES_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async Actions

const { SANITY_API_URL = "" } = process.env;

export const getStateResources = (type: string): void | callApiReturnType => {
  const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);
  const query = `*[_type == "stateReciprocity" && stateName == "${typeCapitalized}"]{
    _id,
    stateName,
    stateAbbreviation,
    backgroundImageUrls,
    statesHonoredCount,
    reciprocatingStatesCount,
    minimumAgeCount,
    yearsPermitValidCount,
    postalCode
  }`;
  if (!query) {
    return;
  }
  const endpoint = `${SANITY_API_URL}?query=${encodeURIComponent(query)}`;

  return {
    type: CALL_API,
    payload: {
      endpoint,
      actionParams: {
        query,
        type,
      },
      actions: {
        request: getResources,
        success: receiveResources,
        failure: getResourcesError,
      },
    },
  };
};

// reducer
export type State = {
  status: string,
  type: string,
  query: string,
  errorMessage: string,
  errors: apiErrorReturn,
  resource: ResourceRecordType,
};

export const initialState = {
  status: NONE,
  type: "",
  query: "",
  errorMessage: "",
  errors: [],
  resource: {},
};

const stateResources = handleActions(
  {
    [GET_RESOURCES]: (
      state: State,
      { payload: { query, type } }: GetResourceReturn
    ): State => ({
      ...state,
      status: LOADING,
      type,
      query,
      errorMessage: "",
      errors: [],
    }),
    [RECEIVE_RESOURCES]: (
      state: State,
      { payload }: ReceiveResourcesReturn
    ): State => {
      const { resource } = parseResult(payload.result);

      return {
        ...state,
        status: LOADED,
        resource,
      };
    },
    [GET_RESOURCES_ERROR]: (
      state: State,
      { payload: { errorMessage, errors } }: apiErrorAction
    ): State => ({
      ...state,
      status: ERROR,
      errorMessage,
      errors,
    }),
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default stateResources;
