// @flow
import { states } from "../constants/states";
import type { stateType } from "../constants/states";

export const returnOptionByStateAbbreviation = (
  abbreviation: string
): stateType => {
  const option = states.find(
    (state: stateType): boolean => state.value === abbreviation
  );

  return option;
};
