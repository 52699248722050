// @flow
import CurriculumRecord from "./CurriculumRecord";
import type { Curriculum } from "redux/api-types/Curriculum";

type ParseCurriculaReturn = {
  [string]: {},
};

export const parseCurricula = (
  keyAttribute: string,
  data: Array<Curriculum>
): ParseCurriculaReturn => {
  let curricula = {};

  for (let i = 0; i < data.length; i++) {
    const { id, attributes: curriculum } = data[i];
    const { name, abbreviation } = curriculum;
    const key = curriculum[keyAttribute] ? curriculum[keyAttribute] : id;

    const Record = CurriculumRecord({
      id,
      name,
      abbreviation,
    });

    curricula[key] = Record;
  }

  return curricula;
};
