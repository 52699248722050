// @flow
import React from "react";
import type { Node } from "react";

import styles from "./CalendarIcon.scss";

type Props = {
  className: string,
  [string]: string,
};

let CalendarIcon = ({ className, ...props }: Props): Node => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.936 20.499"
    {...props}
  >
    <title>Calendar</title>
    <g transform="translate(-162 -1379.5)">
      <path
        className={styles.a}
        d="M17.456-11.094a.463.463,0,0,0,.34-.14.463.463,0,0,0,.14-.34v-1.441a1.853,1.853,0,0,0-.561-1.361,1.853,1.853,0,0,0-1.361-.561H14.093V-17.02a.463.463,0,0,0-.14-.34.463.463,0,0,0-.34-.14h-1.6a.463.463,0,0,0-.34.14.463.463,0,0,0-.14.34v2.082H6.406V-17.02a.463.463,0,0,0-.14-.34.463.463,0,0,0-.34-.14h-1.6a.463.463,0,0,0-.34.14.463.463,0,0,0-.14.34v2.082H1.922a1.853,1.853,0,0,0-1.361.561A1.853,1.853,0,0,0,0-13.016v1.441a.463.463,0,0,0,.14.34.463.463,0,0,0,.34.14Z"
        transform="translate(162 1397)"
      />
      <path
        className={styles.b}
        d="M.48-10a.463.463,0,0,0-.34.14A.463.463,0,0,0,0-9.52V.89A1.853,1.853,0,0,0,.561,2.251a1.853,1.853,0,0,0,1.361.561H16.015a1.853,1.853,0,0,0,1.361-.561A1.853,1.853,0,0,0,17.936.89V-9.52a.463.463,0,0,0-.14-.34.463.463,0,0,0-.34-.14ZM13.813-6.156a.38.38,0,0,1,.16.32.5.5,0,0,1-.16.36L8.087.209a.433.433,0,0,1-.32.12.586.586,0,0,1-.36-.12L4.124-3.154a.38.38,0,0,1-.16-.32.5.5,0,0,1,.16-.36L5.245-4.955a.586.586,0,0,1,.36-.12.433.433,0,0,1,.32.12L7.767-3.074l4.244-4.244a.586.586,0,0,1,.36-.12.38.38,0,0,1,.32.16Z"
        transform="translate(162 1397.187)"
      />
    </g>
  </svg>
);

CalendarIcon.displayName = "CalendarIcon";

CalendarIcon.defaultProps = {
  className: "",
};

export default CalendarIcon;
