// @flow
import { handleActions } from "redux-actions";
import { ERROR, NONE, PENDING, SUCCESS } from "shared/constants/status";

// Type imports
import type { ImageRecordI } from "./ImageRecord";
import type {
  ReceiveImagesReturnType,
  ImageAction,
} from "./imagesActionCreators";
import type {
  apiErrorAction,
  apiErrorReturn,
  simpleReduxAction,
} from "shared/constants/flowTypes";

// Action inports
import {
  POST_IMAGE_REQUEST,
  POST_IMAGE_REQUEST_SUCCESS,
  POST_IMAGE_REQUEST_ERROR,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_REQUEST_SUCCESS,
  DELETE_IMAGE_REQUEST_ERROR,
  CLEAR,
} from "./imagesActions";

// Initial State
export type State = {
  deleteStatus: string,
  errorMessage: string,
  errors: apiErrorReturn,
  imageId: string,
  imageUrl: string,
  imagePostError: string,
  status: string,
  type: string,
  images: {
    [string]: ImageRecordI,
  },
};

const initialState: State = {
  deleteStatus: NONE,
  errorMessage: "",
  errors: [],
  imageId: "",
  imageUrl: "",
  imagePostError: "",
  images: {},
  status: NONE,
  type: "",
};

const images = handleActions(
  {
    [POST_IMAGE_REQUEST]: (state: State, action: simpleReduxAction): State => {
      return {
        ...state,
        status: PENDING,
        deleteStatus: NONE,
      };
    },
    [POST_IMAGE_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveImagesReturnType
    ): State => {
      const { url } = action.payload.data.attributes;
      const { id } = action.payload.data;

      return {
        ...state,
        imageId: id,
        imageUrl: url,
        status: SUCCESS,
      };
    },
    [POST_IMAGE_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        errorMessage: errorMessage,
        errors: errors,
        imageUrl: "",
        images: {},
        type: "",
      };
    },
    [DELETE_IMAGE_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => {
      return {
        ...state,
        deleteStatus: PENDING,
      };
    },
    [DELETE_IMAGE_REQUEST_SUCCESS]: (
      state: State,
      action: ImageAction
    ): State => {
      return {
        ...state,
        deleteStatus: SUCCESS,
        errorMessage: "",
        errors: [],
        imageId: "",
        imageUrl: "",
        imagePostError: "",
        images: {},
        status: NONE,
        type: "",
      };
    },
    [DELETE_IMAGE_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        deleteStatus: ERROR,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default images;
