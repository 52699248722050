// @flow
import { handleActions } from "redux-actions";

import {
  NONE,
  LOADING,
  LOADED,
  PENDING,
  SUCCESS,
  ERROR,
} from "shared/constants/status";
import {
  GET_AGREEMENTS_REQUEST,
  GET_AGREEMENTS_REQUEST_SUCCESS,
  GET_AGREEMENTS_REQUEST_ERROR,
  ACCEPT_AGREEMENTS_REQUEST,
  ACCEPT_AGREEMENTS_REQUEST_SUCCESS,
  ACCEPT_AGREEMENTS_REQUEST_ERROR,
} from "./instructorAgreementsActions";
import type { ParseAgreementsReturn } from "./parseAgreements";
import { parseAgreements } from "./parseAgreements";

// Initial State
export type State = {
  hasAccepted: ?boolean,
  getStatus: string,
  getErrorMessage: string,
  getErrors: apiErrorReturn,
  acceptStatus: string,
  acceptErrorMessage: string,
  acceptErrors: apiErrorReturn,
  agreements: ParseAgreementsReturn,
};

const initialState: State = {
  hasAccepted: undefined,
  getStatus: NONE,
  getErrorMessage: "",
  getErrors: [],
  acceptStatus: NONE,
  acceptErrorMessage: "",
  acceptErrors: [],
  agreements: {},
};

const instructorAgreements = handleActions(
  {
    [GET_AGREEMENTS_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      getStatus: LOADING,
      getErrorMessage: "",
      getErrors: [],
    }),
    [GET_AGREEMENTS_REQUEST_SUCCESS]: (
      state: State,
      action: AccountAction
    ): State => {
      const agreements = parseAgreements(action.payload.data);
      return {
        ...state,
        hasAccepted: !!action.payload.data.length,
        getStatus: LOADED,
        agreements,
      };
    },
    [GET_AGREEMENTS_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        getStatus: ERROR,
        getErrorMessage: errorMessage,
        getErrors: errors,
      };
    },
    [ACCEPT_AGREEMENTS_REQUEST]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      acceptStatus: PENDING,
      acceptErrorMessage: "",
      acceptErrors: [],
    }),
    [ACCEPT_AGREEMENTS_REQUEST_SUCCESS]: (
      state: State,
      action: AccountAction
    ): State => {
      const agreements = parseAgreements(action.payload.data);
      return {
        ...state,
        hasAccepted: true,
        getStatus: LOADED,
        getErrorMessage: "",
        getErrors: [],
        acceptStatus: SUCCESS,
        agreements,
      };
    },
    [ACCEPT_AGREEMENTS_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        acceptStatus: ERROR,
        acceptErrorMessage: errorMessage,
        acceptErrors: errors,
      };
    },
  },
  initialState
);

export default instructorAgreements;
