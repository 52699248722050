// @flow
import React from "react";
import type { Node } from "react";

type Props = {
  className: string,
  [string]: string,
};

let TeachingInClassroomIcon = ({ className, ...props }: Props): Node => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 151.938 151.938"
    {...props}
  >
    <title>Teaching in Classroom</title>
    <g transform="translate(-5 -3)">
      <path
        id="Path_2240"
        data-name="Path 2240"
        d="M143.628,0H8.309a8.309,8.309,0,1,0,0,16.618H9.5v85.465a2.374,2.374,0,0,0,2.374,2.374H91.4V99.709H14.244V16.618H137.693V85.465c.131.226.261.439.368.665l4.38,6.908V16.618h1.187a8.309,8.309,0,0,0,0-16.618Zm0,11.87H8.309a3.561,3.561,0,0,1,0-7.122H143.628a3.561,3.561,0,1,1,0,7.122Z"
        transform="translate(5 3)"
      />
      <path
        id="Path_2241"
        data-name="Path 2241"
        d="M72.181,52H18.374a2.374,2.374,0,0,0,0,4.748H72.181a2.374,2.374,0,0,0,0-4.748Z"
        transform="translate(7.992 12.725)"
      />
      <path
        id="Path_2242"
        data-name="Path 2242"
        d="M72.181,60H18.374a2.374,2.374,0,0,0,0,4.748H72.181a2.374,2.374,0,0,0,0-4.748Z"
        transform="translate(7.992 14.221)"
      />
      <path
        id="Path_2243"
        data-name="Path 2243"
        d="M72.181,68H18.374a2.374,2.374,0,0,0,0,4.748H72.181a2.374,2.374,0,0,0,0-4.748Z"
        transform="translate(7.992 15.717)"
      />
      <path
        id="Path_2244"
        data-name="Path 2244"
        d="M72.181,76H18.374a2.374,2.374,0,0,0,0,4.748H72.181a2.374,2.374,0,0,0,0-4.748Z"
        transform="translate(7.992 17.213)"
      />
      <path
        id="Path_2245"
        data-name="Path 2245"
        d="M89.265,32.914a7.122,7.122,0,1,0-6.79-4.974L69.893,37.983a7.122,7.122,0,0,0-8.653,0l-3.419-3.49a7.122,7.122,0,1,0-12.974.273l-5.591,5.662a7.027,7.027,0,0,0-4.748.119L28.324,31.5a7.122,7.122,0,1,0-4.048,2.481l6.612,9.662a7.122,7.122,0,1,0,12.155-.273l5.187-5.187a7.063,7.063,0,0,0,6.374-.131l3.976,3.976a7.122,7.122,0,1,0,13.876,0l12.879-10.28A7.122,7.122,0,0,0,89.265,32.914Zm0-9.5a2.374,2.374,0,1,1-2.374,2.374A2.374,2.374,0,0,1,89.265,23.418ZM22.792,29.353a2.374,2.374,0,1,1,2.374-2.374A2.374,2.374,0,0,1,22.792,29.353ZM37.037,49.532a2.374,2.374,0,1,1,2.374-2.374A2.374,2.374,0,0,1,37.037,49.532ZM51.281,34.1a2.374,2.374,0,1,1,2.374-2.374A2.374,2.374,0,0,1,51.281,34.1Zm14.244,11.87A2.374,2.374,0,1,1,67.9,43.6,2.374,2.374,0,0,1,65.525,45.971Z"
        transform="translate(7.93 6.492)"
      />
      <path
        id="Path_2246"
        data-name="Path 2246"
        d="M110.8,37.708c-10.018-7.775-18.506-3.561-21.687-1.341-2.968,2.042-3.383,3.074-3.027,4.309a5.941,5.941,0,0,1,0,.641,8.76,8.76,0,0,0,.439,3.514L86,50.551v.214a13.057,13.057,0,0,0,26.114,0v-.214l-.261-2.861a2.374,2.374,0,0,0,.237-.736C113.163,39.547,111.394,38.171,110.8,37.708ZM99.061,59.074a8.309,8.309,0,0,1-8.309-8.215l.237-2.611a15.526,15.526,0,0,0,4.831.071,10.849,10.849,0,0,0,6.8,3.834,8.738,8.738,0,0,0,.89,0,9.057,9.057,0,0,0,3.87-.9,8.309,8.309,0,0,1-8.321,7.822Zm8.428-13.591a5.935,5.935,0,0,1-4.4,1.947c-2.481-.237-4.25-2.9-4.261-2.908a2.374,2.374,0,0,0-1.994-1.092h-.38c-4.155.677-5.223,0-5.389-.19a4.4,4.4,0,0,1-.249-1.8v-.463c1.97-1.686,8.392-6.03,16.737.214A17.805,17.805,0,0,1,107.489,45.483Z"
        transform="translate(21.079 9.212)"
      />
      <path
        id="Path_2247"
        data-name="Path 2247"
        d="M132.193,95.551,121.284,78.363a7.656,7.656,0,0,0-1.187-1.757A18.992,18.992,0,0,0,87.086,72.57l-1.8-.107-4-12.369a7.205,7.205,0,0,0-2.5-4.5,6.814,6.814,0,0,0-5.092-1.591,7.122,7.122,0,0,0-6.149,7.811,1.948,1.948,0,0,0,.071.38l5.069,18.66a7.122,7.122,0,0,0,2.54,4.665,6.742,6.742,0,0,0,4.855,1.508h3.407v15.645a4.748,4.748,0,0,0,1.543,3.478L87.8,133.772c.427,4.273,1.187,6.351,2.516,7.431a3.561,3.561,0,0,0,1.306.641h5.615a4.629,4.629,0,0,0,4.546-3.988l.605-3.893.724,4a4.641,4.641,0,0,0,4.522,3.882H113.2a3.312,3.312,0,0,0,1.27-.594c1.377-1.033,2.089-3.039,2.659-7.478l2.778-27.622a4.748,4.748,0,0,0,1.187-1.875,7.264,7.264,0,0,0,1.935,1.187,7,7,0,0,0,2.754.558,6.861,6.861,0,0,0,2.778-.582,7.324,7.324,0,0,0,3.692-9.6A1.9,1.9,0,0,0,132.193,95.551Zm-19.716,37.7a22.364,22.364,0,0,1-.748,3.87h-3.905l-3.2-17.805a2.374,2.374,0,0,0-2.374-1.959h0a2.374,2.374,0,0,0-2.374,2.006l-2.8,17.805H93.116a19.4,19.4,0,0,1-.653-3.822l-2.493-25.924h25.093Zm4.285-30.577H88.273V87.159l5.567.083h0a2.374,2.374,0,0,0,0-4.748l-13.852-.226h-.3a2.042,2.042,0,0,1-1.519-.463,2.374,2.374,0,0,1-.867-1.626,1.959,1.959,0,0,0-.071-.38L72.249,61.21a2.374,2.374,0,0,1,1.959-2.457,2.077,2.077,0,0,1,1.543.475,2.374,2.374,0,0,1,.843,1.614,3.751,3.751,0,0,0,.107.5l4.641,14.125a2.374,2.374,0,0,0,2.113,1.626l13.579.8a2.378,2.378,0,0,0,.285-4.748l-4.1-.237a14.09,14.09,0,0,1,9.294-3.466,14.244,14.244,0,0,1,14.244,14.244Zm10.03-1.543a2.374,2.374,0,0,1-1.8,0,2.659,2.659,0,0,1-1.413-1.436,2.635,2.635,0,0,0-.249-.439l-1.816-2.516V87.574L128.062,97.9A2.54,2.54,0,0,1,126.792,101.13Z"
        transform="translate(17.623 13.093)"
      />
    </g>
  </svg>
);

TeachingInClassroomIcon.displayName = "TeachingInClassroomIcon";

TeachingInClassroomIcon.defaultProps = {
  className: "",
};

export default TeachingInClassroomIcon;
