// @flow
import type { Node } from "react";
import React, { useEffect } from "react";
import "@delta-defense/universal-components/components/footer/build/footer.css";
import FooterMarkup from "raw-loader!@delta-defense/universal-components/components/footer/footer.html";
import { tIDs } from "shared/constants/tracking";
import styles from "./UnauthenticatedFooter.module.scss";

const UnauthenticatedFooter = (): Node => {
  useEffect(() => {
    const tIDInput: HTMLInputElement = document.getElementById("tID");
    tIDInput.value = tIDs.FOOTER;
    import(
      "@delta-defense/universal-components/components/footer/build/footer.js"
    );
  }, []);

  return (
    <div
      className={styles.unauthenticatedFooter}
      dangerouslySetInnerHTML={{ __html: FooterMarkup }}
    />
  );
};

export default UnauthenticatedFooter;
