// @flow
import type { Registrant as RegistrantInterface } from "redux/api-types/Registrant";
import type { RegistrantRecordI } from "./RegistrantRecord";
import RegistrantRecord from "./RegistrantRecord";

import { STRIPE_PAYMENT_BASE_URL } from "shared/constants/application";

type RegistrantsReturn = {};

export const parseRegistrantReturnToModel = (
  registrant: RegistrantInterface
): RegistrantRecordI => {
  const {
    id,
    attributes: {
      crmId,
      studentId,
      firstName,
      lastName,
      email,
      phone,
      status,
      notes,
      isManualRegistrant,
      hasCompletedPrerequisiteELearning,
      chargeId,
      createdAt,
      removedAt,
    },
  } = registrant;

  const phoneLink =
    typeof phone === "string" ? "tel:" + phone.replace(/[^0-9.]/g, "") : "";
  const chargeUrl = !chargeId ? "" : STRIPE_PAYMENT_BASE_URL + chargeId;

  const record = new RegistrantRecord({
    id,
    studentId,
    crmId,
    firstName,
    lastName,
    email,
    phone,
    phoneLink,
    status,
    isManual: !!isManualRegistrant,
    notes,
    chargeUrl,
    createdAt,
    removedAt,
    isLocked: removedAt || !!status,
    hasCompletedPrerequisiteELearning,
  });

  return record;
};

export const parseRegistrants = (
  data: Array<RegistrantInterface>
): RegistrantsReturn => {
  let registrants = {};

  data.forEach(function (registrant: RegistrantInterface) {
    const Record = parseRegistrantReturnToModel(registrant);

    registrants[Record.id] = Record;
  });
  return registrants;
};
