// @flow
import { Record } from "immutable";

export type CurriculumRecordI = {
  id: string,
  name: string,
  imageUrl: Array<string>,
  thumbnailUrl: string,
  badgeImageUrl: string,
};

const CurriculumRecord = Record({
  id: "",
  name: "",
  imageUrl: [],
  thumbnailUrl: "",
  badgeImageUrl: "",
});

export default CurriculumRecord;
