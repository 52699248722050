// @flow
import React from "react";
import type { Node } from "react";
import { handleActions } from "redux-actions";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";

import { NONE, PENDING, SUCCESS, ERROR } from "shared/constants/status";
import {
  validateLogin,
  validateCreateAccount,
  validateCreateVerification,
  validateVerifyVerification,
} from "./validate";

import type { callApiReturnType } from "redux/middleware/api";
import type {
  simpleReduxAction,
  PayloadAction,
} from "shared/constants/flowTypes";
import { setAccessToken } from "shared/utilities/authCookie";

import {
  getLoginMutation,
  getCreateAccountMutation,
  getCreateVerificationMutation,
  getVerifyVerificationMutation,
} from "./mutations";

// Actions
const namespace: string = `${APP_NAMESPACE}/courseRegistrationDialog`;
export const OPEN_MODAL: string = `${namespace}/MODAL/OPEN`;
export const CLOSE_MODAL: string = `${namespace}/MODAL/CLOSE`;
export const SET_FORM_TYPE: string = `${namespace}/FORM/SET_FORM_TYPE`;
export const SET_VERIFICATION_ID: string = `${namespace}/FORM/SET_VERIFICATION_ID`;
export const LOGIN_UPDATE_FORM: string = `${namespace}/FORM/LOGIN_UPDATE`;
export const LOGIN_REQUEST_SENT: string = `${namespace}/SEND/LOGIN_REQUEST`;
export const LOGIN_REQUEST_SUCCESS: string = `${namespace}/SEND/LOGIN_SUCCESS`;
export const CREATE_ACCOUNT_UPDATE_FORM: string = `${namespace}/FORM/CREATE_ACCOUNT_UPDATE`;
export const CREATE_ACCOUNT_REQUEST_SENT: string = `${namespace}/SEND/CREATE_ACCOUNT_REQUEST`;
export const CREATE_ACCOUNT_REQUEST_SUCCESS: string = `${namespace}/SEND/CREATE_ACCOUNT_SUCCESS`;
export const CREATE_VERIFICATION_UPDATE_FORM: string = `${namespace}/FORM/CREATE_VERIFICATION_UPDATE`;
export const CREATE_VERIFICATION_REQUEST_SENT: string = `${namespace}/SEND/CREATE_VERIFICATION_REQUEST`;
export const CREATE_VERIFICATION_REQUEST_SUCCESS: string = `${namespace}/SEND/CREATE_VERIFICATION_SUCCESS`;
export const VERIFY_VERIFICATION_UPDATE_FORM: string = `${namespace}/FORM/VERIFY_VERIFICATION_UPDATE`;
export const VERIFY_VERIFICATION_REQUEST_SENT: string = `${namespace}/SEND/VERIFY_VERIFICATION_REQUEST`;
export const VERIFY_VERIFICATION_REQUEST_SUCCESS: string = `${namespace}/SEND/VERIFY_VERIFICATION_SUCCESS`;
export const REQUEST_ERROR: string = `${namespace}/SEND/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

type LoginResponse = {
  data: {
    authenticateIdentity: {
      token: string,
    },
  },
};

type CreateAccountResponse = {
  data: {
    createUserIdentity: {
      userToken: {
        token: string,
      },
    },
  },
};

type CreateVerificationResponse = {
  data: {
    createIdentityVerification: {
      id: string,
    },
  },
};

type VerifyVerificationResponse = {
  data: {
    verifyIdentityVerification: {
      token: string,
    },
  },
};

export type Error = {
  message: string,
};

type ErrorResponse = {
  errors: Error[],
};

export const openModal = (
  registrationUrl: string
): PayloadAction<{ registrationUrl: string }> => ({
  type: OPEN_MODAL,
  payload: {
    registrationUrl,
  },
});

export const closeModal = (): simpleReduxAction => ({
  type: CLOSE_MODAL,
});

export const setFormType = (
  formType: string
): PayloadAction<{ formType: string }> => ({
  type: SET_FORM_TYPE,
  payload: {
    formType,
  },
});

export const setVerificationId = (
  verificationId: string
): PayloadAction<{ verificationId: string }> => ({
  type: SET_VERIFICATION_ID,
  payload: {
    verificationId,
  },
});

export const updateLoginForm = (
  name: string,
  value: string
): PayloadAction<{ name: string, value: string }> => ({
  type: LOGIN_UPDATE_FORM,
  payload: {
    name,
    value,
  },
});

export const requestLogin = (
  email: string,
  password: string
): PayloadAction<{ email: string, password: string }> => ({
  type: LOGIN_REQUEST_SENT,
  payload: {
    email,
    password,
  },
});

export const receiveLoginResponse = (
  json: LoginResponse
): PayloadAction<LoginResponse> => ({
  type: LOGIN_REQUEST_SUCCESS,
  payload: json,
});

export const updateCreateAccountForm = (
  name: string,
  value: string
): PayloadAction<{ name: string, value: string }> => ({
  type: CREATE_ACCOUNT_UPDATE_FORM,
  payload: {
    name,
    value,
  },
});

export const requestCreateAccount = (
  name: string,
  surname: string,
  email: string,
  password: string
): PayloadAction<{
  name: string,
  surname: string,
  email: string,
  password: string,
}> => ({
  type: CREATE_ACCOUNT_REQUEST_SENT,
  payload: {
    name,
    surname,
    email,
    password,
  },
});

export const receiveCreateAccountResponse = (
  json: CreateAccountResponse
): PayloadAction<CreateAccountResponse> => ({
  type: CREATE_ACCOUNT_REQUEST_SUCCESS,
  payload: json,
});

export const updateCreateVerificationForm = (
  name: string,
  value: string
): PayloadAction<{ name: string, value: string }> => ({
  type: CREATE_VERIFICATION_UPDATE_FORM,
  payload: {
    name,
    value,
  },
});

export const requestCreateVerification = (
  email: string
): PayloadAction<{
  email: string,
}> => ({
  type: CREATE_VERIFICATION_REQUEST_SENT,
  payload: {
    email,
  },
});

export const receiveCreateVerificationResponse = (
  json: CreateVerificationResponse
): PayloadAction<CreateVerificationResponse> => ({
  type: CREATE_VERIFICATION_REQUEST_SUCCESS,
  payload: json,
});

export const updateVerifyVerificationForm = (
  name: string,
  value: string
): PayloadAction<{ name: string, value: string }> => ({
  type: VERIFY_VERIFICATION_UPDATE_FORM,
  payload: {
    name,
    value,
  },
});

export const requestVerifyVerification = (
  code: string
): PayloadAction<{
  code: string,
}> => ({
  type: VERIFY_VERIFICATION_REQUEST_SENT,
  payload: {
    code,
  },
});

export const receiveVerifyVerificationResponse = (
  json: VerifyVerificationResponse
): PayloadAction<VerifyVerificationResponse> => ({
  type: VERIFY_VERIFICATION_REQUEST_SUCCESS,
  payload: json,
});

export const receiveRequestError = (
  error: ErrorResponse
): PayloadAction<ErrorResponse> => ({
  type: REQUEST_ERROR,
  payload: error,
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async Action
export const submitLoginRequest = (
  email: string,
  password: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: "/mesh/",
    options: {
      query: getLoginMutation(email, password),
    },
    actions: {
      request: requestLogin,
      success: receiveLoginResponse,
      failure: receiveRequestError,
    },
  },
});

// Async Action
export const submitCreateAccountRequest = (
  name: string,
  surname: string,
  email: string,
  password: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: "/mesh/graphql",
    options: {
      query: getCreateAccountMutation(name, surname, email, password),
    },
    actions: {
      request: requestCreateAccount,
      success: receiveCreateAccountResponse,
      failure: receiveRequestError,
    },
  },
});

// Async Action
export const submitCreateVerificationRequest = (
  email: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: "/mesh/graphql",
    options: {
      query: getCreateVerificationMutation(email),
    },
    actions: {
      request: requestCreateVerification,
      success: receiveCreateVerificationResponse,
      failure: receiveRequestError,
    },
  },
});

// Async Action
export const submitVerifyVerificationRequest = (
  id: string,
  code: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: "/mesh/graphql",
    options: {
      query: getVerifyVerificationMutation(id, code),
    },
    actions: {
      request: requestVerifyVerification,
      success: receiveVerifyVerificationResponse,
      failure: receiveRequestError,
    },
  },
});

export const FormTypes = {
  Login: "login",
  CreateAccount: "create_account",
  CreateVerification: "create_verification",
  VerifyVerification: "verify_verification",
};

// State
export type State = {
  registrationUrl: string,
  formType: string,
  verificationId: string,
  loginForm: {
    email: string,
    password: string,
  },
  loginFormErrors: {
    email?: string,
    password?: string,
  },
  createAccountForm: {
    name: string,
    surname: string,
    email: string,
    password: string,
    passwordConfirmation: string,
  },
  createAccountFormErrors: {
    name?: string,
    surname?: string,
    email?: string,
    password?: string,
    passwordConfirmation?: string,
  },
  createVerificationForm: {
    email: string,
  },
  createVerificationFormErrors: {
    email?: string,
  },
  verifyVerificationForm: {
    code: string,
  },
  verifyVerificationFormErrors: {
    code?: string,
  },
  status: string,
  errors: Node[],
};

const loginForm = {
  email: "",
  password: "",
};

const createAccountForm = {
  name: "",
  surname: "",
  email: "",
  password: "",
  passwordConfirmation: "",
};

const createVerificationForm = {
  email: "",
};

const verifyVerificationForm = {
  code: "",
};

const initialState: State = {
  registrationUrl: "",
  formType: FormTypes.CreateAccount,
  verificationId: "",
  loginForm,
  loginFormErrors: {},
  createAccountForm,
  createAccountFormErrors: {},
  createVerificationForm,
  createVerificationFormErrors: {},
  verifyVerificationForm,
  verifyVerificationFormErrors: {},
  status: NONE,
  errors: [],
};

// Reducer
const reducer = handleActions(
  {
    [OPEN_MODAL]: (
      state: State,
      action: PayloadAction<{ registrationUrl: string }>
    ): State => {
      return {
        ...state,
        registrationUrl: action.payload.registrationUrl,
      };
    },
    [CLOSE_MODAL]: (state: State, action: simpleReduxAction): State => {
      return {
        ...state,
        registrationUrl: "",
      };
    },
    [SET_FORM_TYPE]: (
      state: State,
      action: PayloadAction<{ formType: string }>
    ): State => {
      return {
        ...state,
        formType: action.payload.formType,
        loginForm,
        loginFormErrors: {},
        createAccountForm,
        createAccountFormErrors: {},
        createVerificationForm,
        createVerificationFormErrors: {},
        verifyVerificationForm,
        verifyVerificationFormErrors: {},
      };
    },
    [SET_VERIFICATION_ID]: (
      state: State,
      action: PayloadAction<{ verificationId: string }>
    ): State => {
      return {
        ...state,
        verificationId: action.payload.verificationId,
      };
    },
    [LOGIN_UPDATE_FORM]: (
      state: State,
      action: PayloadAction<{ name: string, value: string }>
    ): State => {
      const { name, value } = action.payload;
      const loginForm = {
        ...state.loginForm,
        [name]: value,
      };
      const loginFormErrors = validateLogin(loginForm);
      return {
        ...state,
        loginForm,
        loginFormErrors,
      };
    },
    [LOGIN_REQUEST_SENT]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      status: PENDING,
      errors: [],
    }),
    [LOGIN_REQUEST_SUCCESS]: (
      state: State,
      action: PayloadAction<LoginResponse>
    ): State => {
      const token = action.payload.data?.authenticateIdentity?.token;
      if (!token) {
        return {
          ...state,
          loginForm,
          loginFormErrors: {},
          status: ERROR,
          errors: [
            <span>
              Failed to authenticate, check your email and password then try
              again.
            </span>,
          ],
        };
      }
      setAccessToken(token);
      return {
        ...state,
        loginForm,
        loginFormErrors: {},
        status: SUCCESS,
      };
    },
    [CREATE_ACCOUNT_UPDATE_FORM]: (
      state: State,
      action: PayloadAction<{ name: string, value: string }>
    ): State => {
      const { name, value } = action.payload;
      const createAccountForm = {
        ...state.createAccountForm,
        [name]: value,
      };
      const createAccountFormErrors = validateCreateAccount(createAccountForm);
      return {
        ...state,
        createAccountForm,
        createAccountFormErrors,
      };
    },
    [CREATE_ACCOUNT_REQUEST_SENT]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      status: PENDING,
      errors: [],
    }),
    [CREATE_ACCOUNT_REQUEST_SUCCESS]: (
      state: State,
      action: PayloadAction<CreateAccountResponse>
    ): State => {
      const token = action.payload.data?.createUserIdentity?.userToken?.token;
      if (!token) {
        return {
          ...state,
          createAccountForm,
          createAccountFormErrors: {},
          status: ERROR,
          errors: [
            <span>
              Failed to create your account, please try again or log in if you
              already have an account.
            </span>,
          ],
        };
      }
      setAccessToken(token);
      return {
        ...state,
        createAccountForm,
        createAccountFormErrors: {},
        status: SUCCESS,
      };
    },
    [CREATE_VERIFICATION_UPDATE_FORM]: (
      state: State,
      action: PayloadAction<{ name: string, value: string }>
    ): State => {
      const { name, value } = action.payload;
      const createVerificationForm = {
        ...state.createVerificationForm,
        [name]: value,
      };
      const createVerificationFormErrors = validateCreateVerification(
        createVerificationForm
      );
      return {
        ...state,
        createVerificationForm,
        createVerificationFormErrors,
      };
    },
    [CREATE_VERIFICATION_REQUEST_SENT]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      status: PENDING,
      errors: [],
    }),
    [CREATE_VERIFICATION_REQUEST_SUCCESS]: (
      state: State,
      action: PayloadAction<CreateVerificationResponse>
    ): State => {
      const verificationId =
        action.payload.data?.createIdentityVerification?.id;
      if (!verificationId) {
        return {
          ...state,
          verificationId,
          formType: FormTypes.VerifyVerification,
          createVerificationForm,
          createVerificationFormErrors: {},
          status: ERROR,
          errors: [
            <span>
              An error occurred while sending verification, please try again.
            </span>,
          ],
        };
      }
      return {
        ...state,
        verificationId,
        formType: FormTypes.VerifyVerification,
        createVerificationForm,
        createVerificationFormErrors: {},
        status: NONE,
      };
    },
    [VERIFY_VERIFICATION_UPDATE_FORM]: (
      state: State,
      action: PayloadAction<{ name: string, value: string }>
    ): State => {
      const { name, value } = action.payload;
      const verifyVerificationForm = {
        ...state.createVerificationForm,
        [name]: value,
      };
      const verifyVerificationFormErrors = validateVerifyVerification(
        verifyVerificationForm
      );
      return {
        ...state,
        verifyVerificationForm,
        verifyVerificationFormErrors,
      };
    },
    [VERIFY_VERIFICATION_REQUEST_SENT]: (
      state: State,
      action: simpleReduxAction
    ): State => ({
      ...state,
      status: PENDING,
      errors: [],
    }),
    [VERIFY_VERIFICATION_REQUEST_SUCCESS]: (
      state: State,
      action: PayloadAction<VerifyVerificationResponse>
    ): State => {
      const token = action.payload.data?.verifyIdentityVerification?.token;
      if (!token) {
        return {
          ...state,
          verifyVerificationForm,
          verifyVerificationFormErrors: {},
          status: ERROR,
          errors: [
            <span>
              Failed to authenticate, please check the verification code and try
              again.
            </span>,
          ],
        };
      }
      setAccessToken(token);
      return {
        ...state,
        verifyVerificationForm,
        verifyVerificationFormErrors: {},
        status: SUCCESS,
      };
    },
    [REQUEST_ERROR]: (
      state: State,
      action: PayloadAction<ErrorResponse>
    ): State => {
      let errorNode = <span>An error occurred, please try again.</span>;
      if (
        action.payload.errors.find((e: Error): boolean =>
          e.message.includes("conflicts with another account")
        )
      ) {
        errorNode = (
          <span>
            The email address you entered is already connected to an account in
            our system. Please click the link below to log in.
          </span>
        );
      }
      return {
        ...state,
        status: ERROR,
        errors: [errorNode],
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default reducer;
