// @flow
import React from "react";
import type { Node } from "react";

type Props = {
  className: string,
  [string]: string,
};

let NeoClassicalBuildingOnSignIcon = ({ className, ...props }: Props): Node => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140.5 140.5"
    {...props}
  >
    <title>NeoClassical Building On Sign</title>
    <path
      d="M116.887,112.933H114.4v-7.152a1.756,1.756,0,0,0-1.756-1.756h-4.09V67.7h4.014a1.756,1.756,0,0,0,1.756-1.756V56.088a1.756,1.756,0,0,0-1.756-1.756h-3.172L71.67,26.439a1.755,1.755,0,0,0-2.089,0L31.857,54.332H28.533a1.756,1.756,0,0,0-1.756,1.756v9.853A1.756,1.756,0,0,0,28.533,67.7h4.014v36.327H28.61a1.756,1.756,0,0,0-1.756,1.756v7.152H24.287a1.756,1.756,0,0,0-1.756,1.756v9.239a1.756,1.756,0,0,0,1.756,1.756h92.6a1.756,1.756,0,0,0,1.756-1.756v-9.239A1.756,1.756,0,0,0,116.887,112.933Zm-11.849-8.908H98.757V67.7h6.282ZM63.809,67.7v36.327H45.853V67.7Zm3.512,0H73.6v36.327H67.321Zm9.794,0H95.244v36.327H77.115Zm-6.49-37.662,32.861,24.3H90.457a1.666,1.666,0,0,0-.182-.189l-18.651-12.9a1.76,1.76,0,0,0-2,0L50.75,54.32s-.007.009-.012.012H37.764Zm13.749,24.3H56.9l13.725-9.51ZM30.289,57.844h80.52v6.341H30.289Zm5.77,9.853h6.282v36.327H36.059Zm-5.693,39.84h80.519v5.4H30.366Zm84.765,14.635H26.043v-5.727h89.088Z"
      transform="translate(-0.666 8.459)"
    />
    <path
      d="M148.825,10H11.837a1.756,1.756,0,1,0,0,3.512H37.769v9.881H18.751a1.756,1.756,0,0,0-1.756,1.756V148.744a1.756,1.756,0,0,0,1.756,1.756h123.16a1.756,1.756,0,0,0,1.756-1.756V25.149a1.756,1.756,0,0,0-1.756-1.756H122.894V13.512h25.931a1.756,1.756,0,0,0,0-3.512Zm-8.67,16.906V146.987H20.507V26.906H37.769v4.966a7.916,7.916,0,1,0,3.512,0V26.906h78.1v4.966a7.917,7.917,0,1,0,3.512,0V26.906ZM39.525,35.172a4.4,4.4,0,1,1-4.4,4.4A4.409,4.409,0,0,1,39.525,35.172Zm81.612,0a4.4,4.4,0,1,1-4.4,4.4A4.409,4.409,0,0,1,121.137,35.172Zm-1.756-11.779h-78.1V13.512h78.1Z"
      transform="translate(-10.081 -10)"
    />
  </svg>
);

NeoClassicalBuildingOnSignIcon.displayName = "NeoClassicalBuildingOnSignIcon";

NeoClassicalBuildingOnSignIcon.defaultProps = {
  className: "",
};

export default NeoClassicalBuildingOnSignIcon;
