// @flow
import type { CourseOccurrence as CourseOccurrenceType } from "redux/api-types/CourseOccurrence";
import type { callApiReturnType } from "redux/middleware/api";
import type { Reply as ReplyType } from "redux/api-types/Reply";
import type { ReplyRecordI } from "./ReplyRecord";
import type { Review as ReviewType } from "redux/api-types/Review";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import { setLocalStorage, getLocalStorage } from "shared/utilities/helpers";

// actions imports
import {
  POST_REPLY_REQUEST,
  REMOVE_REPLY_REQUEST,
  REVIEWS_REQUEST,
  POST_REPLY_REQUEST_SUCCESS,
  REMOVE_REPLY_SUCCESS,
  REVIEWS_REQUEST_SUCCESS,
  POST_REPLY_REQUEST_ERROR,
  REMOVE_REPLY_ERROR,
  REVIEWS_REQUEST_ERROR,
  SET_ACTIVE_REVIEW_ID,
  SET_REPLY_INPUT_VALUE,
  CANCEL_REPLY,
  CLEAR,
} from "./instructorReviewActions";

const namespace: string = `${APP_NAMESPACE}/instructorReviews`;
export const PER_PAGE = `${namespace}/PER_PAGE`;
export const DEFAULT_PER_PAGE = 10;
export const storedPerPage = getLocalStorage(PER_PAGE) ?? DEFAULT_PER_PAGE;

// Action Flow Types
export type setReplyInputValueAction = {
  type: string,
  payload: {
    replyInputValue: string,
  },
};

export type setActiveReviewIdAction = {
  type: string,
  payload: {
    activeReviewId: string,
  },
};

type RequestReviewsParams = {
  instructorId: string,
  page: number,
  perPage: number,
};

type RequestRemoveReplyReturn = {
  reviewId: string,
  replyId: string,
};

export type RequestReviewsReturnType = {
  payload: RequestReviewsParams,
} & simpleReduxAction;

type ReviewReturn = {
  data: Array<ReviewType>,
  included: Array<CourseOccurrenceType | ReplyType>,
  meta: {
    total: number,
    count: number,
    currentPage: number,
    totalPages: number,
  },
};

type ReplyReturn = {
  data: ReplyType,
};

type RemoveReplyReturn = {
  data: ReplyType,
};

export type ReceiveReviewsReturnType = {
  payload: ReviewReturn,
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

type ReceiveRepliesReviewReturn = {
  payload: ReplyType,
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export type ReceiveRepliesReturnType = {
  payload: ReplyReturn,
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export type RequestRemoveAction = {
  type: string,
  payload: { reviewId: string, replyId: string },
};

export type ReplyAction = {
  type: string,
  payload: {
    reply: ReplyReturn,
  },
  meta: {
    receivedAt: number,
  },
};

// Actions
export const setReplyInputValue = (
  replyInputValue: string
): setReplyInputValueAction => {
  return {
    type: SET_REPLY_INPUT_VALUE,
    payload: {
      replyInputValue,
    },
  };
};

export const setActiveReviewId = (
  activeReviewId: string
): setActiveReviewIdAction => {
  return {
    type: SET_ACTIVE_REVIEW_ID,
    payload: {
      activeReviewId,
    },
  };
};

export const requestReviews = ({
  instructorId,
  page,
  perPage,
}: RequestReviewsParams): RequestReviewsReturnType => {
  setLocalStorage(PER_PAGE, perPage);

  return {
    type: REVIEWS_REQUEST,
    payload: {
      instructorId,
      page,
      perPage,
    },
  };
};

export const receiveReviews = (
  json: ReviewReturn
): ReceiveReviewsReturnType => ({
  type: REVIEWS_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const reviewsRequestError = (error: apiErrorParams): apiErrorAction => ({
  type: REVIEWS_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestRemoveReply = ({
  reviewId,
  replyId,
}: RequestRemoveReplyReturn): RequestRemoveAction => ({
  type: REMOVE_REPLY_REQUEST,
  payload: {
    reviewId,
    replyId,
  },
});

export const receiveRemoveReply = (json: RemoveReplyReturn): ReplyAction => ({
  type: REMOVE_REPLY_SUCCESS,
  payload: {
    reply: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const removeReplyRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: REMOVE_REPLY_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

export const cancelReply = (): simpleReduxAction => ({
  type: CANCEL_REPLY,
});

export const repliesRequest = (): simpleReduxAction => ({
  type: POST_REPLY_REQUEST,
});

export const receiveReplies = (
  json: ReplyType
): ReceiveRepliesReviewReturn => ({
  type: POST_REPLY_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const repliesRequestError = (error: apiErrorParams): apiErrorAction => ({
  type: POST_REPLY_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

// Async actions
export type GetReviewsParams = {
  instructorId?: string,
  page?: number,
  perPage?: number,
};

export const getReviews = ({
  instructorId = "",
  page = 1,
  perPage,
}: GetReviewsParams): callApiReturnType => {
  if (perPage === undefined) {
    perPage = getLocalStorage(PER_PAGE) ?? DEFAULT_PER_PAGE;
  }

  const baseUrl = !instructorId
    ? "/api/training/instructor/reviews"
    : `/api/training/instructors/${instructorId}/reviews`;
  const endpoint = `${baseUrl}?include=occurrence,reply&page=${page}&perPage=${perPage}`;

  return {
    type: CALL_API,
    payload: {
      method: "GET",
      endpoint,
      actionParams: {
        instructorId,
        page,
        perPage,
      },
      actions: {
        request: requestReviews,
        success: receiveReviews,
        failure: reviewsRequestError,
      },
    },
  };
};

export const postReply = (
  reviewId: string,
  comment: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "POST",
    endpoint: `/api/training/reviews/${reviewId}/replies`,
    options: {
      comment: comment,
    },
    actions: {
      request: repliesRequest,
      success: receiveReplies,
      failure: repliesRequestError,
    },
    toasts: {
      success: "Reply added",
      failure: "Error Adding reply",
    },
  },
});

export const removeReply = (reply: ReplyRecordI): callApiReturnType => {
  const { reviewId, replyId } = reply;
  return {
    type: CALL_API,
    payload: {
      method: "DELETE",
      endpoint: `/api/training/reviews/${reviewId}/replies/${replyId}`,
      actionParams: {
        replyId: replyId,
        reviewId: reviewId,
      },

      actions: {
        request: requestRemoveReply,
        success: receiveRemoveReply,
        failure: removeReplyRequestError,
      },
      toasts: {
        success: "Reply Removed From Review",
        failure: "Error Removing Reply From Review",
      },
    },
  };
};

export const updateReply = (
  reviewId: string,
  replyId: string,
  comment: string
): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "PATCH",
    endpoint: `/api/training/reviews/${reviewId}/replies/${replyId}`,
    options: {
      comment: comment,
    },
    actions: {
      request: repliesRequest,
      success: receiveReplies,
      failure: repliesRequestError,
    },
    toasts: {
      success: "Reply was updated",
      failure: "Error updating reply",
    },
  },
});
