// @flow
import { APP_NAMESPACE } from "shared/constants/application";

//Actions
const namespace: string = `${APP_NAMESPACE}/instructorReviews`;

export const CANCEL_REPLY: string = `${namespace}/REPLY/CANCEL`;

export const CLEAR: string = `${namespace}/CLEAR`;

export const POST_REPLY_REQUEST: string = `${namespace}/POST_REPLY/REQUEST`;
export const POST_REPLY_REQUEST_ERROR: string = `${namespace}/POST_REPLY/ERROR`;
export const POST_REPLY_REQUEST_SUCCESS: string = `${namespace}/POST_REPLY/SUCCESS`;

export const REMOVE_REPLY_ERROR: string = `${namespace}/REMOVE_REPLY/ERROR`;
export const REMOVE_REPLY_REQUEST: string = `${namespace}/REMOVE_REPLY/REQUEST`;
export const REMOVE_REPLY_SUCCESS: string = `${namespace}/REMOVE_REPLY/SUCCESS`;

export const REVIEWS_REQUEST: string = `${namespace}/REQUEST/FETCH`;
export const REVIEWS_REQUEST_ERROR: string = `${namespace}/REQUEST/ERROR`;
export const REVIEWS_REQUEST_SUCCESS: string = `${namespace}/REQUEST/SUCCESS`;

export const SET_ACTIVE_REVIEW_ID: string = `${namespace}/SET_ACTIVE_REVIEW_ID`;
export const SET_REPLY_INPUT_VALUE: string = `${namespace}/REPLY_INPUT/SET_VALUE`;
