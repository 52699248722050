// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
  apiErrorReturn,
} from "shared/constants/flowTypes";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import type { Curriculum as CurriculumType } from "redux/api-types/Curriculum";
import { parseCurricula } from "./parsers";

// actions
const namespace: string = `${APP_NAMESPACE}/curricula`;
const REQUEST_CURRICULA = `${namespace}/FETCH`;
const RECEIVE_CURRICULA = `${namespace}/RECEIVE`;
const REQUEST_CURRICULA_ERROR = `${namespace}/REQUEST_ERROR`;
const CLEAR = `${namespace}/CLEAR`;

// action creators
type RequestCurriculaReturn = {
  payload: {
    key: string,
  },
} & simpleReduxAction;

export const requestCurricula = ({
  key,
}: {
  key: string,
}): RequestCurriculaReturn => ({
  type: REQUEST_CURRICULA,
  payload: {
    key,
  },
});

type CurriculaReturn = {
  data: Array<CurriculumType>,
};

type ReceiveCurriculaReturn = {
  payload: CurriculaReturn,
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export const receiveCurricula = (
  json: CurriculaReturn
): ReceiveCurriculaReturn => ({
  type: RECEIVE_CURRICULA,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestCurriculaError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: REQUEST_CURRICULA_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async Actions
export const getCurricula = ({
  instructorId = "",
  key = "id",
}: {
  instructorId: string,
  key: string,
}): callApiReturnType => ({
  type: CALL_API,
  payload: {
    endpoint: instructorId
      ? `/api/training/instructors/${instructorId}/curricula`
      : "/api/training/curricula",
    actionParams: {
      key,
    },
    actions: {
      request: requestCurricula,
      success: receiveCurricula,
      failure: requestCurriculaError,
    },
  },
});

// reducer
export type State = {
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  dataKey: string,
  resources: {
    [string]: ResourceRecordType,
  },
};

export const initialState = {
  status: NONE,
  errorMessage: "",
  errors: [],
  dataKey: "",
  data: {},
};

const curricula = handleActions(
  {
    [REQUEST_CURRICULA]: (
      state: State,
      { payload: { key: dataKey } }: RequestCurriculaReturn
    ): State => ({
      ...initialState,
      status: LOADING,
      dataKey,
    }),
    [RECEIVE_CURRICULA]: (
      state: State,
      { payload: { data } }: ReceiveCurriculaReturn
    ): State => ({
      ...state,
      status: LOADED,
      data: parseCurricula(state.dataKey, data),
    }),
    [REQUEST_CURRICULA_ERROR]: (
      state: State,
      { payload: { errorMessage, errors } }: apiErrorAction
    ): State => ({
      ...state,
      status: ERROR,
      errorMessage,
      errors,
    }),
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default curricula;
