// @flow
import ItemRecord from "../records/ItemRecord";
import type { CourseItem } from "redux/api-types/CourseItem";

export const parseItems = (data: Array<CourseItem>): Array<RecordOf<T>> => {
  let items = [];

  data.forEach(function ({
    id,
    attributes: { name, category, hasQuantity },
  }: ItemType) {
    const Record = ItemRecord({
      id,
      name,
      category,
      hasQuantity: Boolean(hasQuantity),
    });
    items.push(Record);
  });

  return items;
};
