// @flow
import { handleActions } from "redux-actions";

import {
  ERROR,
  LOADING,
  LOADED,
  PENDING,
  NONE,
  SUCCESS,
} from "shared/constants/status";
import {
  OPEN,
  CLOSE,
  SELECT_TAB,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_ERROR,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
} from "./studentDrawerActions";
import { NO_MODE } from "./constants";
import type {
  OpenDrawerAction,
  Tab,
  SelectTabAction,
  ReceiveStudentAction,
  ReceiveUpdatedStudentAction,
  UpdateStudentErrorAction,
} from "./studentDrawerActionCreators";
import type { Registration } from "./studentDrawerActionCreators";
import type { apiErrorReturn } from "shared/constants/flowTypes";

export type StudentData = {
  instructorUserId: string,
  userId: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  notes: string,
  lastCourseName: string,
  registrations: Array<Registration>,
};

export type InitialValues = {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  notes: string,
};

// reducer
export type State = {
  studentId: ?string,
  activeTab: Tab,
  status: string,
  updateStatus: string,
  isDrawerOpen: boolean,
  data: StudentData,
  initialValues: InitialValues,
  mode: string,
  errors: apiErrorReturn,
  errorMessage: string,
};

export const initialState = {
  studentId: null,
  activeTab: "BASIC",
  status: NONE,
  updateStatus: NONE,
  isDrawerOpen: false,
  mode: NO_MODE,
  data: {
    instructorUserId: "",
    userId: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    notes: "",
    lastCourseName: "",
    registrations: [],
  },
  initialValues: {
    email: "",
    firstName: "",
    lastName: "",
    notes: "",
    phone: "",
    studentId: "",
  },
  errors: [],
  errorMessage: "",
};

const studentDrawerReducer = handleActions(
  {
    [OPEN]: (
      state: State,
      { payload: { id, mode } }: OpenDrawerAction
    ): State => ({
      ...initialState,
      studentId: id,
      isDrawerOpen: true,
      mode: mode,
    }),
    [CLOSE]: (state: State): State => ({
      ...initialState,
      mode: NO_MODE,
    }),
    [SELECT_TAB]: (state: State, actions: SelectTabAction): State => ({
      ...state,
      activeTab: actions.payload.tab,
    }),
    [FETCH_REQUEST]: (state: State): State => ({
      ...state,
      status: LOADING,
    }),
    [FETCH_SUCCESS]: (state: State, action: ReceiveStudentAction): State => {
      const data = action.payload.data?.attributes;

      const initialValues = {
        ...state.values,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        notes: data.notes,
        studentId: state.studentId,
      };

      return {
        ...state,
        status: LOADED,
        data,
        initialValues,
      };
    },
    [FETCH_ERROR]: (state: State, action: apiErrorAction): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [UPDATE_REQUEST]: (state: State): State => ({
      ...state,
      updateStatus: PENDING,
    }),
    [UPDATE_SUCCESS]: (
      state: State,
      action: ReceiveUpdatedStudentAction
    ): State => ({
      ...state,
      updateStatus: SUCCESS,
    }),
    [UPDATE_ERROR]: (
      state: State,
      action: UpdateStudentErrorAction
    ): State => ({
      ...state,
      updateStatus: ERROR,
    }),
  },
  initialState
);

export default studentDrawerReducer;
