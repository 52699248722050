// @flow
import { Record } from "immutable";

export type RangeRecordI = {
  id: string,
  name: string,
  shippingAddress: {
    address1: string,
    city: string,
    country: string,
    postalCode: string,
    state: string,
  },
};

const RangeRecord = Record({
  id: "",
  name: "",
  shippingAddress: {
    address1: "",
    city: "",
    country: "",
    postalCode: "",
    state: "",
  },
});

export default RangeRecord;
