// @flow
import { Record } from "immutable";

export type ResourceRecordType = {
  id: string,
  stateName: string,
  usStateAbbreviation: string,
  postalCode: number,
  backgroundImageUrls: {
    lgImage: string,
    lgImageRetina: string,
    xsImage: string,
    xsImageRetina: string,
  },
  statesHonoredCount: number,
  reciprocatingStatesCount: number,
  minimumAgeCount: number,
  yearsPermitValidCount: number,
};

const ResourceRecord = Record({
  id: "",
  stateName: "",
  usStateAbbreviation: "",
  postalCode: "",
  backgroundImageUrls: {
    lgImage: "",
    lgImageRetina: "",
    xsImage: "",
    xsImageRetina: "",
  },
  statesHonoredCount: 0,
  reciprocatingStatesCount: 0,
  minimumAgeCount: 0,
  yearsPermitValidCount: 0,
});

export default ResourceRecord;
