// @flow
import type { Saga } from "redux-saga";
import { put, take } from "redux-saga/effects";
import { handleActions } from "redux-actions";
import { camelCase } from "lodash";
import { push } from "connected-react-router";
import queryString from "query-string";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
  apiErrorReturn,
} from "shared/constants/flowTypes";
import type { CourseOccurrence } from "redux/api-types/CourseOccurrence";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import { FUTURE, TO_BE_GRADED } from "./occurrencesTypes";
import { RESULTS_PER_PAGE } from "shared/constants/localStorageKeys";
import {
  instructorResponseToRecord,
  parseOccurrence,
  parseOccurrences,
} from "./parseOccurrences";
import {
  ADD_OCCURRENCE_SUCCESS,
  EDIT_OCCURRENCE_SUCCESS,
  DELETE_OCCURRENCE_REQUEST,
  DELETE_OCCURRENCE_SUCCESS,
  DELETE_OCCURRENCE_ERROR,
} from "../manageCourseOccurrence/manageCourseOccurrence";
import { setLocalStorage, getLocalStorage } from "shared/utilities/helpers";
import type { OccurrenceRecordInterface } from "./OccurrenceRecord";
import type { DateRecordInterface } from "./DateRecord";
import type { InstructorRecordInterface } from "./InstructorRecord";
import type {
  AddOccurrenceSuccessAction,
  EditOccurrenceSuccessAction,
  RequestDeleteOccurrenceAction,
  DeleteOccurrenceSuccessAction,
} from "../manageCourseOccurrence/manageCourseOccurrence";
import {
  ADD_SUCCESS as ADD_INSTRUCTOR_SUCCESS,
  REMOVE_SUCCESS as REMOVE_INSTRUCTOR_SUCCESS,
} from "../manageCourseOccurrenceInstructors/manageCourseOccurrenceInstructors";
import type {
  InstructorAction,
  RemoveActionReturn as RemoveInstructorActionReturn,
} from "../manageCourseOccurrenceInstructors/manageCourseOccurrenceInstructors";
import { getResultsPerPage } from "shared/utilities/resultsPerPageModification";

const namespace: string = `${APP_NAMESPACE}/manageCourseOccurrences`;
const DEFAULT_RESULTS_PER_PAGE = 10;

// Actions
export const GET_COURSE_OCCURRENCES: string = `${namespace}/GET`;
export const COURSE_OCCURRENCES_REQUEST: string = `${namespace}/COURSE_OCCURRENCES_REQUEST`;
export const COURSE_OCCURRENCES_REQUEST_SUCCESS: string = `${namespace}/COURSE_OCCURRENCES_REQUEST_SUCCESS`;
export const COURSE_OCCURRENCES_REQUEST_ERROR: string = `${namespace}/COURSE_OCCURRENCES_REQUEST_ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Interfaces
type GetCourseOccurrencesAction = {
  type: string,
  payload: {
    type: string,
    resultsPerPage: number | string,
    page: number,
    courseId: string,
  },
};

type RequestCourseOccurrencesAction = {
  type: string,
  payload: {
    type: string,
    courseId: string,
    resultsPerPage: number | string,
    currentPage: number,
  },
};

type CourseOccurrencesReturn = {
  data: Array<CourseOccurrence>,
  meta: {
    total: number,
  },
};

type ReceiveCourseOccurrencesAction = {
  type: string,
  payload: {
    occurrences: CourseOccurrencesReturn,
  },
  meta: {
    receivedAt: number,
  },
};

// Action Creators
export const getCourseOccurrences = (
  courseId: string,
  type: string = FUTURE,
  page: number,
  resultsPerPage: number | string
): GetCourseOccurrencesAction => {
  resultsPerPage = resultsPerPage === 0 ? "All" : resultsPerPage;
  setLocalStorage(RESULTS_PER_PAGE, resultsPerPage);
  return {
    type: GET_COURSE_OCCURRENCES,
    payload: {
      courseId,
      type,
      resultsPerPage,
      page,
    },
  };
};

export const requestCourseOccurrences = ({
  courseId,
  type,
  resultsPerPage,
  currentPage,
}: {
  courseId: string,
  type: string,
  resultsPerPage: number | string,
  currentPage: number,
}): RequestCourseOccurrencesAction => {
  return {
    type: COURSE_OCCURRENCES_REQUEST,
    payload: {
      type,
      courseId,
      resultsPerPage,
      currentPage,
    },
  };
};

export const receiveCourseOccurrences = (
  json: CourseOccurrencesReturn
): ReceiveCourseOccurrencesAction => {
  return {
    type: COURSE_OCCURRENCES_REQUEST_SUCCESS,
    payload: {
      occurrences: json,
    },
    meta: {
      receivedAt: Date.now(),
    },
  };
};

export const courseOccurrencesRequestError = (
  error: apiErrorParams
): apiErrorAction => {
  return {
    type: COURSE_OCCURRENCES_REQUEST_ERROR,
    error: true,
    payload: {
      errorMessage: undefined,
      errors: [],
      ...error,
    },
    meta: {
      receivedAt: Date.now(),
    },
  };
};

export const clear = (): simpleReduxAction => {
  return {
    type: CLEAR,
  };
};

//Sagas
export function* watchGetCourseOccurrences(): Saga<void> {
  for (;;) {
    const action = yield take(GET_COURSE_OCCURRENCES);
    const { type, resultsPerPage, page, courseId } = action.payload;
    const payload = { type, resultsPerPage, page };
    const params = queryString.stringify(payload).toLowerCase();
    const url = `/manage-course/${courseId}/?${params}`;
    yield put(push(url));
  }
}

// Async actions
export const getCourseOccurrencesCall = (
  courseId: string,
  type: string = FUTURE,
  page: number,
  resultsPerPage?: number | string
): callApiReturnType => {
  resultsPerPage = getResultsPerPage(resultsPerPage);

  let filter = camelCase(type);
  let endpoint = `/api/training/courses/${courseId}/occurrences`;
  let actionParams = {
    courseId,
    type,
    resultsPerPage,
  };
  let getParams = [`filter=${filter}`];

  if (resultsPerPage) {
    getParams.push(`perPage=${resultsPerPage}`);
    if (!page) {
      page = 1;
    }
  }

  if (typeof page !== "undefined") {
    getParams.push(`page=${page}`);
    actionParams.currentPage = page;
  }

  endpoint += `?${getParams.join("&")}`;

  return {
    type: CALL_API,
    payload: {
      method: "GET",
      endpoint,
      actionParams,
      actions: {
        request: requestCourseOccurrences,
        success: receiveCourseOccurrences,
        failure: courseOccurrencesRequestError,
      },
    },
  };
};

// Initial State
export type State = {
  courseId: string,
  type: string,
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  occurrences: {
    [string]: OccurrenceRecordInterface,
  },
  dates: {
    [string]: DateRecordInterface,
  },
  instructors: {
    [string]: InstructorRecordInterface,
  },
  occurrencesCount: number,
  totalOccurrencesCount: number,
  currentPage: number,
  resultsPerPage: number | string,
  totalPages: number,
};

// Initial State
const initialState: State = {
  courseId: "",
  type: "",
  status: NONE,
  errorMessage: "",
  errors: [],
  occurrences: {},
  dates: {},
  instructors: {},
  occurrencesCount: 0,
  totalOccurrencesCount: 0,
  currentPage: 1,
  resultsPerPage: getLocalStorage(RESULTS_PER_PAGE) ?? DEFAULT_RESULTS_PER_PAGE,
  totalPages: 0,
};

// Reducer
const manageCourseOccurrences = handleActions(
  {
    [COURSE_OCCURRENCES_REQUEST]: (
      state: State,
      action: RequestCourseOccurrencesAction
    ): State => {
      return {
        ...state,
        status: LOADING,
        ...action.payload,
      };
    },
    [COURSE_OCCURRENCES_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveCourseOccurrencesAction
    ): State => {
      const { data, meta } = action.payload.occurrences;
      const occurrencesCount = data.length;
      const { occurrences, dates, instructors } = parseOccurrences(data);
      const totalOccurrencesCount =
        meta && meta.hasOwnProperty("total") ? meta.total : data.length;
      const totalPages =
        meta && meta.hasOwnProperty("totalPages") ? meta.totalPages : 1;

      return {
        ...state,
        status: LOADED,
        occurrences,
        dates,
        instructors,
        occurrencesCount,
        totalOccurrencesCount,
        totalPages,
      };
    },
    [COURSE_OCCURRENCES_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [COURSE_OCCURRENCES_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const { errorMessage, errors } = action.payload;
      return {
        ...state,
        status: ERROR,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [ADD_OCCURRENCE_SUCCESS]: (
      state: State,
      action: AddOccurrenceSuccessAction
    ): State => {
      const {
        type,
        occurrences,
        dates,
        instructors,
        occurrencesCount,
        totalOccurrencesCount,
      } = state;
      const { data: rawOccurrence } = action.payload.occurrence;
      const { id, attributes } = rawOccurrence;
      const { isPast } = attributes;
      const addToList =
        (!isPast && type === FUTURE) || (isPast && type === TO_BE_GRADED);
      if (!addToList) {
        return state;
      }
      const { occurrenceRecord, dateRecords, instructorRecords } =
        parseOccurrence(rawOccurrence);

      return {
        ...state,
        occurrences: {
          ...occurrences,
          [id]: occurrenceRecord,
        },
        dates: {
          ...dates,
          ...dateRecords,
        },
        instructors: {
          ...instructors,
          ...instructorRecords,
        },
        occurrencesCount: occurrencesCount + 1,
        totalOccurrencesCount: totalOccurrencesCount + 1,
      };
    },
    [EDIT_OCCURRENCE_SUCCESS]: (
      state: State,
      action: EditOccurrenceSuccessAction
    ): State => {
      let { type, occurrences, dates, instructors } = state;
      const { data: rawOccurrence } = action.payload.occurrence;
      const { id, attributes } = rawOccurrence;
      const { isPast } = attributes;

      const { occurrenceRecord, dateRecords, instructorRecords } =
        parseOccurrence(rawOccurrence);

      const show =
        (!isPast && type === FUTURE) || (isPast && type === TO_BE_GRADED);

      // remove old version
      delete occurrences[id];
      const update = show
        ? {
            occurrences: {
              ...occurrences,
              [id]: occurrenceRecord,
            },
            dates: {
              ...dates,
              ...dateRecords,
            },
            instructors: {
              ...instructors,
              ...instructorRecords,
            },
          }
        : {};

      return {
        ...state,
        ...update,
      };
    },
    [DELETE_OCCURRENCE_REQUEST]: (
      state: State,
      action: RequestDeleteOccurrenceAction
    ): State => {
      const { id } = action.payload;
      const { occurrences } = state;
      const record = occurrences[id];
      const update = record.set("isActionPending", true);
      return {
        ...state,
        occurrences: {
          ...occurrences,
          [id]: update,
        },
      };
    },
    [DELETE_OCCURRENCE_SUCCESS]: (
      state: State,
      action: DeleteOccurrenceSuccessAction
    ): State => {
      const { id } = action.payload.occurrence.data;
      let { occurrences, dates, totalOccurrencesCount } = state;
      // don't act directly on object, make copy
      let updatedOccurrences = {
        ...occurrences,
      };
      const deletedOccurrence = updatedOccurrences[id];
      let updatedDates = {
        ...dates,
      };
      for (let i = 0; i < deletedOccurrence.dateIds.length; i++) {
        const date = deletedOccurrence.dateIds[i];
        delete updatedDates[date.id];
      }
      delete updatedOccurrences[id];
      const occurrencesCount = Object.keys(updatedOccurrences).length;

      return {
        ...state,
        occurrences: updatedOccurrences,
        dates: updatedDates,
        occurrencesCount,
        totalOccurrencesCount: totalOccurrencesCount - 1,
      };
    },
    [DELETE_OCCURRENCE_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const { actionParams } = action.payload;
      const { occurrences: prevOccurrences } = state;
      let occurrences = {
        ...prevOccurrences,
      };
      if (actionParams && actionParams.hasOwnProperty("id")) {
        const { id } = actionParams;
        const record = prevOccurrences[id];
        const update = record.set("isActionPending", false);
        occurrences[id] = update;
      }
      return {
        ...state,
        occurrences,
      };
    },
    [ADD_INSTRUCTOR_SUCCESS]: (
      state: State,
      action: InstructorAction
    ): State => {
      const { instructors, occurrences } = state;
      const { instructor, occurrenceId } = action.payload;
      const { id: instructorId } = instructor.data;
      // make instructor immutable record
      const instructorRecord = instructorResponseToRecord(instructor.data);
      // update occurrence record to add new instructor id
      const oldOccurrenceRecord = occurrences[occurrenceId];
      let instructorIds = oldOccurrenceRecord.get("instructorIds");
      instructorIds.push(instructorId);
      const occurrenceRecord = oldOccurrenceRecord.set(
        "instructorIds",
        instructorIds
      );

      return {
        ...state,
        occurrences: {
          ...occurrences,
          [occurrenceId]: occurrenceRecord,
        },
        instructors: {
          ...instructors,
          [instructorId]: instructorRecord,
        },
      };
    },
    [REMOVE_INSTRUCTOR_SUCCESS]: (
      state: State,
      action: RemoveInstructorActionReturn
    ): State => {
      const { occurrences } = state;
      const { instructorId, occurrenceId } = action.payload;
      // update occurrence record to remove instructor id
      const oldOccurrenceRecord = occurrences[occurrenceId];
      let instructorIds = oldOccurrenceRecord.get("instructorIds");
      const removeIndex = instructorIds.indexOf(instructorId);
      if (removeIndex > -1) {
        instructorIds.splice(removeIndex, 1);
      }
      const occurrenceRecord = oldOccurrenceRecord.set(
        "instructorIds",
        instructorIds
      );

      return {
        ...state,
        occurrences: {
          ...occurrences,
          [occurrenceId]: occurrenceRecord,
        },
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => {
      return initialState;
    },
  },
  initialState
);

export default manageCourseOccurrences;
