// @flow
export const round1 = [
  {
    courseId: "813552be-a667-11ec-b89a-0242ac120011",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-nashville-TN-4-07-22",
  },
  {
    courseId: "50372e2a-a5ff-11ec-9f45-02420a000197",
    overrideUrl:
      "https://uscca.swoogo.com/free-self-defense-workshop-idaho-falls-4-07-22",
  },
  {
    courseId: "94958070-a605-11ec-ab68-02420a000261",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-austin-tx-4-07-22",
  },
  {
    courseId: "df3fe006-a606-11ec-8901-02420a000261",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-Rancho-Cucamonga-CA-4-07-22",
  },
  {
    courseId: "52e2fb78-a608-11ec-8a6d-02420a000197",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-schaumburg-IL-4-07-22",
  },
  {
    courseId: "aad1956a-a608-11ec-a232-02420a000261",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-pearl-MS-4-07-22",
  },
  {
    courseId: "ea2fcc54-a608-11ec-8f62-02420a000261",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-fowlerville-MI-4-07-22",
  },
  {
    courseId: "35669fae-a609-11ec-8ac5-02420a000263",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-glen-mills-PA-4-07-22",
  },
  {
    courseId: "724ba87e-a609-11ec-8fbd-02420a000263",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-van-buren-ar-4-07-22",
  },
  {
    courseId: "57d4eb98-a60b-11ec-98f0-02420a000263",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-brownsville-tx-4-07-22",
  },
  {
    courseId: "8f3ed6a2-a60b-11ec-8f17-02420a000261",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-tucson-az-4-07-22",
  },
  {
    courseId: "a5dca73e-a60d-11ec-a642-02420a000294",
    overrideUrl:
      "https://uscca.swoogo.com/self-defense-workshop-omaha-NE-4-07-22",
  },
];
