// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import { getJwtUser } from "shared/utilities/authCookie";
import type { Certification as CertificationType } from "redux/api-types/Certification";
import type { Curriculum as CurriculumType } from "redux/api-types/Curriculum";
import type { CertificationRecordI } from "./CertificationRecord";
import { parseCertifications } from "./parseCertifications";
import type { CurriculumRecordI } from "./CurriculumRecord";
import { parseCurriculi } from "./parseCurriculi";
import { parseActiveCerts } from "./parseActiveCerts";

// actions
const namespace: string = `${APP_NAMESPACE}/certifications`;
export const CERTIFICATIONS_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const CERTIFICATIONS_REQUEST_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const CERTIFICATIONS_REQUEST_ERROR: string = `${namespace}/FETCH/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Flow Types
type CertificationsReturn = {
  data: Array<CertificationType>,
  included: Array<CurriculumType>,
};

type ReceiveCertificationsReturnType = {
  type: string,
  payload: CertificationsReturn,
  meta: {
    receivedAt: number,
  },
};

// action creators
export const requestCertifications = (): simpleReduxAction => ({
  type: CERTIFICATIONS_REQUEST,
});

export const receiveCertifications = (
  json: CertificationsReturnt
): ReceiveCertificationsReturnType => ({
  type: CERTIFICATIONS_REQUEST_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const certificationsRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: CERTIFICATIONS_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async actions
const { userId } = getJwtUser();

export const getCertifications = (
  id: string = userId,
  status?: string
): callApiReturnType => {
  const endpoint = `/api/training/accounts/${id}/certifications?include=curriculum${
    status ? `&filter[status][_eq]=${status}` : ""
  }`;

  return {
    type: CALL_API,
    payload: {
      method: "GET",
      endpoint,
      actions: {
        request: requestCertifications,
        success: receiveCertifications,
        failure: certificationsRequestError,
      },
    },
  };
};

// Initial State
export type State = {
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  receivedAt: number,
  activeCerts: Array<string>,
  certifications: {
    [string]: CertificationRecordI,
  },
  curriculi: {
    [string]: CurriculumRecordI,
  },
  certificateUrl: string,
};

const initialState: State = {
  status: NONE,
  errorMessage: "",
  errors: [],
  receivedAt: 0,
  activeCerts: [],
  certifications: {},
  curriculi: {},
  certificateUrl: "",
};

// Selectors
export const activeCertsSelector = ({
  certifications: { activeCerts },
}: {
  certifications: State,
}): Array<string> => activeCerts;

const certifications = handleActions(
  {
    [CERTIFICATIONS_REQUEST]: (
      state: State,
      action: RequestCertificationsReturnType
    ): State => ({
      ...state,
      status: LOADING,
      errorMessage: "",
      errors: [],
      receivedAt: 0,
      certifications: {},
      curriculi: {},
    }),
    [CERTIFICATIONS_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveCertificationsReturnType
    ): State => {
      const {
        payload,
        meta: { receivedAt },
      } = action;
      const { data, included } = payload;
      const activeCerts = included ? parseActiveCerts(payload) : [];
      const certifications = parseCertifications(data);
      const curriculi = included ? parseCurriculi(included) : {};

      return {
        ...state,
        status: LOADED,
        receivedAt,
        activeCerts,
        certifications,
        curriculi,
      };
    },
    [CERTIFICATIONS_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        status: ERROR,
        errorMessage,
        errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default certifications;
