// @flow
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import {
  LATLNG_REQUEST,
  LATLNG_SUCCESS,
  LATLNG_ERROR,
  CLEAR,
} from "./courseSearchByActions";

// Action Creators
type GetLatLngParams = {
  postal_code?: string,
};

type GetLatLngReturn = {
  type: string,
  payload: GetLatLngParams,
};

export const getLatLng = (params: GetLatLngParams): GetLatLngReturn => ({
  type: LATLNG_REQUEST,
  payload: params,
});

type AddressComponent = {
  long_name: string,
  short_name: string,
  types: Array<string>,
};

type LatLng = {
  lat: number,
  lng: number,
};

type LatLngResult = {
  address_components: Array<AddressComponent>,
  formatted_address: string,
  geometry: {
    bounds: {
      northeast: LatLng,
      southwest: LatLng,
    },
    location: LatLng,
    location_type: string,
    viewport: {
      northeast: LatLng,
      southwest: LatLng,
    },
  },
  place_id: string,
  postal_localities: Array<string>,
  types: Array<string>,
};

type LatLngResponse = {
  results: Array<LatLngResult>,
  status: string,
};

type GetLatLngSuccessReturn = {
  type: string,
  payload: LatLngResponse,
};

export const getLatLngSuccess = (
  response: LatLngResponse
): GetLatLngSuccessReturn => ({
  type: LATLNG_SUCCESS,
  payload: response,
});

export const getLatLngError = (error: apiErrorParams): apiErrorAction => ({
  type: LATLNG_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

export const fetchIt = (url: string): Promise<mixed> => fetch(url);
