// @flow
import { APP_NAMESPACE } from "shared/constants/application";

//Actions
const namespace: string = `${APP_NAMESPACE}/images`;

export const CANCEL_REPLY: string = `${namespace}/REPLY/CANCEL`;

export const CLEAR: string = `${namespace}/CLEAR`;

export const POST_IMAGE_REQUEST: string = `${namespace}/POST_IMAGE/REQUEST`;
export const POST_IMAGE_REQUEST_ERROR: string = `${namespace}/POST_IMAGE/ERROR`;
export const POST_IMAGE_REQUEST_SUCCESS: string = `${namespace}/POST_IMAGE/SUCCESS`;

export const DELETE_IMAGE_REQUEST: stirng = `${namespace}/DELETE_IMAGE/REQUEST`;
export const DELETE_IMAGE_REQUEST_ERROR: stirng = `${namespace}/DELETE_IMAGE/ERROR`;
export const DELETE_IMAGE_REQUEST_SUCCESS: stirng = `${namespace}/DELETE_IMAGE/SUCCESS`;
