// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import type { Registrant as RegistrantType } from "redux/api-types/Registrant";
import {
  parseRegistrantReturnToModel,
  parseRegistrants,
} from "./parseRegistrants";
import {
  ADD_REGISTRANT_SUCCESS,
  REMOVE_REGISTRANT_REQUEST,
  REMOVE_REGISTRANT_SUCCESS,
  REMOVE_REGISTRANT_ERROR,
  EDIT_REGISTRANT_REQUEST,
  EDIT_REGISTRANT_SUCCESS,
  EDIT_REGISTRANT_ERROR,
  RESCHEDULE_REGISTRANT_SUCCESS,
} from "../registrant/registrant";

import type {
  RegistrantAction,
  RequestRemoveRegistrantAction,
  RequestEditRegistrantAction,
  EditRegistrantRequestErrorAction,
} from "../registrant/registrant";
import { UPDATE_SUCCESS as UPDATE_STUDENT_SUCCESS } from "../studentDrawer/studentDrawerActions";
import type { ReceiveUpdatedStudentAction } from "../studentDrawer/studentDrawerActionCreators";
// Actions
const namespace: string = `${APP_NAMESPACE}/registrants`;

export const REGISTRANTS_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const REGISTRANTS_REQUEST_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const REGISTRANTS_REQUEST_ERROR: string = `${namespace}/FETCH/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Flow Types
type RequestRegistrantsParams = {
  occurrenceId: string,
  showDeleted: boolean,
};

type RequestRegistrantsAction = {
  type: string,
  payload: {
    occurrenceId: string,
    showDeleted: boolean,
  },
};

type RegistrantsReturn = {
  data: Array<RegistrantType>,
};

export type ReceiveRegistrantsReturnType = {
  type: string,
  payload: {
    registrants: RegistrantsReturn,
  },
  meta: {
    receivedAt: number,
  },
};

// Action Creators

export const requestRegistrants = ({
  occurrenceId,
  showDeleted,
}: RequestRegistrantsParams): RequestRegistrantsAction => ({
  type: REGISTRANTS_REQUEST,
  payload: {
    occurrenceId,
    showDeleted,
  },
});

export const receiveRegistrants = (
  json: RegistrantsReturn
): ReceiveRegistrantsReturnType => ({
  type: REGISTRANTS_REQUEST_SUCCESS,
  payload: {
    registrants: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const registrantsRequestError = (
  error: apiErrorParams
): apiErrorAction => ({
  type: REGISTRANTS_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async Actions
export const getRegistrants = (
  occurrenceId: string,
  showDeleted: boolean
): callApiReturnType => {
  const filter = showDeleted ? "?filter=deleted" : "";
  const endpoint = `/api/training/occurrences/${occurrenceId}/registrants${filter}`;
  return {
    type: CALL_API,
    payload: {
      method: "GET",
      endpoint,
      actionParams: {
        occurrenceId,
        showDeleted,
      },
      actions: {
        request: requestRegistrants,
        success: receiveRegistrants,
        failure: registrantsRequestError,
      },
    },
  };
};

// Initial State
export type State = {
  occurrenceId: string,
  isShowingDeleted: boolean,
  isLoading: boolean,
  isLoaded: boolean,
  isError: boolean,
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  receivedAt: number,
  registrantsCount: number,
  registrants: {},
  isOpen: boolean,
};

const initialState: State = {
  occurrenceId: "",
  isShowingDeleted: false,
  isLoading: false,
  isLoaded: false,
  isError: false,
  status: NONE,
  errorMessage: "",
  errors: [],
  receivedAt: 0,
  registrantsCount: 0,
  registrants: {},
  isOpen: false,
};

// Reducer
const registrants = handleActions(
  {
    [REGISTRANTS_REQUEST]: (
      state: State,
      action: RequestRegistrantsAction
    ): State => {
      const { occurrenceId: occurrenceId, showDeleted: isShowingDeleted } =
        action.payload;
      return {
        ...state,
        isShowingDeleted,
        occurrenceId,
        isLoading: true,
        isLoaded: false,
        isError: false,
        status: LOADING,
        errorMessage: "",
        errors: [],
        receivedAt: 0,
        registrantsCount: 0,
        registrants: {},
      };
    },
    [REGISTRANTS_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveRegistrantsReturnType
    ): State => {
      const {
        payload: {
          registrants: { data },
        },
        meta: { receivedAt },
      } = action;
      const registrants = parseRegistrants(data);

      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        status: LOADED,
        receivedAt,
        registrantsCount: data.length,
        registrants,
      };
    },
    [REGISTRANTS_REQUEST_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        isLoading: false,
        isError: true,
        status: ERROR,
        errorMessage,
        errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => {
      return initialState;
    },
    [ADD_REGISTRANT_SUCCESS]: (
      state: State,
      action: RegistrantAction
    ): State => {
      const { isShowingDeleted } = state;
      if (isShowingDeleted) {
        return state;
      }

      const {
        registrant: { data },
      } = action.payload;
      let { registrantsCount, registrants } = state;
      registrantsCount++;
      const record = parseRegistrantReturnToModel(data);
      return {
        ...state,
        registrantsCount,
        registrants: {
          ...registrants,
          [record.id]: record,
        },
      };
    },
    [REMOVE_REGISTRANT_REQUEST]: (
      state: State,
      action: RequestRemoveRegistrantAction
    ): State => {
      const { id } = action.payload;
      const { registrants } = state;
      const record = registrants[id];
      const update = record.set("isDeletePending", true);
      return {
        ...state,
        registrants: {
          ...registrants,
          [id]: update,
        },
      };
    },
    [REMOVE_REGISTRANT_SUCCESS]: (
      state: State,
      action: RegistrantAction
    ): State => {
      const {
        registrant: {
          data: { id },
        },
      } = action.payload;
      let { registrants } = state;
      // don't act directly on object, make copy
      registrants = {
        ...registrants,
      };
      delete registrants[id];
      const registrantsCount = Object.keys(registrants).length;

      return {
        ...state,
        registrants: {
          ...registrants,
        },
        registrantsCount,
      };
    },
    [REMOVE_REGISTRANT_ERROR]: (
      state: State,
      action: apiErrorAction
    ): State => {
      const { actionParams } = action.payload;
      let { registrants } = state;
      if (actionParams && actionParams.hasOwnProperty("id")) {
        const { id } = actionParams;
        const record = registrants[id];
        const update = record.set("isDeletePending", false);
        registrants[id] = update;
      }
      return {
        ...state,
        registrants,
      };
    },
    [EDIT_REGISTRANT_REQUEST]: (
      state: State,
      action: RequestEditRegistrantAction
    ): State => {
      const id = action.payload.id;
      const { registrants } = state;
      const record = registrants[id];
      const update = record.set("isEditPending", true);

      return {
        ...state,
        registrants: {
          ...registrants,
          [id]: update,
        },
      };
    },
    [EDIT_REGISTRANT_SUCCESS]: (
      state: State,
      action: RegistrantAction
    ): State => {
      const id = action.payload.data.id;
      const update = parseRegistrantReturnToModel(action.payload.data);
      const { registrants } = state;

      return {
        ...state,
        registrants: {
          ...registrants,
          [id]: update,
        },
      };
    },
    [EDIT_REGISTRANT_ERROR]: (
      state: State,
      action: EditRegistrantRequestErrorAction
    ): State => {
      const id = action.payload.actionParams.id;
      const { registrants } = state;
      const record = registrants[id];
      const update = record.set("isEditPending", false);

      return {
        ...state,
        registrants: {
          ...registrants,
          [id]: update,
        },
      };
    },
    [UPDATE_STUDENT_SUCCESS]: (
      state: State,
      action: ReceiveUpdatedStudentAction
    ): State => {
      const id = action.payload.data.id;
      const { firstName, lastName, phone, email } =
        action.payload.data.attributes;
      const { registrants } = state;
      const registrantId = Object.keys(registrants).find(
        (key: string): boolean => {
          return registrants[key].studentId === id;
        }
      );
      if (registrantId) {
        const record = registrants[registrantId];
        const update = record
          .set("firstName", firstName)
          .set("lastName", lastName)
          .set("phone", phone)
          .set("email", email);
        return {
          ...state,
          registrants: {
            ...registrants,
            [registrantId]: update,
          },
        };
      }
      return {
        ...state,
      };
    },
    [RESCHEDULE_REGISTRANT_SUCCESS]: (
      state: State,
      action: RegistrantAction
    ): State => {
      const id = action.payload.registrant.data.id;
      const { registrants } = state;
      const record = registrants[id];
      const update = record.set("isRescheduled", true);

      return {
        ...state,
        registrants: {
          ...registrants,
          [id]: update,
        },
      };
    },
  },
  initialState
);

export default registrants;
