// @flow

export const queries = {
  educational: `*[_type == "educationalResource"]{
    _id,
    name,
    resourceUrl,
    fileLastUpdated
  }`,
  course: `*[_type == "courseResource"]{
    _id,
    name,
    filetype,
    resourceUrl,
    fileLastUpdated,
    category,
    curriculum->{
      abbreviation
    }
  } | order(category asc, name asc)`,
  state: `*[_type == "stateResource"]{
    _id,
    name,
    filetype,
    resourceUrl,
    fileLastUpdated,
    category,
    stateName
  } | order(category asc, name asc)`,
  marketing: `*[_type == "marketingResource"]{
    _id,
    name,
    filetype,
    resourceUrl,
    fileLastUpdated,
    curriculum->{
      abbreviation
    }
  } | order(name asc)`,
};
