// @flow
import React from "react";
import type { Node } from "react";

import { deleteAuthCookies, loginRedirect } from "shared/utilities/authCookie";
import { intent } from "shared/constants/intent";
import FaAlarmExclamation from "shared/icons/FaAlarmExclamation";
import Dialog from "../Dialog/Dialog";
import Button from "shared/components/Button/Button";
import styles from "./SessionTimeoutDialog.scss";

const { DANGER, SECONDARY } = intent;

const SessionTimeoutDialog = (): Node => {
  const handleClick = () => {
    deleteAuthCookies();
    loginRedirect();
  };

  return (
    <Dialog
      title="Session Expired"
      subhead="Your session has expired."
      Icon={FaAlarmExclamation}
      autoFocus
      enforceFocus
      isOpen
      usePortal
      intent={DANGER}
    >
      <div>
        Click below to log-in and start a new session. We apologize for the
        inconvenience.
      </div>

      <div className={styles.actions}>
        <Button intent={SECONDARY} onClick={handleClick}>
          OK
        </Button>
      </div>
    </Dialog>
  );
};

export default SessionTimeoutDialog;
