// @flow
import type { Course as CourseType } from "redux/api-types/Course";
import type { CourseRecordI } from "./CourseRecord";
import CourseRecord from "./CourseRecord";
import { createCourseUrl } from "shared/utilities/course";

type CoursesReturn = {
  data: Array<CourseRecordI>,
};

export const parseCourseReturnToRecord = (
  course: CourseType
): CourseRecordI => {
  const {
    id,
    attributes: {
      name,
      overrideUrl,
      price,
      location: { name: venue },
      isArchived,
      canDelete,
      canArchive,
      futureOccurrencesCount,
      pastOccurrencesCount,
      isEligibleForInstructorAutomation,
      isInstructorCertifying,
      curriculumId,
    },
  } = course;

  const link = createCourseUrl(id, name);
  const editLink = `/edit-course/${id}`;
  const manageLink = `/manage-course/${id}`;
  const record = new CourseRecord({
    id,
    name,
    overrideUrl,
    link,
    editLink,
    manageLink,
    price,
    venue,
    futureOccurrencesCount,
    pastOccurrencesCount,
    isArchived,
    canDelete,
    canArchive,
    isEligibleForInstructorAutomation,
    isInstructorCertifying,
    curriculumId,
  });

  return record;
};

export const parseCourses = (data: Array<CourseType>): CoursesReturn => {
  let courses = {};
  data.forEach(function (course: CourseType) {
    const record = parseCourseReturnToRecord(course);
    const { id } = course;
    courses[id] = record;
  });

  return courses;
};
