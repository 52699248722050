// @flow
import type { Saga } from "redux-saga";
import { take, put, delay } from "redux-saga/effects";

import { LOGOUT } from "redux/modules/auth.js";
import { deleteAuthCookies } from "shared/utilities/authCookie";

// Sagas
export function* onLogout(): Saga<void> {
  for (;;) {
    yield take(LOGOUT);
    deleteAuthCookies();
    const pathname = "/classes";
    const { APP_URL } = process.env;
    let redirectUrl = APP_URL
      ? `${APP_URL}${pathname}`
      : "https://www.usconcealedcarry.com/";
    yield delay(1000);
    yield put(window.location.replace(redirectUrl));
  }
}
