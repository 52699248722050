// @flow
import { Record } from "immutable";

export type CertificationRecordI = {
  id: string,
  status: string,
  certifiedAt: string,
  expiresAt: Array<string>,
  curriculumId: string,
  activeEndDate: string,
  activeCreditsCount: number,
  requiredCreditsForRenewal: number,
  certificateUrl: string,
};

const CertificationRecord = Record({
  id: "",
  status: "",
  certifiedAt: "",
  expiresAt: "",
  curriculumId: "",
  activeEndDate: "",
  activeCreditsCount: 0,
  requiredCreditsForRenewal: 0,
  certificateUrl: "",
});

export default CertificationRecord;
