// @flow
import validator from "validator";

type valuesType = {
  email?: string,
};

type errorsType = {
  email?: string,
};

const validate = (values: valuesType): errorsType => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  }

  if (values.email && !validator.isEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

export default validate;
