// @flow
import React, { Suspense } from "react";
import type { Node } from "react";

import LargeSpinner from "shared/loading/LargeSpinner";
import styles from "./AsyncComponent.scss";

type Props = {
  LazyComponent: {} | Node,
};

let AsyncLoader = ({ LazyComponent }: Props): Node => (
  <Suspense
    fallback={
      <div className={styles.wrap}>
        <LargeSpinner />
      </div>
    }
  >
    <LazyComponent />
  </Suspense>
);

AsyncLoader.displayName = "AsyncLoader";

export default AsyncLoader;
