// @flow
import { getLocalStorage, setLocalStorage } from "./helpers";
import { RESULTS_PER_PAGE } from "../constants/localStorageKeys";

const DEFAULT_RESULTS_PER_PAGE = 10;

export const getResultsPerPage = (resultsPerPage: number): number => {
  const storedResultsPerPage = parseInt(
    getLocalStorage(RESULTS_PER_PAGE) ?? DEFAULT_RESULTS_PER_PAGE,
    10
  );
  switch (true) {
    case resultsPerPage === undefined:
      return storedResultsPerPage || 0;
    case resultsPerPage === undefined && !storedResultsPerPage:
      return DEFAULT_RESULTS_PER_PAGE;
    // no longer supporting return all to avoid hitting api hard
    // it was never truly supported in the API anyway, just the api would default to a very high number
    case resultsPerPage === "all":
    case storedResultsPerPage === "all":
      setLocalStorage(RESULTS_PER_PAGE, DEFAULT_RESULTS_PER_PAGE);
      return DEFAULT_RESULTS_PER_PAGE;
    case !resultsPerPage && !!storedResultsPerPage:
      return parseInt(storedResultsPerPage, 10);
    case !resultsPerPage && !storedResultsPerPage:
      return DEFAULT_RESULTS_PER_PAGE;
    case !!resultsPerPage:
      return parseInt(resultsPerPage, 10);
  }
};
