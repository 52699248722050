// @flow
import "core-js/stable";
import "regenerator-runtime/runtime";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "Styles/tailwind.css";
import "Styles/style.scss";
import "./favicon.ico";

import React from "react";
import { createRoot } from "react-dom/client";

import { Provider } from "react-redux";
import { FocusStyleManager } from "@blueprintjs/core";
import { createBrowserHistory } from "history";

import initializeSentry from "./initializeSentry";
import configureStore from "redux/configureStore";
import rootSaga from "redux/middleware/sagas";
import Router from "./Router.js";

const { SENTRY_ERROR_TRACKING } = process.env;
const initSentry = SENTRY_ERROR_TRACKING === "true";

if (initSentry) {
  initializeSentry();
}

FocusStyleManager.onlyShowFocusOnTabs();
const history = createBrowserHistory();

const store = configureStore(history);
store.runSaga(rootSaga);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router history={history} />
  </Provider>
);
