// @flow
import type { Saga } from "redux-saga";
import { take, put, delay } from "redux-saga/effects";
import { push } from "connected-react-router";

import { CLONE_COURSE_SUCCESS } from "../instructorCourse/instructorCourse";

// Sagas

export function* onCloneSuccess(): Saga<void> {
  for (;;) {
    const { payload } = yield take(CLONE_COURSE_SUCCESS);
    const id = payload.data.id;
    const url = `/edit-course/${id}`;
    // Delay added so that form's initialFormValues load consistently
    yield delay(300);
    yield put(push(url));
  }
}
