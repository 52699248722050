// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import type { CourseItem } from "redux/api-types/CourseItem";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import { parseItems } from "./itemsParser";

const namespace: string = `${APP_NAMESPACE}/courseItems`;

// Actions
export const ITEMS_REQUEST = `${namespace}/ITEMS/FETCH/REQUEST`;
export const ITEMS_REQUEST_SUCCESS = `${namespace}/ITEMS/FETCH/SUCCESS`;
export const ITEMS_REQUEST_ERROR = `${namespace}/ITEMS/FETCH/ERROR`;
export const CLEAR = `${namespace}/CLEAR`;

// Action Flow Types
export type ItemResponse = {
  data: Array<CourseItem>,
};

export type ReceiveItemsAction = {
  type: string,
  payload: ItemResponse,
  meta: {
    receivedAt: number,
  },
};

// Action Creators
export const requestItems = (): simpleReduxAction => {
  return {
    type: ITEMS_REQUEST,
  };
};

export const receiveItems = (json: ItemResponse): ReceiveItemsAction => {
  return {
    type: ITEMS_REQUEST_SUCCESS,
    payload: json,
    meta: {
      receivedAt: Date.now(),
    },
  };
};

export const requestItemsError = (error: apiErrorParams): apiErrorAction => {
  return {
    type: ITEMS_REQUEST_ERROR,
    error: true,
    payload: {
      errorMessage: undefined,
      errors: [],
      ...error,
    },
    meta: {
      receivedAt: Date.now(),
    },
  };
};

export const clearCourseItems = (): simpleReduxAction => {
  return {
    type: CLEAR,
  };
};

// Async actions
export const getCourseItems = (): callApiReturnType => {
  return {
    type: CALL_API,
    payload: {
      method: "GET",
      endpoint: "/api/training/course-items",
      actions: {
        request: requestItems,
        success: receiveItems,
        failure: requestItemsError,
      },
      toasts: {
        failure: "There was a problem loading class items",
      },
    },
  };
};

// Sagas

// Initial State
export type CourseItemI = {
  id: string,
  name: string,
  category: string,
  hasQuantity: false,
};

export type State = {
  status: string,
  isLoading: boolean,
  isError: boolean,
  errorMessage: ?string,
  errors: apiErrorReturn,
  items: {
    [string]: CourseItemI,
  },
  receivedAt: ?number,
};

const initialState: State = {
  status: NONE,
  isLoading: false,
  isError: false,
  errorMessage: null,
  errors: [],
  items: {},
  receivedAt: null,
};

// Reducer
const courseItems = handleActions(
  {
    [ITEMS_REQUEST]: (state: State, action: simpleReduxAction): State => {
      return {
        ...state,
        status: LOADING,
        isLoading: true,
        isError: false,
        errorMessage: null,
        errors: [],
        items: {},
        receivedAt: null,
      };
    },
    [ITEMS_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveItemsAction
    ): State => {
      const {
        payload: { data },
        meta: { receivedAt },
      } = action;
      const items = parseItems(data);

      return {
        ...state,
        status: LOADED,
        isLoading: false,
        items,
        receivedAt: receivedAt,
      };
    },
    [ITEMS_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;

      return {
        ...state,
        status: ERROR,
        isLoading: false,
        isError: true,
        errorMessage: errorMessage,
        errors: errors,
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => {
      return initialState;
    },
  },
  initialState
);

export default courseItems;
