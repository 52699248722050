// @flow

import { Record } from "immutable";

export type DateRecordInterface = {
  id: string,
  startTime: string,
  endTime: string,
};

const DateRecord = Record({
  id: "",
  startTime: "",
  endTime: "",
});

export default DateRecord;
