// @flow
import { Record } from "immutable";

export type InstructorRecordI = {
  id: string,
  firstName: string,
  lastName: string,
  number: string,
};

const InstructorRecord = Record({
  id: "",
  firstName: "",
  lastName: "",
  number: "",
});

export default InstructorRecord;
