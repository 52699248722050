// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import { APP_NAMESPACE } from "shared/constants/application";
import type {
  simpleReduxAction,
  apiErrorParams,
  apiErrorAction,
  apiErrorReturn,
} from "shared/constants/flowTypes";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";
import { queries } from "./queries";
import type { Result } from "./types";
import type { ResourceRecordType } from "./ResourceRecord";
import { parseResult } from "./parsers";

const namespace: string = `${APP_NAMESPACE}/instructorResources`;

// actions
const SET_TYPE = `${namespace}/SET_TYPE`;
const GET_RESOURCES = `${namespace}/FETCH`;
const RECEIVE_RESOURCES = `${namespace}/RECEIVE`;
const GET_RESOURCES_ERROR = `${namespace}/GET_ERROR`;
const CLEAR = `${namespace}/CLEAR`;

// action creators
type SetTypeReturn = {
  payload: {
    type: string,
  },
} & simpleReduxAction;

export const setType = (type: string): SetTypeReturn => ({
  type: SET_TYPE,
  payload: {
    type,
  },
});

type GetResourceReturn = {
  payload: {
    query: string,
    type: string,
  },
} & simpleReduxAction;

export const getResources = ({
  query,
  type,
}: {
  query: string,
  type: string,
}): GetResourceReturn => ({
  type: GET_RESOURCES,
  payload: {
    query,
    type,
  },
});

type ReceiveResourcesParams = {
  ms: number,
  query: string,
  result: Array<Result>,
};

type ReceiveResourcesReturn = {
  payload: ReceiveResourcesParams,
  meta: {
    receivedAt: number,
  },
} & simpleReduxAction;

export const receiveResources = (
  json: ReceiveResourcesParams
): ReceiveResourcesReturn => ({
  type: RECEIVE_RESOURCES,
  payload: json,
  meta: {
    receivedAt: Date.now(),
  },
});

export const getResourcesError = (error: apiErrorParams): apiErrorAction => ({
  type: GET_RESOURCES_ERROR,
  error: true,
  payload: {
    errorMessage: "",
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async Actions

const { SANITY_API_URL = "" } = process.env;

export const getInstructorResources = (
  type: string
): void | callApiReturnType => {
  const query = queries[type];
  if (!query) {
    return;
  }
  const endpoint = `${SANITY_API_URL}?query=${encodeURIComponent(query)}`;

  return {
    type: CALL_API,
    payload: {
      endpoint,
      actionParams: {
        query,
        type,
      },
      actions: {
        request: getResources,
        success: receiveResources,
        failure: getResourcesError,
      },
    },
  };
};

// reducer
export type State = {
  status: string,
  type: string,
  query: string,
  errorMessage: string,
  errors: apiErrorReturn,
  curriculumCodesToIds: {
    [string]: Array<string>,
  },
  categoriesToIds: {
    [string]: Array<string>,
  },
  resources: {
    [string]: ResourceRecordType,
  },
};

export const initialState = {
  status: NONE,
  type: "",
  query: "",
  errorMessage: "",
  errors: [],
  curriculumCodesToIds: {},
  categoriesToIds: {},
  resources: {},
};

const instructorResources = handleActions(
  {
    [SET_TYPE]: (
      state: State,
      { payload: { type } }: SetTypeReturn
    ): State => ({
      ...state,
      type,
    }),
    [GET_RESOURCES]: (
      state: State,
      { payload: { query, type } }: GetResourceReturn
    ): State => ({
      ...state,
      status: LOADING,
      type,
      query,
      errorMessage: "",
      errors: [],
      categories: {},
      resources: {},
    }),
    [RECEIVE_RESOURCES]: (
      state: State,
      { payload: { result } }: ReceiveResourcesReturn
    ): State => {
      const { curriculumCodesToIds, categoriesToIds, resources } =
        parseResult(result);
      return {
        ...state,
        status: LOADED,
        curriculumCodesToIds,
        categoriesToIds,
        resources,
      };
    },
    [GET_RESOURCES_ERROR]: (
      state: State,
      { payload: { errorMessage, errors } }: apiErrorAction
    ): State => ({
      ...state,
      status: ERROR,
      errorMessage,
      errors,
    }),
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default instructorResources;
