// @flow
import { Record } from "immutable";

export type RegistrantRecordI = {
  id: string,
  studentId: string,
  crmId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: number,
  phoneLink: number,
  status: string,
  notes: string,
  isManual: string,
  chargeUrl: string,
  createdAt: string,
  removedAt: string,
  isLocked: string,
  isDeletePending: boolean,
  isEditPending: boolean,
  isRescheduled: boolean,
  hasCompletedPrerequisiteELearning: boolean,
};

const RegistrantRecord = Record({
  id: "",
  crmId: "",
  studentId: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  phoneLink: "",
  status: "",
  notes: "",
  isManual: "",
  chargeUrl: "",
  createdAt: "",
  removedAt: "",
  isLocked: false,
  isDeletePending: false,
  isEditPending: false,
  isRescheduled: false,
  hasCompletedPrerequisiteELearning: false,
});

export const blankRegistrant = new RegistrantRecord();

export default RegistrantRecord;
