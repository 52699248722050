// @flow
import ReviewRecord from "./ReviewRecord";

import type { Review } from "redux/api-types/Review";
import type { ReviewRecordI } from "./ReviewRecord";

type ParseReviewReturn = {
  reviewRecord: {
    [string]: ReviewRecordI,
  },
};

export const parseReviewsCourseOccurences = (
  review: Review
): ParseReviewReturn => {
  const {
    id,
    attributes: { rating, comment, isPublic: showName, createdAt },
    relationships: {
      occurrence: {
        data: { id: occurrenceId },
      },
    },
  } = review;

  const firstName =
    (review.attributes.reviewer && review.attributes.reviewer.firstName) || "";
  const lastNameInitial =
    (review.attributes.reviewer &&
      review.attributes.reviewer.lastNameInitial) ||
    "";
  const courseReviewId = id;
  let replyId =
    (review.relationships.reply.data && review.relationships.reply.data.id) ||
    "";
  const reviewRecord = new ReviewRecord({
    comment,
    courseReviewId,
    createdAt,
    firstName: showName ? firstName : "",
    lastNameInitial: showName ? lastNameInitial : "",
    occurrenceId,
    rating,
    replyId: replyId,
    reviewId: id,
  });
  return {
    reviewRecord,
  };
};

type ParseReviewsReturn = {
  reviews: {
    [string]: ReviewRecordI,
  },
};

export const parseInstructorReviews = (
  data: Array<Review>
): ParseReviewsReturn => {
  let reviews = {};
  data.forEach(function (review: Review) {
    const { id } = review;
    const { reviewRecord } = parseReviewsCourseOccurences(review);
    reviews[id] = reviewRecord;
  });
  return {
    reviews,
  };
};
