// @flow
import * as Sentry from "@sentry/browser";

const { NODE_ENV } = process.env;

const initializeSentry = () => {
  Sentry.init({
    release: "training-ui@v2.13.39",
    environment: NODE_ENV,
    dsn: "https://c605e6d330384611bfea6370fcd4aae3@sentry.io/1314933",
  });
};

export default initializeSentry;
