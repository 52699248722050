// @flow
import React from "react";
import type { Node } from "react";
import { reduxForm, Field } from "redux-form";
import type { FormProps } from "redux-form";
import { Spinner } from "@blueprintjs/core";

import { STUDENT_REQUEST_COURSE_OCCURRENCE_FORM as FORM } from "redux/modules/studentRequestCourseOccurrence/studentRequestCourseOccurrence.js";
import RenderField from "shared/forms/RenderField";
import RenderTextArea from "shared/forms/RenderTextArea";
import RenderCheckbox from "shared/forms/RenderCheckbox";
import Button from "./Button";
import validate from "./validate";

import styles from "./RequestForm.scss";

type Props = {
  isPending: boolean,
} & FormProps;

let RequestForm = ({ invalid, isPending, handleSubmit }: Props): Node => (
  <form className={styles.form} name={FORM} onSubmit={handleSubmit}>
    <Field
      containerClass={styles.field}
      inputClass={styles.input}
      name="email"
      type="text"
      component={RenderField}
      label="Email*"
      placeholder=""
    />
    <Field
      containerClass={styles.field}
      inputClass={styles.input}
      name="phone"
      type="text"
      component={RenderField}
      label="Phone"
      helperText="Your phone number is optional and will only be used if you consent to be contacted"
      placeholder=""
    />
    <Field
      containerClass={styles.field}
      inputClass={styles.textarea}
      name="comment"
      label="Comments/Desired Date(s)*"
      type="text"
      component={RenderTextArea}
      placeholder=""
    />
    <Field
      containerClass={styles.field}
      name="consentedToContact"
      type="checkbox"
      label={"I'd like to be contacted by the instructor"}
      component={RenderCheckbox}
    />
    <Button type="submit" disabled={invalid || isPending}>
      {isPending ? <Spinner size={Spinner.SIZE_SMALL} /> : <>Submit</>}
    </Button>
  </form>
);

RequestForm.displayName = "RequestForm";

RequestForm = reduxForm({
  form: FORM,
  validate,
  initialValues: {
    email: "",
    phone: "",
    comment: "",
    consentedToContact: true,
  },
})(RequestForm);

export default RequestForm;
