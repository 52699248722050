// @flow
import InteractiveMediaIcon from "shared/icons/InteractiveMediaIcon";
import TeachingInClassroomIcon from "shared/icons/TeachingInClassroomIcon";
import NeoClassicalBuildingOnSignIcon from "shared/icons/NeoClassicalBuildingOnSignIcon";
import PersonOnLoadspeakerIcon from "shared/icons/PersonOnLoadspeakerIcon";

export const EDUCATIONAL = "educational";
export const COURSE = "course";
export const STATE = "state";
export const MARKETING = "marketing";

export const types = [EDUCATIONAL, COURSE, STATE, MARKETING];

export const colorScheme = {
  [EDUCATIONAL]: "leaf",
  [COURSE]: "plum",
  [STATE]: "rust",
  [MARKETING]: "storm",
};

export const icon = {
  [EDUCATIONAL]: InteractiveMediaIcon,
  [COURSE]: TeachingInClassroomIcon,
  [STATE]: NeoClassicalBuildingOnSignIcon,
  [MARKETING]: PersonOnLoadspeakerIcon,
};

export const title = {
  [EDUCATIONAL]: "Guides & Videos",
  [COURSE]: "Course Materials",
  [STATE]: "State Documents",
  [MARKETING]: "Marketing Materials",
};

export const description = {
  [EDUCATIONAL]:
    "Instructor Starter Guide, How to Post Classes & Submit your Class Rosters and more.",
  [COURSE]:
    "Registration form and Liability Waiver, Course Requirements, Quizzes, Certificates and more.",
  [STATE]: "State Supplements, Approvals Letters and Certificates.",
  [MARKETING]: "Logos, Stationary, Handouts and Toolkits",
};
