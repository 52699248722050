// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".shared-utilities-AsyncComponent__wrap--_sEwC{margin:40px;text-align:center;width:100%}", "",{"version":3,"sources":["webpack://./shared/utilities/AsyncComponent.scss"],"names":[],"mappings":"AAAA,8CACE,WAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":[".wrap {\n  margin: 40px;\n  text-align: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "shared-utilities-AsyncComponent__wrap--_sEwC"
};
export default ___CSS_LOADER_EXPORT___;
